/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CategorizedComponent from './CategorizedComponent';
import ComponentFilterRequest from './ComponentFilterRequest';
import ComponentFilterResultCategories from './ComponentFilterResultCategories';
import Property from './Property';
import PropertyEncoding from './PropertyEncoding';
import PropertyMeasure from './PropertyMeasure';
import PropertyUnit from './PropertyUnit';

export default class ComponentFilterResult {
  constructor() {


  }

  static typeDescription() {
    return "ComponentFilterResult";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ComponentFilterResult();
      if (data.hasOwnProperty('request')) {
        obj['request'] = ComponentFilterRequest.constructFromObject(data['request']);
      }
      if (data.hasOwnProperty('results')) {
        obj['results'] = ApiClient.convertToType(data['results'], [CategorizedComponent]);
      }
      if (data.hasOwnProperty('nbMatches')) {
        obj['nbMatches'] = ApiClient.convertToType(data['nbMatches'], 'Number');
      }
      if (data.hasOwnProperty('categories')) {
        obj['categories'] = ApiClient.convertToType(data['categories'], [ComponentFilterResultCategories]);
      }
      if (data.hasOwnProperty('properties')) {
        obj['properties'] = ApiClient.convertToType(data['properties'], [Property]);
      }
      if (data.hasOwnProperty('propertyEncodings')) {
        obj['propertyEncodings'] = ApiClient.convertToType(data['propertyEncodings'], [PropertyEncoding]);
      }
      if (data.hasOwnProperty('propertyMeasures')) {
        obj['propertyMeasures'] = ApiClient.convertToType(data['propertyMeasures'], [PropertyMeasure]);
      }
      if (data.hasOwnProperty('propertyUnits')) {
        obj['propertyUnits'] = ApiClient.convertToType(data['propertyUnits'], [PropertyUnit]);
      }
    }
    return obj;
  }


  request = undefined;

  results = undefined;

  nbMatches = undefined;

  categories = undefined;

  properties = undefined;

  propertyEncodings = undefined;

  propertyMeasures = undefined;

  propertyUnits = undefined;




  getRequest() {
    return this.request;
  }

  setRequest(request) {
    this['request'] = request;
  }

  getResults() {
    return this.results;
  }

  setResults(results) {
    this['results'] = results;
  }

  getNbMatches() {
    return this.nbMatches;
  }

  setNbMatches(nbMatches) {
    this['nbMatches'] = nbMatches;
  }

  getCategories() {
    return this.categories;
  }

  setCategories(categories) {
    this['categories'] = categories;
  }

  getProperties() {
    return this.properties;
  }

  setProperties(properties) {
    this['properties'] = properties;
  }

  getPropertyEncodings() {
    return this.propertyEncodings;
  }

  setPropertyEncodings(propertyEncodings) {
    this['propertyEncodings'] = propertyEncodings;
  }

  getPropertyMeasures() {
    return this.propertyMeasures;
  }

  setPropertyMeasures(propertyMeasures) {
    this['propertyMeasures'] = propertyMeasures;
  }

  getPropertyUnits() {
    return this.propertyUnits;
  }

  setPropertyUnits(propertyUnits) {
    this['propertyUnits'] = propertyUnits;
  }


}