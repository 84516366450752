import { update, remove } from "../utils/updateArray";

const requests = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {

    // return [];
  }if (action.type === "requests/ADD") {
    return update(state, {
      id: action.id,
      status: "pending"
    });
  }
  if (action.type === "requests/DONE") {
    return update(state, {
      id: action.id,
      status: "done"
    });
  }
  if (action.type === "requests/ERR") {
    return update(state, {
      id: action.id,
      status: "err",
      err: action.err
    });
  }
  if (action.type === "requests/REMOVE") {
    return state.filter((r) => r.id !== action.id);
  }
  if (action.type === "requests/REMOVE_ALL") {
    return state.filter((r) => (action.ids || []).indexOf(r.id) < 0);
  }
  return state;
};

export default requests;