/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Timetable from './Timetable';

export default class ResourcesPlanning {
  constructor(timetablesByResourceId) {
    this['timetablesByResourceId'] = timetablesByResourceId || [];


  }

  static typeDescription() {
    return "ResourcesPlanning";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ResourcesPlanning();
      if (data.hasOwnProperty('timetablesByResourceId')) {
        obj['timetablesByResourceId'] = ApiClient.convertToType(data['timetablesByResourceId'], { 'String': Timetable });
      }
    }
    return obj;
  }


  timetablesByResourceId = {};




  getTimetablesByResourceId() {
    return this.timetablesByResourceId;
  }

  setTimetablesByResourceId(timetablesByResourceId) {
    this['timetablesByResourceId'] = timetablesByResourceId;
  }


}