import React from "react";

const icon =
<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 100 100"
  id="wiring"
  style={{
    width: "100%",
    maxHeight: "100%"
  }}>

    <defs>
      <pattern
      href="#wiring_a"
      id="wiring_b"
      patternTransform="rotate(45 -78.45 -62.04) scale(.897)" />

      <pattern
      patternUnits="userSpaceOnUse"
      width="4.38"
      height="4.38"
      patternTransform="translate(-54.23 41.58)"
      id="wiring_a">

        <g transform="translate(54.23 -41.58)" fillRule="evenodd">
          <circle
          r=".57"
          cy="42.68"
          cx="-50.94"
          // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
          overflow="visible"
          // fill="#fff"
          strokeWidth=".1" />

          <circle
          transform="rotate(-90)"
          r=".57"
          cy="-50.94"
          cx="-44.87"
          // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
          overflow="visible"
          // fill="#fff"
          strokeWidth=".1" />

          <circle
          transform="scale(-1)"
          r=".57"
          cy="-42.68"
          cx="53.14"
          // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
          overflow="visible"
          // fill="#fff"
          strokeWidth=".1" />

          <circle
          r=".57"
          cy="-53.14"
          cx="-44.87"
          // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
          transform="rotate(-90)"
          overflow="visible"
          // fill="#fff"
          strokeWidth=".1" />

        </g>
      </pattern>
    </defs>
    <path
    d="M31.33 28.54a.76.76 0 0 0-.78-.78h-6.02a.76.76 0 0 0-.78.78c0 .45.33.78.78.78h6.02c.44 0 .78-.33.78-.78zm-2.23 5.24h-3.01a.76.76 0 0 0-.78.78c0 .45.33.78.78.78h3c.45 0 .79-.33.79-.78a.76.76 0 0 0-.78-.78z"
    // className="st2"
    // fill="#fff"
  />
    <path
    d="M79.71 22.52v-.22a.76.76 0 0 0-.78-.78h-1v-4.35a.76.76 0 0 0-.78-.78h-8.7a.76.76 0 0 0-.78.78v.33l.11 4.02h-1a.76.76 0 0 0-.78.78v.33l.11 15.05v.34h2.79v17.05a26.54 26.54 0 0 0-35-2.12c-.12.12-1.57 1.34-2.57 2.23V38.35h1.22v-.33l1.56-15.17V4.35a.76.76 0 0 0-.78-.78h-1.67V0h-2v3.57H28.2V0h-2v3.57h-1.45V0h-2v3.57h-1.68a.76.76 0 0 0-.78.78v18.4l1.56 14.93v.34c0 .1 0 0 0 0h1.45v35.56c0 7.25 3.01 13.93 7.8 18.73A26.27 26.27 0 0 0 49.83 100c14.38 0 26.2-11.6 26.42-25.98V38.35h3.01v-.45l.45-15.38zM23.75 36.79l-.45-4.57h1.78c.45 0 .78-.34.78-.78a.76.76 0 0 0-.78-.78h-1.9l-.44-4.57h2.57c.44 0 .78-.34.78-.78a.76.76 0 0 0-.78-.78h-2.68l-.22-1.34h10.37l-.12 1.56h-2.45a.76.76 0 0 0-.78.78c0 .45.34.78.78.78h2.23L32 30.88h-1.68a.76.76 0 0 0-.78.78c0 .45.34.78.78.78h1.56l-.44 4.57h-7.7zm7.58 33.67c0-1.67 3.23-7.14 3.34-7.36a18.9 18.9 0 0 1 34.45 10.8 18.89 18.89 0 0 1-18.84 18.84A18.98 18.98 0 0 1 31.33 73.9v-3.45zm43.81 3.45a24.97 24.97 0 0 1-24.97 24.86A25.08 25.08 0 0 1 25.2 73.8V38.35h4.57v35.56a20.4 20.4 0 0 0 20.4 20.3 20.4 20.4 0 0 0 0-40.81c-6.8 0-13.05 3.34-16.84 8.92a30.5 30.5 0 0 0-2 3.68v-8.8c.22-.12 3.23-2.8 3.34-2.9a24.88 24.88 0 0 1 15.5-5.36 24.93 24.93 0 0 1 24.97 24.97zm0-8.8a26.76 26.76 0 0 0-4.68-8.14c.1-.11.1-.23.1-.45V38.35h4.58v26.76zm-7.47-28.32v-13.6h10.48l.22 13.6h-10.7z"
    // className="st2"
    // fill="#fff"
  />
    <path
    d="M49.2 58.4a24 24 0 0 0-4.2-7.3c.1-.1.1-.2.1-.4V34.4h4.1v24zm0 7.9a22.4 22.4 0 0 1-22.4 22.3A22.5 22.5 0 0 1 4.4 66.2V34.4h4.1v31.9a18.3 18.3 0 0 0 18.3 18.2 18.3 18.3 0 0 0 0-36.6c-6.1 0-11.7 3-15.1 8-.1.1-1 1.6-1.8 3.3v-7.9c.2-.1 2.9-2.5 3-2.6 3.9-3.1 8.8-4.8 13.9-4.8 12.4 0 22.4 10 22.4 22.4z"
    fill="url(#wiring_b)"
    transform="translate(20.29) scale(1.11483)" />

  </svg>;


export default icon;