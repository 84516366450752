/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Address from './Address';
import DeliveryParcel from './DeliveryParcel';

export default class Delivery {
  constructor() {


  }

  static typeDescription() {
    return "Delivery";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Delivery();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('ownerName')) {
        obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
      }
      if (data.hasOwnProperty('mode')) {
        obj['mode'] = ApiClient.convertToType(data['mode'], 'String');
      }
      if (data.hasOwnProperty('address')) {
        obj['address'] = Address.constructFromObject(data['address']);
      }
      if (data.hasOwnProperty('service')) {
        obj['service'] = ApiClient.convertToType(data['service'], 'String');
      }
      if (data.hasOwnProperty('origin')) {
        obj['origin'] = ApiClient.convertToType(data['origin'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String');
      }
      if (data.hasOwnProperty('dateSent')) {
        obj['dateSent'] = ApiClient.convertToType(data['dateSent'], 'String');
      }
      if (data.hasOwnProperty('dateReceived')) {
        obj['dateReceived'] = ApiClient.convertToType(data['dateReceived'], 'String');
      }
      if (data.hasOwnProperty('parcels')) {
        obj['parcels'] = ApiClient.convertToType(data['parcels'], [DeliveryParcel]);
      }
      if (data.hasOwnProperty('deliveryNoteFiles')) {
        obj['deliveryNoteFiles'] = ApiClient.convertToType(data['deliveryNoteFiles'], ['String']);
      }
      if (data.hasOwnProperty('commercialInvoiceFiles')) {
        obj['commercialInvoiceFiles'] = ApiClient.convertToType(data['commercialInvoiceFiles'], ['String']);
      }
    }
    return obj;
  }


  id = undefined;

  ownerId = undefined;

  ownerName = undefined;

  mode = undefined;

  address = undefined;

  service = undefined;

  origin = undefined;

  status = undefined;

  dateSent = undefined;

  dateReceived = undefined;

  parcels = undefined;

  deliveryNoteFiles = undefined;

  commercialInvoiceFiles = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getOwnerName() {
    return this.ownerName;
  }

  setOwnerName(ownerName) {
    this['ownerName'] = ownerName;
  }

  getMode() {
    return this.mode;
  }

  setMode(mode) {
    this['mode'] = mode;
  }

  getAddress() {
    return this.address;
  }

  setAddress(address) {
    this['address'] = address;
  }

  getService() {
    return this.service;
  }

  setService(service) {
    this['service'] = service;
  }

  getOrigin() {
    return this.origin;
  }

  setOrigin(origin) {
    this['origin'] = origin;
  }

  getStatus() {
    return this.status;
  }

  setStatus(status) {
    this['status'] = status;
  }

  getDateSent() {
    return this.dateSent;
  }

  setDateSent(dateSent) {
    this['dateSent'] = dateSent;
  }

  getDateReceived() {
    return this.dateReceived;
  }

  setDateReceived(dateReceived) {
    this['dateReceived'] = dateReceived;
  }

  getParcels() {
    return this.parcels;
  }

  setParcels(parcels) {
    this['parcels'] = parcels;
  }

  getDeliveryNoteFiles() {
    return this.deliveryNoteFiles;
  }

  setDeliveryNoteFiles(deliveryNoteFiles) {
    this['deliveryNoteFiles'] = deliveryNoteFiles;
  }

  getCommercialInvoiceFiles() {
    return this.commercialInvoiceFiles;
  }

  setCommercialInvoiceFiles(commercialInvoiceFiles) {
    this['commercialInvoiceFiles'] = commercialInvoiceFiles;
  }


  static ModeEnum = {
    "Transporter": "Transporter",
    "Catch": "Catch"
  };

  static OriginEnum = {
    "customer": "customer",
    "supplier": "supplier",
    "emsfactory": "emsfactory"
  };

  static StatusEnum = {
    "waiting": "waiting",
    "processing": "processing",
    "delivered": "delivered"
  };

}