/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import DailyTimetable from './DailyTimetable';

export default class Timetable {
  constructor(timetablesByDate) {
    this['timetablesByDate'] = timetablesByDate || [];


  }

  static typeDescription() {
    return "Timetable";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Timetable();
      if (data.hasOwnProperty('timetablesByDate')) {
        obj['timetablesByDate'] = ApiClient.convertToType(data['timetablesByDate'], { 'String': DailyTimetable });
      }
    }
    return obj;
  }


  timetablesByDate = {};




  getTimetablesByDate() {
    return this.timetablesByDate;
  }

  setTimetablesByDate(timetablesByDate) {
    this['timetablesByDate'] = timetablesByDate;
  }


}