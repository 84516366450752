import { update, updateIndex } from "../utils/updateArray";

const invoices = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "invoices/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "invoices/SET") {
    return update(state, action.data);
  }
  return state;
};

export default invoices;