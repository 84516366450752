/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ComponentFilterRequestOrdering {
  constructor(propertyName, order) {
    if (propertyName) {
      this['propertyName'] = propertyName;
    }
    if (order) {
      this['order'] = order;
    }


  }

  static typeDescription() {
    return "ComponentFilterRequestOrdering";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ComponentFilterRequestOrdering();
      if (data.hasOwnProperty('propertyName')) {
        obj['propertyName'] = ApiClient.convertToType(data['propertyName'], 'String');
      }
      if (data.hasOwnProperty('order')) {
        obj['order'] = ApiClient.convertToType(data['order'], 'String');
      }
    }
    return obj;
  }


  propertyName = "";

  order = "asc";




  getPropertyName() {
    return this.propertyName;
  }

  setPropertyName(propertyName) {
    this['propertyName'] = propertyName;
  }

  getOrder() {
    return this.order;
  }

  setOrder(order) {
    this['order'] = order;
  }


  static OrderEnum = {
    "asc": "asc",
    "desc": "desc"
  };

}