/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ManufacturingOperator from './ManufacturingOperator';
import ManufacturingWorkstation from './ManufacturingWorkstation';
import Timetable from './Timetable';
import WeeklyTimetable from './WeeklyTimetable';

export default class ManufacturingWorkshop {
  constructor() {


  }

  static typeDescription() {
    return "ManufacturingWorkshop";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingWorkshop();
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('workstationsById')) {
        obj['workstationsById'] = ApiClient.convertToType(data['workstationsById'], { 'String': ManufacturingWorkstation });
      }
      if (data.hasOwnProperty('operatorsById')) {
        obj['operatorsById'] = ApiClient.convertToType(data['operatorsById'], { 'String': ManufacturingOperator });
      }
      if (data.hasOwnProperty('timetablesByName')) {
        obj['timetablesByName'] = ApiClient.convertToType(data['timetablesByName'], { 'String': WeeklyTimetable });
      }
      if (data.hasOwnProperty('defaultTimetableName')) {
        obj['defaultTimetableName'] = ApiClient.convertToType(data['defaultTimetableName'], 'String');
      }
      if (data.hasOwnProperty('timetableAlterationsByName')) {
        obj['timetableAlterationsByName'] = ApiClient.convertToType(data['timetableAlterationsByName'], { 'String': Timetable });
      }
      if (data.hasOwnProperty('resourcesAlterationsByResourceId')) {
        obj['resourcesAlterationsByResourceId'] = ApiClient.convertToType(data['resourcesAlterationsByResourceId'], { 'String': Timetable });
      }
      if (data.hasOwnProperty('resourcesTimetableMapByResourceId')) {
        obj['resourcesTimetableMapByResourceId'] = ApiClient.convertToType(data['resourcesTimetableMapByResourceId'], { 'String': 'String' });
      }
      if (data.hasOwnProperty('resourcesTimetableMapAlterationsByDate')) {
        obj['resourcesTimetableMapAlterationsByDate'] = ApiClient.convertToType(data['resourcesTimetableMapAlterationsByDate'], { 'String': { 'String': 'String' } });
      }
    }
    return obj;
  }


  name = undefined;

  workstationsById = undefined;

  operatorsById = undefined;

  timetablesByName = undefined;

  defaultTimetableName = undefined;

  timetableAlterationsByName = undefined;

  resourcesAlterationsByResourceId = undefined;

  resourcesTimetableMapByResourceId = undefined;

  resourcesTimetableMapAlterationsByDate = undefined;




  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getWorkstationsById() {
    return this.workstationsById;
  }

  setWorkstationsById(workstationsById) {
    this['workstationsById'] = workstationsById;
  }

  getOperatorsById() {
    return this.operatorsById;
  }

  setOperatorsById(operatorsById) {
    this['operatorsById'] = operatorsById;
  }

  getTimetablesByName() {
    return this.timetablesByName;
  }

  setTimetablesByName(timetablesByName) {
    this['timetablesByName'] = timetablesByName;
  }

  getDefaultTimetableName() {
    return this.defaultTimetableName;
  }

  setDefaultTimetableName(defaultTimetableName) {
    this['defaultTimetableName'] = defaultTimetableName;
  }

  getTimetableAlterationsByName() {
    return this.timetableAlterationsByName;
  }

  setTimetableAlterationsByName(timetableAlterationsByName) {
    this['timetableAlterationsByName'] = timetableAlterationsByName;
  }

  getResourcesAlterationsByResourceId() {
    return this.resourcesAlterationsByResourceId;
  }

  setResourcesAlterationsByResourceId(resourcesAlterationsByResourceId) {
    this['resourcesAlterationsByResourceId'] = resourcesAlterationsByResourceId;
  }

  getResourcesTimetableMapByResourceId() {
    return this.resourcesTimetableMapByResourceId;
  }

  setResourcesTimetableMapByResourceId(resourcesTimetableMapByResourceId) {
    this['resourcesTimetableMapByResourceId'] = resourcesTimetableMapByResourceId;
  }

  getResourcesTimetableMapAlterationsByDate() {
    return this.resourcesTimetableMapAlterationsByDate;
  }

  setResourcesTimetableMapAlterationsByDate(resourcesTimetableMapAlterationsByDate) {
    this['resourcesTimetableMapAlterationsByDate'] = resourcesTimetableMapAlterationsByDate;
  }


}