import CartApi from "api/api/CartApi";
import QuoteApi from "api/api/QuoteApi";

import { store } from "initStore";

import usersActions from "Users/actions";
import {
  createSpecActions,
  createQuotationActions } from
"commonTunnel/actions";

const cartApi = new CartApi();
const quoteApi = new QuoteApi();
// const store = getStore()

const actions = {
  createCart: (name) =>
  cartApi.createCart(name).then((res) => {
    return res;
  }),
  getCarts: () =>
  cartApi.getCarts().then((data) => {
    store.dispatch({
      type: "savedCarts/setAll",
      data
    });
    return data;
  }),
  setCart: (data) =>
  store.dispatch({
    type: "savedCarts/set",
    data
  }),
  getCart: (cartId) =>
  cartApi.getCart(cartId).then((data) => {
    actions.setCart(data);
    return data;
  }),
  loadCart: (cartId) =>
  actions.getCart(cartId).then((cart) =>
  usersActions.getUserName(cart.ownerId).then((ownerName) =>
  actions.getCartQuotations(cart.id).then((quotations) => {
    return quoteApi.
    createQuoteSimulation(
      quotations.map((quotation) => {
        return {
          type: quotation.type + "_quotation",
          id: quotation.id
        };
      })
    ).
    then((quoting) =>
    // console.log("quoteApi.createQuoteSimulation()", res)
    Promise.all(
      quotations.map((quotation) =>
      createSpecActions(quotation.type).getSpec(
        quotation.specificationId
      )
      )
    ).
    then((specs) =>
    actions.setCart(
      Object.assign({}, quoting, cart, {
        quotationIds: quotations.map((q) => q.id),
        ownerName
      })
    )
    ).
    then((e) =>
    Promise.all(
      quotations.map((quotation) =>
      createQuotationActions(quotation.type).getQuotation(
        quotation.id
      )
      )
    )
    )
    );
  })
  )
  ),

  saveCart: (cartId, cart) =>
  cartApi.setCart(cartId, cart).then((res) => {
    actions.setCart(res);
    return res;
  }),

  removeCart: (cartId) =>
  cartApi.deleteCart(cartId).then((res) => {
    return actions.getCarts();
  }),

  getCartAccessPermissions: (cartId) =>
  cartApi.getCartAccessPermissions(cartId).then((accessPermissions) =>
  actions.setCart({
    id: cartId,
    accessPermissions
  })
  ),

  addAccessPermission: (cartId, content, opts) =>
  cartApi.
  createCartAccessPermission(cartId, content, opts).
  then((accessPermissions) =>
  actions.setCart({ id: cartId, accessPermissions })
  ),

  // createCartUserAccessPermission: (cartId, userId, content) =>
  //   cartApi.createCartUserAccessPermission(cartId, userId, content).then(res => {
  //     return res;
  //   }),

  updateAccessPermission: (cartId, userId, content = "no_access") =>
  cartApi.
  setCartAccessPermission(cartId, userId, content).
  then((accessPermissions) =>
  actions.setCart({ id: cartId, accessPermissions })
  ),

  removeAccessPermission: (cartId, userId, opts) =>
  cartApi.
  deleteCartAccessPermission(cartId, userId, opts).
  then((accessPermissions) =>
  actions.setCart({ id: cartId, accessPermissions })
  ),

  getCartQuotations: (cartId) =>
  cartApi.getCartQuotations(cartId).then((data) => {
    console.log("savedCarts actions getCartQuotations", data);
    store.dispatch({
      type: "quotations/updateAll",
      data
    });
    return data;
  }),

  //ASSEMBLY
  getCartAssemblyQuotations: (cartId) =>
  cartApi.getCartAssemblyQuotations(cartId).then((res) => {
    return res;
  }),

  appendAssemblyQuotationToCart: (cartId, quotId) =>
  cartApi.appendAssemblyQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeAssemblyQuotationFromCart: (cartId, quotId) =>
  cartApi.removeAssemblyQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //COATING
  getCartCoatingQuotations: (cartId) =>
  cartApi.getCartCoatingQuotations(cartId).then((res) => {
    return res;
  }),

  appendCoatingQuotationToCart: (cartId, quotId) =>
  cartApi.appendCoatingQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeCoatingQuotationFromCart: (cartId, quotId) =>
  cartApi.removeCoatingQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //DFM
  getCartDfmQuotations: (cartId) =>
  cartApi.getCartDfmQuotations(cartId).then((res) => {
    return res;
  }),

  appendDfmQuotationToCart: (cartId, quotId) =>
  cartApi.appendDfmQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeDfmQuotationFromCart: (cartId, quotId) =>
  cartApi.removeDfmQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //ENCLOSURE
  getCartEnclosureQuotations: (cartId) =>
  cartApi.getCartEnclosureQuotations(cartId).then((res) => {
    return res;
  }),

  appendEnclosureQuotationToCart: (cartId, quotId) =>
  cartApi.appendEnclosureQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeEnclosureQuotationFromCart: (cartId, quotId) =>
  cartApi.removeEnclosureQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //ENGINEERING
  getCartEngineeringQuotations: (cartId) =>
  cartApi.getCartEngineeringQuotations(cartId).then((res) => {
    return res;
  }),

  appendEngineeringQuotationToCart: (cartId, quotId) =>
  cartApi.appendEngineeringQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeEngineeringQuotationFromCart: (cartId, quotId) =>
  cartApi.removeEngineeringQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //PCBA
  getCartPcbaQuotations: (cartId) =>
  cartApi.getCartPCBAQuotations(cartId).then((res) => {
    return res;
  }),

  appendPcbaQuotationToCart: (cartId, quotId) =>
  cartApi.appendPCBAQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removePcbaQuotationFromCart: (cartId, quotId) =>
  cartApi.removePcbaQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //PCB
  getCartPcbQuotations: (cartId) =>
  cartApi.getCartPcbQuotations(cartId).then((res) => {
    return res;
  }),

  appendPcbQuotationToCart: (cartId, quotId) =>
  cartApi.appendPcbQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removePcbQuotationFromCart: (cartId, quotId) =>
  cartApi.removePcbQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //PCBDESIGN
  getCartPcbDesignQuotations: (cartId) =>
  cartApi.getCartPcbDesignQuotations(cartId).then((res) => {
    return res;
  }),

  appendPcbDesignQuotationToCart: (cartId, quotId) =>
  cartApi.appendPcbDesignQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removePcbDesignQuotationFromCart: (cartId, quotId) =>
  cartApi.removePcbDesignQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //REWORK
  getCartReworkQuotations: (cartId) =>
  cartApi.getCartReworkQuotations(cartId).then((res) => {
    return res;
  }),

  appendReworkQuotationToCart: (cartId, quotId) =>
  cartApi.appendReworkQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeReworkQuotationFromCart: (cartId, quotId) =>
  cartApi.removeReworkQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //SUPPLY
  getCartSupplyQuotations: (cartId) =>
  cartApi.getCartSupplyQuotations(cartId).then((res) => {
    return res;
  }),

  appendSupplyQuotationToCart: (cartId, quotId) =>
  cartApi.appendSupplyQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeSupplyQuotationFromCart: (cartId, quotId) =>
  cartApi.removeSupplyQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //WIRING
  getCartWiringQuotations: (cartId) =>
  cartApi.getCartWiringQuotations(cartId).then((res) => {
    return res;
  }),

  appendWiringQuotationToCart: (cartId, quotId) =>
  cartApi.appendWiringQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeWiringQuotationFromCart: (cartId, quotId) =>
  cartApi.removeWiringQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  }),

  //XRAY
  getCartXrayQuotations: (cartId) =>
  cartApi.getCartXRayQuotations(cartId).then((res) => {
    return res;
  }),

  appendXrayQuotationToCart: (cartId, quotId) =>
  cartApi.appendXRayQuotationToCart(cartId, quotId).then((res) => {
    return res;
  }),

  removeXrayQuotationFromCart: (cartId, quotId) =>
  cartApi.removeXRayQuotationFromCart(cartId, quotId).then((res) => {
    return res;
  })
};

export default actions;