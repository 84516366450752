/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ManufacturingOperation from './ManufacturingOperation';

export default class ManufacturingProcess {
  constructor(id, type) {
    if (id) {
      this['id'] = id;
    }
    if (type) {
      this['type'] = type;
    }


  }

  static typeDescription() {
    return "ManufacturingProcess";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingProcess();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('isInterruptible')) {
        obj['isInterruptible'] = ApiClient.convertToType(data['isInterruptible'], 'Boolean');
      }
      if (data.hasOwnProperty('subProcesses')) {
        obj['subProcesses'] = ApiClient.convertToType(data['subProcesses'], [ManufacturingProcess]);
      }
      if (data.hasOwnProperty('operations')) {
        obj['operations'] = ApiClient.convertToType(data['operations'], [ManufacturingOperation]);
      }
      if (data.hasOwnProperty('soonestStart')) {
        obj['soonestStart'] = ApiClient.convertToType(data['soonestStart'], 'String');
      }
      if (data.hasOwnProperty('soonestEnd')) {
        obj['soonestEnd'] = ApiClient.convertToType(data['soonestEnd'], 'String');
      }
      if (data.hasOwnProperty('latestStart')) {
        obj['latestStart'] = ApiClient.convertToType(data['latestStart'], 'String');
      }
      if (data.hasOwnProperty('latestEnd')) {
        obj['latestEnd'] = ApiClient.convertToType(data['latestEnd'], 'String');
      }
    }
    return obj;
  }


  id = "";

  type = "";

  name = undefined;

  isInterruptible = undefined;

  subProcesses = undefined;

  operations = undefined;

  soonestStart = undefined;

  soonestEnd = undefined;

  latestStart = undefined;

  latestEnd = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getIsInterruptible() {
    return this.isInterruptible;
  }

  setIsInterruptible(isInterruptible) {
    this['isInterruptible'] = isInterruptible;
  }

  getSubProcesses() {
    return this.subProcesses;
  }

  setSubProcesses(subProcesses) {
    this['subProcesses'] = subProcesses;
  }

  getOperations() {
    return this.operations;
  }

  setOperations(operations) {
    this['operations'] = operations;
  }

  getSoonestStart() {
    return this.soonestStart;
  }

  setSoonestStart(soonestStart) {
    this['soonestStart'] = soonestStart;
  }

  getSoonestEnd() {
    return this.soonestEnd;
  }

  setSoonestEnd(soonestEnd) {
    this['soonestEnd'] = soonestEnd;
  }

  getLatestStart() {
    return this.latestStart;
  }

  setLatestStart(latestStart) {
    this['latestStart'] = latestStart;
  }

  getLatestEnd() {
    return this.latestEnd;
  }

  setLatestEnd(latestEnd) {
    this['latestEnd'] = latestEnd;
  }


}