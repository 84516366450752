/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ObjectReference from './ObjectReference';

export default class CreateEvent {
  constructor(eventClass, title, description, _object, recipients, notificationSubjectFr, notificationContentFr) {
    if (eventClass) {
      this['eventClass'] = eventClass;
    }
    if (title) {
      this['title'] = title;
    }
    if (description) {
      this['description'] = description;
    }
    this['object'] = _object || new ObjectReference();
    this['recipients'] = recipients || [];
    if (notificationSubjectFr) {
      this['notificationSubjectFr'] = notificationSubjectFr;
    }
    if (notificationContentFr) {
      this['notificationContentFr'] = notificationContentFr;
    }


  }

  static typeDescription() {
    return "CreateEvent";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateEvent();
      if (data.hasOwnProperty('eventClass')) {
        obj['eventClass'] = ApiClient.convertToType(data['eventClass'], 'String');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
      if (data.hasOwnProperty('object')) {
        obj['object'] = ObjectReference.constructFromObject(data['object']);
      }
      if (data.hasOwnProperty('recipients')) {
        obj['recipients'] = ApiClient.convertToType(data['recipients'], ['String']);
      }
      if (data.hasOwnProperty('notificationLevel')) {
        obj['notificationLevel'] = ApiClient.convertToType(data['notificationLevel'], 'String');
      }
      if (data.hasOwnProperty('notificationSubjectFr')) {
        obj['notificationSubjectFr'] = ApiClient.convertToType(data['notificationSubjectFr'], 'String');
      }
      if (data.hasOwnProperty('notificationSubjectEn')) {
        obj['notificationSubjectEn'] = ApiClient.convertToType(data['notificationSubjectEn'], 'String');
      }
      if (data.hasOwnProperty('notificationContentFr')) {
        obj['notificationContentFr'] = ApiClient.convertToType(data['notificationContentFr'], 'String');
      }
      if (data.hasOwnProperty('notificationContentEn')) {
        obj['notificationContentEn'] = ApiClient.convertToType(data['notificationContentEn'], 'String');
      }
    }
    return obj;
  }


  eventClass = "Account";

  title = "";

  description = "";

  date = undefined;

  object = undefined;

  recipients = [];

  notificationLevel = undefined;

  notificationSubjectFr = "";

  notificationSubjectEn = undefined;

  notificationContentFr = "";

  notificationContentEn = undefined;




  getEventClass() {
    return this.eventClass;
  }

  setEventClass(eventClass) {
    this['eventClass'] = eventClass;
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this['title'] = title;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }

  getObject() {
    return this.object;
  }

  setObject(_object) {
    this['object'] = _object;
  }

  getRecipients() {
    return this.recipients;
  }

  setRecipients(recipients) {
    this['recipients'] = recipients;
  }

  getNotificationLevel() {
    return this.notificationLevel;
  }

  setNotificationLevel(notificationLevel) {
    this['notificationLevel'] = notificationLevel;
  }

  getNotificationSubjectFr() {
    return this.notificationSubjectFr;
  }

  setNotificationSubjectFr(notificationSubjectFr) {
    this['notificationSubjectFr'] = notificationSubjectFr;
  }

  getNotificationSubjectEn() {
    return this.notificationSubjectEn;
  }

  setNotificationSubjectEn(notificationSubjectEn) {
    this['notificationSubjectEn'] = notificationSubjectEn;
  }

  getNotificationContentFr() {
    return this.notificationContentFr;
  }

  setNotificationContentFr(notificationContentFr) {
    this['notificationContentFr'] = notificationContentFr;
  }

  getNotificationContentEn() {
    return this.notificationContentEn;
  }

  setNotificationContentEn(notificationContentEn) {
    this['notificationContentEn'] = notificationContentEn;
  }


  static EventClassEnum = {
    "Account": "Account",
    "Anomaly": "Anomaly",
    "Financial": "Financial",
    "Order": "Order",
    "Quotation": "Quotation"
  };

  static NotificationLevelEnum = {
    "info": "info",
    "warning": "warning"
  };

}