import { update } from "utils/updateArray";

const quoteIdToReferences = (state = new Map(), action) => {
  if (action.type === "AUTH_LOGOUT") {
    return new Map();
  }
  if (action.type === "auth/user/GET_QUOTATIONS") {
    const quoteIds = (action.data || []).reduce(
      (quoteIdToReferences, quotation) => {
        for (const quoteId of quotation.quoteIds) {
          let value = [
          { id: quotation.id, type: `${quotation.type}_quotation` },
          {
            id: quotation.specificationId,
            type: `${quotation.type}_specification`
          }];

          if (quoteIdToReferences.has(quoteId)) {
            quoteIdToReferences.get(quoteId).push(...value);
          } else {
            quoteIdToReferences.set(quoteId, value);
          }
        }

        return quoteIdToReferences;
      },
      new Map()
    );
    return quoteIds;
  }
  return state;
};

export default quoteIdToReferences;