/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MultiPricingDetails from './MultiPricingDetails';
import MultiPricingDiscounts from './MultiPricingDiscounts';
import MultiPricingTaxes from './MultiPricingTaxes';

export default class MultiPricing {
  constructor() {


  }

  static typeDescription() {
    return "MultiPricing";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MultiPricing();
      if (data.hasOwnProperty('value')) {
        obj['value'] = ApiClient.convertToType(data['value'], 'Number');
      }
      if (data.hasOwnProperty('taxes')) {
        obj['taxes'] = ApiClient.convertToType(data['taxes'], [MultiPricingTaxes]);
      }
      if (data.hasOwnProperty('discounts')) {
        obj['discounts'] = ApiClient.convertToType(data['discounts'], [MultiPricingDiscounts]);
      }
      if (data.hasOwnProperty('progress')) {
        obj['progress'] = ApiClient.convertToType(data['progress'], 'Number');
      }
      if (data.hasOwnProperty('details')) {
        obj['details'] = ApiClient.convertToType(data['details'], [MultiPricingDetails]);
      }
      if (data.hasOwnProperty('isValid')) {
        obj['isValid'] = ApiClient.convertToType(data['isValid'], 'Boolean');
      }
    }
    return obj;
  }


  value = undefined;

  taxes = undefined;

  discounts = undefined;

  progress = undefined;

  details = undefined;

  isValid = undefined;




  getValue() {
    return this.value;
  }

  setValue(value) {
    this['value'] = value;
  }

  getTaxes() {
    return this.taxes;
  }

  setTaxes(taxes) {
    this['taxes'] = taxes;
  }

  getDiscounts() {
    return this.discounts;
  }

  setDiscounts(discounts) {
    this['discounts'] = discounts;
  }

  getProgress() {
    return this.progress;
  }

  setProgress(progress) {
    this['progress'] = progress;
  }

  getDetails() {
    return this.details;
  }

  setDetails(details) {
    this['details'] = details;
  }

  getIsValid() {
    return this.isValid;
  }

  setIsValid(isValid) {
    this['isValid'] = isValid;
  }


}