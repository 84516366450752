import React from "react";
import Section from "marketing/components/Section";

const Stocks = ({ data = {} }) => {
  const { title, text, stocks = [] } = data;
  return stocks.length ?
  <Section className="stocks" title={title}>
      <div className="stocks_text">
        <p>{text}</p>
      </div>
      <div className="stocks_stocks">
        {stocks.map((s, i) => {
        const { image = {}, title } = s;
        return (
          <div className="stocks_stock" key={i}>
              <div className="stock_img">
                <img
                data-src={image.preview}
                alt={image.description}
                className="img-fluid lazyload" />

              </div>
              <span className="stock_title">{title}</span>
            </div>);

      })}
      </div>
    </Section> :
  null;
};

export default Stocks;