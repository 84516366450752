/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ManufacturingResource from './ManufacturingResource';
import ManufacturingWorkstationOperationCapacities from './ManufacturingWorkstationOperationCapacities';

export default class ManufacturingWorkstation {
  constructor(asResource) {
    this['asResource'] = asResource || new ManufacturingResource();


  }

  static typeDescription() {
    return "ManufacturingWorkstation";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingWorkstation();
      if (data.hasOwnProperty('asResource')) {
        obj['asResource'] = ManufacturingResource.constructFromObject(data['asResource']);
      }
      if (data.hasOwnProperty('typeDescription')) {
        obj['typeDescription'] = ApiClient.convertToType(data['typeDescription'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('nbOperators')) {
        obj['nbOperators'] = ApiClient.convertToType(data['nbOperators'], 'Number');
      }
      if (data.hasOwnProperty('lineId')) {
        obj['lineId'] = ApiClient.convertToType(data['lineId'], 'String');
      }
      if (data.hasOwnProperty('operationCapacities')) {
        obj['operationCapacities'] = ApiClient.convertToType(data['operationCapacities'], [ManufacturingWorkstationOperationCapacities]);
      }
    }
    return obj;
  }


  asResource = undefined;

  typeDescription = undefined;

  description = undefined;

  nbOperators = undefined;

  lineId = undefined;

  operationCapacities = undefined;




  getAsResource() {
    return this.asResource;
  }

  setAsResource(asResource) {
    this['asResource'] = asResource;
  }

  getTypeDescription() {
    return this.typeDescription;
  }

  setTypeDescription(typeDescription) {
    this['typeDescription'] = typeDescription;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getNbOperators() {
    return this.nbOperators;
  }

  setNbOperators(nbOperators) {
    this['nbOperators'] = nbOperators;
  }

  getLineId() {
    return this.lineId;
  }

  setLineId(lineId) {
    this['lineId'] = lineId;
  }

  getOperationCapacities() {
    return this.operationCapacities;
  }

  setOperationCapacities(operationCapacities) {
    this['operationCapacities'] = operationCapacities;
  }


  static TypeDescriptionEnum = {
    "aoi": "aoi",
    "cabin": "cabin",
    "cleaning": "cleaning",
    "control_out": "control_out",
    "depanelization": "depanelization",
    "drying": "drying",
    "iled": "iled",
    "jetting": "jetting",
    "methods": "methods",
    "pnp": "pnp",
    "pressfit": "pressfit",
    "purchasing": "purchasing",
    "reception": "reception",
    "reception_control": "reception_control",
    "repair": "repair",
    "rework": "rework",
    "shipping": "shipping",
    "smt_oven": "smt_oven",
    "tht": "tht",
    "xray": "xray"
  };

}