import UserApi from "../api/api/UserApi";
import MiscApi from "api/api/MiscApi";
import anonymousPwd from "../api/anonymous_password";
import { store } from "../initStore";
import requestsActions from "../Requests/actions";
import { getLocal, setLocal, removeLocal } from "../utils/localStorage";
import notificationsActions from "Notifications/actions";

const userApi = new UserApi();
const miscApi = new MiscApi();
// const userApi = withRequests(UserApi)

// const store = getStore()
const { dispatch } = store;

// requests
const addRequest = requestsActions.add;

const actions = {
  // Login
  init: () => {
    const state = store.getState();
    const requests = state.requests || [];
    const request = requests.find((r) => r.id === "auth/init") || {};
    if (request.status === "err" && (request.err || {}).type === "invalid ID") {
      removeLocal("apiKey");
    }
    return addRequest(
      "auth/init",
      new Promise((res, rej) => {
        let apiKey = getLocal("apiKey");
        if (apiKey) {
          userApi.apiClient.authentications.api_key.apiKey = apiKey;
        }
        return (
        apiKey ?
        actions.
        getUser().
        then((data) => res(data)).
        catch((err) => rej(err)) :
        actions.loginAnonymous().then((data) => res(data))).
        then((u) => actions.keepAlive());
      })
    );
  },
  keepAlive: (e) => {
    const now = new Date();
    let endTime = getLocal("keepAliveEndTime") || null;
    let endTimeDate = new Date(endTime);
    let lapse = endTimeDate - now - 1 * 60 * 1000;
    return (
    lapse < 0 ?
    miscApi.keepAlive().then((data) => {
      endTime = data.endTime;
      endTimeDate = new Date(endTime);
      setLocal("keepAliveEndTime", endTimeDate);
    }) :
    Promise.resolve()).
    then((e) => {
      if (window && window.setTimeout) {
        lapse = endTimeDate - now - 1 * 60 * 1000;
        return window.setTimeout(actions.keepAlive, lapse);
      }
    });
  },
  login: (login = "", password = "") => {
    const state = Object.assign({}, store.getState());
    const { auth = {} } = state;
    const { user = {} } = auth;
    const wasAnonymous = user.webLogin ? false : true;

    const oldApiKey = wasAnonymous ?
    userApi.apiClient.authentications.api_key.apiKey || false :
    false;
    let carts = {};
    try {
      carts = JSON.parse(getLocal("cart")) || {};
    } catch (err) {
      console.log("login getCarts err", err);
    }
    const oldCart = carts[oldApiKey] || {};
    console.log(
      "login",
      state,
      auth,
      user,
      wasAnonymous,
      oldApiKey,
      carts,
      oldCart
    );
    return addRequest(
      "auth/login",
      userApi.
      loginUser(
        login,
        password,
        oldApiKey ?
        {
          transferAnonymousSpecifications: oldApiKey
        } :
        {}
      ).
      catch((e) => userApi.loginUser(login, password)).
      then((apiKey) => {
        console.log("login apiKey", apiKey);
        // return (oldApiKey
        //   ? actions
        //       .getQuotations()
        //       .then(quotations => {
        //         console.log("login quotations", quotations);
        //         return Promise.all(
        //           quotations.map(quotation => {
        //             console.log(
        //               "login quotation",
        //               quotation,
        //               productsActions[quotation.type]
        //             );
        //             // TODO: addAccessPermission on user logged to all quotations of user unlogged
        //             return quotations;
        //             return productsActions[quotation.type].addAccessPermission(
        //               quotation.id,
        //               {
        //                 userLogin: login,
        //                 content: "full"
        //               }
        //             );
        //           })
        //         );
        //       })
        //       .then(e => apiKey)
        //   : Promise.resolve(apiKey)
        // )
        //   .catch(e => apiKey)
        //   .then(apiKey => {
        const cart = carts[apiKey.api_key] || {};
        let newCart = Object.assign({}, cart);
        Object.keys(oldCart).forEach(
          (p) => newCart[p] = [...(newCart[p] || []), ...(oldCart[p] || [])]
        );
        carts[apiKey.api_key] = newCart;
        delete carts[oldApiKey];
        console.log("login carts", carts);
        setLocal("cart", JSON.stringify(carts));
        setLocal("apiKey", apiKey.api_key);
        userApi.apiClient.authentications.api_key.apiKey = apiKey.api_key;
        return actions.
        getUser().
        then((e) => notificationsActions.getAll()).
        then((user) => apiKey);
        // });
      })
    );
  },
  setApiKey: (apiKey) => {
    setLocal("apiKey", apiKey);
    return actions.init();
  },
  loginAnonymous: () => {
    return actions.login("anonymous", anonymousPwd).catch((err) => {});
  },
  logout: (e) => {
    return addRequest(
      "auth/logout",
      userApi.
      logoutUser().
      catch((e) => e).
      then((res) => {
        removeLocal("apiKey");
        userApi.apiClient.authentications.api_key.apiKey = false;
        store.dispatch({
          type: "AUTH_LOGOUT"
        });
        actions.loginAnonymous();
        // window.document.location.assign("/");
      })
    );
  },
  userChangeEmail: (email) =>
  addRequest("auth/userChangeEmail", userApi.setUser({ email })).then((e) =>
  actions.getUser()
  ),
  userValidateEmail: (token) =>
  addRequest(
    "auth/userValidateEmail",
    userApi.
    resetValidateEmailToken(token).
    catch((e) => Promise.reject({ type: "expired token" })).
    then((token) => userApi.userEmailValidation(token)).
    then((data) => actions.getUser())
  ),
  userLoginRequest: (email) =>
  addRequest("auth/userLoginRequest", userApi.userLoginRequest(email)),
  userPasswordRequest: (login, email) =>
  addRequest(
    "auth/userPasswordRequest",
    userApi.userPasswordRequest({
      login,
      email
    })
  ),
  resetPassword: (token, pwd) =>
  addRequest("auth/resetPassword", userApi.userPasswordReset(token, pwd)),
  // Global
  // User + Customer
  // User
  getUser: () => {
    // no addRequest because of init action
    return userApi.
    getUser().
    then((data) => {
      store.dispatch({
        type: "USER_GET",
        data
      });
      return data;
    }).
    catch((err) => {
      if (err && ["invalid ID", "invalid API key"].indexOf(err.type) >= 0) {
        actions.logout();
      }
      return new Promise((res, rej) => rej(err));
    });
  },
  setUser: (data) => {
    store.dispatch({
      type: "USER_SET",
      data
    });
  },
  saveUser: (data) => {
    const res = Object.assign({}, data);
    if (res.language) {
      res.language = (res.language + "").toUpperCase();
    }
    return userApi.setUser(res).then((data) => {
      store.dispatch({
        type: "USER_GET",
        data
      });
    });
  },
  // Customer
  getCustomer: () => {
    return userApi.getCustomer().then((data) => {
      store.dispatch({
        type: "AUTH_CUSTOMER_GET",
        data
      });

      actions.getCustomerStocks(data.id);
      return data;
    });
  },
  setCustomer: (data) => {
    store.dispatch({
      type: "AUTH_CUSTOMER_SET",
      data
    });
  },
  saveCustomer: (data = store.getState().auth.customer) => {
    const state = store.getState();
    const { auth = {} } = state;
    const { customer = {} } = auth;
    const { billingAddress = {} } = data;
    const { country } = billingAddress;
    let res = {};
    if (country) {
      res.language = country.toLowerCase() === "fr" ? "FR" : "EN";
    }
    const nData = Object.assign({}, customer, data, res);
    return addRequest(
      "auth/saveCustomer",
      userApi.setCustomer(nData).then((data) => {
        return actions.getCustomer();
      })
    );
  },

  // Collaborators
  createCollaborator: (data) =>
  addRequest("auth/createCollaborator", userApi.createCollaborator(data)),
  completeInvitation: (data) => {
    const { login, password, token } = data;
    return addRequest(
      "auth/completeInvitation",
      userApi.completeCollaboratorInvitation(login, password, token)
    );
  },
  sendInvitation: (userId, email, callbackUrl) =>
  addRequest(
    "auth/sendInvitation/",
    userApi.sendCollaboratorInvitationEmail(userId, { email, callbackUrl })
  ),

  // User Quotations
  getQuotations: (opts) =>
  userApi.getQuotations(opts).then((data) => {
    store.dispatch({
      type: "auth/user/GET_QUOTATIONS",
      data
    });
    return data;
  }),
  getSpecs: (opts) =>
  userApi.getSpecifications(opts).then((data) =>
  store.dispatch({
    type: "auth/user/GET_SPECS",
    data
  })
  ),

  // Anomalies
  getAnomalies: (e) =>
  userApi.getUserAnomalies().then((data) => {
    console.log("getAnomalies", data);
    store.dispatch({ type: "anomalies/ADD_ALL", data });
    return data;
  }),
  getCustomerAnomalies: (e) =>
  userApi.getCustomerAnomalies().then((data) => {
    console.log("getAnomalies", data);
    store.dispatch({ type: "anomalies/ADD_ALL", data });
    return data;
  }),
  // PaymentSurcharge
  getSurcharge: (quotations) =>
  userApi.getQuotationsPaymentSurcharges(quotations),

  // CutomerStocks
  getCustomerStocks: (customerId) => {
    userApi.
    getCustomersStocks({
      customerId,
      withAvailableStocks: false,
      excludeInactiveLocations: false
    }).
    then((customerStocks) => {
      const { stocks = [] } = customerStocks[0];

      const haveSupply =
      stocks.find(
        (stock) => "supply_quotation" === stock.location.quotationType
      ) !== undefined;
      store.dispatch({
        type: "AUTH_CUSTOMER_STOCKS_GET",
        data: {
          haveSupply,
          stocks
        }
      });
    }).
    catch((err) => {
      store.dispatch({
        type: "AUTH_CUSTOMER_STOCKS_GET",
        data: {
          haveSupply: false,
          stocks: []
        }
      });
    });
  }
};

export default actions;