import { update } from "../utils/updateArray";

const payments = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "payments/SET_ALL") {
    let nState = [...state];
    action.data.forEach((p) => nState = update(nState, p));
    return nState;
  }
  if (action.type === "payments/SET") {
    let nState = [...state];
    nState = update(nState, action.data);
    return nState;
  }
  return state;
};

export default payments;