/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import OrderInProductionQuotations from './OrderInProductionQuotations';

export default class OrderInProduction {
  constructor() {


  }

  static typeDescription() {
    return "OrderInProduction";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderInProduction();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('validationDate')) {
        obj['validationDate'] = ApiClient.convertToType(data['validationDate'], 'String');
      }
      if (data.hasOwnProperty('productionStartDay')) {
        obj['productionStartDay'] = ApiClient.convertToType(data['productionStartDay'], 'Date');
      }
      if (data.hasOwnProperty('finalCustomerName')) {
        obj['finalCustomerName'] = ApiClient.convertToType(data['finalCustomerName'], 'String');
      }
      if (data.hasOwnProperty('finalCustomerId')) {
        obj['finalCustomerId'] = ApiClient.convertToType(data['finalCustomerId'], 'String');
      }
      if (data.hasOwnProperty('quotations')) {
        obj['quotations'] = ApiClient.convertToType(data['quotations'], [OrderInProductionQuotations]);
      }
    }
    return obj;
  }


  id = undefined;

  reference = undefined;

  validationDate = undefined;

  productionStartDay = undefined;

  finalCustomerName = undefined;

  finalCustomerId = undefined;

  quotations = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getValidationDate() {
    return this.validationDate;
  }

  setValidationDate(validationDate) {
    this['validationDate'] = validationDate;
  }

  getProductionStartDay() {
    return this.productionStartDay;
  }

  setProductionStartDay(productionStartDay) {
    this['productionStartDay'] = productionStartDay;
  }

  getFinalCustomerName() {
    return this.finalCustomerName;
  }

  setFinalCustomerName(finalCustomerName) {
    this['finalCustomerName'] = finalCustomerName;
  }

  getFinalCustomerId() {
    return this.finalCustomerId;
  }

  setFinalCustomerId(finalCustomerId) {
    this['finalCustomerId'] = finalCustomerId;
  }

  getQuotations() {
    return this.quotations;
  }

  setQuotations(quotations) {
    this['quotations'] = quotations;
  }


}