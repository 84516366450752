/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PaymentSurcharge {
  constructor(mode, amount) {
    if (mode) {
      this['mode'] = mode;
    }
    if (amount) {
      this['amount'] = amount;
    }


  }

  static typeDescription() {
    return "PaymentSurcharge";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PaymentSurcharge();
      if (data.hasOwnProperty('mode')) {
        obj['mode'] = ApiClient.convertToType(data['mode'], 'String');
      }
      if (data.hasOwnProperty('amount')) {
        obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
      }
    }
    return obj;
  }


  mode = "cb";

  amount = 0;




  getMode() {
    return this.mode;
  }

  setMode(mode) {
    this['mode'] = mode;
  }

  getAmount() {
    return this.amount;
  }

  setAmount(amount) {
    this['amount'] = amount;
  }


  static ModeEnum = {
    "cb": "cb",
    "paypal": "paypal",
    "cash": "cash",
    "cheque": "cheque",
    "transfer": "transfer",
    "account": "account"
  };

}