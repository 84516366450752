import { updateMultiple } from "utils/updateArray";

const quotationCustomerDeliveryContent = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "quotationCustomerDeliveryContent/SET") {
    console.log("setQuotationCustomerDeliveryContent action", action);
    return updateMultiple(
      state,
      (action.data || []).map((c) =>
      Object.assign(
        { id: [c.quotationType, c.quotationId, c.description].join(":") },
        c
      )
      )
    );
  }
  return state;
};

export default quotationCustomerDeliveryContent;