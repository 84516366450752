import { update, updateMultiple } from "utils/updateArray";

const deliveries = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "deliveries/SET_ALL") {
    return updateMultiple(state, action.data);
    // return [...action.data];
  }
  if (action.type === "deliveries/SET") {
    return update(state, action.data);
  }
  if (action.type === "deliveries/REMOVE") {
    const index = state.findIndex((delivery) => delivery.id === action.data);
    if (index < 0) {
      return state;
    }
    return [...state.slice(0, index), ...state.slice(index + 1)];
  }
  return state;
};

export default deliveries;