/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class UserNotificationMedia {
  constructor(eventClass, media) {
    if (eventClass) {
      this['eventClass'] = eventClass;
    }
    this['media'] = media || [];


  }

  static typeDescription() {
    return "UserNotificationMedia";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserNotificationMedia();
      if (data.hasOwnProperty('eventClass')) {
        obj['eventClass'] = ApiClient.convertToType(data['eventClass'], 'String');
      }
      if (data.hasOwnProperty('media')) {
        obj['media'] = ApiClient.convertToType(data['media'], ['String']);
      }
    }
    return obj;
  }


  eventClass = "Account";

  media = "";




  getEventClass() {
    return this.eventClass;
  }

  setEventClass(eventClass) {
    this['eventClass'] = eventClass;
  }

  getMedia() {
    return this.media;
  }

  setMedia(media) {
    this['media'] = media;
  }


  static EventClassEnum = {
    "Account": "Account",
    "Anomaly": "Anomaly",
    "Financial": "Financial",
    "Order": "Order"
  };

  static MediaEnum = {
    "Email": "Email",
    "SMS": "SMS"
  };

}