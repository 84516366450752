import React from "react";
import Content from "components/ContentEditor/Content";
import { Image } from "Elements";

const EngineeringTool = ({ data }) => {
  const { title, image, text, button } = data;
  return (
    <section className="marketing_section" id="">
      <div className="container">
        <h2 className="marketing_section__title">{title}</h2>
        <div className="row">
          <div className="col-8 engineeringtools_img">
            <Image image={image} />
          </div>
          <div className="col-4 product_page_cover_intro">
            <Content blocks={text} />

            <span className="mr-3 d-none d-lg-inline-block">
              <a
                className="btn btn-outline-primary"
                href="/engineering/start?tour=1">

                <span>{button}</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </section>);

};

export default EngineeringTool;