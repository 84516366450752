import request from "superagent";
import UserApi from "api/api/UserApi";
import { store } from "initStore";

const userApi = new UserApi();
let calls = [];

export const api = {
  getOpts: () => {
    const apiClient = userApi.apiClient || {};
    const authentications = apiClient.authentications || {};
    const apiKey = authentications.api_key.apiKey;
    const basePath = apiClient.basePath;
    return { apiKey, basePath };
  },
  get: (path, opts = {}) => {
    const callKey = ["get", path, opts.query].filter((s) => s).join(" ");
    if (calls[callKey] && !opts.noRecycle) {
      return calls[callKey];
    }
    const { apiKey, basePath } = api.getOpts();
    let req = request.get(basePath + path).set("api_key", apiKey);
    if (opts.file) {
      req = req.responseType("blob");
    }
    if (opts.query) {
      req = req.query(opts.query);
    }
    calls[callKey] = req.
    catch((err) => {
      delete calls[callKey];
      return Promise.reject(err);
    }).
    then((r) => {
      delete calls[callKey];
      return r.body;
    });
    return calls[callKey];
  },
  post: (path, data = {}, opts = {}) => {
    const callKey = ["post", path, opts.query].filter((s) => s).join(" ");
    if (calls[callKey] && !opts.noRecycle) {
      return calls[callKey];
    }
    const { apiKey, basePath } = api.getOpts();
    let req = request.
    post(basePath + path).
    timeout({
      deadline: opts.timeout || 60 * 1e3
    }).
    send(data).
    set("api_key", apiKey);
    if (opts.query) {
      req = req.query(opts.query);
    }
    req = req.then((res) => res.body);
    calls[callKey] = req;
    return req.
    catch((err) => {
      delete calls[callKey];
      return Promise.reject(err);
    }).
    then((r) => {
      delete calls[callKey];
      return r;
    });
  },
  put: (path, data = {}, opts = {}) => {
    const callKey = ["put", path, opts.query].filter((s) => s).join(" ");
    if (calls[callKey] && !opts.noRecycle) {
      return calls[callKey];
    }
    const { apiKey, basePath } = api.getOpts();
    let req = request.
    put(basePath + path).
    send(data).
    set("api_key", apiKey);
    if (opts.query) {
      req = req.query(opts.query);
    }
    req = req.then((res) => res.body);
    calls[callKey] = req;
    return req.
    catch((err) => {
      delete calls[callKey];
      return Promise.reject(err);
    }).
    then((r) => {
      delete calls[callKey];
      return r;
    });
  },
  delete: (path, opts = {}) => {
    const callKey = ["delete", path, opts.query].filter((s) => s).join(" ");
    if (calls[callKey] && !opts.noRecycle) {
      return calls[callKey];
    }
    const { apiKey, basePath } = api.getOpts();
    const req = request.del(basePath + path).set("api_key", apiKey);
    calls[callKey] = req;
    return req.
    catch((err) => {
      delete calls[callKey];
      return Promise.reject(err);
    }).
    then((res) => {
      delete calls[callKey];
      return res.body;
    });
  }
};

export const createSpecActions = (slug) => {
  const path = "/" + slug + "/spec";
  const actions = {
    getAll: () => {
      return api.get(path).then((data) => {
        store.dispatch({
          type: slug + "/specs/SET_ALL",
          data
        });
        return data;
      });
    },
    getSpec: (specId) => {
      return api.get(path + "/" + specId).then((spec) => actions.setSpec(spec));
    },
    setSpec: (data) => {
      store.dispatch({
        type: slug + "/specs/SET",
        data
      });
      return data;
    },
    createSpec: (spec) => {
      return api.post(path, spec).then((spec) => actions.setSpec(spec));
    },
    saveSpec: (spec) => {
      return api.
      put(path + "/" + spec.id, spec).
      then((spec) => actions.setSpec(spec));
    },
    duplicate: (specId) =>
    api.post(path + "/" + specId).then((spec) => actions.setSpec(spec)),
    delete: (id) => {
      console.log("delete", slug, id);
      return api.delete(path + "/" + id).then((e) =>
      store.dispatch({
        type: slug + "/specs/REMOVE",
        id
      })
      );
    },
    downloadSpec: (id, filename = id) =>
    api.get(path + "/" + id + "/download", { file: true }).then((data) => {
      console.log("download spec", slug, id, data);
      const a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = filename + ".zip";
      a.click();
      window.URL.revokeObjectURL(a.href);
    }),
    createSpecFromTemplate: (templateId) =>
    userApi.
    createSpecificationFromTemplate({
      type: slug,
      id: templateId
    }).
    then((spec) => actions.setSpec(spec)),
    getSpecAnomalies: (specId) =>
    api.
    get(path + "/" + specId + "/anomaly").
    then((anomalies) =>
    store.dispatch({
      type: slug + "/anomalies/ADD_ALL",
      data: anomalies
    })
    ).
    catch((err) => console.log("getSpecAnomalies", specId, err))
  };
  return actions;
};

export const createQuotationActions = (slug) => {
  const path = "/" + slug + "/quotation";
  const specPath = (specId) => "/" + slug + "/spec/" + specId + "/quotation";
  const actions = {
    createQuotation: (specId, data = {}) => {
      const state = store.getState();
      const { auth = {} } = state;
      const { user = {} } = auth;
      const { deliveryAddresses = [] } = user;
      const defaultDeliveryAddress =
      deliveryAddresses[user.defaultDeliveryAddressIndex];
      let defaultQuotation = {};
      if (defaultDeliveryAddress) {
        defaultQuotation.deliveryMode = "Transporter";
        defaultQuotation.deliveryAddress = defaultDeliveryAddress;
      }
      if (slug === "pcba") {
        data.delayDays = data.delayDays || 12;
        data.pcbaQuantity = data.pcbaQuantity || 1;
      }
      return api.
      post(specPath(specId), Object.assign(defaultQuotation, data)).
      then((data) => actions.setQuotation(data));
    },
    getSpecQuotations: (specId) =>
    api.get(specPath(specId)).then((quotations) => {
      store.dispatch({
        type: slug + "/quotations/SET_MULTIPLE",
        data: quotations
      });
      return quotations;
    }),
    getQuotation: (id) => {
      return !id ?
      Promise.reject() :
      api.get(path + "/" + id).then((data) => actions.setQuotation(data));
    },
    setQuotation: (data) => {
      store.dispatch({
        type: slug + "/quotations/SET",
        data
      });
      return data;
    },
    saveQuotation: (quotationId = data.id, data = {}) => {
      let nData = Object.assign({}, data);
      delete nData.components;
      delete nData.durationQuantities;
      delete nData.accessPermissions;
      delete nData.delay;
      delete nData.quantity;
      return api.
      put(path + "/" + quotationId, data).
      then((data) => actions.setQuotation(data));
    },
    // getQuotationAnomalies: quotationId =>
    //   api.get(path + "/" + quotationId + "/anomaly"),
    duplicateQuotation: (quotationId, noSpecDuplication = false) =>
    api.
    post(path + "/" + quotationId, null, {
      query: { noSpecificationDuplication: noSpecDuplication }
    }).
    then((data) => actions.setQuotation(data)),
    downloadQuotation: (id, filename = id) =>
    api.get(path + "/" + id + "/download", { file: true }).then((data) => {
      const a = window.document.createElement("a");
      // a.style = "display: none"
      a.href = window.URL.createObjectURL(data);
      a.download = filename + ".zip";
      // window.document.body.appendChild(a)
      a.click();
      window.URL.revokeObjectURL(a.href);
    }),
    // // accessPermissions
    getAccessPermissions: (quotationId) =>
    api.
    get(path + "/" + quotationId + "/accessPermission").
    then((accessPermissions) => {
      console.log("accessPermissions", accessPermissions);
      return actions.setQuotation({ id: quotationId, accessPermissions });
    }),
    addAccessPermission: (quotationId, opts) =>
    api.
    post(path + "/" + quotationId + "/accessPermission", "read_only", {
      query: {
        user_login: opts.userLogin,
        email: opts.email
      }
    }).
    then((accessPermissions) =>
    actions.setQuotation({ id: quotationId, accessPermissions })
    ),
    updateAccessPermission: (quotationId, userId, content = "no_access") =>
    api.
    put(path + "/" + quotationId + "/accessPermission/" + userId, content).
    then((accessPermissions) =>
    actions.setQuotation({ id: quotationId, accessPermissions })
    ),
    deleteAccessPermission: (quotationId, userId) =>
    api.
    delete(path + "/" + quotationId + "/accessPermission/" + userId).
    then((accessPermissions) =>
    actions.setQuotation({ id: quotationId, accessPermissions })
    ),
    getQuotationAnomalies: (id) =>
    api.get(path + "/" + id + "/anomaly").then((anomalies) => {
      const state = store.getState();
      const typeState = state[slug] || {};
      const { quotations = [] } = typeState;
      const quotation =
      quotations.find((quotation) => quotation.id === id) || {};

      const { anomalies: stateAnomalies = [] } = state;
      const quotationAnomalies = stateAnomalies.filter((anomaly) => {
        const { objects = [] } = anomaly;
        return objects.find(
          (o) =>
          quotation.specId === o.id &&
          o.type === slug + "_specification" ||
          quotation.id === o.id && o.type === slug + "_quotation"
        );
      });

      const removedAnomalies = quotationAnomalies.filter(
        (anomaly) => !anomalies.find((value) => anomaly.id === value.id)
      );
      if (removedAnomalies.length > 0) {
        store.dispatch({
          type: "anomalies/REMOVE_ALL",
          data: removedAnomalies
        });
      }

      store.dispatch({
        type: "anomalies/ADD_ALL",
        data: anomalies
      });
      return anomalies;
    }),
    getQuotationCustomerDeliveryContent: (id) =>
    api.
    get(path + "/" + id + "/customerDeliveryContent", { noRecycle: true }).
    then((content) =>
    actions.setQuotationCustomerDeliveryContent(content)
    ),
    setQuotationCustomerDeliveryContent: (data) => {
      store.dispatch({
        type: "quotationCustomerDeliveryContent/SET",
        data
      });
      return data;
    }
  };
  return actions;
};

export const createBomActions = (slug) => {
  const actions = {};
  return actions;
};

// Files
// export const createFileActions = (
//   slug,
//   object,
//   path = "file",
//   hasType,
//   hasName
// ) => {
//   path = path || "file";
//   const fileTypeName =
//     path
//       .split("/")
//       .map((w) => w[0].toUpperCase() + w.slice(1))
//       .join("") +
//     "In" +
//     slug[0].toUpperCase() +
//     slug.slice(1) +
//     object[0].toUpperCase() +
//     object.slice(1);
//   const actionsPath = "/" + [slug, object].filter((e) => e).join("/");
//   let res = {};
//   res["create" + fileTypeName] = function (id, b, c, d) {
//     const file = hasType ? (hasName ? d : c) : b;
//     const name = hasName ? (hasType ? c : b) : false;
//     const type = hasType ? b : false;
//     const fnPath = [actionsPath, id, path, type, name]
//       .filter((e) => e)
//       .join("/");
//     return convertFileToUpload(file).then((content) => {
//       return api.post(fnPath, content, { timeout: 3 * 60 * 60 * 1e3 });
//     });
//   };
//   res["get" + fileTypeName] = function (id, b, c) {
//     const name = hasName ? (hasType ? c : b) : false;
//     const type = hasType ? b : false;
//     const fnPath = [actionsPath, id, path, type, name]
//       .filter((e) => e)
//       .join("/");
//     return api.get(fnPath, { file: true });
//   };
//   res["update" + fileTypeName] = function (id, b, c, d) {
//     const file = hasType ? (hasName ? d : c) : b;
//     const name = hasName ? (hasType ? c : b) : false;
//     const type = hasType ? b : false;
//     const fnPath = [actionsPath, id, path, type, name]
//       .filter((e) => e)
//       .join("/");
//     return convertFileToUpload(file).then((content) => {
//       return api.put(fnPath, content, { timeout: 3 * 60 * 60 * 1e3 });
//     });
//   };
//   res["delete" + fileTypeName] = function (id, b, c) {
//     const name = hasName ? (hasType ? c : b) : false;
//     const type = hasType ? b : false;
//     const fnPath = [actionsPath, id, path, type, name]
//       .filter((e) => e)
//       .join("/");
//     return api.delete(fnPath);
//   };

//   return res;
// };

// const convertFileToUpload = (file, type) => {
//   const reader = new FileReader();
//   if (type === "base64") {
//     reader.readAsDataURL(file);
//   } else {
//     reader.readAsArrayBuffer(file);
//   }
//   return new Promise((res, rej) => {
//     reader.onload = () => {
//       try {
//         if (type === "base64") {
//           const index = reader.result.indexOf(";base64,");
//           const content = reader.result.slice(index + 8);
//           return res(content);
//         }
//         // const index = reader.result.indexOf(';base64,')
//         // const content = reader.result.slice(index + 8)
//         return res(reader.result);
//       } catch (err) {
//         return rej(err);
//       }
//     };
//   });
// };