/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ObjectReference from './ObjectReference';

export default class Quoting {
  constructor() {


  }

  static typeDescription() {
    return "Quoting";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Quoting();
      if (data.hasOwnProperty('customerId')) {
        obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
      }
      if (data.hasOwnProperty('quotations')) {
        obj['quotations'] = ApiClient.convertToType(data['quotations'], [ObjectReference]);
      }
      if (data.hasOwnProperty('totalPriceTaxesExcluded')) {
        obj['totalPriceTaxesExcluded'] = ApiClient.convertToType(data['totalPriceTaxesExcluded'], 'Number');
      }
      if (data.hasOwnProperty('totalPriceTaxesIncluded')) {
        obj['totalPriceTaxesIncluded'] = ApiClient.convertToType(data['totalPriceTaxesIncluded'], 'Number');
      }
      if (data.hasOwnProperty('couldBeOrdered')) {
        obj['couldBeOrdered'] = ApiClient.convertToType(data['couldBeOrdered'], 'Boolean');
      }
    }
    return obj;
  }


  customerId = undefined;

  quotations = undefined;

  totalPriceTaxesExcluded = undefined;

  totalPriceTaxesIncluded = undefined;

  couldBeOrdered = undefined;




  getCustomerId() {
    return this.customerId;
  }

  setCustomerId(customerId) {
    this['customerId'] = customerId;
  }

  getQuotations() {
    return this.quotations;
  }

  setQuotations(quotations) {
    this['quotations'] = quotations;
  }

  getTotalPriceTaxesExcluded() {
    return this.totalPriceTaxesExcluded;
  }

  setTotalPriceTaxesExcluded(totalPriceTaxesExcluded) {
    this['totalPriceTaxesExcluded'] = totalPriceTaxesExcluded;
  }

  getTotalPriceTaxesIncluded() {
    return this.totalPriceTaxesIncluded;
  }

  setTotalPriceTaxesIncluded(totalPriceTaxesIncluded) {
    this['totalPriceTaxesIncluded'] = totalPriceTaxesIncluded;
  }

  getCouldBeOrdered() {
    return this.couldBeOrdered;
  }

  setCouldBeOrdered(couldBeOrdered) {
    this['couldBeOrdered'] = couldBeOrdered;
  }


}