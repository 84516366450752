/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ComponentReference {
  constructor() {


  }

  static typeDescription() {
    return "ComponentReference";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ComponentReference();
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('origin')) {
        obj['origin'] = ApiClient.convertToType(data['origin'], 'String');
      }
    }
    return obj;
  }


  reference = undefined;

  origin = undefined;




  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getOrigin() {
    return this.origin;
  }

  setOrigin(origin) {
    this['origin'] = origin;
  }


}