/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Sourceable from './Sourceable';

export default class SpecificationItem {
  constructor(asSourceable, id, type) {
    this['asSourceable'] = asSourceable || new Sourceable();
    if (id) {
      this['id'] = id;
    }
    if (type) {
      this['type'] = type;
    }


  }

  static typeDescription() {
    return "SpecificationItem";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SpecificationItem();
      if (data.hasOwnProperty('asSourceable')) {
        obj['asSourceable'] = Sourceable.constructFromObject(data['asSourceable']);
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('designator')) {
        obj['designator'] = ApiClient.convertToType(data['designator'], 'String');
      }
    }
    return obj;
  }


  asSourceable = undefined;

  id = undefined;

  type = "";

  name = undefined;

  designator = undefined;




  getAsSourceable() {
    return this.asSourceable;
  }

  setAsSourceable(asSourceable) {
    this['asSourceable'] = asSourceable;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getDesignator() {
    return this.designator;
  }

  setDesignator(designator) {
    this['designator'] = designator;
  }


}