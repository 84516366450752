/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CreateEvent from '../model/CreateEvent';
import Event from '../model/Event';
import Notification from '../model/Notification';
import TrackedQuotation from '../model/TrackedQuotation';
import TrackingInfos from '../model/TrackingInfos';


export default class NotificationApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  createEventWithHttpInfo(createEvent) {
    let postBody = createEvent;

    // verify the required parameter 'createEvent' is set
    if (createEvent === undefined || createEvent === null) {
      throw new Error("Missing the required parameter 'createEvent' when calling createEvent");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Event;

    return this.apiClient.callApi(
      '/notification/', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createEvent(createEvent) {
    return this.createEventWithHttpInfo(createEvent).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createTrackingEventWithHttpInfo(trackingInfos, targetId, targetType) {
    let postBody = trackingInfos;

    // verify the required parameter 'trackingInfos' is set
    if (trackingInfos === undefined || trackingInfos === null) {
      throw new Error("Missing the required parameter 'trackingInfos' when calling createTrackingEvent");
    }

    // verify the required parameter 'targetId' is set
    if (targetId === undefined || targetId === null) {
      throw new Error("Missing the required parameter 'targetId' when calling createTrackingEvent");
    }

    // verify the required parameter 'targetType' is set
    if (targetType === undefined || targetType === null) {
      throw new Error("Missing the required parameter 'targetType' when calling createTrackingEvent");
    }


    let pathParams = {
    };
    let queryParams = {
      'targetId': targetId,
      'targetType': targetType
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/tracking', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createTrackingEvent(trackingInfos, targetId, targetType) {
    return this.createTrackingEventWithHttpInfo(trackingInfos, targetId, targetType).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getEventWithHttpInfo(eventId) {
    let postBody = null;

    // verify the required parameter 'eventId' is set
    if (eventId === undefined || eventId === null) {
      throw new Error("Missing the required parameter 'eventId' when calling getEvent");
    }


    let pathParams = {
      'eventId': eventId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Event;

    return this.apiClient.callApi(
      '/event/{eventId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getEvent(eventId) {
    return this.getEventWithHttpInfo(eventId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getEventsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'targetId': opts['targetId'],
      'targetType': opts['targetType'],
      'dateFrom': opts['dateFrom'],
      'dateUntil': opts['dateUntil'],
      'eventClass': opts['eventClass'],
      'titlePattern': opts['titlePattern'],
      'descriptionPattern': opts['descriptionPattern'],
      'orderBy': opts['orderBy'],
      'orderByDirection': opts['orderByDirection'],
      'pagingStart': opts['pagingStart'],
      'pagingSize': opts['pagingSize']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Event];

    return this.apiClient.callApi(
      '/events', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getEvents(opts) {
    return this.getEventsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getNotificationWithHttpInfo(notificationId) {
    let postBody = null;

    // verify the required parameter 'notificationId' is set
    if (notificationId === undefined || notificationId === null) {
      throw new Error("Missing the required parameter 'notificationId' when calling getNotification");
    }


    let pathParams = {
      'notificationId': notificationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Notification;

    return this.apiClient.callApi(
      '/notification/{notificationId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getNotification(notificationId) {
    return this.getNotificationWithHttpInfo(notificationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getNotificationsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'status': opts['status'],
      'orderBy': opts['orderBy']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['String'];

    return this.apiClient.callApi(
      '/notification/', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getNotifications(opts) {
    return this.getNotificationsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getTrackedQuotationsWithHttpInfo(dateFrom, opts) {
    opts = opts || {};
    let postBody = null;

    // verify the required parameter 'dateFrom' is set
    if (dateFrom === undefined || dateFrom === null) {
      throw new Error("Missing the required parameter 'dateFrom' when calling getTrackedQuotations");
    }


    let pathParams = {
    };
    let queryParams = {
      'dateFrom': dateFrom,
      'dateUntil': opts['dateUntil']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [TrackedQuotation];

    return this.apiClient.callApi(
      '/trackings', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getTrackedQuotations(dateFrom, opts) {
    return this.getTrackedQuotationsWithHttpInfo(dateFrom, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setNotificationReadWithHttpInfo(notificationId) {
    let postBody = null;

    // verify the required parameter 'notificationId' is set
    if (notificationId === undefined || notificationId === null) {
      throw new Error("Missing the required parameter 'notificationId' when calling setNotificationRead");
    }


    let pathParams = {
      'notificationId': notificationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Notification;

    return this.apiClient.callApi(
      '/notification/{notificationId}/read', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setNotificationRead(notificationId) {
    return this.setNotificationReadWithHttpInfo(notificationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setNotificationUnreadWithHttpInfo(notificationId) {
    let postBody = null;

    // verify the required parameter 'notificationId' is set
    if (notificationId === undefined || notificationId === null) {
      throw new Error("Missing the required parameter 'notificationId' when calling setNotificationUnread");
    }


    let pathParams = {
      'notificationId': notificationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Notification;

    return this.apiClient.callApi(
      '/notification/{notificationId}/unread', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setNotificationUnread(notificationId) {
    return this.setNotificationUnreadWithHttpInfo(notificationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}