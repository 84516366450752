/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class DeliveryParcelContent {
  constructor(quotationId, quotationType, quantity, description) {
    if (quotationId) {
      this['quotationId'] = quotationId;
    }
    if (quotationType) {
      this['quotationType'] = quotationType;
    }
    if (quantity) {
      this['quantity'] = quantity;
    }
    if (description) {
      this['description'] = description;
    }


  }

  static typeDescription() {
    return "DeliveryParcelContent";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DeliveryParcelContent();
      if (data.hasOwnProperty('quotationId')) {
        obj['quotationId'] = ApiClient.convertToType(data['quotationId'], 'String');
      }
      if (data.hasOwnProperty('quotationType')) {
        obj['quotationType'] = ApiClient.convertToType(data['quotationType'], 'String');
      }
      if (data.hasOwnProperty('quantity')) {
        obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('quantityUnit')) {
        obj['quantityUnit'] = ApiClient.convertToType(data['quantityUnit'], 'String');
      }
    }
    return obj;
  }


  quotationId = undefined;

  quotationType = "assembly_quotation";

  quantity = 0.0;

  description = "";

  quantityUnit = undefined;




  getQuotationId() {
    return this.quotationId;
  }

  setQuotationId(quotationId) {
    this['quotationId'] = quotationId;
  }

  getQuotationType() {
    return this.quotationType;
  }

  setQuotationType(quotationType) {
    this['quotationType'] = quotationType;
  }

  getQuantity() {
    return this.quantity;
  }

  setQuantity(quantity) {
    this['quantity'] = quantity;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getQuantityUnit() {
    return this.quantityUnit;
  }

  setQuantityUnit(quantityUnit) {
    this['quantityUnit'] = quantityUnit;
  }


  static QuotationTypeEnum = {
    "assembly_quotation": "assembly_quotation",
    "coating_quotation": "coating_quotation",
    "dfm_quotation": "dfm_quotation",
    "enclosure_quotation": "enclosure_quotation",
    "engineering_quotation": "engineering_quotation",
    "pcb_quotation": "pcb_quotation",
    "pcba_quotation": "pcba_quotation",
    "pcb_design_quotation": "pcb_design_quotation",
    "rework_quotation": "rework_quotation",
    "supply_quotation": "supply_quotation",
    "wiring_quotation": "wiring_quotation",
    "xray_quotation": "xray_quotation"
  };

  static QuantityUnitEnum = {
    "unit": "unit",
    "m": "m",
    "m2": "m2",
    "m3": "m3",
    "l": "l",
    "kg": "kg"
  };

}