import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter, matchPath } from "react-router";

import { Link } from "Elements";
import CookiesModal from "marketing/components/CookiesModal";
import { Button } from "Elements";
import miscActions from "Misc/actions";

import marketingMessages from "marketing/messages";

class Cookie extends React.Component {
  state = {
    showModal: false
  };
  componentDidMount() {
    const { props } = this;
    if (!!props.cookies) {
      props.updateGTagConsent(true);
    }
  }
  render() {
    const { props, state } = this;
    const { location = {} } = props;
    const { pathname } = location;

    return props.cookies === null &&
    !matchPath(pathname, { path: "/:lang/cookies" }) ?
    <div>
        <div className="cookie py-2 py-md-3 py-lg-4">
          <div className="row">
            <span className="text-white col-10 offset-1 col-md-8 offset-md-2">
              <FormattedMessage
              id="marketing.cookies.bottomAlert.message"
              defaultMessage="By continuing to browse the emsfactory.com website, you accept the use of cookies or similar technologies to offer you electronic assembly and delivery services, improve your user experience and let us make some basic analytics." />





              <Link
              to="/cookies"
              className="text-secondary"
              style={{ fontWeight: "500" }}>

                {" "}
                {props.intl.formatMessage(marketingMessages.learnMore)}{" "}
              </Link>{" "}
              <FormattedMessage id="marketing.cookies.or" defaultMessage="or" />{" "}
              <Link
              to="#"
              onClick={(e) => this.setState({ showModal: true })}
              className="text-secondary"
              style={{ fontWeight: "500" }}>

                {props.intl.formatMessage(marketingMessages.setCookies)}
              </Link>
            </span>
          </div>
          <div className="d-flex justify-content-around pt-2">
            <Button onClick={(e) => props.setCookies(true)}>
              <FormattedMessage
              id="marketing.cookies.accept"
              defaultMessage="Accept" />

            </Button>
          </div>
        </div>
        <CookiesModal
        open={state.showModal}
        onHide={(e) => this.setState({ showModal: false })} />

        {/* {state.showModal ? (
        ) : null} */}
      </div> :
    null;
  }
}

const mapStateToProps = (state) => {
  const { misc = {}, intl = {} } = state;
  const { messages = [] } = intl;
  return { cookies: misc.cookies, messages };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCookies: (data) => miscActions.setCookies(data),
    updateGTagConsent: (data) => miscActions.updateGTagConsent(data)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(Cookie)));