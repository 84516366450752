/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ProductionPhases from './ProductionPhases';

export default class ProductionProgress {
  constructor(phases, currentPhase) {
    this['phases'] = phases || [];
    this['currentPhase'] = currentPhase || new ProductionPhases();


  }

  static typeDescription() {
    return "ProductionProgress";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProductionProgress();
      if (data.hasOwnProperty('phases')) {
        obj['phases'] = ApiClient.convertToType(data['phases'], [ProductionPhases]);
      }
      if (data.hasOwnProperty('currentPhase')) {
        obj['currentPhase'] = ProductionPhases.constructFromObject(data['currentPhase']);
      }
    }
    return obj;
  }


  phases = [];

  currentPhase = undefined;




  getPhases() {
    return this.phases;
  }

  setPhases(phases) {
    this['phases'] = phases;
  }

  getCurrentPhase() {
    return this.currentPhase;
  }

  setCurrentPhase(currentPhase) {
    this['currentPhase'] = currentPhase;
  }


}