/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Action from './Action';

export default class MissingPaymentAnomalyActionsCancelOrder {
  constructor(asAction) {
    this['asAction'] = asAction || new Action();


    if (!this.asAction.type || this.asAction.type === "") {
      this.asAction.type = "cancelOrder";
    }
    if (!this.asAction.description || this.asAction.description === "") {
      this.asAction.description = "cancel the order";
    }
  }

  static typeDescription() {
    return "MissingPaymentAnomalyActionsCancelOrder";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MissingPaymentAnomalyActionsCancelOrder();
      if (data.hasOwnProperty('asAction')) {
        obj['asAction'] = Action.constructFromObject(data['asAction']);
      }
    }
    return obj;
  }


  asAction = undefined;




  getAsAction() {
    return this.asAction;
  }

  setAsAction(asAction) {
    this['asAction'] = asAction;
  }


}