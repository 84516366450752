/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class CalendarEntry {
  constructor(_date, isOpen) {
    if (_date) {
      this['date'] = _date;
    }
    if (isOpen) {
      this['isOpen'] = isOpen;
    }


  }

  static typeDescription() {
    return "CalendarEntry";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CalendarEntry();
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'Date');
      }
      if (data.hasOwnProperty('isOpen')) {
        obj['isOpen'] = ApiClient.convertToType(data['isOpen'], 'Boolean');
      }
    }
    return obj;
  }


  date = undefined;

  isOpen = false;




  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }

  getIsOpen() {
    return this.isOpen;
  }

  setIsOpen(isOpen) {
    this['isOpen'] = isOpen;
  }


}