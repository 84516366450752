import { update, updateMultiple } from "utils/updateArray";

const notifications = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "notifications/SET_ALL") {
    return action.data;
  }
  if (action.type === "notifications/UPDATE_ALL") {
    return updateMultiple(state, action.data);
  }
  if ("notifications/SET" === action.type) {
    return update(state, action.data);
  }
  if ("notifications/SET_EVENT" === action.type) {
    const event = action.data;
    if (event) {
      return state.map((notification) => {
        if (event.notificationIds && event.notificationIds.includes(notification.id)) {
          return {
            ...notification,
            event
          };
        }
        return notification;
      });
    }
  }
  return state;
};

// export default function(state, action) {
//   return notifications(state, action).map(notification =>
//     Object.assign({}, notification, {
//       status: notification.title ? notification.readDate ? "read" : "unread"
//     })
//   );
// }

export default notifications;