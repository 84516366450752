/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class SpecificationTemplate {
  constructor() {


  }

  static typeDescription() {
    return "SpecificationTemplate";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SpecificationTemplate();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('categories')) {
        obj['categories'] = ApiClient.convertToType(data['categories'], [['String']]);
      }
    }
    return obj;
  }


  id = undefined;

  type = undefined;

  name = undefined;

  description = undefined;

  categories = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getCategories() {
    return this.categories;
  }

  setCategories(categories) {
    this['categories'] = categories;
  }


}