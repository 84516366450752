import { store } from "initStore";
import request from "superagent";
import allowedDomains from "./domains";
import languages from "languages";

let requests = {};

const actions = {
  // Get messages json file. Needs language and domains
  getMessages: (language, domainsAsked = []) => {
    let domains = [...domainsAsked];
    if (!domains.length) {
      domains = store.getState().intl.domains;
    }

    domains = domains.filter((d) => allowedDomains.indexOf(d) >= 0);

    const loadedDomains = store.getState().intl.loadedDomains;
    const askedDomains = domains.filter((d) => loadedDomains.indexOf(d) < 0);

    return Promise.all(
      askedDomains.
      filter((domain) => !requests[language + "/" + domain]).
      map((domain) => {
        console.log(
          "intlDebugGetMessages",
          language,
          domainsAsked,
          domains,
          loadedDomains,
          askedDomains,
          languages,
          language === languages[0],
          domain
        );
        requests[language + "/" + domain] = true;
        return language === languages[0] ?
        Promise.resolve({}) :
        request("get", "/locales/" + domain + "/" + language + ".json").
        catch((e) => {
          return {};
        }).
        then((e) => {
          requests[language + "/" + domain] = false;
          return e;
        });
      })
    ).
    then((res) => {
      let nMessages = {};
      res.forEach(
        (r) => nMessages = Object.assign({}, nMessages, r.body || {})
      );
      if (language === languages[0]) {
        actions.emptyMessages();
      } else {
        actions.setMessages(nMessages);
      }
      actions.setDomains(domains);
      return nMessages;
    }).
    catch((e) => {
      return {};
    });
  },
  setMessages: (messages) =>
  store.dispatch({
    type: "intl/SET_MESSAGES",
    messages
  }),
  emptyMessages: () =>
  store.dispatch({
    type: "intl/EMPTY_MESSAGES"
  }),
  askDomains: (domains) =>
  store.dispatch({
    type: "intl/ASK_DOMAINS",
    domains
  }),
  setDomains: (domains) =>
  store.dispatch({
    type: "intl/SET_DOMAINS",
    domains
  }),
  emptyLoadedDomains: () => {
    console.log("intl actions getMessages emptyLoadedDomains");
    return store.dispatch({
      type: "intl/EMPTY_LOADED_DOMAINS"
    });
  },
  // loadMessages: (language, domains) => {
  //   actions.getMessages(language, domains)
  //     .then(messages => store.dispatch({
  //       type: 'intl/SET_LANGUAGE',
  //       messages,
  //       domains
  //     }))
  // },
  setLanguage: (language) =>
  store.dispatch({
    type: "intl/SET_LANGUAGE",
    language
  })
  // setLanguage: language => {
  //   console.log('intl actions setLanguage', language)
  //   actions.emptyLoadedDomains()
  //   actions.getMessages(language, store.getState().intl.domains)
  //   .then(messages => store.dispatch({
  //     type: 'intl/SET_LANGUAGE',
  //     language
  //   }))}
  //   actions.getMessages(language)
  //     .then(messages => store.dispatch({
  //         type: 'intl/SET_LANGUAGE',
  //         messages,
  //         language
  //       })
  //     )
  //
  // }
};

export default actions;