/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AccessPermission from '../model/AccessPermission';
import AssemblyQuotation from '../model/AssemblyQuotation';
import Cart from '../model/Cart';
import CoatingQuotation from '../model/CoatingQuotation';
import DfmQuotation from '../model/DfmQuotation';
import EnclosureQuotation from '../model/EnclosureQuotation';
import EngineeringQuotation from '../model/EngineeringQuotation';
import Error from '../model/Error';
import PcbDesignQuotation from '../model/PcbDesignQuotation';
import PcbQuotation from '../model/PcbQuotation';
import PcbaQuotation from '../model/PcbaQuotation';
import Quotation from '../model/Quotation';
import ReworkQuotation from '../model/ReworkQuotation';
import SupplyQuotation from '../model/SupplyQuotation';
import WiringQuotation from '../model/WiringQuotation';
import XRayQuotation from '../model/XRayQuotation';


export default class CartApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  appendAssemblyQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendAssemblyQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendAssemblyQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/assembly/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendAssemblyQuotationToCart(cartId, quotId) {
    return this.appendAssemblyQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendCoatingQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendCoatingQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendCoatingQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/coating/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendCoatingQuotationToCart(cartId, quotId) {
    return this.appendCoatingQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendDfmQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendDfmQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendDfmQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/dfm/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendDfmQuotationToCart(cartId, quotId) {
    return this.appendDfmQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendEnclosureQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendEnclosureQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendEnclosureQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/enclosure/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendEnclosureQuotationToCart(cartId, quotId) {
    return this.appendEnclosureQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendEngineeringQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendEngineeringQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendEngineeringQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/engineering/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendEngineeringQuotationToCart(cartId, quotId) {
    return this.appendEngineeringQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendPCBAQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendPCBAQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendPCBAQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/pcba/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendPCBAQuotationToCart(cartId, quotId) {
    return this.appendPCBAQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendPcbDesignQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendPcbDesignQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendPcbDesignQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/pcbDesign/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendPcbDesignQuotationToCart(cartId, quotId) {
    return this.appendPcbDesignQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendPcbQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendPcbQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendPcbQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/pcb/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendPcbQuotationToCart(cartId, quotId) {
    return this.appendPcbQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendReworkQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendReworkQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendReworkQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/rework/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendReworkQuotationToCart(cartId, quotId) {
    return this.appendReworkQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendSupplyQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendSupplyQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendSupplyQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/supply/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendSupplyQuotationToCart(cartId, quotId) {
    return this.appendSupplyQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendWiringQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendWiringQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendWiringQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/wiring/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendWiringQuotationToCart(cartId, quotId) {
    return this.appendWiringQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendXRayQuotationToCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling appendXRayQuotationToCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling appendXRayQuotationToCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/xray/quotation/{quotId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendXRayQuotationToCart(cartId, quotId) {
    return this.appendXRayQuotationToCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createCartWithHttpInfo(name) {
    let postBody = name;

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling createCart");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Cart;

    return this.apiClient.callApi(
      '/cart', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createCart(name) {
    return this.createCartWithHttpInfo(name).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createCartAccessPermissionWithHttpInfo(cartId, content, opts) {
    opts = opts || {};
    let postBody = content;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling createCartAccessPermission");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createCartAccessPermission");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
      'user_login': opts['userLogin'],
      'email': opts['email']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = [AccessPermission];

    return this.apiClient.callApi(
      '/cart/{cartId}/accessPermission', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createCartAccessPermission(cartId, content, opts) {
    return this.createCartAccessPermissionWithHttpInfo(cartId, content, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createCartUserAccessPermissionWithHttpInfo(cartId, userId, content) {
    let postBody = content;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling createCartUserAccessPermission");
    }

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling createCartUserAccessPermission");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createCartUserAccessPermission");
    }


    let pathParams = {
      'cartId': cartId,
      'userId': userId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = [AccessPermission];

    return this.apiClient.callApi(
      '/cart/{cartId}/accessPermission/{userId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createCartUserAccessPermission(cartId, userId, content) {
    return this.createCartUserAccessPermissionWithHttpInfo(cartId, userId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteCartWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling deleteCart");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/cart/{cartId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteCart(cartId) {
    return this.deleteCartWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteCartAccessPermissionWithHttpInfo(cartId, userId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling deleteCartAccessPermission");
    }

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling deleteCartAccessPermission");
    }


    let pathParams = {
      'cartId': cartId,
      'userId': userId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [AccessPermission];

    return this.apiClient.callApi(
      '/cart/{cartId}/accessPermission/{userId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteCartAccessPermission(cartId, userId) {
    return this.deleteCartAccessPermissionWithHttpInfo(cartId, userId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCart");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Cart;

    return this.apiClient.callApi(
      '/cart/{cartId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCart(cartId) {
    return this.getCartWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartAccessPermissionsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartAccessPermissions");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [AccessPermission];

    return this.apiClient.callApi(
      '/cart/{cartId}/accessPermission', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartAccessPermissions(cartId) {
    return this.getCartAccessPermissionsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartAssemblyQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartAssemblyQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [AssemblyQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/assembly/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartAssemblyQuotations(cartId) {
    return this.getCartAssemblyQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartCoatingQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartCoatingQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [CoatingQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/coating/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartCoatingQuotations(cartId) {
    return this.getCartCoatingQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartDfmQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartDfmQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [DfmQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/dfm/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartDfmQuotations(cartId) {
    return this.getCartDfmQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartEnclosureQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartEnclosureQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [EnclosureQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/enclosure/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartEnclosureQuotations(cartId) {
    return this.getCartEnclosureQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartEngineeringQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartEngineeringQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [EngineeringQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/engineering/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartEngineeringQuotations(cartId) {
    return this.getCartEngineeringQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartPCBAQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartPCBAQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PcbaQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/pcba/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartPCBAQuotations(cartId) {
    return this.getCartPCBAQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartPcbDesignQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartPcbDesignQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PcbDesignQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/pcbDesign/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartPcbDesignQuotations(cartId) {
    return this.getCartPcbDesignQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartPcbQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartPcbQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PcbQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/pcb/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartPcbQuotations(cartId) {
    return this.getCartPcbQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Quotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/quotations', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartQuotations(cartId) {
    return this.getCartQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartReworkQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartReworkQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [ReworkQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/rework/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartReworkQuotations(cartId) {
    return this.getCartReworkQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartSupplyQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartSupplyQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [SupplyQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/supply/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartSupplyQuotations(cartId) {
    return this.getCartSupplyQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartWiringQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartWiringQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [WiringQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/wiring/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartWiringQuotations(cartId) {
    return this.getCartWiringQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartXRayQuotationsWithHttpInfo(cartId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling getCartXRayQuotations");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [XRayQuotation];

    return this.apiClient.callApi(
      '/cart/{cartId}/xray/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCartXRayQuotations(cartId) {
    return this.getCartXRayQuotationsWithHttpInfo(cartId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCartsWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Cart];

    return this.apiClient.callApi(
      '/cart', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCarts() {
    return this.getCartsWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeAssemblyQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeAssemblyQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeAssemblyQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/assembly/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeAssemblyQuotationFromCart(cartId, quotId) {
    return this.removeAssemblyQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeCoatingQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeCoatingQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeCoatingQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/coating/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeCoatingQuotationFromCart(cartId, quotId) {
    return this.removeCoatingQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeDfmQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeDfmQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeDfmQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/dfm/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeDfmQuotationFromCart(cartId, quotId) {
    return this.removeDfmQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeEnclosureQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeEnclosureQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeEnclosureQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/enclosure/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeEnclosureQuotationFromCart(cartId, quotId) {
    return this.removeEnclosureQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeEngineeringQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeEngineeringQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeEngineeringQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/engineering/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeEngineeringQuotationFromCart(cartId, quotId) {
    return this.removeEngineeringQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removePcbDesignQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removePcbDesignQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removePcbDesignQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/pcbDesign/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removePcbDesignQuotationFromCart(cartId, quotId) {
    return this.removePcbDesignQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removePcbQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removePcbQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removePcbQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/pcb/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removePcbQuotationFromCart(cartId, quotId) {
    return this.removePcbQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removePcbaQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removePcbaQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removePcbaQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/pcba/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removePcbaQuotationFromCart(cartId, quotId) {
    return this.removePcbaQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeReworkQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeReworkQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeReworkQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/rework/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeReworkQuotationFromCart(cartId, quotId) {
    return this.removeReworkQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeSupplyQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeSupplyQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeSupplyQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/supply/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeSupplyQuotationFromCart(cartId, quotId) {
    return this.removeSupplyQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeWiringQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeWiringQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeWiringQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/wiring/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeWiringQuotationFromCart(cartId, quotId) {
    return this.removeWiringQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeXRayQuotationFromCartWithHttpInfo(cartId, quotId) {
    let postBody = null;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling removeXRayQuotationFromCart");
    }

    // verify the required parameter 'quotId' is set
    if (quotId === undefined || quotId === null) {
      throw new Error("Missing the required parameter 'quotId' when calling removeXRayQuotationFromCart");
    }


    let pathParams = {
      'cartId': cartId,
      'quotId': quotId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/cart/{cartId}/xray/quotation/{quotId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeXRayQuotationFromCart(cartId, quotId) {
    return this.removeXRayQuotationFromCartWithHttpInfo(cartId, quotId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setCartWithHttpInfo(cartId, cart) {
    let postBody = cart;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling setCart");
    }

    // verify the required parameter 'cart' is set
    if (cart === undefined || cart === null) {
      throw new Error("Missing the required parameter 'cart' when calling setCart");
    }


    let pathParams = {
      'cartId': cartId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Cart;

    return this.apiClient.callApi(
      '/cart/{cartId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setCart(cartId, cart) {
    return this.setCartWithHttpInfo(cartId, cart).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setCartAccessPermissionWithHttpInfo(cartId, userId, content) {
    let postBody = content;

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling setCartAccessPermission");
    }

    // verify the required parameter 'userId' is set
    if (userId === undefined || userId === null) {
      throw new Error("Missing the required parameter 'userId' when calling setCartAccessPermission");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setCartAccessPermission");
    }


    let pathParams = {
      'cartId': cartId,
      'userId': userId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = [AccessPermission];

    return this.apiClient.callApi(
      '/cart/{cartId}/accessPermission/{userId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setCartAccessPermission(cartId, userId, content) {
    return this.setCartAccessPermissionWithHttpInfo(cartId, userId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}