import React from "react";
import { Link, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import languages from "languages";
import intlActions from "Intl/actions";

const Component = (props) => {
  const to = props.to || "";
  let query = to.split("?")[1];

  const { onClick = (e) => e, lang = props.intl.locale } = props;

  const hash = to.split("#")[1];
  let pathname = to.
  split("#")[0].
  split("?")[0].
  split("/").
  filter((s) => s !== "");
  pathname = languages.indexOf(pathname[0]) >= 0 ? pathname.slice(1) : pathname;
  pathname = pathname.length ? pathname.join("/") : "";
  const nTo = (
  "/" +
  lang + (
  pathname.length ? "/" + pathname : "") + (
  hash ? "#" + hash : "") + (
  query ? "?" + query : "")).
  replace(/\/\//, "/");
  const { className, id, style, disabled } = props;
  const nClassName = className + (disabled ? " disabled" : "");
  return (
    <Link
      target={props.target}
      className={nClassName}
      id={id}
      style={style}
      to={nTo}
      onClick={(e) => {
        if (!to || disabled) {
          return e.preventDefault();
        }
        if (props.intl.locale !== lang) {
          intlActions.setLanguage(lang);
        }
        onClick(e);
      }}
      replace={props.replace}>

      {props.children}
    </Link>);

};

export default injectIntl(withRouter(Component));