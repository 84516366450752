/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CategorizedComponent from '../model/CategorizedComponent';
import ComponentCategory from '../model/ComponentCategory';
import ComponentFilterRequest from '../model/ComponentFilterRequest';
import ComponentFilterResult from '../model/ComponentFilterResult';
import CrossReferencesExport from '../model/CrossReferencesExport';
import OfflineComponentSource from '../model/OfflineComponentSource';
import PackageAndMountingTechnology from '../model/PackageAndMountingTechnology';
import PartNumberMatching from '../model/PartNumberMatching';
import PropertyEncoding from '../model/PropertyEncoding';
import PropertyMeasure from '../model/PropertyMeasure';
import PropertyUnit from '../model/PropertyUnit';
import Specification from '../model/Specification';
import SpecificationItem from '../model/SpecificationItem';


export default class ComponentsApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  addCrossReferencesWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling addCrossReferences");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/crossReferences', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  addCrossReferences(content) {
    return this.addCrossReferencesWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  associateComponentsWithHttpInfo(componentId, associatedComponentId) {
    let postBody = associatedComponentId;

    // verify the required parameter 'componentId' is set
    if (componentId === undefined || componentId === null) {
      throw new Error("Missing the required parameter 'componentId' when calling associateComponents");
    }

    // verify the required parameter 'associatedComponentId' is set
    if (associatedComponentId === undefined || associatedComponentId === null) {
      throw new Error("Missing the required parameter 'associatedComponentId' when calling associateComponents");
    }


    let pathParams = {
      'componentId': componentId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/components/{componentId}/associated', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  associateComponents(componentId, associatedComponentId) {
    return this.associateComponentsWithHttpInfo(componentId, associatedComponentId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createComponentWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createComponent");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = CategorizedComponent;

    return this.apiClient.callApi(
      '/components', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createComponent(content) {
    return this.createComponentWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createComponentCategoryWithHttpInfo(createComponentCategory) {
    let postBody = createComponentCategory;

    // verify the required parameter 'createComponentCategory' is set
    if (createComponentCategory === undefined || createComponentCategory === null) {
      throw new Error("Missing the required parameter 'createComponentCategory' when calling createComponentCategory");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ComponentCategory;

    return this.apiClient.callApi(
      '/components/category', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createComponentCategory(createComponentCategory) {
    return this.createComponentCategoryWithHttpInfo(createComponentCategory).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createComponentPropertyEncodingWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createComponentPropertyEncoding");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = PropertyEncoding;

    return this.apiClient.callApi(
      '/components/property/encodings', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createComponentPropertyEncoding(content) {
    return this.createComponentPropertyEncodingWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createComponentPropertyMeasureWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createComponentPropertyMeasure");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = PropertyMeasure;

    return this.apiClient.callApi(
      '/components/property/measures', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createComponentPropertyMeasure(content) {
    return this.createComponentPropertyMeasureWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createComponentPropertyUnitWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createComponentPropertyUnit");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = PropertyUnit;

    return this.apiClient.callApi(
      '/components/property/units', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createComponentPropertyUnit(content) {
    return this.createComponentPropertyUnitWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createOfflineComponentSourceWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createOfflineComponentSource");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = OfflineComponentSource;

    return this.apiClient.callApi(
      '/offlineComponentSource', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createOfflineComponentSource(content) {
    return this.createOfflineComponentSourceWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  crossReferenceCompatibilityWithHttpInfo(partNumber1, partNumber2) {
    let postBody = null;

    // verify the required parameter 'partNumber1' is set
    if (partNumber1 === undefined || partNumber1 === null) {
      throw new Error("Missing the required parameter 'partNumber1' when calling crossReferenceCompatibility");
    }

    // verify the required parameter 'partNumber2' is set
    if (partNumber2 === undefined || partNumber2 === null) {
      throw new Error("Missing the required parameter 'partNumber2' when calling crossReferenceCompatibility");
    }


    let pathParams = {
      'partNumber1': partNumber1,
      'partNumber2': partNumber2
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/crossReferences/compatibility/{partNumber1}/{partNumber2}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  crossReferenceCompatibility(partNumber1, partNumber2) {
    return this.crossReferenceCompatibilityWithHttpInfo(partNumber1, partNumber2).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  crossReferenceCompatiblesWithHttpInfo(partNumber, opts) {
    opts = opts || {};
    let postBody = null;

    // verify the required parameter 'partNumber' is set
    if (partNumber === undefined || partNumber === null) {
      throw new Error("Missing the required parameter 'partNumber' when calling crossReferenceCompatibles");
    }


    let pathParams = {
      'partNumber': partNumber
    };
    let queryParams = {
      'direction': opts['direction']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PartNumberMatching];

    return this.apiClient.callApi(
      '/crossReferences/compatibles/{partNumber}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  crossReferenceCompatibles(partNumber, opts) {
    return this.crossReferenceCompatiblesWithHttpInfo(partNumber, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  crossReferenceDeclinationsWithHttpInfo(partNumber) {
    let postBody = null;

    // verify the required parameter 'partNumber' is set
    if (partNumber === undefined || partNumber === null) {
      throw new Error("Missing the required parameter 'partNumber' when calling crossReferenceDeclinations");
    }


    let pathParams = {
      'partNumber': partNumber
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['String'];

    return this.apiClient.callApi(
      '/crossReferences/declinations/{partNumber}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  crossReferenceDeclinations(partNumber) {
    return this.crossReferenceDeclinationsWithHttpInfo(partNumber).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  crossReferenceMatchingWithHttpInfo(pattern) {
    let postBody = null;

    // verify the required parameter 'pattern' is set
    if (pattern === undefined || pattern === null) {
      throw new Error("Missing the required parameter 'pattern' when calling crossReferenceMatching");
    }


    let pathParams = {
      'pattern': pattern
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ['String'];

    return this.apiClient.callApi(
      '/crossReferences/findPartNumbers/{pattern}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  crossReferenceMatching(pattern) {
    return this.crossReferenceMatchingWithHttpInfo(pattern).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteComponentWithHttpInfo(componentId) {
    let postBody = null;

    // verify the required parameter 'componentId' is set
    if (componentId === undefined || componentId === null) {
      throw new Error("Missing the required parameter 'componentId' when calling deleteComponent");
    }


    let pathParams = {
      'componentId': componentId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/components/{componentId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteComponent(componentId) {
    return this.deleteComponentWithHttpInfo(componentId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteComponentCategoryWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling deleteComponentCategory");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/components/category', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteComponentCategory(content) {
    return this.deleteComponentCategoryWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteComponentPropertyEncodingWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling deleteComponentPropertyEncoding");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/components/property/encodings', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteComponentPropertyEncoding(content) {
    return this.deleteComponentPropertyEncodingWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteComponentPropertyMeasureWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling deleteComponentPropertyMeasure");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/components/property/measures', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteComponentPropertyMeasure(content) {
    return this.deleteComponentPropertyMeasureWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteComponentPropertyUnitWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling deleteComponentPropertyUnit");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/components/property/units', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteComponentPropertyUnit(content) {
    return this.deleteComponentPropertyUnitWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteCrossReferenceWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling deleteCrossReference");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/crossReferences', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteCrossReference(content) {
    return this.deleteCrossReferenceWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteOfflineComponentSourceWithHttpInfo(sourceId) {
    let postBody = null;

    // verify the required parameter 'sourceId' is set
    if (sourceId === undefined || sourceId === null) {
      throw new Error("Missing the required parameter 'sourceId' when calling deleteOfflineComponentSource");
    }


    let pathParams = {
      'sourceId': sourceId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/offlineComponentSource/{sourceId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteOfflineComponentSource(sourceId) {
    return this.deleteOfflineComponentSourceWithHttpInfo(sourceId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deletePackageWithHttpInfo(_package) {
    let postBody = null;

    // verify the required parameter '_package' is set
    if (_package === undefined || _package === null) {
      throw new Error("Missing the required parameter '_package' when calling deletePackage");
    }


    let pathParams = {
      'package': _package
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/packages/{package}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deletePackage(_package) {
    return this.deletePackageWithHttpInfo(_package).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  dissociateComponentsWithHttpInfo(componentId, associatedComponentId) {
    let postBody = associatedComponentId;

    // verify the required parameter 'componentId' is set
    if (componentId === undefined || componentId === null) {
      throw new Error("Missing the required parameter 'componentId' when calling dissociateComponents");
    }

    // verify the required parameter 'associatedComponentId' is set
    if (associatedComponentId === undefined || associatedComponentId === null) {
      throw new Error("Missing the required parameter 'associatedComponentId' when calling dissociateComponents");
    }


    let pathParams = {
      'componentId': componentId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/components/{componentId}/associated', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  dissociateComponents(componentId, associatedComponentId) {
    return this.dissociateComponentsWithHttpInfo(componentId, associatedComponentId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  dumpCrossReferencesToFileWithHttpInfo(filePath) {
    let postBody = null;

    // verify the required parameter 'filePath' is set
    if (filePath === undefined || filePath === null) {
      throw new Error("Missing the required parameter 'filePath' when calling dumpCrossReferencesToFile");
    }


    let pathParams = {
      'filePath': filePath
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/crossReferences/dump/{filePath}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  dumpCrossReferencesToFile(filePath) {
    return this.dumpCrossReferencesToFileWithHttpInfo(filePath).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  exportCrossReferencesOfPartNumberWithHttpInfo(partNumber) {
    let postBody = null;

    // verify the required parameter 'partNumber' is set
    if (partNumber === undefined || partNumber === null) {
      throw new Error("Missing the required parameter 'partNumber' when calling exportCrossReferencesOfPartNumber");
    }


    let pathParams = {
      'partNumber': partNumber
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = CrossReferencesExport;

    return this.apiClient.callApi(
      '/crossReferences/export/{partNumber}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  exportCrossReferencesOfPartNumber(partNumber) {
    return this.exportCrossReferencesOfPartNumberWithHttpInfo(partNumber).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getAssociatedComponentsWithHttpInfo(componentId) {
    let postBody = null;

    // verify the required parameter 'componentId' is set
    if (componentId === undefined || componentId === null) {
      throw new Error("Missing the required parameter 'componentId' when calling getAssociatedComponents");
    }


    let pathParams = {
      'componentId': componentId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [CategorizedComponent];

    return this.apiClient.callApi(
      '/components/{componentId}/associated', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getAssociatedComponents(componentId) {
    return this.getAssociatedComponentsWithHttpInfo(componentId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getComponentWithHttpInfo(componentId) {
    let postBody = null;

    // verify the required parameter 'componentId' is set
    if (componentId === undefined || componentId === null) {
      throw new Error("Missing the required parameter 'componentId' when calling getComponent");
    }


    let pathParams = {
      'componentId': componentId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = CategorizedComponent;

    return this.apiClient.callApi(
      '/components/{componentId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getComponent(componentId) {
    return this.getComponentWithHttpInfo(componentId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getComponentByMpnWithHttpInfo(manufacturer, partNumber) {
    let postBody = null;

    // verify the required parameter 'manufacturer' is set
    if (manufacturer === undefined || manufacturer === null) {
      throw new Error("Missing the required parameter 'manufacturer' when calling getComponentByMpn");
    }

    // verify the required parameter 'partNumber' is set
    if (partNumber === undefined || partNumber === null) {
      throw new Error("Missing the required parameter 'partNumber' when calling getComponentByMpn");
    }


    let pathParams = {
    };
    let queryParams = {
      'manufacturer': manufacturer,
      'partNumber': partNumber
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = CategorizedComponent;

    return this.apiClient.callApi(
      '/components', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getComponentByMpn(manufacturer, partNumber) {
    return this.getComponentByMpnWithHttpInfo(manufacturer, partNumber).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getComponentPropertyEncodingsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'code': opts['code']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PropertyEncoding];

    return this.apiClient.callApi(
      '/components/property/encodings', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getComponentPropertyEncodings(opts) {
    return this.getComponentPropertyEncodingsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getComponentPropertyMeasuresWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'code': opts['code']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PropertyMeasure];

    return this.apiClient.callApi(
      '/components/property/measures', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getComponentPropertyMeasures(opts) {
    return this.getComponentPropertyMeasuresWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getComponentPropertyUnitsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'code': opts['code']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PropertyUnit];

    return this.apiClient.callApi(
      '/components/property/units', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getComponentPropertyUnits(opts) {
    return this.getComponentPropertyUnitsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getComponentRootCategoryWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ComponentCategory;

    return this.apiClient.callApi(
      '/components/category', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getComponentRootCategory() {
    return this.getComponentRootCategoryWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getComponentTypeSpecificationsWithHttpInfo(componentType, opts) {
    opts = opts || {};
    let postBody = null;

    // verify the required parameter 'componentType' is set
    if (componentType === undefined || componentType === null) {
      throw new Error("Missing the required parameter 'componentType' when calling getComponentTypeSpecifications");
    }


    let pathParams = {
      'componentType': componentType
    };
    let queryParams = {
      'specificationType': opts['specificationType'],
      'componentPartNumber': opts['componentPartNumber']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Specification];

    return this.apiClient.callApi(
      '/component/templates/{componentType}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getComponentTypeSpecifications(componentType, opts) {
    return this.getComponentTypeSpecificationsWithHttpInfo(componentType, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getComponentsWithHttpInfo(filter) {
    let postBody = filter;

    // verify the required parameter 'filter' is set
    if (filter === undefined || filter === null) {
      throw new Error("Missing the required parameter 'filter' when calling getComponents");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ComponentFilterResult;

    return this.apiClient.callApi(
      '/components/filter', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getComponents(filter) {
    return this.getComponentsWithHttpInfo(filter).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOfflineComponentSourceWithHttpInfo(sourceId) {
    let postBody = null;

    // verify the required parameter 'sourceId' is set
    if (sourceId === undefined || sourceId === null) {
      throw new Error("Missing the required parameter 'sourceId' when calling getOfflineComponentSource");
    }


    let pathParams = {
      'sourceId': sourceId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = OfflineComponentSource;

    return this.apiClient.callApi(
      '/offlineComponentSource/{sourceId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOfflineComponentSource(sourceId) {
    return this.getOfflineComponentSourceWithHttpInfo(sourceId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getPackageWithHttpInfo(_package) {
    let postBody = null;

    // verify the required parameter '_package' is set
    if (_package === undefined || _package === null) {
      throw new Error("Missing the required parameter '_package' when calling getPackage");
    }


    let pathParams = {
      'package': _package
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = PackageAndMountingTechnology;

    return this.apiClient.callApi(
      '/packages/{package}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getPackage(_package) {
    return this.getPackageWithHttpInfo(_package).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getSpecificationTemplateTypedComponentsWithHttpInfo(specificationType, specificationId, componentType, opts) {
    opts = opts || {};
    let postBody = null;

    // verify the required parameter 'specificationType' is set
    if (specificationType === undefined || specificationType === null) {
      throw new Error("Missing the required parameter 'specificationType' when calling getSpecificationTemplateTypedComponents");
    }

    // verify the required parameter 'specificationId' is set
    if (specificationId === undefined || specificationId === null) {
      throw new Error("Missing the required parameter 'specificationId' when calling getSpecificationTemplateTypedComponents");
    }

    // verify the required parameter 'componentType' is set
    if (componentType === undefined || componentType === null) {
      throw new Error("Missing the required parameter 'componentType' when calling getSpecificationTemplateTypedComponents");
    }


    let pathParams = {
      'specificationType': specificationType,
      'specificationId': specificationId,
      'componentType': componentType
    };
    let queryParams = {
      'componentPartNumber': opts['componentPartNumber']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [SpecificationItem];

    return this.apiClient.callApi(
      '/component/templates/{specificationType}/{specificationId}/components/{componentType}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getSpecificationTemplateTypedComponents(specificationType, specificationId, componentType, opts) {
    return this.getSpecificationTemplateTypedComponentsWithHttpInfo(specificationType, specificationId, componentType, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  importCrossReferencesCompatiblesCsvWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling importCrossReferencesCompatiblesCsv");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/crossReferences/import_compatibility', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  importCrossReferencesCompatiblesCsv(content) {
    return this.importCrossReferencesCompatiblesCsvWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  importCrossReferencesDeclinationsCsvWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling importCrossReferencesDeclinationsCsv");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/crossReferences/import_declinations', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  importCrossReferencesDeclinationsCsv(content) {
    return this.importCrossReferencesDeclinationsCsvWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  mergeCrossReferenceDeclinationsWithHttpInfo(partNumber1, partNumber2) {
    let postBody = null;

    // verify the required parameter 'partNumber1' is set
    if (partNumber1 === undefined || partNumber1 === null) {
      throw new Error("Missing the required parameter 'partNumber1' when calling mergeCrossReferenceDeclinations");
    }

    // verify the required parameter 'partNumber2' is set
    if (partNumber2 === undefined || partNumber2 === null) {
      throw new Error("Missing the required parameter 'partNumber2' when calling mergeCrossReferenceDeclinations");
    }


    let pathParams = {
      'partNumber1': partNumber1,
      'partNumber2': partNumber2
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/crossReferences/declinations/merge/{partNumber1}/{partNumber2}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  mergeCrossReferenceDeclinations(partNumber1, partNumber2) {
    return this.mergeCrossReferenceDeclinationsWithHttpInfo(partNumber1, partNumber2).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeFromCrossReferenceDeclinationsWithHttpInfo(partNumber) {
    let postBody = null;

    // verify the required parameter 'partNumber' is set
    if (partNumber === undefined || partNumber === null) {
      throw new Error("Missing the required parameter 'partNumber' when calling removeFromCrossReferenceDeclinations");
    }


    let pathParams = {
      'partNumber': partNumber
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/crossReferences/declinations/remove/{partNumber}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeFromCrossReferenceDeclinations(partNumber) {
    return this.removeFromCrossReferenceDeclinationsWithHttpInfo(partNumber).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  searchOfflineComponentSourcesWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'mpn': opts['mpn'],
      'sku': opts['sku'],
      'supplier': opts['supplier'],
      'keywords': opts['keywords'],
      'category': opts['category']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [OfflineComponentSource];

    return this.apiClient.callApi(
      '/offlineComponentSource', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  searchOfflineComponentSources(opts) {
    return this.searchOfflineComponentSourcesWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setComponentWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setComponent");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = CategorizedComponent;

    return this.apiClient.callApi(
      '/components', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setComponent(content) {
    return this.setComponentWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setComponentCategoryWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setComponentCategory");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ComponentCategory;

    return this.apiClient.callApi(
      '/components/category', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setComponentCategory(content) {
    return this.setComponentCategoryWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setComponentPropertyEncodingWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setComponentPropertyEncoding");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = PropertyEncoding;

    return this.apiClient.callApi(
      '/components/property/encodings', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setComponentPropertyEncoding(content) {
    return this.setComponentPropertyEncodingWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setComponentPropertyMeasureWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setComponentPropertyMeasure");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = PropertyMeasure;

    return this.apiClient.callApi(
      '/components/property/measures', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setComponentPropertyMeasure(content) {
    return this.setComponentPropertyMeasureWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setComponentPropertyUnitWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setComponentPropertyUnit");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = PropertyUnit;

    return this.apiClient.callApi(
      '/components/property/units', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setComponentPropertyUnit(content) {
    return this.setComponentPropertyUnitWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setOfflineComponentSourceWithHttpInfo(sourceId, content) {
    let postBody = content;

    // verify the required parameter 'sourceId' is set
    if (sourceId === undefined || sourceId === null) {
      throw new Error("Missing the required parameter 'sourceId' when calling setOfflineComponentSource");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setOfflineComponentSource");
    }


    let pathParams = {
      'sourceId': sourceId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = OfflineComponentSource;

    return this.apiClient.callApi(
      '/offlineComponentSource/{sourceId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setOfflineComponentSource(sourceId, content) {
    return this.setOfflineComponentSourceWithHttpInfo(sourceId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setPackageWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setPackage");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/packages', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setPackage(content) {
    return this.setPackageWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}