/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class LogEntry {
  constructor(severity, description, _date) {
    if (severity) {
      this['severity'] = severity;
    }
    if (description) {
      this['description'] = description;
    }
    if (_date) {
      this['date'] = _date;
    }


  }

  static typeDescription() {
    return "LogEntry";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LogEntry();
      if (data.hasOwnProperty('severity')) {
        obj['severity'] = ApiClient.convertToType(data['severity'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
      if (data.hasOwnProperty('clientIP')) {
        obj['clientIP'] = ApiClient.convertToType(data['clientIP'], 'String');
      }
      if (data.hasOwnProperty('clientBrowser')) {
        obj['clientBrowser'] = ApiClient.convertToType(data['clientBrowser'], 'String');
      }
      if (data.hasOwnProperty('clientLogin')) {
        obj['clientLogin'] = ApiClient.convertToType(data['clientLogin'], 'String');
      }
      if (data.hasOwnProperty('request')) {
        obj['request'] = ApiClient.convertToType(data['request'], 'String');
      }
    }
    return obj;
  }


  severity = "debug";

  description = "";

  date = "";

  clientIP = undefined;

  clientBrowser = undefined;

  clientLogin = undefined;

  request = undefined;




  getSeverity() {
    return this.severity;
  }

  setSeverity(severity) {
    this['severity'] = severity;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }

  getClientIP() {
    return this.clientIP;
  }

  setClientIP(clientIP) {
    this['clientIP'] = clientIP;
  }

  getClientBrowser() {
    return this.clientBrowser;
  }

  setClientBrowser(clientBrowser) {
    this['clientBrowser'] = clientBrowser;
  }

  getClientLogin() {
    return this.clientLogin;
  }

  setClientLogin(clientLogin) {
    this['clientLogin'] = clientLogin;
  }

  getRequest() {
    return this.request;
  }

  setRequest(request) {
    this['request'] = request;
  }


  static SeverityEnum = {
    "debug": "debug",
    "info": "info",
    "warning": "warning",
    "error": "error",
    "critical": "critical"
  };

}