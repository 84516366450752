import { update, updateIndex } from "../utils/updateArray";

const orders = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "orders/GET_ALL") {
    return [...action.data];
  }
  if (action.type === "orders/SET") {
    return update(state, action.data);
  }
  return state;
};

export default function (state, action) {
  return orders(state, action).map((order) => {
    let status = "waitingForPayment";
    if (order.isPaid) {
      status = "validating";
    }
    if (order.isValidated) {
      status = "inProduction";
    }
    if (order.deliveryProgress) {
      status = "delivering";
    }
    if (order.deliveryProgress === 1) {
      status = "closed";
    }
    if (order.isCancelled) {
      status = "cancelled";
    }
    return Object.assign({}, order, { status });
  });
}