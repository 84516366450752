/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class TrackingInfos {
  constructor(type, operator) {
    if (type) {
      this['type'] = type;
    }
    if (operator) {
      this['operator'] = operator;
    }


  }

  static typeDescription() {
    return "TrackingInfos";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TrackingInfos();
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('operator')) {
        obj['operator'] = ApiClient.convertToType(data['operator'], 'String');
      }
    }
    return obj;
  }


  type = "start";

  operator = "";




  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getOperator() {
    return this.operator;
  }

  setOperator(operator) {
    this['operator'] = operator;
  }


  static TypeEnum = {
    "start": "start",
    "end": "end",
    "followup": "followup"
  };

}