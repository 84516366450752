/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PropertyDescription from './PropertyDescription';

export default class PropertyUnit {
  constructor(code, dataType) {
    if (code) {
      this['code'] = code;
    }
    if (dataType) {
      this['dataType'] = dataType;
    }


  }

  static typeDescription() {
    return "PropertyUnit";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PropertyUnit();
      if (data.hasOwnProperty('code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String');
      }
      if (data.hasOwnProperty('dataType')) {
        obj['dataType'] = ApiClient.convertToType(data['dataType'], 'String');
      }
      if (data.hasOwnProperty('symbols')) {
        obj['symbols'] = ApiClient.convertToType(data['symbols'], ['String']);
      }
      if (data.hasOwnProperty('descriptions')) {
        obj['descriptions'] = PropertyDescription.constructFromObject(data['descriptions']);
      }
    }
    return obj;
  }


  code = "";

  dataType = "Boolean";

  symbols = undefined;

  descriptions = undefined;




  getCode() {
    return this.code;
  }

  setCode(code) {
    this['code'] = code;
  }

  getDataType() {
    return this.dataType;
  }

  setDataType(dataType) {
    this['dataType'] = dataType;
  }

  getSymbols() {
    return this.symbols;
  }

  setSymbols(symbols) {
    this['symbols'] = symbols;
  }

  getDescriptions() {
    return this.descriptions;
  }

  setDescriptions(descriptions) {
    this['descriptions'] = descriptions;
  }


  static DataTypeEnum = {
    "Boolean": "Boolean",
    "Integer": "Integer",
    "UnsignedInteger": "UnsignedInteger",
    "FloatingPoint": "FloatingPoint",
    "UnsignedFloatingPoint": "UnsignedFloatingPoint",
    "String": "String"
  };

}