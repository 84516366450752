import { store } from "initStore";
import MessagingApi from "api/api/MessagingApi";
// import requestsActions from "Requests/actions";
// import request from "superagent";

const messagingApi = new MessagingApi();

const actions = {
  show: () =>
  store.dispatch({
    type: "messaging/SHOW"
  }),
  hide: () =>
  store.dispatch({
    type: "messaging/HIDE"
  }),
  toggleMinimize: () =>
  store.dispatch({
    type: "messaging/TOGGLE_MESSAGING"
  }),
  getChatThread: (userId) => {
    if (isChatInMaintenance()) {
      return Promise.reject();
    }
    const state = store.getState();
    const { messaging = {} } = state;
    const { thread = {} } = messaging;
    let filter = {
      objectType: "user",
      objectId: userId,
      threadTitleIsMatching: "Chat"
    };
    if (thread.id) {
      filter.messageIsUnreadByRequester = true;
    }
    return actions.
    getThreads(filter).
    then((threads) => {
      const chatThread = threads[0];
      const res = Object.assign({ title: "Chat" }, thread, chatThread, {
        unread: chatThread && thread.id ? true : false
      });
      return res;
    }).
    then((data) => {
      console.log("Messaging getThread data", data, thread);
      store.dispatch({
        type: "messaging/SET_THREAD",
        data
      });
      // console.log('messaging chatThread', chatThread)
      return data;
    });
  },
  // .then((chatThread) =>
  //   (chatThread.messageIds || []).forEach((id) => actions.getMessage(id))
  // )
  // getUnreadThread: (userId) => {
  //   const state = store.getState();
  //   const { messaging = {} } = state;
  //   const { thread = {} } = messaging;
  //   actions
  //     .getThreads({
  //       objectType: "user",
  //       objectId: userId,
  //       messageIsUnreadByRequester: true,
  //     })
  //     .then((threads) => {
  //       console.log("Messaging getUnreadThread", threads);
  //     });
  // },
  createThread: (content, opts) =>
  isChatInMaintenance() ?
  Promise.reject() :
  messagingApi.createMessageThread(content, opts),
  getThreads: (opts) =>
  isChatInMaintenance() ?
  Promise.reject() :
  messagingApi.getMessageThreads(opts),
  getMessage: (id) =>
  isChatInMaintenance() ?
  Promise.reject() :
  messagingApi.getMessage(id).then((message) => {
    store.dispatch({
      type: "messaging/SET_MESSAGE",
      data: message
    });
    return message;
  }),
  setMessageRead: (id) =>
  isChatInMaintenance() ?
  Promise.reject() :
  messagingApi.setMessageRead(id).then((message) => {
    store.dispatch({
      type: "messaging/SET_MESSAGE",
      data: message
    });
    return message;
  }),
  sendMessage: (threadId, content) =>
  isChatInMaintenance() ?
  Promise.reject() :
  messagingApi.
  createMessage(threadId, {
    body: content,
    threadId,
    subject: "chat"
  }).
  then((message) => messagingApi.postMessage(message.id)).
  then((message) => {
    store.dispatch({
      type: "messaging/SET_MESSAGE",
      data: message
    });
    return actions.getThread(threadId);
  }),
  getThread: (threadId) =>
  isChatInMaintenance() ?
  Promise.reject() :
  messagingApi.getMessageThread(threadId).then((thread) => {
    store.dispatch({
      type: "messaging/SET_THREAD",
      data: thread
    });
    // thread.messageIds.forEach(id => actions.getMessage(id))
    actions.getMessages();
    return thread;
  }),
  getMessages: () => {
    if (isChatInMaintenance()) {
      return Promise.reject();
    }
    const state = store.getState().messaging;
    const messageIds = state.messageIds || [];
    const messages = state.messages || [];
    messageIds.
    filter((id) => !messages.find((m) => m.id === id)).
    forEach(actions.getMessage);
  }
};

const isChatInMaintenance = () => {
  const state = store.getState();
  const { misc = {} } = state;
  const { cpmMaintenance } = misc;
  return (
    cpmMaintenance.indexOf("all") >= 0 || cpmMaintenance.indexOf("chat") >= 0);

};

export default actions;