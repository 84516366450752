/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ManufacturingOperatorSkills from './ManufacturingOperatorSkills';
import ManufacturingResource from './ManufacturingResource';

export default class ManufacturingOperator {
  constructor(asResource, skills) {
    this['asResource'] = asResource || new ManufacturingResource();
    this['skills'] = skills || [];


  }

  static typeDescription() {
    return "ManufacturingOperator";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingOperator();
      if (data.hasOwnProperty('asResource')) {
        obj['asResource'] = ManufacturingResource.constructFromObject(data['asResource']);
      }
      if (data.hasOwnProperty('skills')) {
        obj['skills'] = ApiClient.convertToType(data['skills'], [ManufacturingOperatorSkills]);
      }
    }
    return obj;
  }


  asResource = undefined;

  skills = [];




  getAsResource() {
    return this.asResource;
  }

  setAsResource(asResource) {
    this['asResource'] = asResource;
  }

  getSkills() {
    return this.skills;
  }

  setSkills(skills) {
    this['skills'] = skills;
  }


}