import React from "react";
import FormControl from "./FormControl";
import { convertFloat, unitFloat } from "utils/float";

class SIInput extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = this.initState(props);
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    const { value = 0 } = props;
    const { value: oValue = 0 } = oProps;
    if (isNaN(value) && isNaN(oValue)) {
      return;
    }
    if (value != oValue) {
      console.log("floatInput didUpdate", value, oValue);
      this.setState(this.initState());
    }
  }

  initState(props = this.props) {
    const { value = 0, symbol, defaultSize, pitch, fixed } = props;
    return {
      value: unitFloat(value, symbol, defaultSize, pitch, fixed)
    };
  }

  render() {
    const { props, state } = this;
    const { placeholder = "", symbol, defaultSize, pitch } = props;
    const { value = "" } = state;
    console.log("SIInput render", props, state);
    return (
      <FormControl
        {...props}
        placeholder={placeholder + (symbol ? " (" + symbol + ")" : "")}
        value={value}
        onChange={(e) => this.setState({ value: e.target.value })}
        onBlur={(e) => {
          const value = convertFloat(
            e.target.value,
            symbol,
            defaultSize,
            pitch
          );
          props.onChange(value);
        }}
        type="text" />);


  }
}

export default SIInput;