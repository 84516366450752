/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Pricing {
  constructor(value, taxRate, discountRate, progress, mode, isValid) {
    if (value) {
      this['value'] = value;
    }
    if (taxRate) {
      this['taxRate'] = taxRate;
    }
    if (discountRate) {
      this['discountRate'] = discountRate;
    }
    if (progress) {
      this['progress'] = progress;
    }
    if (mode) {
      this['mode'] = mode;
    }
    if (isValid) {
      this['isValid'] = isValid;
    }


  }

  static typeDescription() {
    return "Pricing";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Pricing();
      if (data.hasOwnProperty('value')) {
        obj['value'] = ApiClient.convertToType(data['value'], 'Number');
      }
      if (data.hasOwnProperty('taxRate')) {
        obj['taxRate'] = ApiClient.convertToType(data['taxRate'], 'Number');
      }
      if (data.hasOwnProperty('discountRate')) {
        obj['discountRate'] = ApiClient.convertToType(data['discountRate'], 'Number');
      }
      if (data.hasOwnProperty('progress')) {
        obj['progress'] = ApiClient.convertToType(data['progress'], 'Number');
      }
      if (data.hasOwnProperty('mode')) {
        obj['mode'] = ApiClient.convertToType(data['mode'], 'String');
      }
      if (data.hasOwnProperty('isValid')) {
        obj['isValid'] = ApiClient.convertToType(data['isValid'], 'Boolean');
      }
    }
    return obj;
  }


  value = 0.0;

  taxRate = 0.0;

  discountRate = 0.0;

  progress = 0.0;

  mode = "auto";

  isValid = false;




  getValue() {
    return this.value;
  }

  setValue(value) {
    this['value'] = value;
  }

  getTaxRate() {
    return this.taxRate;
  }

  setTaxRate(taxRate) {
    this['taxRate'] = taxRate;
  }

  getDiscountRate() {
    return this.discountRate;
  }

  setDiscountRate(discountRate) {
    this['discountRate'] = discountRate;
  }

  getProgress() {
    return this.progress;
  }

  setProgress(progress) {
    this['progress'] = progress;
  }

  getMode() {
    return this.mode;
  }

  setMode(mode) {
    this['mode'] = mode;
  }

  getIsValid() {
    return this.isValid;
  }

  setIsValid(isValid) {
    this['isValid'] = isValid;
  }


  static ModeEnum = {
    "auto": "auto",
    "manual": "manual"
  };

}