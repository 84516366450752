import React from "react";
import Section from "marketing/components/Section";
import Content from "components/ContentEditor/Content";

class Testimonials extends React.Component {
  render() {
    const { props } = this;
    const { data = {} } = props;
    const { articles = [], title } = data;
    console.log("TestimonialsEmployee render", props);
    if (!articles.length) {
      return null;
    }
    return (
      <Section
        title={title}
        className="testimonials"
        // noContainer
        fluidContainer>

        <div className="row text-center">
          {articles.slice(0, 3).map((article, i) => {
            const { cover = {} } = article;
            return (
              <div className="col-12 col-md testimonial" key={i}>
                <img
                  className="lazyload"
                  data-src={cover.preview}
                  // srcset={cover.srcset}
                  alt={cover.description} />

                <h5 className="testimonial__title">{article.title}</h5>
                <div className="testimonial__text">
                  <Content blocks={article.content} />
                </div>
              </div>);

          })}
        </div>
      </Section>);

  }
}

export default Testimonials;