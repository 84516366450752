/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ProductionProgress from './ProductionProgress';

export default class Quotation {
  constructor() {


  }

  static typeDescription() {
    return "Quotation";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Quotation();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('trackingId')) {
        obj['trackingId'] = ApiClient.convertToType(data['trackingId'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('ownerName')) {
        obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
      }
      if (data.hasOwnProperty('isInProduction')) {
        obj['isInProduction'] = ApiClient.convertToType(data['isInProduction'], 'Boolean');
      }
      if (data.hasOwnProperty('isProductionHalted')) {
        obj['isProductionHalted'] = ApiClient.convertToType(data['isProductionHalted'], 'Boolean');
      }
      if (data.hasOwnProperty('productionHaltDate')) {
        obj['productionHaltDate'] = ApiClient.convertToType(data['productionHaltDate'], 'String');
      }
      if (data.hasOwnProperty('accessPermission')) {
        obj['accessPermission'] = ApiClient.convertToType(data['accessPermission'], 'String');
      }
      if (data.hasOwnProperty('editionAllowed')) {
        obj['editionAllowed'] = ApiClient.convertToType(data['editionAllowed'], 'Boolean');
      }
      if (data.hasOwnProperty('isValid')) {
        obj['isValid'] = ApiClient.convertToType(data['isValid'], 'Boolean');
      }
      if (data.hasOwnProperty('isPriced')) {
        obj['isPriced'] = ApiClient.convertToType(data['isPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('isManuallyPriced')) {
        obj['isManuallyPriced'] = ApiClient.convertToType(data['isManuallyPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('isNoDeliveryPriced')) {
        obj['isNoDeliveryPriced'] = ApiClient.convertToType(data['isNoDeliveryPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('totalPriceTaxesExcluded')) {
        obj['totalPriceTaxesExcluded'] = ApiClient.convertToType(data['totalPriceTaxesExcluded'], 'Number');
      }
      if (data.hasOwnProperty('totalPriceTaxesIncluded')) {
        obj['totalPriceTaxesIncluded'] = ApiClient.convertToType(data['totalPriceTaxesIncluded'], 'Number');
      }
      if (data.hasOwnProperty('specificationId')) {
        obj['specificationId'] = ApiClient.convertToType(data['specificationId'], 'String');
      }
      if (data.hasOwnProperty('specId')) {
        obj['specId'] = ApiClient.convertToType(data['specId'], 'String');
      }
      if (data.hasOwnProperty('quantity')) {
        obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
      }
      if (data.hasOwnProperty('durationDays')) {
        obj['durationDays'] = ApiClient.convertToType(data['durationDays'], 'Number');
      }
      if (data.hasOwnProperty('quoteIds')) {
        obj['quoteIds'] = ApiClient.convertToType(data['quoteIds'], ['String']);
      }
      if (data.hasOwnProperty('allQuoteIds')) {
        obj['allQuoteIds'] = ApiClient.convertToType(data['allQuoteIds'], ['String']);
      }
      if (data.hasOwnProperty('isPublic')) {
        obj['isPublic'] = ApiClient.convertToType(data['isPublic'], 'Boolean');
      }
      if (data.hasOwnProperty('isReadOnly')) {
        obj['isReadOnly'] = ApiClient.convertToType(data['isReadOnly'], 'Boolean');
      }
      if (data.hasOwnProperty('orderId')) {
        obj['orderId'] = ApiClient.convertToType(data['orderId'], 'String');
      }
      if (data.hasOwnProperty('expectedExpeditionDate')) {
        obj['expectedExpeditionDate'] = ApiClient.convertToType(data['expectedExpeditionDate'], 'Date');
      }
      if (data.hasOwnProperty('productionStartDay')) {
        obj['productionStartDay'] = ApiClient.convertToType(data['productionStartDay'], 'Date');
      }
      if (data.hasOwnProperty('productionProgress')) {
        obj['productionProgress'] = ProductionProgress.constructFromObject(data['productionProgress']);
      }
    }
    return obj;
  }


  id = undefined;

  trackingId = undefined;

  type = undefined;

  name = undefined;

  reference = undefined;

  creationDate = undefined;

  ownerId = undefined;

  ownerName = undefined;

  isInProduction = undefined;

  isProductionHalted = undefined;

  productionHaltDate = undefined;

  accessPermission = undefined;

  editionAllowed = undefined;

  isValid = undefined;

  isPriced = undefined;

  isManuallyPriced = undefined;

  isNoDeliveryPriced = undefined;

  totalPriceTaxesExcluded = undefined;

  totalPriceTaxesIncluded = undefined;

  specificationId = undefined;

  specId = undefined;

  quantity = undefined;

  durationDays = undefined;

  quoteIds = undefined;

  allQuoteIds = undefined;

  isPublic = undefined;

  isReadOnly = undefined;

  orderId = undefined;

  expectedExpeditionDate = undefined;

  productionStartDay = undefined;

  productionProgress = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getTrackingId() {
    return this.trackingId;
  }

  setTrackingId(trackingId) {
    this['trackingId'] = trackingId;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getOwnerName() {
    return this.ownerName;
  }

  setOwnerName(ownerName) {
    this['ownerName'] = ownerName;
  }

  getIsInProduction() {
    return this.isInProduction;
  }

  setIsInProduction(isInProduction) {
    this['isInProduction'] = isInProduction;
  }

  getIsProductionHalted() {
    return this.isProductionHalted;
  }

  setIsProductionHalted(isProductionHalted) {
    this['isProductionHalted'] = isProductionHalted;
  }

  getProductionHaltDate() {
    return this.productionHaltDate;
  }

  setProductionHaltDate(productionHaltDate) {
    this['productionHaltDate'] = productionHaltDate;
  }

  getAccessPermission() {
    return this.accessPermission;
  }

  setAccessPermission(accessPermission) {
    this['accessPermission'] = accessPermission;
  }

  getEditionAllowed() {
    return this.editionAllowed;
  }

  setEditionAllowed(editionAllowed) {
    this['editionAllowed'] = editionAllowed;
  }

  getIsValid() {
    return this.isValid;
  }

  setIsValid(isValid) {
    this['isValid'] = isValid;
  }

  getIsPriced() {
    return this.isPriced;
  }

  setIsPriced(isPriced) {
    this['isPriced'] = isPriced;
  }

  getIsManuallyPriced() {
    return this.isManuallyPriced;
  }

  setIsManuallyPriced(isManuallyPriced) {
    this['isManuallyPriced'] = isManuallyPriced;
  }

  getIsNoDeliveryPriced() {
    return this.isNoDeliveryPriced;
  }

  setIsNoDeliveryPriced(isNoDeliveryPriced) {
    this['isNoDeliveryPriced'] = isNoDeliveryPriced;
  }

  getTotalPriceTaxesExcluded() {
    return this.totalPriceTaxesExcluded;
  }

  setTotalPriceTaxesExcluded(totalPriceTaxesExcluded) {
    this['totalPriceTaxesExcluded'] = totalPriceTaxesExcluded;
  }

  getTotalPriceTaxesIncluded() {
    return this.totalPriceTaxesIncluded;
  }

  setTotalPriceTaxesIncluded(totalPriceTaxesIncluded) {
    this['totalPriceTaxesIncluded'] = totalPriceTaxesIncluded;
  }

  getSpecificationId() {
    return this.specificationId;
  }

  setSpecificationId(specificationId) {
    this['specificationId'] = specificationId;
  }

  getSpecId() {
    return this.specId;
  }

  setSpecId(specId) {
    this['specId'] = specId;
  }

  getQuantity() {
    return this.quantity;
  }

  setQuantity(quantity) {
    this['quantity'] = quantity;
  }

  getDurationDays() {
    return this.durationDays;
  }

  setDurationDays(durationDays) {
    this['durationDays'] = durationDays;
  }

  getQuoteIds() {
    return this.quoteIds;
  }

  setQuoteIds(quoteIds) {
    this['quoteIds'] = quoteIds;
  }

  getAllQuoteIds() {
    return this.allQuoteIds;
  }

  setAllQuoteIds(allQuoteIds) {
    this['allQuoteIds'] = allQuoteIds;
  }

  getIsPublic() {
    return this.isPublic;
  }

  setIsPublic(isPublic) {
    this['isPublic'] = isPublic;
  }

  getIsReadOnly() {
    return this.isReadOnly;
  }

  setIsReadOnly(isReadOnly) {
    this['isReadOnly'] = isReadOnly;
  }

  getOrderId() {
    return this.orderId;
  }

  setOrderId(orderId) {
    this['orderId'] = orderId;
  }

  getExpectedExpeditionDate() {
    return this.expectedExpeditionDate;
  }

  setExpectedExpeditionDate(expectedExpeditionDate) {
    this['expectedExpeditionDate'] = expectedExpeditionDate;
  }

  getProductionStartDay() {
    return this.productionStartDay;
  }

  setProductionStartDay(productionStartDay) {
    this['productionStartDay'] = productionStartDay;
  }

  getProductionProgress() {
    return this.productionProgress;
  }

  setProductionProgress(productionProgress) {
    this['productionProgress'] = productionProgress;
  }


  static TypeEnum = {
    "assembly": "assembly",
    "coating": "coating",
    "dfm": "dfm",
    "enclosure": "enclosure",
    "engineering": "engineering",
    "pcb": "pcb",
    "pcba": "pcba",
    "pcbDesign": "pcbDesign",
    "rework": "rework",
    "supply": "supply",
    "wiring": "wiring",
    "xray": "xray"
  };

  static AccessPermissionEnum = {
    "no_access": "no_access",
    "read_only": "read_only",
    "read_and_write": "read_and_write",
    "full": "full"
  };

}