import { update } from "utils/updateArray";

const savedCarts = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "savedCarts/setAll") {
    return action.data;
  }
  if (action.type === "savedCarts/set") {
    return update(state, action.data);
  }
  return state;
};

export default savedCarts;