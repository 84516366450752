/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PaymentInvoicesDispatch from './PaymentInvoicesDispatch';
import PaymentOrdersDispatch from './PaymentOrdersDispatch';

export default class PaymentTransaction {
  constructor() {


  }

  static typeDescription() {
    return "PaymentTransaction";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PaymentTransaction();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('mode')) {
        obj['mode'] = ApiClient.convertToType(data['mode'], 'String');
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('devise')) {
        obj['devise'] = ApiClient.convertToType(data['devise'], 'String');
      }
      if (data.hasOwnProperty('service')) {
        obj['service'] = ApiClient.convertToType(data['service'], 'String');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String');
      }
      if (data.hasOwnProperty('authorizationNumber')) {
        obj['authorizationNumber'] = ApiClient.convertToType(data['authorizationNumber'], 'String');
      }
      if (data.hasOwnProperty('cvx')) {
        obj['cvx'] = ApiClient.convertToType(data['cvx'], 'Boolean');
      }
      if (data.hasOwnProperty('cbNumber')) {
        obj['cbNumber'] = ApiClient.convertToType(data['cbNumber'], 'String');
      }
      if (data.hasOwnProperty('validity')) {
        obj['validity'] = ApiClient.convertToType(data['validity'], 'Date');
      }
      if (data.hasOwnProperty('brand')) {
        obj['brand'] = ApiClient.convertToType(data['brand'], 'String');
      }
      if (data.hasOwnProperty('abortReason')) {
        obj['abortReason'] = ApiClient.convertToType(data['abortReason'], 'String');
      }
      if (data.hasOwnProperty('invoicesDispatch')) {
        obj['invoicesDispatch'] = ApiClient.convertToType(data['invoicesDispatch'], [PaymentInvoicesDispatch]);
      }
      if (data.hasOwnProperty('ordersDispatch')) {
        obj['ordersDispatch'] = ApiClient.convertToType(data['ordersDispatch'], [PaymentOrdersDispatch]);
      }
      if (data.hasOwnProperty('totalAmount')) {
        obj['totalAmount'] = ApiClient.convertToType(data['totalAmount'], 'Number');
      }
      if (data.hasOwnProperty('surchargeAmount')) {
        obj['surchargeAmount'] = ApiClient.convertToType(data['surchargeAmount'], 'Number');
      }
      if (data.hasOwnProperty('files')) {
        obj['files'] = ApiClient.convertToType(data['files'], ['String']);
      }
    }
    return obj;
  }


  id = undefined;

  ownerId = undefined;

  mode = undefined;

  date = undefined;

  email = undefined;

  reference = undefined;

  devise = undefined;

  service = undefined;

  status = undefined;

  authorizationNumber = undefined;

  cvx = undefined;

  cbNumber = undefined;

  validity = undefined;

  brand = undefined;

  abortReason = undefined;

  invoicesDispatch = undefined;

  ordersDispatch = undefined;

  totalAmount = undefined;

  surchargeAmount = undefined;

  files = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getMode() {
    return this.mode;
  }

  setMode(mode) {
    this['mode'] = mode;
  }

  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    this['email'] = email;
  }

  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getDevise() {
    return this.devise;
  }

  setDevise(devise) {
    this['devise'] = devise;
  }

  getService() {
    return this.service;
  }

  setService(service) {
    this['service'] = service;
  }

  getStatus() {
    return this.status;
  }

  setStatus(status) {
    this['status'] = status;
  }

  getAuthorizationNumber() {
    return this.authorizationNumber;
  }

  setAuthorizationNumber(authorizationNumber) {
    this['authorizationNumber'] = authorizationNumber;
  }

  getCvx() {
    return this.cvx;
  }

  setCvx(cvx) {
    this['cvx'] = cvx;
  }

  getCbNumber() {
    return this.cbNumber;
  }

  setCbNumber(cbNumber) {
    this['cbNumber'] = cbNumber;
  }

  getValidity() {
    return this.validity;
  }

  setValidity(validity) {
    this['validity'] = validity;
  }

  getBrand() {
    return this.brand;
  }

  setBrand(brand) {
    this['brand'] = brand;
  }

  getAbortReason() {
    return this.abortReason;
  }

  setAbortReason(abortReason) {
    this['abortReason'] = abortReason;
  }

  getInvoicesDispatch() {
    return this.invoicesDispatch;
  }

  setInvoicesDispatch(invoicesDispatch) {
    this['invoicesDispatch'] = invoicesDispatch;
  }

  getOrdersDispatch() {
    return this.ordersDispatch;
  }

  setOrdersDispatch(ordersDispatch) {
    this['ordersDispatch'] = ordersDispatch;
  }

  getTotalAmount() {
    return this.totalAmount;
  }

  setTotalAmount(totalAmount) {
    this['totalAmount'] = totalAmount;
  }

  getSurchargeAmount() {
    return this.surchargeAmount;
  }

  setSurchargeAmount(surchargeAmount) {
    this['surchargeAmount'] = surchargeAmount;
  }

  getFiles() {
    return this.files;
  }

  setFiles(files) {
    this['files'] = files;
  }


  static ModeEnum = {
    "cb": "cb",
    "paypal": "paypal",
    "cash": "cash",
    "cheque": "cheque",
    "transfer": "transfer",
    "account": "account",
    "tip": "tip",
    "transferProof": "transferProof"
  };

  static StatusEnum = {
    "payed": "payed",
    "cancelled": "cancelled"
  };

  static BrandEnum = {
    "AM": "AM",
    "CB": "CB",
    "MC": "MC",
    "VI": "VI",
    "na": "na"
  };

  static AbortReasonEnum = {
    "phonyRequest": "phonyRequest",
    "refused": "refused",
    "unallowed": "unallowed",
    "filtered": "filtered",
    "scoring": "scoring",
    "ThreeDSecure": "ThreeDSecure"
  };

}