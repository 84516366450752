import React from "react";
import { injectIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  date: { "id": "marketing.DateInput.date", "defaultMessage": "Date" }



});

class InputDate extends React.Component {
  state = {};

  render() {
    const { props, state } = this;
    return (
      <input
        placeholder={props.intl.formatMessage(messages.date)}
        className="form-control"
        style={{ backgroundColor: "#e9ecef" }}
        type={state.date ? "date" : "text"}
        onClick={(e) => this.setState({ date: e.target })} />);


  }
}

export default injectIntl(InputDate);