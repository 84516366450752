/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class CustomerNotificationUsers {
  constructor(eventClass, userIds) {
    if (eventClass) {
      this['eventClass'] = eventClass;
    }
    this['userIds'] = userIds || [];


  }

  static typeDescription() {
    return "CustomerNotificationUsers";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerNotificationUsers();
      if (data.hasOwnProperty('eventClass')) {
        obj['eventClass'] = ApiClient.convertToType(data['eventClass'], 'String');
      }
      if (data.hasOwnProperty('userIds')) {
        obj['userIds'] = ApiClient.convertToType(data['userIds'], ['String']);
      }
    }
    return obj;
  }


  eventClass = "Account";

  userIds = [];




  getEventClass() {
    return this.eventClass;
  }

  setEventClass(eventClass) {
    this['eventClass'] = eventClass;
  }

  getUserIds() {
    return this.userIds;
  }

  setUserIds(userIds) {
    this['userIds'] = userIds;
  }


  static EventClassEnum = {
    "Account": "Account",
    "Anomaly": "Anomaly",
    "Financial": "Financial",
    "Order": "Order"
  };

}