/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ManufacturingOperationDateConstraints from './ManufacturingOperationDateConstraints';
import ManufacturingOperationOperationConstraints from './ManufacturingOperationOperationConstraints';

export default class ManufacturingOperation {
  constructor(code, id, name) {
    if (code) {
      this['code'] = code;
    }
    if (id) {
      this['id'] = id;
    }
    if (name) {
      this['name'] = name;
    }


  }

  static typeDescription() {
    return "ManufacturingOperation";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingOperation();
      if (data.hasOwnProperty('code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'Number');
      }
      if (data.hasOwnProperty('dateConstraints')) {
        obj['dateConstraints'] = ApiClient.convertToType(data['dateConstraints'], [ManufacturingOperationDateConstraints]);
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('executionContext')) {
        obj['executionContext'] = ApiClient.convertToType(data['executionContext'], 'String');
      }
      if (data.hasOwnProperty('executionQuantity')) {
        obj['executionQuantity'] = ApiClient.convertToType(data['executionQuantity'], 'Number');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('machineExecutionDurationSeconds')) {
        obj['machineExecutionDurationSeconds'] = ApiClient.convertToType(data['machineExecutionDurationSeconds'], 'Number');
      }
      if (data.hasOwnProperty('machineName')) {
        obj['machineName'] = ApiClient.convertToType(data['machineName'], 'String');
      }
      if (data.hasOwnProperty('machineCost')) {
        obj['machineCost'] = ApiClient.convertToType(data['machineCost'], 'Number');
      }
      if (data.hasOwnProperty('machineDepreciationCost')) {
        obj['machineDepreciationCost'] = ApiClient.convertToType(data['machineDepreciationCost'], 'Number');
      }
      if (data.hasOwnProperty('machineSetupDurationSeconds')) {
        obj['machineSetupDurationSeconds'] = ApiClient.convertToType(data['machineSetupDurationSeconds'], 'Number');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('operationConstraints')) {
        obj['operationConstraints'] = ApiClient.convertToType(data['operationConstraints'], [ManufacturingOperationOperationConstraints]);
      }
      if (data.hasOwnProperty('operationExecutionDurationSeconds')) {
        obj['operationExecutionDurationSeconds'] = ApiClient.convertToType(data['operationExecutionDurationSeconds'], 'Number');
      }
      if (data.hasOwnProperty('operationSetupDurationSeconds')) {
        obj['operationSetupDurationSeconds'] = ApiClient.convertToType(data['operationSetupDurationSeconds'], 'Number');
      }
      if (data.hasOwnProperty('operatorCost')) {
        obj['operatorCost'] = ApiClient.convertToType(data['operatorCost'], 'Number');
      }
      if (data.hasOwnProperty('operatorLevel')) {
        obj['operatorLevel'] = ApiClient.convertToType(data['operatorLevel'], 'String');
      }
      if (data.hasOwnProperty('operatorSetupCost')) {
        obj['operatorSetupCost'] = ApiClient.convertToType(data['operatorSetupCost'], 'Number');
      }
      if (data.hasOwnProperty('operatorExecutionDurationSeconds')) {
        obj['operatorExecutionDurationSeconds'] = ApiClient.convertToType(data['operatorExecutionDurationSeconds'], 'Number');
      }
      if (data.hasOwnProperty('operatorSetupDurationSeconds')) {
        obj['operatorSetupDurationSeconds'] = ApiClient.convertToType(data['operatorSetupDurationSeconds'], 'Number');
      }
      if (data.hasOwnProperty('parameters')) {
        obj['parameters'] = ApiClient.convertToType(data['parameters'], 'String');
      }
      if (data.hasOwnProperty('totalDurationSeconds')) {
        obj['totalDurationSeconds'] = ApiClient.convertToType(data['totalDurationSeconds'], 'Number');
      }
      if (data.hasOwnProperty('setupContext')) {
        obj['setupContext'] = ApiClient.convertToType(data['setupContext'], 'String');
      }
      if (data.hasOwnProperty('setupDurationSeconds')) {
        obj['setupDurationSeconds'] = ApiClient.convertToType(data['setupDurationSeconds'], 'Number');
      }
      if (data.hasOwnProperty('setupQuantity')) {
        obj['setupQuantity'] = ApiClient.convertToType(data['setupQuantity'], 'Number');
      }
      if (data.hasOwnProperty('soonestStart')) {
        obj['soonestStart'] = ApiClient.convertToType(data['soonestStart'], 'String');
      }
      if (data.hasOwnProperty('soonestEnd')) {
        obj['soonestEnd'] = ApiClient.convertToType(data['soonestEnd'], 'String');
      }
      if (data.hasOwnProperty('latestStart')) {
        obj['latestStart'] = ApiClient.convertToType(data['latestStart'], 'String');
      }
      if (data.hasOwnProperty('latestEnd')) {
        obj['latestEnd'] = ApiClient.convertToType(data['latestEnd'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
    }
    return obj;
  }


  code = 0;

  dateConstraints = undefined;

  description = undefined;

  executionContext = undefined;

  executionQuantity = undefined;

  id = "";

  machineExecutionDurationSeconds = undefined;

  machineName = undefined;

  machineCost = undefined;

  machineDepreciationCost = undefined;

  machineSetupDurationSeconds = undefined;

  name = "";

  operationConstraints = undefined;

  operationExecutionDurationSeconds = undefined;

  operationSetupDurationSeconds = undefined;

  operatorCost = undefined;

  operatorLevel = undefined;

  operatorSetupCost = undefined;

  operatorExecutionDurationSeconds = undefined;

  operatorSetupDurationSeconds = undefined;

  parameters = undefined;

  totalDurationSeconds = undefined;

  setupContext = undefined;

  setupDurationSeconds = undefined;

  setupQuantity = undefined;

  soonestStart = undefined;

  soonestEnd = undefined;

  latestStart = undefined;

  latestEnd = undefined;

  type = undefined;




  getCode() {
    return this.code;
  }

  setCode(code) {
    this['code'] = code;
  }

  getDateConstraints() {
    return this.dateConstraints;
  }

  setDateConstraints(dateConstraints) {
    this['dateConstraints'] = dateConstraints;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getExecutionContext() {
    return this.executionContext;
  }

  setExecutionContext(executionContext) {
    this['executionContext'] = executionContext;
  }

  getExecutionQuantity() {
    return this.executionQuantity;
  }

  setExecutionQuantity(executionQuantity) {
    this['executionQuantity'] = executionQuantity;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getMachineExecutionDurationSeconds() {
    return this.machineExecutionDurationSeconds;
  }

  setMachineExecutionDurationSeconds(machineExecutionDurationSeconds) {
    this['machineExecutionDurationSeconds'] = machineExecutionDurationSeconds;
  }

  getMachineName() {
    return this.machineName;
  }

  setMachineName(machineName) {
    this['machineName'] = machineName;
  }

  getMachineCost() {
    return this.machineCost;
  }

  setMachineCost(machineCost) {
    this['machineCost'] = machineCost;
  }

  getMachineDepreciationCost() {
    return this.machineDepreciationCost;
  }

  setMachineDepreciationCost(machineDepreciationCost) {
    this['machineDepreciationCost'] = machineDepreciationCost;
  }

  getMachineSetupDurationSeconds() {
    return this.machineSetupDurationSeconds;
  }

  setMachineSetupDurationSeconds(machineSetupDurationSeconds) {
    this['machineSetupDurationSeconds'] = machineSetupDurationSeconds;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getOperationConstraints() {
    return this.operationConstraints;
  }

  setOperationConstraints(operationConstraints) {
    this['operationConstraints'] = operationConstraints;
  }

  getOperationExecutionDurationSeconds() {
    return this.operationExecutionDurationSeconds;
  }

  setOperationExecutionDurationSeconds(operationExecutionDurationSeconds) {
    this['operationExecutionDurationSeconds'] = operationExecutionDurationSeconds;
  }

  getOperationSetupDurationSeconds() {
    return this.operationSetupDurationSeconds;
  }

  setOperationSetupDurationSeconds(operationSetupDurationSeconds) {
    this['operationSetupDurationSeconds'] = operationSetupDurationSeconds;
  }

  getOperatorCost() {
    return this.operatorCost;
  }

  setOperatorCost(operatorCost) {
    this['operatorCost'] = operatorCost;
  }

  getOperatorLevel() {
    return this.operatorLevel;
  }

  setOperatorLevel(operatorLevel) {
    this['operatorLevel'] = operatorLevel;
  }

  getOperatorSetupCost() {
    return this.operatorSetupCost;
  }

  setOperatorSetupCost(operatorSetupCost) {
    this['operatorSetupCost'] = operatorSetupCost;
  }

  getOperatorExecutionDurationSeconds() {
    return this.operatorExecutionDurationSeconds;
  }

  setOperatorExecutionDurationSeconds(operatorExecutionDurationSeconds) {
    this['operatorExecutionDurationSeconds'] = operatorExecutionDurationSeconds;
  }

  getOperatorSetupDurationSeconds() {
    return this.operatorSetupDurationSeconds;
  }

  setOperatorSetupDurationSeconds(operatorSetupDurationSeconds) {
    this['operatorSetupDurationSeconds'] = operatorSetupDurationSeconds;
  }

  getParameters() {
    return this.parameters;
  }

  setParameters(parameters) {
    this['parameters'] = parameters;
  }

  getTotalDurationSeconds() {
    return this.totalDurationSeconds;
  }

  setTotalDurationSeconds(totalDurationSeconds) {
    this['totalDurationSeconds'] = totalDurationSeconds;
  }

  getSetupContext() {
    return this.setupContext;
  }

  setSetupContext(setupContext) {
    this['setupContext'] = setupContext;
  }

  getSetupDurationSeconds() {
    return this.setupDurationSeconds;
  }

  setSetupDurationSeconds(setupDurationSeconds) {
    this['setupDurationSeconds'] = setupDurationSeconds;
  }

  getSetupQuantity() {
    return this.setupQuantity;
  }

  setSetupQuantity(setupQuantity) {
    this['setupQuantity'] = setupQuantity;
  }

  getSoonestStart() {
    return this.soonestStart;
  }

  setSoonestStart(soonestStart) {
    this['soonestStart'] = soonestStart;
  }

  getSoonestEnd() {
    return this.soonestEnd;
  }

  setSoonestEnd(soonestEnd) {
    this['soonestEnd'] = soonestEnd;
  }

  getLatestStart() {
    return this.latestStart;
  }

  setLatestStart(latestStart) {
    this['latestStart'] = latestStart;
  }

  getLatestEnd() {
    return this.latestEnd;
  }

  setLatestEnd(latestEnd) {
    this['latestEnd'] = latestEnd;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }


  static ExecutionContextEnum = {
    "byOrder": "byOrder",
    "byWorkOrder": "byWorkOrder",
    "byItem": "byItem"
  };

  static SetupContextEnum = {
    "none": "none",
    "once": "once",
    "byReference": "byReference",
    "byQuantityOrSize": "byQuantityOrSize",
    "byItem": "byItem"
  };

}