import React from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import ArrowIcon from 'Icon/Arrow';

class CarouselComponent extends React.Component {
  state = {
    activeIndex: this.props.activeIndex
  };
  prev() {
    this.setState({
      activeIndex: this.state.activeIndex === 0 ?
      this.props.items.length - 1 :
      this.state.activeIndex - 1
    });
  }
  next() {
    this.setState({
      activeIndex: (this.state.activeIndex + 1) % this.props.items.length
    });
  }
  render() {
    const { props, state } = this;
    return <Carousel
      activeIndex={state.activeIndex}
      next={(e) => this.next()}
      prev={(e) => this.prev()}>

      {props.items.map((item, i) => <CarouselItem
        key={i}>

        {item}
      </CarouselItem>)}

      <a className="carousel-control-prev" href="#" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        this.prev();
      }}>
      <ArrowIcon direction="left" className="carousel-control-prev-icon" />
      </a>
      <a className="carousel-control-next" href="#" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        this.next();
      }}>
      <ArrowIcon direction="right" className="carousel-control-next-icon" />
      </a>
    </Carousel>;
  }
}

export default CarouselComponent;