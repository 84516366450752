/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Event from './Event';

export default class TrackedQuotation {
  constructor() {


  }

  static typeDescription() {
    return "TrackedQuotation";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new TrackedQuotation();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('ownerName')) {
        obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('trackingId')) {
        obj['trackingId'] = ApiClient.convertToType(data['trackingId'], 'String');
      }
      if (data.hasOwnProperty('events')) {
        obj['events'] = ApiClient.convertToType(data['events'], [Event]);
      }
      if (data.hasOwnProperty('isPriced')) {
        obj['isPriced'] = ApiClient.convertToType(data['isPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('requiredAction')) {
        obj['requiredAction'] = ApiClient.convertToType(data['requiredAction'], 'String');
      }
    }
    return obj;
  }


  id = undefined;

  ownerId = undefined;

  ownerName = undefined;

  type = undefined;

  trackingId = undefined;

  events = undefined;

  isPriced = undefined;

  requiredAction = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getOwnerName() {
    return this.ownerName;
  }

  setOwnerName(ownerName) {
    this['ownerName'] = ownerName;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getTrackingId() {
    return this.trackingId;
  }

  setTrackingId(trackingId) {
    this['trackingId'] = trackingId;
  }

  getEvents() {
    return this.events;
  }

  setEvents(events) {
    this['events'] = events;
  }

  getIsPriced() {
    return this.isPriced;
  }

  setIsPriced(isPriced) {
    this['isPriced'] = isPriced;
  }

  getRequiredAction() {
    return this.requiredAction;
  }

  setRequiredAction(requiredAction) {
    this['requiredAction'] = requiredAction;
  }


  static TypeEnum = {
    "assembly_quotation": "assembly_quotation",
    "coating_quotation": "coating_quotation",
    "dfm_quotation": "dfm_quotation",
    "enclosure_quotation": "enclosure_quotation",
    "engineering_quotation": "engineering_quotation",
    "pcb_quotation": "pcb_quotation",
    "pcba_quotation": "pcba_quotation",
    "pcbDesign_quotation": "pcbDesign_quotation",
    "rework_quotation": "rework_quotation",
    "supply_quotation": "supply_quotation",
    "wiring_quotation": "wiring_quotation",
    "xray_quotation": "xray_quotation"
  };

  static RequiredActionEnum = {
    "start": "start",
    "end": "end",
    "followup": "followup"
  };

}