/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class EmsxFileContentObjects {
  constructor() {


  }

  static typeDescription() {
    return "EmsxFileContentObjects";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmsxFileContentObjects();
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('creator')) {
        obj['creator'] = ApiClient.convertToType(data['creator'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('trackingId')) {
        obj['trackingId'] = ApiClient.convertToType(data['trackingId'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
    }
    return obj;
  }


  creationDate = undefined;

  creator = undefined;

  id = undefined;

  name = undefined;

  trackingId = undefined;

  type = undefined;




  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getCreator() {
    return this.creator;
  }

  setCreator(creator) {
    this['creator'] = creator;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getTrackingId() {
    return this.trackingId;
  }

  setTrackingId(trackingId) {
    this['trackingId'] = trackingId;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }


}