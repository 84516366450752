/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PartNumberMatching {
  constructor(partNumberA, partNumberB, direction) {
    if (partNumberA) {
      this['partNumberA'] = partNumberA;
    }
    if (partNumberB) {
      this['partNumberB'] = partNumberB;
    }
    if (direction) {
      this['direction'] = direction;
    }


  }

  static typeDescription() {
    return "PartNumberMatching";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PartNumberMatching();
      if (data.hasOwnProperty('partNumberA')) {
        obj['partNumberA'] = ApiClient.convertToType(data['partNumberA'], 'String');
      }
      if (data.hasOwnProperty('partNumberB')) {
        obj['partNumberB'] = ApiClient.convertToType(data['partNumberB'], 'String');
      }
      if (data.hasOwnProperty('direction')) {
        obj['direction'] = ApiClient.convertToType(data['direction'], 'String');
      }
    }
    return obj;
  }


  partNumberA = "";

  partNumberB = "";

  direction = "AtoB";




  getPartNumberA() {
    return this.partNumberA;
  }

  setPartNumberA(partNumberA) {
    this['partNumberA'] = partNumberA;
  }

  getPartNumberB() {
    return this.partNumberB;
  }

  setPartNumberB(partNumberB) {
    this['partNumberB'] = partNumberB;
  }

  getDirection() {
    return this.direction;
  }

  setDirection(direction) {
    this['direction'] = direction;
  }


  static DirectionEnum = {
    "AtoB": "AtoB",
    "BtoA": "BtoA",
    "Both": "Both"
  };

}