import { updateMultiple } from "utils/updateArray";

const anomalies = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "anomalies/ADD_ALL") {
    return updateMultiple(state, action.data);
  }
  if (action.type === "anomalies/REMOVE_ALL") {
    const ids = action.data.map((anomaly) => anomaly.id);
    return state.filter((anomaly) => !ids.includes(anomaly.id));
  }
  return state;
};

export default anomalies;