/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class AnomalyEdition {
  constructor() {


  }

  static typeDescription() {
    return "AnomalyEdition";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AnomalyEdition();
      if (data.hasOwnProperty('core')) {
        obj['core'] = ApiClient.convertToType(data['core'], 'Boolean');
      }
      if (data.hasOwnProperty('actions')) {
        obj['actions'] = ApiClient.convertToType(data['actions'], 'Boolean');
      }
      if (data.hasOwnProperty('actionSelection')) {
        obj['actionSelection'] = ApiClient.convertToType(data['actionSelection'], 'Boolean');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'Boolean');
      }
    }
    return obj;
  }


  core = undefined;

  actions = undefined;

  actionSelection = undefined;

  status = undefined;




  getCore() {
    return this.core;
  }

  setCore(core) {
    this['core'] = core;
  }

  getActions() {
    return this.actions;
  }

  setActions(actions) {
    this['actions'] = actions;
  }

  getActionSelection() {
    return this.actionSelection;
  }

  setActionSelection(actionSelection) {
    this['actionSelection'] = actionSelection;
  }

  getStatus() {
    return this.status;
  }

  setStatus(status) {
    this['status'] = status;
  }


}