import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import Section from "marketing/components/Section";
import CookiesModal from "marketing/components/CookiesModal";
import StructuredPage from "components/StructuredPage";
import cmsActions from "Cms/actions";
import metaTags from "../utils/metaTags";
import messages from "marketing/messages";

class Cookie extends React.Component {
  state = {
    showModal: false
  };
  componentDidMount() {
    this.getPage();
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    const { intl = {} } = props;
    const { intl: oIntl = {} } = oProps;
    if (intl.locale !== oIntl.locale) {
      this.getPage();
    }
  }
  getPage() {
    cmsActions.getPage("cookies");
  }
  checkCookie = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { props, state } = this;
    const { pages = [] } = props;
    const page = pages.find((p) => p.id === "cookies") || {};
    console.log("Cookies render", page);
    metaTags.set(page);

    return (
      <Section>
        <div
          className="d-flex justify-content-between pb-5"
          style={{ marginTop: "100px" }}>

          <h1>{page.title}</h1>
          <button
            onClick={(e) => this.setState({ showModal: true })}
            className="btn btn-primary">

            {props.intl.formatMessage(messages.setCookies)}
          </button>
        </div>
        <StructuredPage data={page} style={{}} />

        <CookiesModal
          open={state.showModal}
          onHide={(e) => this.checkCookie()} />

      </Section>);

  }
}

const mapStateToProps = (state) => {
  return {
    pages: state.cms.pages
  };
};

export default connect(mapStateToProps)(injectIntl(Cookie));