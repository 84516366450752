import { defineMessages } from "react-intl";

const messages = defineMessages({
  pendingMessage: { "id": "account.requests.defaultPendingMessage", "defaultMessage": "Saving in progress" },



  errorMessage: { "id": "account.requests.defaultErrorMessage", "defaultMessage": "An error has occurred. Please try again." },



  doneMessage: { "id": "account.requests.defaultDoneMessage", "defaultMessage": "Your changes have been saved" }



});

export default messages;