/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class SessionExpiration {
  constructor() {


  }

  static typeDescription() {
    return "SessionExpiration";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SessionExpiration();
      if (data.hasOwnProperty('remainingSeconds')) {
        obj['remainingSeconds'] = ApiClient.convertToType(data['remainingSeconds'], 'Number');
      }
      if (data.hasOwnProperty('endTime')) {
        obj['endTime'] = ApiClient.convertToType(data['endTime'], 'String');
      }
    }
    return obj;
  }


  remainingSeconds = undefined;

  endTime = undefined;




  getRemainingSeconds() {
    return this.remainingSeconds;
  }

  setRemainingSeconds(remainingSeconds) {
    this['remainingSeconds'] = remainingSeconds;
  }

  getEndTime() {
    return this.endTime;
  }

  setEndTime(endTime) {
    this['endTime'] = endTime;
  }


}