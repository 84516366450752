/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ComponentReference from './ComponentReference';
import Property from './Property';

export default class Component {
  constructor() {


  }

  static typeDescription() {
    return "Component";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Component();
      if (data.hasOwnProperty('manufacturer')) {
        obj['manufacturer'] = ComponentReference.constructFromObject(data['manufacturer']);
      }
      if (data.hasOwnProperty('descriptionFr')) {
        obj['descriptionFr'] = ApiClient.convertToType(data['descriptionFr'], 'String');
      }
      if (data.hasOwnProperty('descriptionEn')) {
        obj['descriptionEn'] = ApiClient.convertToType(data['descriptionEn'], 'String');
      }
      if (data.hasOwnProperty('componentProperties')) {
        obj['componentProperties'] = ApiClient.convertToType(data['componentProperties'], { 'String': Property });
      }
    }
    return obj;
  }


  manufacturer = undefined;

  descriptionFr = undefined;

  descriptionEn = undefined;

  componentProperties = undefined;




  getManufacturer() {
    return this.manufacturer;
  }

  setManufacturer(manufacturer) {
    this['manufacturer'] = manufacturer;
  }

  getDescriptionFr() {
    return this.descriptionFr;
  }

  setDescriptionFr(descriptionFr) {
    this['descriptionFr'] = descriptionFr;
  }

  getDescriptionEn() {
    return this.descriptionEn;
  }

  setDescriptionEn(descriptionEn) {
    this['descriptionEn'] = descriptionEn;
  }

  getComponentProperties() {
    return this.componentProperties;
  }

  setComponentProperties(componentProperties) {
    this['componentProperties'] = componentProperties;
  }


}