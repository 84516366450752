import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

// import Section from "marketing/components/Section";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Switch from "react-switch";
import marketingMessages from "marketing/messages";
import miscActions from "Misc/actions";

class CookiesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.cookies === "true"
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked });
  }
  render() {
    const { props, state } = this;
    return (
      <Modal isOpen={props.open} toggle={props.onHide}>
        <ModalHeader>
          {props.intl.formatMessage(marketingMessages.setCookies)}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex align-items-center py-2">
            <Switch onChange={(e) => {}} checked disabled />
            <span className="ml-2 mt-1">
              <FormattedMessage
                id="marketing.cookies.fonctionalCookies"
                defaultMessage="Functional cookies (mandatory)" />

            </span>
          </div>
          <div className="d-flex align-items-center py-2">
            <Switch
              checked={state.checked ? true : false}
              onChange={this.handleChange} />

            <span className="ml-2 mt-1">
              <FormattedMessage
                id="marketing.cookies.analyticCookies"
                defaultMessage="Cookies Analytic" />

            </span>
          </div>
          <div className="d-flex align-items-center py-2">
            <Switch onChange={(e) => {}} checked={false} disabled />
            <span className="ml-2 mt-1">
              <FormattedMessage
                id="marketing.cookies.marketingCookies"
                defaultMessage="Marketing cookies (not used)" />

            </span>
          </div>
          <div className="d-flex justify-content-end pt-2">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                props.setCookies(state.checked);
                props.onHide();
              }}>

              <FormattedMessage
                id="marketing.cookiesModal.saveAndContinue"
                defaultMessage="Save & continue" />

            </button>
          </div>
        </ModalBody>
      </Modal>);

  }
}

const mapStateToProps = (state) => {
  const { misc = {} } = state;
  return { cookies: misc.cookies };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCookies: (data) => miscActions.setCookies(data)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CookiesModal));