import React from "react";
import { Label } from "reactstrap";
import FormGroup from "./FormGroup";
import FormControl from "./FormControl";

const Radio = (props) => {
  const groupProps = {
    className: props.className || "",
    disabled: props.disabled
  };
  if (props.validationState === "success") {
    groupProps.className += " has-success";
  }
  if (props.validationState === "warning") {
    groupProps.className += " has-warning";
  }
  if (props.validationState === "error") {
    groupProps.className += " has-error";
  }
  const checkProps = {
    checked: props.checked,
    onChange: props.onChange,
    disabled: props.disabled
  };
  return (
    <FormGroup {...groupProps} check>
      <Label check>
        <FormControl
          {...checkProps}
          type="radio"
          // style={{
          //   top: "50%",
          //   transform: "translateY(-50%)"
          // }}
        />{" "}
        {props.children}
      </Label>
    </FormGroup>);

};

export default Radio;