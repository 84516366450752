/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class XRayTargetDevice {
  constructor(objectTypeID) {
    if (objectTypeID) {
      this['objectTypeID'] = objectTypeID;
    }


  }

  static typeDescription() {
    return "XRayTargetDevice";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new XRayTargetDevice();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('objectTypeID')) {
        obj['objectTypeID'] = ApiClient.convertToType(data['objectTypeID'], 'String');
      }
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
    }
    return obj;
  }


  id = undefined;

  objectTypeID = undefined;

  reference = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getObjectTypeID() {
    return this.objectTypeID;
  }

  setObjectTypeID(objectTypeID) {
    this['objectTypeID'] = objectTypeID;
  }

  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }


}