/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ServerStatusInMemoryObjects {
  constructor() {


  }

  static typeDescription() {
    return "ServerStatusInMemoryObjects";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServerStatusInMemoryObjects();
      if (data.hasOwnProperty('users')) {
        obj['users'] = ApiClient.convertToType(data['users'], ['String']);
      }
      if (data.hasOwnProperty('customers')) {
        obj['customers'] = ApiClient.convertToType(data['customers'], ['String']);
      }
      if (data.hasOwnProperty('specifications')) {
        obj['specifications'] = ApiClient.convertToType(data['specifications'], ['String']);
      }
      if (data.hasOwnProperty('quotations')) {
        obj['quotations'] = ApiClient.convertToType(data['quotations'], ['String']);
      }
      if (data.hasOwnProperty('quotes')) {
        obj['quotes'] = ApiClient.convertToType(data['quotes'], ['String']);
      }
      if (data.hasOwnProperty('orders')) {
        obj['orders'] = ApiClient.convertToType(data['orders'], ['String']);
      }
      if (data.hasOwnProperty('invoices')) {
        obj['invoices'] = ApiClient.convertToType(data['invoices'], ['String']);
      }
    }
    return obj;
  }


  users = undefined;

  customers = undefined;

  specifications = undefined;

  quotations = undefined;

  quotes = undefined;

  orders = undefined;

  invoices = undefined;




  getUsers() {
    return this.users;
  }

  setUsers(users) {
    this['users'] = users;
  }

  getCustomers() {
    return this.customers;
  }

  setCustomers(customers) {
    this['customers'] = customers;
  }

  getSpecifications() {
    return this.specifications;
  }

  setSpecifications(specifications) {
    this['specifications'] = specifications;
  }

  getQuotations() {
    return this.quotations;
  }

  setQuotations(quotations) {
    this['quotations'] = quotations;
  }

  getQuotes() {
    return this.quotes;
  }

  setQuotes(quotes) {
    this['quotes'] = quotes;
  }

  getOrders() {
    return this.orders;
  }

  setOrders(orders) {
    this['orders'] = orders;
  }

  getInvoices() {
    return this.invoices;
  }

  setInvoices(invoices) {
    this['invoices'] = invoices;
  }


}