import { update, updateMultiple } from "utils/updateArray";

const commonReducer = (slug) => {
  const specs = (state = [], action) => {
    if (action.type === "AUTH_LOGOUT") {
      return [];
    }
    if (action.type === slug + "/specs/SET_ALL") {
      return [...(action.data || [])];
    }
    if (action.type === slug + "/specs/SET") {
      return update(state, action.data);
    }
    if (action.type === slug + "/specs/REMOVE") {
      return state.filter((s) => s.id !== action.id);
    }
    return state;
  };
  const quotations = (state = [], action) => {
    if (action.type === "AUTH_LOGOUT") {
      return [];
    }
    if (action.type === slug + "/quotations/SET_MULTIPLE") {
      return updateMultiple(state, action.data);
    }
    if (action.type === slug + "/quotations/SET") {
      return update(state, action.data);
    }
    if (action.type === slug + "/quotations/SET_ALL") {
      return action.data;
    }
    if (action.type === "quotes/SET") {
      const { data = {} } = action;
      const { quotations = [] } = data;
      return updateMultiple(
        state,
        quotations.filter((q) => q.type === slug)
      );
    }
    return state;
  };
  return {
    specs,
    quotations
  };
};

export default commonReducer;