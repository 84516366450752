/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ManufacturingOperatorSkills {
  constructor(operationId, afinity) {
    if (operationId) {
      this['operationId'] = operationId;
    }
    if (afinity) {
      this['afinity'] = afinity;
    }


  }

  static typeDescription() {
    return "ManufacturingOperatorSkills";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingOperatorSkills();
      if (data.hasOwnProperty('operationId')) {
        obj['operationId'] = ApiClient.convertToType(data['operationId'], 'String');
      }
      if (data.hasOwnProperty('afinity')) {
        obj['afinity'] = ApiClient.convertToType(data['afinity'], 'Number');
      }
    }
    return obj;
  }


  operationId = "aoiInspection";

  afinity = 0;




  getOperationId() {
    return this.operationId;
  }

  setOperationId(operationId) {
    this['operationId'] = operationId;
  }

  getAfinity() {
    return this.afinity;
  }

  setAfinity(afinity) {
    this['afinity'] = afinity;
  }


  static OperationIdEnum = {
    "aoiInspection": "aoiInspection",
    "aoiProgram": "aoiProgram",
    "componentControl": "componentControl",
    "componentDrying": "componentDrying",
    "componentPurchasing": "componentPurchasing",
    "componentReceipt": "componentReceipt",
    "custom": "custom",
    "depanelization": "depanelization",
    "expedition": "expedition",
    "iledInspection": "iledInspection",
    "jetting": "jetting",
    "jettingProgram": "jettingProgram",
    "orderControl": "orderControl",
    "pcbDrying": "pcbDrying",
    "pcbPackaging": "pcbPackaging",
    "pcbPurchasing": "pcbPurchasing",
    "pcbReceipt": "pcbReceipt",
    "pcbaCleaning": "pcbaCleaning",
    "pcbaCoating": "pcbaCoating",
    "pcbaDrying": "pcbaDrying",
    "pcbaFinalInspection": "pcbaFinalInspection",
    "pcbaPackaging": "pcbaPackaging",
    "pcbaRework": "pcbaRework",
    "pcbaVisualInspection": "pcbaVisualInspection",
    "pnpLoading": "pnpLoading",
    "pnpProgram": "pnpProgram",
    "potting": "potting",
    "pressfitAssembly": "pressfitAssembly",
    "pressfitTooling": "pressfitTooling",
    "smtInspection": "smtInspection",
    "smtManualAssembly": "smtManualAssembly",
    "smtPnpAssembly": "smtPnpAssembly",
    "smtRefusion": "smtRefusion",
    "taskCreation": "taskCreation",
    "taskPlanification": "taskPlanification",
    "thtAutoAssembly": "thtAutoAssembly",
    "thtManualAssembly": "thtManualAssembly",
    "xrayInspection": "xrayInspection"
  };

}