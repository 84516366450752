// Home
import HomeCover from "./HomeCover";
import Events from "./Events";
import ControlManufacturing from "./ControlManufacturing";
import KeyBenefits from "./KeyBenefits";
import BehindScene from "./BehindScene";
import OurProducts from "./OurProducts";
// import CaseStories from "./tileComponents/CaseStories";
import OurCustomers from "./OurCustomers";

// Factory
import FactoryCover from "./FactoryCover";
import FactoryProcess from "./FactoryProcess";
import Map from "./Map";
import FactoryPrizes from "./FactoryPrizes";
import Video from "./Video";
import ManufacturingLines from "./ManufacturingLines";
import RespectfulFactory from "./RespectfulFactory";
import TestimonialsEmployee from "./TestimonialsEmployee";
import FactoryKeyFigure from "./FactoryKeyFigure";
import Norms from "./Norms";
import Stocks from "./Stocks";
import Characteristics from "./Characteristics";

// ProductPages
import ProductCover from "./ProductCover";
import ProductSelector from "./ProductSelector";
import ShortProcess from "./ShortProcess";
import OnlineSetup from "./OnlineSetup";
import Capabilities from "./Capabilities";
import Process from "./Process";

// Engineering
import EngineeringProducts from "./EngineeringProducts";
import EngineeringTool from "./EngineeringTool";

// Pricing
import PricingCover from "./PricingCover";
import PricingSelector from "./PricingSelector";
import PricingExperience from "./PricingExperience";
import PricingOptimization from "./PricingOptimization";

// Press
import PressPage from "./PressPage";

// Contact
import ContactPage from "./ContactPage";

// Others
import PageTitle from "./PageTitle";
import HtmlContent from "./HtmlContent";
import StartProject from "./StartProject";
import CommonTiles from "./CommonTiles";

const tiles = {
  // Home
  HomeCover,
  Events,
  ControlManufacturing,
  KeyBenefits,
  BehindScene,
  OurProducts,
  // CaseStories,
  OurCustomers,

  // Factory page
  FactoryCover,
  FactoryProcess,
  Map,
  FactoryPrizes,
  Video,
  ManufacturingLines,
  RespectfulFactory,
  TestimonialsEmployee,
  FactoryKeyFigure,
  Norms,
  Stocks,

  // Product page
  ProductCover,
  ProductSelector,
  ShortProcess,
  OnlineSetup,
  Capabilities,
  Process,
  Characteristics,

  // Engineering
  EngineeringProducts,
  EngineeringTool,

  // Pricing
  PricingCover,
  PricingSelector,
  PricingExperience,
  PricingOptimization,

  // Press
  PressPage,

  // Contact
  ContactPage,

  // Others
  PageTitle,
  HtmlContent,
  StartProject,
  CommonTiles
};

export default tiles;