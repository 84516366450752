import React from 'react';
import { NavLink } from 'Elements';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Component extends React.Component {
  state = {};
  toggle() {
    this.setState({
      open: !this.state.open
    });
  }
  render() {
    const { props, state } = this;
    return <Dropdown isOpen={state.open} toggle={(e) => this.toggle()}>
      <DropdownToggle
        caret
        tag={props.tag || 'span'}
        onClick={(e) => this.toggle()}>

        <NavLink to="/" onClick={(e) => {
          e.preventDefault();
          this.toggle();
        }}>
          {props.title}
        </NavLink>
      </DropdownToggle>
      <DropdownMenu>
        {(props.items || []).map((v, i) => <DropdownItem key={i}>
          <NavLink activeClassName="active" to={v.href} onClick={v.onClick}>
            {v.title}
          </NavLink>
        </DropdownItem>)}
      </DropdownMenu>
    </Dropdown>;
  }
}

export default Component;