import React from "react";
import { FormGroup } from "reactstrap";

const FormGroupComponent = (props) => {
  let inputProps = {
    check: props.check,
    className: props.className,
    row: props.row,
    style: props.style
  };
  if (props.validationState === "success") {
    inputProps.className = [inputProps.className, "has-success"].
    filter((s) => s).
    join(" ");
  }
  if (props.validationState === "error") {
    inputProps.className = [inputProps.className, "has-error"].
    filter((s) => s).
    join(" ");
  }
  if (props.validationState === "warning") {
    inputProps.className = [inputProps.className, "has-warning"].
    filter((s) => s).
    join(" ");
  }

  return <FormGroup {...inputProps}>{props.children}</FormGroup>;
};

export default FormGroupComponent;