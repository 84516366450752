import { store } from "initStore";
import config from "config";
import requestsActions from "Requests/actions";

import request from "superagent";

import { locale } from "Intl";

const actions = {
  getIntlLocale: () => locale,
  getBlog: (l = locale) => {
    return requestsActions.
    add(
      "cms/blog/get_ALL",
      request.get("/content/blog/" + l + "/index.json")
    ).
    then((data) => {
      store.dispatch({
        type: "cms/blog/SET_ALL",
        data: data.body
      });
      return data.body;
    });
  },
  getBlogArticle: (l = locale, id) =>
  requestsActions.
  add(
    "cms/blog/get_Article",
    request.get("/content/blog/" + l + "/" + id + ".json")
  ).
  then((data) => {
    store.dispatch({
      type: "cms/blog/SET",
      data: data.body
    });
    return data.body;
  }),
  getTwitterTimeline: () =>
  requestsActions.
  add(
    "cms/twitter/get_timeline",
    request.get("/content/twitter/timeline.json")
  ).
  then((data) => {
    store.dispatch({
      type: "cms/timeline/SET",
      data: data.body
    });
    return data.body;
  }),
  getFaq: (l = locale) =>
  requestsActions.
  add("cms/faq/get_ALL", request.get("/content/faq/" + l + "/index.json")).
  then((data) => {
    store.dispatch({
      type: "cms/faq/SET_ALL",
      data: data.body
    });
    return data.body;
  }),
  getRessources: (l = locale) =>
  requestsActions.
  add(
    "cms/ressources/get_ALL",
    request.get("/content/ressources/" + l + "/index.json")
  ).
  then((data) => {
    store.dispatch({
      type: "cms/ressources/SET_ALL",
      data: data.body
    });
    return data.body;
  }),
  getRessourcesArticle: (l = locale, id) =>
  requestsActions.
  add(
    "cms/ressources/get_Article",
    request.get("/content/ressources/" + l + "/" + id + ".json")
  ).
  then((data) => {
    store.dispatch({
      type: "cms/ressources/SET",
      data: data.body
    });
    return data.body;
  }),

  getImages: (product, l = locale) =>
  requestsActions.
  add(
    "cms/images/get_images",
    request.get("/content/images/" + product + "/" + l + ".json")
  ).
  then((data) => {
    console.log("cms/get/images render", data.body, product, l);
    store.dispatch({
      type: "cms/images/GET",
      data: data.body
    });
    return data.body;
  }),

  // Pages
  getPage: (id, lang = locale) =>
  requestsActions.
  add(
    "cms/pages/get_page",
    request.get("/content/pages/" + lang + "/" + id + ".json")
  ).
  then((data) => {
    const { body } = data;
    console.log("getPage", id, lang, data, body);
    if (body) {
      store.dispatch({ type: "cms/pages/SET", data: body });
      return body;
    }
    return false;
  }),
  getDemo: (id, lang = locale) =>
  requestsActions.add(
    "cms/demos/get",
    request.get("/content/demos/" + lang + "/" + id + ".json").then((res) => {
      store.dispatch({
        type: "cms/demos/SET",
        data: res.body
      });
      return res.body;
    })
  ),
  getHelpMessage: (id, lang = locale) => {
    return request.
    get(
      "/content/helpMessages/" + lang + "/" + encodeURIComponent(id) + ".json"
    ).
    then((res) => {
      store.dispatch({
        type: "cms/helpMessages/SET",
        data: res.body
      });
      return res.body;
    });
  },
  sendForm: (id, data) =>
  requestsActions.add(
    "cms/forms/send",
    request.post(config.cmsUrl + "/forms/" + id, data)
  )
};

export default actions;