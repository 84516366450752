import { combineReducers } from "redux";
import { update, updateMultiple } from "utils/updateArray";
import createReducer from "commonTunnel/reducer";

import libraryActions from "Library/actions";

const commonReducer = createReducer("wiring");

const quotations = (state = [], action) => {
  if (action.type === "wiring/quotations/SET") {
    return update(state, action.data);
  }
  if (action.type === "auth/user/GET_QUOTATIONS") {
    return action.data.filter((q) => q.type === "wiring");
  }

  if (action.type === "wiring/quotations/SET_DURATION_QUANTITIES") {
    const index = state.findIndex((q) => q.id === action.id);
    if (index < 0) {
      return state;
    }
    return update(state, {
      id: action.id,
      durationQuantities: updateMultiple(
        state[index].durationQuantities,
        action.data.map((dq) =>
        Object.assign({}, dq, { id: dq.durationDays + "-" + dq.quantity })
        )
      )
    });
  }
  return state;
};

const specs = (state = [], action) => {
  if (action.type === "wiring/specs/SET") {
    return update(state, action.data);
  }
  if (action.type === "auth/user/GET_SPECS") {
    return action.data.filter((s) => s.type === "wiring");
  }
  return state;
};

const harnesses = (state = [], action) => {
  if (action.type === "wiring/harnesses/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/harnesses/SET") {
    return update(state, action.data);
  }
  return state;
};

const connectors = (state = [], action) => {
  if (action.type === "wiring/connectors/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/connectors/SET") {
    return update(state, action.data);
  }
  return state;
};

const wires = (state = [], action) => {
  if (action.type === "wiring/wires/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/wires/SET") {
    return update(state, action.data);
  }
  if (action.type === "wiring/wires/REMOVE") {
    return state.filter((w) => w.id !== action.id);
  }
  if (action.type === "wiring/wires/REMOVE_WIRES") {
    return state.filter((w) => (action.ids || []).indexOf(w.id) < 0);
  }
  return state;
};

const cables = (state = [], action) => {
  if (action.type === "wiring/cables/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/cables/SET") {
    return update(state, action.data);
  }
  if (action.type === "wiring/cables/REMOVE") {
    return state.filter((w) => w.id !== action.id);
  }
  return state;
};

const routes = (state = [], action) => {
  if (action.type === "wiring/routes/GET") {
    return update(state, action.data);
  }
  if (action.type === "wiring/routes/REMOVE_ALL") {
    return [];
  }
  return state;
};

const labels = (state = [], action) => {
  if (action.type === "wiring/labels/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/labels/SET") {
    return update(state, action.data);
  }
  if (action.type === "wiring/labels/REMOVE") {
    return state.filter((w) => w.id !== action.id);
  }
  return state;
};

const terminals = (state = [], action) => {
  if (action.type === "wiring/terminals/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/terminals/SET") {
    return update(state, action.data);
  }
  if (action.type === "wiring/terminals/REMOVE") {
    return state.filter((w) => w.id !== action.id);
  }
  if (action.type === "wiring/terminals/REMOVE_MULTIPLE") {
    return state.filter((w) => action.ids.indexOf(w.id) < 0);
  }
  return state;
};

const splices = (state = [], action) => {
  if (action.type === "wiring/splices/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/splices/SET") {
    return update(state, action.data);
  }
  if (action.type === "wiring/splices/REMOVE") {
    return state.filter((w) => w.id !== action.id);
  }
  return state;
};

const freeEnds = (state = [], action) => {
  if (action.type === "wiring/freeEnds/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/freeEnds/SET") {
    return update(state, action.data);
  }
  return state;
};

const paths = (state = [], action) => {
  if (action.type === "wiring/paths/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/paths/SET") {
    return update(state, action.data);
  }
  return state;
};

const pathJunctions = (state = [], action) => {
  if (action.type === "wiring/pathJunctions/SET_ALL") {
    return [...action.data];
  }
  if (action.type === "wiring/pathJunctions/SET") {
    return update(state, action.data);
  }
  return state;
};

const bom = (state = {}, action) => {
  if (action.type === "wiring/bom/SET") {
    return action.data;
  }
  return state;
};

const emsSleevingComponents = (state = [], action) => {
  if (action.type === "wiring/emsSleevingComponents/SET") {
    const { data = [] } = action;
    return data.
    map((c) => {
      const { asComponent = {} } = c;
      const { manufacturer = {} } = asComponent;
      // const componentProperties = asComponent.componentProperties || {};
      // const { color = {}, sleeving_type = {} } = componentProperties;
      // const { values: colorValues = {} } = color;
      // const colorValue = colorValues[""];
      // const { values: modeValues = {} } = sleeving_type;
      // const modeValue = modeValues[""];
      return Object.assign(
        {
          color: libraryActions.getComponentPropertyValue(c, "color"),
          component: asComponent,
          // id: c.id,
          mode: libraryActions.getComponentPropertyValue(c, "sleeving_type"),
          mpn: manufacturer.reference
          // type: c.type,
        },
        c
      );
    }).
    filter((c) => c.color && c.mode && c.id && c.mpn);
  }
  return state;
};

const emsWireComponents = (state = [], action) => {
  // console.log("action", action.type);
  if (action.type === "wiring/emsWireComponents/SET") {
    console.log("emsWireComponents", action);
    const { data = [] } = action;
    const nData = data.
    map((c) => {
      const { asComponent = {} } = c;
      const { manufacturer = {} } = asComponent;
      // const componentProperties = asComponent.componentProperties || {};
      // const { color = {}, sleeving_type = {} } = componentProperties;
      // const { values: colorValues = {} } = color;
      // const colorValue = colorValues[""];
      // const { values: modeValues = {} } = sleeving_type;
      // const modeValue = modeValues[""];
      return Object.assign(
        {
          color: libraryActions.getComponentPropertyValue(c, "color"),
          component: asComponent,
          coreSize: libraryActions.getComponentPropertyValue(
            c,
            "core_size",
            "section",
            "unsignedFloatValue"
          ),
          mpn: manufacturer.reference
        },
        c
      );
    }).
    filter((c) => c.color && c.coreSize && c.id && c.mpn);

    console.log("emsWireComponents res", nData);
    return nData;
  }
  return state;
};

const circuits = (state = [], action) => {
  if (action.type === "wiring/circuits/SET") {
    return [...action.data];
  }
  return state;
};

const anomalies = (state = [], action) => {
  if (action.type === "wiring/anomalies/ADD_ALL") {
    return [...action.data];
  }
  return state;
};

export default combineReducers({
  quotations: (state, action) =>
  quotations(commonReducer.quotations(state, action), action).map(
    (quotation) =>
    Object.assign({}, quotation, {
      specId: quotation.specId || quotation.specificationId,
      delay: quotation.durationDays
    })
  ),
  specs: (state, action) =>
  specs(commonReducer.specs(state, action), action).map((spec) =>
  Object.assign({}, spec, { type: "wiring" })
  ),
  harnesses,
  connectors,
  wires,
  cables,
  routes,
  labels,
  terminals,
  splices,
  freeEnds,
  paths,
  pathJunctions,
  bom,
  emsSleevingComponents,
  emsWireComponents,
  circuits,
  anomalies
});

// import {combineReducers} from 'redux'
//
//
// import CableTree from './CableTreeModel'
// import cableSources from './cableSources'
// import connectorSources from './connectorSources'
//
// import {update, updateIndex, removeId} from './utils'
// import validateSegments from './validateSegments'
// import validatePoints from './validatePoints'
// import validateCableSources from './validateCableSources'
// import validateCables from './validateCables'
//
// import fakeInitialState from './fakeInitialState'
//
// const initialState = [
//   {
//     id: 'start',
//     points: [],
//     "segments": [],
//     cables: [],
//     cableSources,
//     connectorSources,
//   }
// ]
//
//
// const validateSpecs = specs => specs.map(spec => {
//   validateCableSources(spec)
//   validateCables(spec)
//   validateSegments(spec)
//   validatePoints(spec)
//   return spec
// })
//
//
// const specs = (state = fakeInitialState, action) => {
//   if (!action.type.startsWith('wiring/specs/')) {
//     return validateSpecs(state)
//   }
//   let spec = false
//   if (action.specId) {
//     spec = Object.assign({}, state.find(s=>s.id===action.specId))
//   }
//   let nState = [...state]
//   switch(action.type){
//     case 'wiring/specs/ADD_POINT':
//       addPoint(action.data, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/UPDATE_POINT':
//       updatePoint(action.data, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/MERGE_POINTS':
//       const point1 = spec.points.find(p=>p.id===action.id1)
//       const point2 = Object.assign({}, point1, spec.points.find(p=>p.id===action.id2))
//       removePoint(action.id1, spec)
//       updatePoint(point2, spec)
//       mergeEndsSegments(action.id1, action.id2, spec)
//       nState = update(state, spec)
//       break;
//     case 'wiring/specs/REMOVE_POINT':
//       removePoint(action.data.id, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/ADD_SEGMENT':
//       addSegment(action.data, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/CREATE_SEGMENT':
//       createSegment(action.pos, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/SET_SEGMENT':
//       setSegment(action.data, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/SPLIT_SEGMENT':
//       splitSegment(action.segmentId, action.data, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/ADD_SEGMENT_FROM_SEGMENT':
//       addSegmentFromSegment(action.segmentId, action.pos, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/UNLINK_SEGMENT':
//       unlinkSegment(action.segmentId, action.end, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/REMOVE_SEGMENT':
//       removeSegment(action.data.id, spec)
//       nState = update(state, spec)
//       break
//     case 'wiring/specs/SET':
//       nState = update(nState, action.data)
//       break
//   }
//   // console.log('wiring reducer', nState, action)
//   return validateSpecs(nState)
// }
//
// export default combineReducers({
//   specs,
// })
//
//
//
//
//
//
//
//
// // Points
// const addPoint = (opts, spec) => {
//   let id = Math.round(Math.random() * Math.pow(10, 17))+''
//   spec.points = [
//     ...spec.points,
//     Object.assign({}, opts, {id})
//   ]
//   return id
// }
// const updatePoint = (point, spec) => {
//   spec.points = update(spec.points, point)
// }
// const removePoint = (id, spec) => {
//   spec.points = removeId(spec.points, id)
// }
//
// // Segments
// const addSegment = (data, spec) => {
//   data = Object.assign({}, data)
//   data.start = data.start.pos ? addPoint(data.start, spec) : data.start
//   data.end = data.end.pos ? addPoint(data.end, spec) : data.end
//   spec.segments = [
//     ...spec.segments,
//     data
//   ]
// }
// const createSegment = (pos, spec) => {
//   const start = addPoint({pos}, spec)
//   const end = addPoint({pos}, spec)
//   addSegment({start, end}, spec)
// }
// const setSegment = (data, spec) => {
//   spec.segments = update(spec.segments, data)
// }
// const mergeEndsSegments = (oldId, newId, spec) => {
//   spec.segments = spec.segments.map(segment => {
//     let res = Object.assign({}, segment)
//     res.start = res.start === oldId ? newId : res.start
//     res.end = res.end === oldId ? newId : res.end
//     return res
//   })
// }
// const splitSegment = (id, point, spec) => {
//   const segment = Object.assign({}, spec.segments.find(s=>s.id===id))
//   delete segment.id
//   const middle = addPoint(point, spec)
//   addSegment(Object.assign({}, segment, {start: segment.start, end: middle}), spec)
//   addSegment(Object.assign({}, segment, {start: middle, end: segment.end}), spec)
//   removeSegment(id, spec)
//   return middle
// }
// const addSegmentFromSegment = (segmentId, pos, spec) => {
//   const middle = splitSegment(segmentId, {pos}, spec)
//   addSegment({start: middle, end: {pos}}, spec)
// }
// const unlinkSegment = (id, end, spec) => {
//   const point = addPoint({pos: [0,0]}, spec)
//   let res = {id}
//   res[end] = point
//   spec.segments = update(spec.segments, res)
// }
// const removeSegment = (id, spec) => {
//   spec.segments = removeId(spec.segments, id)
// }