import React from "react";

const icon =
<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 100 100"
  id="pcb"
  style={{
    width: "100%",
    maxHeight: "100%"
  }}>

    <defs>
      <pattern
      href="#pcb_a"
      id="pcb_b"
      patternTransform="rotate(40.58 -100.3 -344.14) scale(1.023)" />

      <pattern
      patternUnits="userSpaceOnUse"
      width="5.52"
      height="5.52"
      patternTransform="translate(-242.44 -4.18)"
      id="pcb_a">

        <g transform="translate(242.44 4.18)" fillRule="evenodd">
          <path
        // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
        overflow="visible"
        fill="none"
        strokeWidth="3"
        strokeDasharray="3 9"
        d="M-242.44-4.18h5.52v5.52h-5.52z" />

          <circle
        // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
        cx="-2.8"
        cy="238.31"
        r=".83"
        transform="rotate(90)"
        overflow="visible"
        // fill="#fff"
        strokeWidth=".1" />

          <circle
          r=".83"
          cy="238.31"
          cx="-.04"
          // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
          transform="rotate(90)"
          overflow="visible"
          // fill="#fff"
          strokeWidth=".1" />

          <circle
          transform="rotate(90)"
          // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
          cx="-.04"
          cy="241.07"
          r=".83"
          overflow="visible"
          // fill="#fff"
          strokeWidth=".1" />

          <circle
        // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
        cx="-2.8"
        cy="241.07"
        r=".83"
        transform="rotate(90)"
        overflow="visible"
        // fill="#fff"
        strokeWidth=".1" />

        </g>
      </pattern>
    </defs>
    <path
    d="M66.3 1.9c-5.5 0-10 4.5-10 10 0 1.4.3 2.8.9 4.1l.3.6L44 28.9l-.5-.3c-1.5-.8-3.2-1.3-4.8-1.3-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10c0-1.4-.3-2.8-.9-4.1l-.3-.6L61 20.4l.5.3c2.9 1.6 6.4 1.7 9.4.2l.6-.3 10.8 12.5-.3.5a10.02 10.02 0 0 0-.5 9.2l.3.6-13.5 12.2-.5-.3c-1.6-.8-3.2-1.2-4.9-1.2-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10c0-1.4-.3-2.8-.9-4.1l-.3-.6 13.5-12.3.5.3c1.5.8 3.2 1.3 4.9 1.3 5.5 0 10-4.5 10-10s-4.5-10-10-10c-1.3 0-2.6.3-3.8.7l-.6.2-11.1-12.9.2-.5c.7-1.4 1-2.8 1-4.3 0-5.5-4.5-10-10-10zm.1 5.3a4.8 4.8 0 1 1 0 9.6 4.8 4.8 0 0 1 0-9.6zM38.6 32.6a4.8 4.8 0 1 1-4.8 4.8c0-2.6 2.2-4.8 4.8-4.8zm52 1.3c2.6 0 4.8 2.2 4.8 4.8a4.8 4.8 0 0 1-9.6 0c0-2.6 2.2-4.8 4.8-4.8zM62.9 59.3c2.6 0 4.8 2.2 4.8 4.8 0 2.6-2.2 4.8-4.8 4.8a4.8 4.8 0 0 1-4.8-4.8c0-2.6 2.2-4.8 4.8-4.8z"
    fill="url(#pcb_b)"
    transform="translate(0 12.95) scale(.97752)" />

    <path
    d="M38.61 20.57c2.15 0 3.91 1.76 3.91 3.91s-1.76 3.91-3.9 3.91-3.92-1.75-3.92-3.9 1.76-3.92 3.91-3.92zM11.53 53.13a3.91 3.91 0 0 1 0-7.82c2.15 0 3.91 1.76 3.91 3.9s-1.75 3.92-3.9 3.92zM35.2 79.32c-2.16 0-3.92-1.76-3.92-3.9s1.76-3.92 3.91-3.92 3.91 1.76 3.91 3.91-1.76 3.91-3.9 3.91zM62.37 54.5a3.92 3.92 0 1 1 3.91-3.91 3.99 3.99 0 0 1-3.91 3.9z"
    className="st2"
    // fill="#fff"
  />
    <path
    d="M35.2 86.85a11.45 11.45 0 0 1-11.45-11.44c0-1.56.3-3.03.88-4.5l-9.38-10.84a12 12 0 0 1-3.81.68A11.45 11.45 0 0 1 0 49.31c0-6.25 5.18-11.53 11.53-11.53a11 11 0 0 1 4.99 1.17L27.96 28.5A11.45 11.45 0 0 1 38.6 12.95c6.36 0 11.44 5.18 11.44 11.44 0 6.25-5.18 11.43-11.44 11.43a11 11 0 0 1-4.98-1.17L22.19 45.11a10.89 10.89 0 0 1-.59 9.38l8.9 10.37a11.55 11.55 0 0 1 9.68.2L51.6 54.58a11.45 11.45 0 0 1 10.65-15.54c6.36 0 11.45 5.18 11.45 11.44 0 6.25-5.09 11.53-11.34 11.53a11 11 0 0 1-4.99-1.17L45.94 71.3c.5 1.27.79 2.64.79 4.1 0 6.36-5.19 11.44-11.54 11.44zM15.73 58.11l10.85 12.61-.2.49a9.14 9.14 0 0 0-.97 4.2c0 5.38 4.4 9.78 9.77 9.78s9.78-4.4 9.78-9.78c0-1.37-.3-2.73-.88-4l-.3-.6L57 58.8l.49.29c1.46.78 3.13 1.27 4.69 1.27 5.38 0 9.78-4.4 9.78-9.78s-4.4-9.77-9.78-9.77a9.8 9.8 0 0 0-8.9 13.78l.3.59-13.1 11.92-.49-.29a9.86 9.86 0 0 0-9.19-.2l-.58.3-10.66-12.12.3-.5a10.07 10.07 0 0 0 1.36-4.98c0-1.36-.3-2.73-.88-4l-.3-.6 13.2-12.01.5.29c1.46.78 3.12 1.27 4.69 1.27 5.37 0 9.77-4.4 9.77-9.78s-4.4-9.77-9.77-9.77-9.78 4.4-9.78 9.77c0 1.37.3 2.74.88 4.01l.3.59-13 11.92-.6-.29a10.24 10.24 0 0 0-4.69-1.27c-5.37 0-9.77 4.4-9.77 9.78s4.4 9.77 9.77 9.77c1.28 0 2.55-.3 3.72-.68l.49-.2z"
    className="st2"
    // fill="#fff"
  />
    <path
    d="M61.49 80.3a4.7 4.7 0 0 1-4.7-4.7 4.76 4.76 0 0 1 4.7-4.68 4.76 4.76 0 0 1 4.69 4.69c0 2.54-2.15 4.7-4.7 4.7zm0-7.72a3.05 3.05 0 0 0-3.03 3.03 3.05 3.05 0 0 0 3.03 3.03 3.05 3.05 0 0 0 3.03-3.03 3.05 3.05 0 0 0-3.03-3.03zm27.07-17.1a4.7 4.7 0 0 1-4.69-4.7c0-2.54 2.15-4.7 4.7-4.7a4.77 4.77 0 0 1 4.69 4.7 4.7 4.7 0 0 1-4.7 4.7zm0-7.73a3.05 3.05 0 0 0-3.03 3.03 3.05 3.05 0 0 0 3.03 3.03 3.05 3.05 0 0 0 3.03-3.03 3.05 3.05 0 0 0-3.03-3.03zM37.73 54.2a4.7 4.7 0 0 1-4.69-4.7 4.76 4.76 0 0 1 4.7-4.68 4.7 4.7 0 0 1 0 9.38zm0-7.82a3.05 3.05 0 0 0-3.03 3.03 3.05 3.05 0 0 0 3.03 3.03 3.05 3.05 0 0 0 3.03-3.03 3.05 3.05 0 0 0-3.03-3.03zm27.18-17a4.7 4.7 0 1 1 0-9.4 4.7 4.7 0 0 1 0 9.4zm0-7.83a3.05 3.05 0 0 0-3.03 3.03 3.05 3.05 0 0 0 3.03 3.03 3.05 3.05 0 0 0 3.03-3.03 3.05 3.05 0 0 0-3.03-3.03z"
    className="st2"
    // fill="#fff"
  />
    <path
    d="M61.49 87.05A11.45 11.45 0 0 1 50.05 75.6c0-6.35 5.18-11.44 11.44-11.44a11 11 0 0 1 4.98 1.18l11.44-10.46a10.89 10.89 0 0 1 .58-9.39l-8.9-10.36a11.55 11.55 0 0 1-9.67-.2L48.48 45.31c.5 1.27.79 2.64.79 4.1 0 6.36-5.18 11.44-11.44 11.44A11.52 11.52 0 0 1 26.39 49.4c0-6.25 5.09-11.44 11.34-11.44a11 11 0 0 1 4.99 1.18l11.43-10.46A11.45 11.45 0 0 1 64.8 13.15c6.36 0 11.45 5.18 11.45 11.43 0 1.57-.3 3.03-.88 4.5l9.38 10.85a12 12 0 0 1 3.81-.68c6.36 0 11.44 5.18 11.44 11.43 0 6.26-5.08 11.54-11.44 11.54a11 11 0 0 1-4.98-1.18L72.14 71.5c.49 1.27.78 2.74.78 4.1 0 6.27-5.18 11.45-11.43 11.45zm0-21.22c-5.38 0-9.78 4.4-9.78 9.78s4.4 9.77 9.78 9.77 9.77-4.4 9.77-9.77c0-1.37-.3-2.74-.88-4l-.3-.6L83.29 59l.5.3a10.3 10.3 0 0 0 4.78 1.27 9.8 9.8 0 0 0 9.78-9.78 9.82 9.82 0 0 0-13.5-9.1l-.59.2-10.85-12.62.2-.48a9.2 9.2 0 0 0 .97-4.2c0-5.38-4.4-9.78-9.77-9.78s-9.78 4.4-9.78 9.77c0 1.37.3 2.74.88 4.01l.3.59L43 41.2l-.49-.3a10.24 10.24 0 0 0-4.69-1.26c-5.38 0-9.78 4.4-9.78 9.77s4.4 9.78 9.78 9.78 9.78-4.4 9.78-9.78c0-1.37-.3-2.74-.88-4l-.3-.6 13.2-11.92.49.3a9.86 9.86 0 0 0 9.19.19l.58-.3 10.56 12.23-.3.48a9.8 9.8 0 0 0-.48 9l.3.58-13.2 11.93-.5-.3a10.5 10.5 0 0 0-4.78-1.17Z"
    className="st2"
    // fill="#fff"
  />
  </svg>;


export default icon;