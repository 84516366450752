/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ObjectReference from './ObjectReference';

export default class Order {
  constructor() {


  }

  static typeDescription() {
    return "Order";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Order();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('customerReference')) {
        obj['customerReference'] = ApiClient.convertToType(data['customerReference'], 'String');
      }
      if (data.hasOwnProperty('erpDocumentUrl')) {
        obj['erpDocumentUrl'] = ApiClient.convertToType(data['erpDocumentUrl'], 'String');
      }
      if (data.hasOwnProperty('erpProformaDocumentUrl')) {
        obj['erpProformaDocumentUrl'] = ApiClient.convertToType(data['erpProformaDocumentUrl'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('ownerName')) {
        obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
      }
      if (data.hasOwnProperty('finalCustomerId')) {
        obj['finalCustomerId'] = ApiClient.convertToType(data['finalCustomerId'], 'String');
      }
      if (data.hasOwnProperty('finalCustomerName')) {
        obj['finalCustomerName'] = ApiClient.convertToType(data['finalCustomerName'], 'String');
      }
      if (data.hasOwnProperty('creatorId')) {
        obj['creatorId'] = ApiClient.convertToType(data['creatorId'], 'String');
      }
      if (data.hasOwnProperty('quoteId')) {
        obj['quoteId'] = ApiClient.convertToType(data['quoteId'], 'String');
      }
      if (data.hasOwnProperty('quotationReferences')) {
        obj['quotationReferences'] = ApiClient.convertToType(data['quotationReferences'], [ObjectReference]);
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('isCancelled')) {
        obj['isCancelled'] = ApiClient.convertToType(data['isCancelled'], 'Boolean');
      }
      if (data.hasOwnProperty('isCancellable')) {
        obj['isCancellable'] = ApiClient.convertToType(data['isCancellable'], 'Boolean');
      }
      if (data.hasOwnProperty('isCompleted')) {
        obj['isCompleted'] = ApiClient.convertToType(data['isCompleted'], 'Boolean');
      }
      if (data.hasOwnProperty('isExpired')) {
        obj['isExpired'] = ApiClient.convertToType(data['isExpired'], 'Boolean');
      }
      if (data.hasOwnProperty('isOpen')) {
        obj['isOpen'] = ApiClient.convertToType(data['isOpen'], 'Boolean');
      }
      if (data.hasOwnProperty('isPaid')) {
        obj['isPaid'] = ApiClient.convertToType(data['isPaid'], 'Boolean');
      }
      if (data.hasOwnProperty('isValidated')) {
        obj['isValidated'] = ApiClient.convertToType(data['isValidated'], 'Boolean');
      }
      if (data.hasOwnProperty('isProducible')) {
        obj['isProducible'] = ApiClient.convertToType(data['isProducible'], 'Boolean');
      }
      if (data.hasOwnProperty('validationDate')) {
        obj['validationDate'] = ApiClient.convertToType(data['validationDate'], 'String');
      }
      if (data.hasOwnProperty('expirationDate')) {
        obj['expirationDate'] = ApiClient.convertToType(data['expirationDate'], 'String');
      }
      if (data.hasOwnProperty('productionStartDay')) {
        obj['productionStartDay'] = ApiClient.convertToType(data['productionStartDay'], 'Date');
      }
      if (data.hasOwnProperty('totalPriceTaxesExcluded')) {
        obj['totalPriceTaxesExcluded'] = ApiClient.convertToType(data['totalPriceTaxesExcluded'], 'Number');
      }
      if (data.hasOwnProperty('totalPriceTaxesIncluded')) {
        obj['totalPriceTaxesIncluded'] = ApiClient.convertToType(data['totalPriceTaxesIncluded'], 'Number');
      }
      if (data.hasOwnProperty('remainingDues')) {
        obj['remainingDues'] = ApiClient.convertToType(data['remainingDues'], 'Number');
      }
      if (data.hasOwnProperty('files')) {
        obj['files'] = ApiClient.convertToType(data['files'], ['String']);
      }
      if (data.hasOwnProperty('comment')) {
        obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
      }
      if (data.hasOwnProperty('privateComment')) {
        obj['privateComment'] = ApiClient.convertToType(data['privateComment'], 'String');
      }
      if (data.hasOwnProperty('paymentCondition')) {
        obj['paymentCondition'] = ApiClient.convertToType(data['paymentCondition'], 'String');
      }
      if (data.hasOwnProperty('paymentMethod')) {
        obj['paymentMethod'] = ApiClient.convertToType(data['paymentMethod'], 'String');
      }
    }
    return obj;
  }


  id = undefined;

  reference = undefined;

  customerReference = undefined;

  erpDocumentUrl = undefined;

  erpProformaDocumentUrl = undefined;

  ownerId = undefined;

  ownerName = undefined;

  finalCustomerId = undefined;

  finalCustomerName = undefined;

  creatorId = undefined;

  quoteId = undefined;

  quotationReferences = undefined;

  creationDate = undefined;

  isCancelled = undefined;

  isCancellable = undefined;

  isCompleted = undefined;

  isExpired = undefined;

  isOpen = undefined;

  isPaid = undefined;

  isValidated = undefined;

  isProducible = undefined;

  validationDate = undefined;

  expirationDate = undefined;

  productionStartDay = undefined;

  totalPriceTaxesExcluded = undefined;

  totalPriceTaxesIncluded = undefined;

  remainingDues = undefined;

  files = undefined;

  comment = undefined;

  privateComment = undefined;

  paymentCondition = undefined;

  paymentMethod = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getCustomerReference() {
    return this.customerReference;
  }

  setCustomerReference(customerReference) {
    this['customerReference'] = customerReference;
  }

  getErpDocumentUrl() {
    return this.erpDocumentUrl;
  }

  setErpDocumentUrl(erpDocumentUrl) {
    this['erpDocumentUrl'] = erpDocumentUrl;
  }

  getErpProformaDocumentUrl() {
    return this.erpProformaDocumentUrl;
  }

  setErpProformaDocumentUrl(erpProformaDocumentUrl) {
    this['erpProformaDocumentUrl'] = erpProformaDocumentUrl;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getOwnerName() {
    return this.ownerName;
  }

  setOwnerName(ownerName) {
    this['ownerName'] = ownerName;
  }

  getFinalCustomerId() {
    return this.finalCustomerId;
  }

  setFinalCustomerId(finalCustomerId) {
    this['finalCustomerId'] = finalCustomerId;
  }

  getFinalCustomerName() {
    return this.finalCustomerName;
  }

  setFinalCustomerName(finalCustomerName) {
    this['finalCustomerName'] = finalCustomerName;
  }

  getCreatorId() {
    return this.creatorId;
  }

  setCreatorId(creatorId) {
    this['creatorId'] = creatorId;
  }

  getQuoteId() {
    return this.quoteId;
  }

  setQuoteId(quoteId) {
    this['quoteId'] = quoteId;
  }

  getQuotationReferences() {
    return this.quotationReferences;
  }

  setQuotationReferences(quotationReferences) {
    this['quotationReferences'] = quotationReferences;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getIsCancelled() {
    return this.isCancelled;
  }

  setIsCancelled(isCancelled) {
    this['isCancelled'] = isCancelled;
  }

  getIsCancellable() {
    return this.isCancellable;
  }

  setIsCancellable(isCancellable) {
    this['isCancellable'] = isCancellable;
  }

  getIsCompleted() {
    return this.isCompleted;
  }

  setIsCompleted(isCompleted) {
    this['isCompleted'] = isCompleted;
  }

  getIsExpired() {
    return this.isExpired;
  }

  setIsExpired(isExpired) {
    this['isExpired'] = isExpired;
  }

  getIsOpen() {
    return this.isOpen;
  }

  setIsOpen(isOpen) {
    this['isOpen'] = isOpen;
  }

  getIsPaid() {
    return this.isPaid;
  }

  setIsPaid(isPaid) {
    this['isPaid'] = isPaid;
  }

  getIsValidated() {
    return this.isValidated;
  }

  setIsValidated(isValidated) {
    this['isValidated'] = isValidated;
  }

  getIsProducible() {
    return this.isProducible;
  }

  setIsProducible(isProducible) {
    this['isProducible'] = isProducible;
  }

  getValidationDate() {
    return this.validationDate;
  }

  setValidationDate(validationDate) {
    this['validationDate'] = validationDate;
  }

  getExpirationDate() {
    return this.expirationDate;
  }

  setExpirationDate(expirationDate) {
    this['expirationDate'] = expirationDate;
  }

  getProductionStartDay() {
    return this.productionStartDay;
  }

  setProductionStartDay(productionStartDay) {
    this['productionStartDay'] = productionStartDay;
  }

  getTotalPriceTaxesExcluded() {
    return this.totalPriceTaxesExcluded;
  }

  setTotalPriceTaxesExcluded(totalPriceTaxesExcluded) {
    this['totalPriceTaxesExcluded'] = totalPriceTaxesExcluded;
  }

  getTotalPriceTaxesIncluded() {
    return this.totalPriceTaxesIncluded;
  }

  setTotalPriceTaxesIncluded(totalPriceTaxesIncluded) {
    this['totalPriceTaxesIncluded'] = totalPriceTaxesIncluded;
  }

  getRemainingDues() {
    return this.remainingDues;
  }

  setRemainingDues(remainingDues) {
    this['remainingDues'] = remainingDues;
  }

  getFiles() {
    return this.files;
  }

  setFiles(files) {
    this['files'] = files;
  }

  getComment() {
    return this.comment;
  }

  setComment(comment) {
    this['comment'] = comment;
  }

  getPrivateComment() {
    return this.privateComment;
  }

  setPrivateComment(privateComment) {
    this['privateComment'] = privateComment;
  }

  getPaymentCondition() {
    return this.paymentCondition;
  }

  setPaymentCondition(paymentCondition) {
    this['paymentCondition'] = paymentCondition;
  }

  getPaymentMethod() {
    return this.paymentMethod;
  }

  setPaymentMethod(paymentMethod) {
    this['paymentMethod'] = paymentMethod;
  }


  static PaymentConditionEnum = {
    "FiftyFifty": "FiftyFifty",
    "FortyFiveDays": "FortyFiveDays",
    "FortyFiveDaysEndOfMonth": "FortyFiveDaysEndOfMonth",
    "OnDelivery": "OnDelivery",
    "OnInvoice": "OnInvoice",
    "OnOrder": "OnOrder",
    "SixtyDays": "SixtyDays",
    "SixtyDaysEndOfMonth": "SixtyDaysEndOfMonth",
    "ThirtyDays": "ThirtyDays",
    "ThirtyDaysEndOfMonth": "ThirtyDaysEndOfMonth",
    "ThirtyDaysEndOfMonth15th": "ThirtyDaysEndOfMonth15th"
  };

  static PaymentMethodEnum = {
    "cb": "cb",
    "paypal": "paypal",
    "cash": "cash",
    "cheque": "cheque",
    "transfer": "transfer",
    "account": "account"
  };

}