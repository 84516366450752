/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Pricing from './Pricing';

export default class MissingPaymentAnomalyParameters {
  constructor(price) {
    this['price'] = price || new Pricing();


  }

  static typeDescription() {
    return "MissingPaymentAnomalyParameters";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MissingPaymentAnomalyParameters();
      if (data.hasOwnProperty('price')) {
        obj['price'] = Pricing.constructFromObject(data['price']);
      }
    }
    return obj;
  }


  price = undefined;




  getPrice() {
    return this.price;
  }

  setPrice(price) {
    this['price'] = price;
  }


}