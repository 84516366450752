/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Message from '../model/Message';
import MessageThread from '../model/MessageThread';
import ObjectReference from '../model/ObjectReference';


export default class MessagingApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  addMessageAttachmentWithHttpInfo(messageId, fileName, content) {
    let postBody = content;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling addMessageAttachment");
    }

    // verify the required parameter 'fileName' is set
    if (fileName === undefined || fileName === null) {
      throw new Error("Missing the required parameter 'fileName' when calling addMessageAttachment");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling addMessageAttachment");
    }


    let pathParams = {
      'messageId': messageId,
      'fileName': fileName
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}/attachment/{fileName}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  addMessageAttachment(messageId, fileName, content) {
    return this.addMessageAttachmentWithHttpInfo(messageId, fileName, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createAnswerMessageWithHttpInfo(messageId, content) {
    let postBody = content;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling createAnswerMessage");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createAnswerMessage");
    }


    let pathParams = {
      'messageId': messageId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createAnswerMessage(messageId, content) {
    return this.createAnswerMessageWithHttpInfo(messageId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createMessageWithHttpInfo(threadId, content) {
    let postBody = content;

    // verify the required parameter 'threadId' is set
    if (threadId === undefined || threadId === null) {
      throw new Error("Missing the required parameter 'threadId' when calling createMessage");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createMessage");
    }


    let pathParams = {
      'threadId': threadId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/thread/{threadId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createMessage(threadId, content) {
    return this.createMessageWithHttpInfo(threadId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createMessageThreadWithHttpInfo(object) {
    let postBody = object;

    // verify the required parameter 'object' is set
    if (object === undefined || object === null) {
      throw new Error("Missing the required parameter 'object' when calling createMessageThread");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = MessageThread;

    return this.apiClient.callApi(
      '/messaging/threads', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createMessageThread(object) {
    return this.createMessageThreadWithHttpInfo(object).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteMessageWithHttpInfo(messageId) {
    let postBody = null;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling deleteMessage");
    }


    let pathParams = {
      'messageId': messageId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteMessage(messageId) {
    return this.deleteMessageWithHttpInfo(messageId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteMessageAttachmentWithHttpInfo(messageId, fileName) {
    let postBody = null;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling deleteMessageAttachment");
    }

    // verify the required parameter 'fileName' is set
    if (fileName === undefined || fileName === null) {
      throw new Error("Missing the required parameter 'fileName' when calling deleteMessageAttachment");
    }


    let pathParams = {
      'messageId': messageId,
      'fileName': fileName
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}/attachment/{fileName}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteMessageAttachment(messageId, fileName) {
    return this.deleteMessageAttachmentWithHttpInfo(messageId, fileName).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getMessageWithHttpInfo(messageId) {
    let postBody = null;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling getMessage");
    }


    let pathParams = {
      'messageId': messageId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getMessage(messageId) {
    return this.getMessageWithHttpInfo(messageId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getMessageAttachmentWithHttpInfo(messageId, fileName) {
    let postBody = null;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling getMessageAttachment");
    }

    // verify the required parameter 'fileName' is set
    if (fileName === undefined || fileName === null) {
      throw new Error("Missing the required parameter 'fileName' when calling getMessageAttachment");
    }


    let pathParams = {
      'messageId': messageId,
      'fileName': fileName
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/octet-stream'];
    let returnType = 'Blob';

    return this.apiClient.callApi(
      '/messaging/message/{messageId}/attachment/{fileName}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getMessageAttachment(messageId, fileName) {
    return this.getMessageAttachmentWithHttpInfo(messageId, fileName).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getMessageThreadWithHttpInfo(threadId, opts) {
    opts = opts || {};
    let postBody = null;

    // verify the required parameter 'threadId' is set
    if (threadId === undefined || threadId === null) {
      throw new Error("Missing the required parameter 'threadId' when calling getMessageThread");
    }


    let pathParams = {
      'threadId': threadId
    };
    let queryParams = {
      'orderBy': opts['orderBy']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = MessageThread;

    return this.apiClient.callApi(
      '/messaging/thread/{threadId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getMessageThread(threadId, opts) {
    return this.getMessageThreadWithHttpInfo(threadId, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getMessageThreadsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'objectType': opts['objectType'],
      'objectId': opts['objectId'],
      'messageIsUnreadByRequester': opts['messageIsUnreadByRequester'],
      'messageAuthorId': opts['messageAuthorId'],
      'messageRespondToMessage': opts['messageRespondToMessage'],
      'messageIsDraft': opts['messageIsDraft'],
      'messageHaveAttachment': opts['messageHaveAttachment'],
      'messageHaveAttachmentNameMatching': opts['messageHaveAttachmentNameMatching'],
      'messageSubjectIsMatching': opts['messageSubjectIsMatching'],
      'messageBodyIsMatching': opts['messageBodyIsMatching'],
      'threadTitleIsMatching': opts['threadTitleIsMatching'],
      'orderBy': opts['orderBy']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [MessageThread];

    return this.apiClient.callApi(
      '/messaging/threads', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getMessageThreads(opts) {
    return this.getMessageThreadsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  haveMessageThreadUnreadMessagesWithHttpInfo(threadId) {
    let postBody = null;

    // verify the required parameter 'threadId' is set
    if (threadId === undefined || threadId === null) {
      throw new Error("Missing the required parameter 'threadId' when calling haveMessageThreadUnreadMessages");
    }


    let pathParams = {
      'threadId': threadId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/messaging/thread/{threadId}/haveUnreadMessages', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  haveMessageThreadUnreadMessages(threadId) {
    return this.haveMessageThreadUnreadMessagesWithHttpInfo(threadId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  postMessageWithHttpInfo(messageId) {
    let postBody = null;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling postMessage");
    }


    let pathParams = {
      'messageId': messageId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}/post', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  postMessage(messageId) {
    return this.postMessageWithHttpInfo(messageId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setMessageWithHttpInfo(messageId, content) {
    let postBody = content;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling setMessage");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setMessage");
    }


    let pathParams = {
      'messageId': messageId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setMessage(messageId, content) {
    return this.setMessageWithHttpInfo(messageId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setMessageReadWithHttpInfo(messageId) {
    let postBody = null;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling setMessageRead");
    }


    let pathParams = {
      'messageId': messageId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}/read', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setMessageRead(messageId) {
    return this.setMessageReadWithHttpInfo(messageId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setMessageThreadWithHttpInfo(threadId, content) {
    let postBody = content;

    // verify the required parameter 'threadId' is set
    if (threadId === undefined || threadId === null) {
      throw new Error("Missing the required parameter 'threadId' when calling setMessageThread");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setMessageThread");
    }


    let pathParams = {
      'threadId': threadId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = MessageThread;

    return this.apiClient.callApi(
      '/messaging/thread/{threadId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setMessageThread(threadId, content) {
    return this.setMessageThreadWithHttpInfo(threadId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setMessageUnreadWithHttpInfo(messageId) {
    let postBody = null;

    // verify the required parameter 'messageId' is set
    if (messageId === undefined || messageId === null) {
      throw new Error("Missing the required parameter 'messageId' when calling setMessageUnread");
    }


    let pathParams = {
      'messageId': messageId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Message;

    return this.apiClient.callApi(
      '/messaging/message/{messageId}/unread', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setMessageUnread(messageId) {
    return this.setMessageUnreadWithHttpInfo(messageId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}