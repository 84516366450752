/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ObjectReference from './ObjectReference';

export default class Event {
  constructor() {


  }

  static typeDescription() {
    return "Event";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Event();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('eventClass')) {
        obj['eventClass'] = ApiClient.convertToType(data['eventClass'], 'String');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
      if (data.hasOwnProperty('object')) {
        obj['object'] = ObjectReference.constructFromObject(data['object']);
      }
      if (data.hasOwnProperty('notificationIds')) {
        obj['notificationIds'] = ApiClient.convertToType(data['notificationIds'], ['String']);
      }
    }
    return obj;
  }


  id = undefined;

  eventClass = undefined;

  title = undefined;

  description = undefined;

  date = undefined;

  object = undefined;

  notificationIds = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getEventClass() {
    return this.eventClass;
  }

  setEventClass(eventClass) {
    this['eventClass'] = eventClass;
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this['title'] = title;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }

  getObject() {
    return this.object;
  }

  setObject(_object) {
    this['object'] = _object;
  }

  getNotificationIds() {
    return this.notificationIds;
  }

  setNotificationIds(notificationIds) {
    this['notificationIds'] = notificationIds;
  }


  static EventClassEnum = {
    "Account": "Account",
    "Anomaly": "Anomaly",
    "Financial": "Financial",
    "Order": "Order",
    "Quotation": "Quotation"
  };

}