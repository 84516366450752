/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PackageAndMountingTechnology {
  constructor(_package, mountingTechnology) {
    if (_package) {
      this['package'] = _package;
    }
    if (mountingTechnology) {
      this['mountingTechnology'] = mountingTechnology;
    }


  }

  static typeDescription() {
    return "PackageAndMountingTechnology";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PackageAndMountingTechnology();
      if (data.hasOwnProperty('package')) {
        obj['package'] = ApiClient.convertToType(data['package'], 'String');
      }
      if (data.hasOwnProperty('mountingTechnology')) {
        obj['mountingTechnology'] = ApiClient.convertToType(data['mountingTechnology'], 'String');
      }
    }
    return obj;
  }


  package = "";

  mountingTechnology = "ThruHole";




  getPackage() {
    return this.package;
  }

  setPackage(_package) {
    this['package'] = _package;
  }

  getMountingTechnology() {
    return this.mountingTechnology;
  }

  setMountingTechnology(mountingTechnology) {
    this['mountingTechnology'] = mountingTechnology;
  }


  static MountingTechnologyEnum = {
    "ThruHole": "ThruHole",
    "SMT": "SMT",
    "Pressfit": "Pressfit",
    "Screwing": "Screwing",
    "Riveting": "Riveting",
    "Sticking": "Sticking",
    "Clipping": "Clipping"
  };

}