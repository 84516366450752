import React from "react";
import Wrapper from "../components/Wrapper";

const SiteMap = (props) => {
  return (
    <div className="py-5 my-5">
      <Wrapper></Wrapper>
    </div>);

};

export default SiteMap;