/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Action from './Action';

export default class MissingPaymentAnomalyActionsPay {
  constructor(asAction) {
    this['asAction'] = asAction || new Action();


    if (!this.asAction.type || this.asAction.type === "") {
      this.asAction.type = "pay";
    }
    if (!this.asAction.description || this.asAction.description === "") {
      this.asAction.description = "make payment";
    }
  }

  static typeDescription() {
    return "MissingPaymentAnomalyActionsPay";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MissingPaymentAnomalyActionsPay();
      if (data.hasOwnProperty('asAction')) {
        obj['asAction'] = Action.constructFromObject(data['asAction']);
      }
      if (data.hasOwnProperty('paymentTransactionId')) {
        obj['paymentTransactionId'] = ApiClient.convertToType(data['paymentTransactionId'], 'String');
      }
    }
    return obj;
  }


  asAction = undefined;

  paymentTransactionId = undefined;




  getAsAction() {
    return this.asAction;
  }

  setAsAction(asAction) {
    this['asAction'] = asAction;
  }

  getPaymentTransactionId() {
    return this.paymentTransactionId;
  }

  setPaymentTransactionId(paymentTransactionId) {
    this['paymentTransactionId'] = paymentTransactionId;
  }


}