/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Collaborator {
  constructor() {


  }

  static typeDescription() {
    return "Collaborator";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Collaborator();
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
      if (data.hasOwnProperty('firstName')) {
        obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
      }
      if (data.hasOwnProperty('lastName')) {
        obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
      }
      if (data.hasOwnProperty('isActive')) {
        obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
      }
      if (data.hasOwnProperty('hasWebLogin')) {
        obj['hasWebLogin'] = ApiClient.convertToType(data['hasWebLogin'], 'Boolean');
      }
      if (data.hasOwnProperty('webLogin')) {
        obj['webLogin'] = ApiClient.convertToType(data['webLogin'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('pendingEmail')) {
        obj['pendingEmail'] = ApiClient.convertToType(data['pendingEmail'], 'String');
      }
      if (data.hasOwnProperty('canAccess')) {
        obj['canAccess'] = ApiClient.convertToType(data['canAccess'], 'Boolean');
      }
      if (data.hasOwnProperty('canOrder')) {
        obj['canOrder'] = ApiClient.convertToType(data['canOrder'], 'Boolean');
      }
      if (data.hasOwnProperty('canPay')) {
        obj['canPay'] = ApiClient.convertToType(data['canPay'], 'Boolean');
      }
      if (data.hasOwnProperty('canAdministrate')) {
        obj['canAdministrate'] = ApiClient.convertToType(data['canAdministrate'], 'Boolean');
      }
      if (data.hasOwnProperty('canShare')) {
        obj['canShare'] = ApiClient.convertToType(data['canShare'], 'Boolean');
      }
    }
    return obj;
  }


  userId = undefined;

  firstName = undefined;

  lastName = undefined;

  isActive = undefined;

  hasWebLogin = undefined;

  webLogin = undefined;

  email = undefined;

  pendingEmail = undefined;

  canAccess = undefined;

  canOrder = undefined;

  canPay = undefined;

  canAdministrate = undefined;

  canShare = undefined;




  getUserId() {
    return this.userId;
  }

  setUserId(userId) {
    this['userId'] = userId;
  }

  getFirstName() {
    return this.firstName;
  }

  setFirstName(firstName) {
    this['firstName'] = firstName;
  }

  getLastName() {
    return this.lastName;
  }

  setLastName(lastName) {
    this['lastName'] = lastName;
  }

  getIsActive() {
    return this.isActive;
  }

  setIsActive(isActive) {
    this['isActive'] = isActive;
  }

  getHasWebLogin() {
    return this.hasWebLogin;
  }

  setHasWebLogin(hasWebLogin) {
    this['hasWebLogin'] = hasWebLogin;
  }

  getWebLogin() {
    return this.webLogin;
  }

  setWebLogin(webLogin) {
    this['webLogin'] = webLogin;
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    this['email'] = email;
  }

  getPendingEmail() {
    return this.pendingEmail;
  }

  setPendingEmail(pendingEmail) {
    this['pendingEmail'] = pendingEmail;
  }

  getCanAccess() {
    return this.canAccess;
  }

  setCanAccess(canAccess) {
    this['canAccess'] = canAccess;
  }

  getCanOrder() {
    return this.canOrder;
  }

  setCanOrder(canOrder) {
    this['canOrder'] = canOrder;
  }

  getCanPay() {
    return this.canPay;
  }

  setCanPay(canPay) {
    this['canPay'] = canPay;
  }

  getCanAdministrate() {
    return this.canAdministrate;
  }

  setCanAdministrate(canAdministrate) {
    this['canAdministrate'] = canAdministrate;
  }

  getCanShare() {
    return this.canShare;
  }

  setCanShare(canShare) {
    this['canShare'] = canShare;
  }


}