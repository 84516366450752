import React from "react";
import { connect } from "react-redux";

import { Switch, Route } from "react-router-dom";
import { IntlProvider, addLocaleData, injectIntl } from "react-intl";
import fr from "react-intl/locale-data/fr";
import en from "react-intl/locale-data/en";
import it from "react-intl/locale-data/it";
import de from "react-intl/locale-data/de";
import es from "react-intl/locale-data/es";
import nl from "react-intl/locale-data/nl";

// import { InitIntl, execInitEvents } from "utils/intl";
// messages
// import messages from 'locales/messages.json'

import App from "App";
// import authActions from 'Auth/actions'
import actions from "./actions";
import languages from "languages";
import products from "productList";

// console.error = e => {}

addLocaleData([...fr, ...en, ...it, ...de, ...es, ...nl]);

export let locale = languages[0];

// TODO: nginx redirect /fr to /
const IntlRouter = () => {
  return (
    <Switch>
      <Route path="/:lang" component={Intl} />
      {/* <Redirect exact from="/" to="/fr" /> */}
      <Route path="/" component={Intl} />
    </Switch>);

};

class IntlComponent extends React.Component {
  state = {};
  componentDidMount() {
    this.update();
  }
  componentDidUpdate(oProps) {
    this.update(oProps);
  }
  update(oProps = {}) {
    const { props } = this;
    if (props.locale !== props.langParam) {
      if (!props.langParam) {
        props.history.replace(
          "/" + props.locale + props.location.pathname + props.location.search
        );
      } else {
        props.history.replace(
          "/" +
          props.locale +
          props.location.pathname.slice(3) +
          props.location.search
        );
      }
    }
    if (document.getElementsByTagName("html")[0].lang !== props.locale) {
      document.getElementsByTagName("html")[0].lang = props.locale;
    }
    if (
    props.locale !== oProps.locale ||
    props.domains.join() !== oProps.domains.join())
    {
      if (props.locale !== oProps.locale) {
        props.emptyLoadedDomains();
      }
      props.getMessages(props.locale);
    }
  }
  render() {
    const { props } = this;
    const { location = {} } = props;
    const pathArray = location.pathname.split("/");
    const messages =
    pathArray[2] === "account" ||
    products.indexOf(pathArray[2]) >= 0 && pathArray[3] ?
    ["en", "fr"].indexOf(props.locale) >= 0 ?
    props.messages :
    {} :
    props.messages;
    console.log("Intldebug render", props, pathArray, props.locale, messages);
    return (
      <IntlProvider
        locale={props.locale}
        defaultLocale={languages[0]}
        messages={messages}
        onError={(e) => {}}
        formatMessage={(e) => "test"}>

        <App />
        {/* <IntlFormatMessageOverride>
          </IntlFormatMessageOverride> */}
      </IntlProvider>);

  }
}

// let oFormatMessage = false;

// const IntlFormatMessageOverride = injectIntl((props) => {
//   oFormatMessage = oFormatMessage || props.intl.formatMessage;
//   props.intl.formatMessage = function (message = {}) {
//     console.log("newFormatMessage", message, arguments);
//     return message.id
//       ? oFormatMessage(...arguments)
//       : message.defaultMessage || "InIdPb";
//   };
//   return props.children;
// });

const mapStateToProps = (state, props) => {
  // Router lang
  const langParam = !props.match.params.lang ?
  false :
  languages.indexOf(props.match.params.lang.toLowerCase()) >= 0 ?
  props.match.params.lang.toLowerCase() :
  false;
  // LocaleStorage Lang
  const language = state.intl.language ?
  state.intl.language.toLowerCase() :
  false;
  // User profile lang
  const userLanguage = state.auth.user.language ?
  state.auth.user.language.toLowerCase() :
  false;
  // Browser lang
  let navLang = false;
  if (typeof window !== "undefined") {
    navLang = (
    window.navigator.language || window.navigator.userLanguage).
    slice(0, 2);
  }
  // Output Lang
  locale = (
  language ||
  userLanguage ||
  langParam ||
  navLang ||
  languages[0]).
  toLowerCase();
  if (languages.indexOf(locale) < 0) {
    locale = langParam || languages[0];
  }
  return {
    langParam: langParam,
    language,
    locale: locale.toLowerCase(),
    messages: state.intl.messages || {},
    domains: state.intl.domains
  };
};

const mapDispatchToProps = (dispatch) => {
  return actions;
};

const Intl = connect(mapStateToProps, mapDispatchToProps)(IntlComponent);

export default IntlRouter;