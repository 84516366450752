import React from "react";
import Section from "marketing/components/Section";
import { Image } from "Elements";

const PricingCover = (props) => {
  const { data = {} } = props;
  const { title, text, image } = data;
  return (
    <Section className="marketing_section" bgWhite>
      <span className="marketing_section__title pricing_selector pt-3">
        {title}
      </span>

      <div className="row">
        <div className="col-10 offset-1 d-flex flex-column item-align-center">
          <span className="pricingOptimizationText">{text}</span>
          <Image
            image={image}
            sizes="
            (min-width:576px) 420px,
            (min-width:768px) 570px, 
            (min-width:992px) 770px, 
            (min-width:1200px) 919px,
            " />






          {/* <img
             src="/images/marketing/pricing/pcba.png"
             style={{ width: "100%" }}
            /> */}
        </div>
      </div>
    </Section>);

};

export default PricingCover;