/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ComponentFilterRequestOrdering from './ComponentFilterRequestOrdering';
import ComponentFilterRequestProperties from './ComponentFilterRequestProperties';

export default class ComponentFilterRequest {
  constructor() {


  }

  static typeDescription() {
    return "ComponentFilterRequest";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ComponentFilterRequest();
      if (data.hasOwnProperty('MPNPattern')) {
        obj['MPNPattern'] = ApiClient.convertToType(data['MPNPattern'], 'String');
      }
      if (data.hasOwnProperty('ManufacturerPattern')) {
        obj['ManufacturerPattern'] = ApiClient.convertToType(data['ManufacturerPattern'], 'String');
      }
      if (data.hasOwnProperty('descriptionPattern')) {
        obj['descriptionPattern'] = ApiClient.convertToType(data['descriptionPattern'], 'String');
      }
      if (data.hasOwnProperty('categoryNames')) {
        obj['categoryNames'] = ApiClient.convertToType(data['categoryNames'], ['String']);
      }
      if (data.hasOwnProperty('imageUrlPattern')) {
        obj['imageUrlPattern'] = ApiClient.convertToType(data['imageUrlPattern'], 'String');
      }
      if (data.hasOwnProperty('datasheetUrlPattern')) {
        obj['datasheetUrlPattern'] = ApiClient.convertToType(data['datasheetUrlPattern'], 'String');
      }
      if (data.hasOwnProperty('cadFileUrlPattern')) {
        obj['cadFileUrlPattern'] = ApiClient.convertToType(data['cadFileUrlPattern'], 'String');
      }
      if (data.hasOwnProperty('normsPattern')) {
        obj['normsPattern'] = ApiClient.convertToType(data['normsPattern'], 'String');
      }
      if (data.hasOwnProperty('commonNamesPattern')) {
        obj['commonNamesPattern'] = ApiClient.convertToType(data['commonNamesPattern'], 'String');
      }
      if (data.hasOwnProperty('includeInactive')) {
        obj['includeInactive'] = ApiClient.convertToType(data['includeInactive'], 'Boolean');
      }
      if (data.hasOwnProperty('isApproved')) {
        obj['isApproved'] = ApiClient.convertToType(data['isApproved'], 'Boolean');
      }
      if (data.hasOwnProperty('types')) {
        obj['types'] = ApiClient.convertToType(data['types'], ['String']);
      }
      if (data.hasOwnProperty('categoryPaths')) {
        obj['categoryPaths'] = ApiClient.convertToType(data['categoryPaths'], [['String']]);
      }
      if (data.hasOwnProperty('properties')) {
        obj['properties'] = ApiClient.convertToType(data['properties'], [ComponentFilterRequestProperties]);
      }
      if (data.hasOwnProperty('ordering')) {
        obj['ordering'] = ApiClient.convertToType(data['ordering'], [ComponentFilterRequestOrdering]);
      }
      if (data.hasOwnProperty('pagingStart')) {
        obj['pagingStart'] = ApiClient.convertToType(data['pagingStart'], 'Number');
      }
      if (data.hasOwnProperty('pagingSize')) {
        obj['pagingSize'] = ApiClient.convertToType(data['pagingSize'], 'Number');
      }
    }
    return obj;
  }


  MPNPattern = undefined;

  ManufacturerPattern = undefined;

  descriptionPattern = undefined;

  categoryNames = undefined;

  imageUrlPattern = undefined;

  datasheetUrlPattern = undefined;

  cadFileUrlPattern = undefined;

  normsPattern = undefined;

  commonNamesPattern = undefined;

  includeInactive = undefined;

  isApproved = undefined;

  types = undefined;

  categoryPaths = undefined;

  properties = undefined;

  ordering = undefined;

  pagingStart = undefined;

  pagingSize = undefined;




  getMPNPattern() {
    return this.MPNPattern;
  }

  setMPNPattern(mPNPattern) {
    this['MPNPattern'] = mPNPattern;
  }

  getManufacturerPattern() {
    return this.ManufacturerPattern;
  }

  setManufacturerPattern(manufacturerPattern) {
    this['ManufacturerPattern'] = manufacturerPattern;
  }

  getDescriptionPattern() {
    return this.descriptionPattern;
  }

  setDescriptionPattern(descriptionPattern) {
    this['descriptionPattern'] = descriptionPattern;
  }

  getCategoryNames() {
    return this.categoryNames;
  }

  setCategoryNames(categoryNames) {
    this['categoryNames'] = categoryNames;
  }

  getImageUrlPattern() {
    return this.imageUrlPattern;
  }

  setImageUrlPattern(imageUrlPattern) {
    this['imageUrlPattern'] = imageUrlPattern;
  }

  getDatasheetUrlPattern() {
    return this.datasheetUrlPattern;
  }

  setDatasheetUrlPattern(datasheetUrlPattern) {
    this['datasheetUrlPattern'] = datasheetUrlPattern;
  }

  getCadFileUrlPattern() {
    return this.cadFileUrlPattern;
  }

  setCadFileUrlPattern(cadFileUrlPattern) {
    this['cadFileUrlPattern'] = cadFileUrlPattern;
  }

  getNormsPattern() {
    return this.normsPattern;
  }

  setNormsPattern(normsPattern) {
    this['normsPattern'] = normsPattern;
  }

  getCommonNamesPattern() {
    return this.commonNamesPattern;
  }

  setCommonNamesPattern(commonNamesPattern) {
    this['commonNamesPattern'] = commonNamesPattern;
  }

  getIncludeInactive() {
    return this.includeInactive;
  }

  setIncludeInactive(includeInactive) {
    this['includeInactive'] = includeInactive;
  }

  getIsApproved() {
    return this.isApproved;
  }

  setIsApproved(isApproved) {
    this['isApproved'] = isApproved;
  }

  getTypes() {
    return this.types;
  }

  setTypes(types) {
    this['types'] = types;
  }

  getCategoryPaths() {
    return this.categoryPaths;
  }

  setCategoryPaths(categoryPaths) {
    this['categoryPaths'] = categoryPaths;
  }

  getProperties() {
    return this.properties;
  }

  setProperties(properties) {
    this['properties'] = properties;
  }

  getOrdering() {
    return this.ordering;
  }

  setOrdering(ordering) {
    this['ordering'] = ordering;
  }

  getPagingStart() {
    return this.pagingStart;
  }

  setPagingStart(pagingStart) {
    this['pagingStart'] = pagingStart;
  }

  getPagingSize() {
    return this.pagingSize;
  }

  setPagingSize(pagingSize) {
    this['pagingSize'] = pagingSize;
  }


  static TypesEnum = {
    "Cable": "Cable",
    "CableTie": "CableTie",
    "CableTieMountAndAccessories": "CableTieMountAndAccessories",
    "ConnectorAndContactSeal": "ConnectorAndContactSeal",
    "ConnectorBackshell": "ConnectorBackshell",
    "ConnectorContact": "ConnectorContact",
    "ConnectorGrommet": "ConnectorGrommet",
    "ConnectorHousing": "ConnectorHousing",
    "ConnectorPlug": "ConnectorPlug",
    "ConnectorSet": "ConnectorSet",
    "ElectronicComponent": "ElectronicComponent",
    "Fastener": "Fastener",
    "Label": "Label",
    "Miscellaneous": "Miscellaneous",
    "PathJunction": "PathJunction",
    "Shielding": "Shielding",
    "Sleeving": "Sleeving",
    "Splice": "Splice",
    "TerminalComponent": "TerminalComponent",
    "Undef": "Undef",
    "Wire": "Wire"
  };

}