/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PcbValue {
  constructor() {


  }

  static typeDescription() {
    return "PcbValue";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PcbValue();
      if (data.hasOwnProperty('supplier')) {
        obj['supplier'] = ApiClient.convertToType(data['supplier'], 'String');
      }
      if (data.hasOwnProperty('orderedQuantity')) {
        obj['orderedQuantity'] = ApiClient.convertToType(data['orderedQuantity'], 'Number');
      }
      if (data.hasOwnProperty('totalSellingPriceEurosHT')) {
        obj['totalSellingPriceEurosHT'] = ApiClient.convertToType(data['totalSellingPriceEurosHT'], 'Number');
      }
      if (data.hasOwnProperty('totalBuyingPriceEurosHT')) {
        obj['totalBuyingPriceEurosHT'] = ApiClient.convertToType(data['totalBuyingPriceEurosHT'], 'Number');
      }
    }
    return obj;
  }


  supplier = undefined;

  orderedQuantity = undefined;

  totalSellingPriceEurosHT = undefined;

  totalBuyingPriceEurosHT = undefined;




  getSupplier() {
    return this.supplier;
  }

  setSupplier(supplier) {
    this['supplier'] = supplier;
  }

  getOrderedQuantity() {
    return this.orderedQuantity;
  }

  setOrderedQuantity(orderedQuantity) {
    this['orderedQuantity'] = orderedQuantity;
  }

  getTotalSellingPriceEurosHT() {
    return this.totalSellingPriceEurosHT;
  }

  setTotalSellingPriceEurosHT(totalSellingPriceEurosHT) {
    this['totalSellingPriceEurosHT'] = totalSellingPriceEurosHT;
  }

  getTotalBuyingPriceEurosHT() {
    return this.totalBuyingPriceEurosHT;
  }

  setTotalBuyingPriceEurosHT(totalBuyingPriceEurosHT) {
    this['totalBuyingPriceEurosHT'] = totalBuyingPriceEurosHT;
  }


}