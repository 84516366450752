import Loadable from "react-loadable";

import Loading from "./components/Loading";

const ModuleLoader = (module) =>
Loadable({
  loader: () => import(`./${module}/Module`), modules: [`./${module}/Module`], webpack: () => [require.resolveWeak(`./${module}/Module`)],
  loading: Loading
});

// const ModuleLoadable = Loadable({
//   loader: import(``)
// })
//
// class ModuleLoader extends React.Component{
//   componentDidMount() {
//     if (typeof window !== 'undefined') {
//       const {module} = this.props
//       import(`./${module}/Module`)
//       .then(module => {
//         this.module = module.default
//         this.forceUpdate()
//       })
//       .catch(err => {
//         console.log('module err', err)
//       })
//     }
//   }
//   render(){
//     if (this.module) {
//       return React.createElement(this.module, this.props.props)
//     }
//     return <Loading />
//   }
// }

export default ModuleLoader;