import { combineReducers } from "redux";
// import Spec from "../api/model/PcbaSpecification";
// import Quotation from "../api/model/PcbaQuotation";
// import BomFile from "../api/model/BomFile";
import createReducer from "commonTunnel/reducer";

import { update, updateIndex, updateMultiple } from "utils/updateArray";

const commonReducer = createReducer("pcba");

const specs = (state = [], action) => {
  return (function (state, action) {
    let nState = [...state];
    switch (action.type) {
      case "auth/user/GET_SPECS":
        return (action.data || []).filter((s) => s.type === "pcba");
        break;
    }
    if (action.type === "pcba/specs/SET_BOM_LINE") {
      const specBom = nState.find((s) => s.id === action.specId).bom || {};
      nState = update(nState, {
        id: action.specId,
        bom: Object.assign({}, specBom, {
          lines: update(specBom.lines, action.data)
        })
      });
    } else if (action.type === "pcba/specs/ADD_GERBER_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          pcbSpec: Object.assign({}, nState[index].pcbSpec, {
            gerberFiles: [
            ...(nState[index].pcbSpec.gerberFiles || []),
            action.data]

          })
        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/REMOVE_GERBER_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        const fileIndex = (nState[index].pcbSpec.gerberFiles || []).findIndex(
          (f) => f === action.data
        );
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          pcbSpec: Object.assign({}, nState[index].pcbSpec, {
            gerberFiles: [
            ...(nState[index].pcbSpec.gerberFiles || []).slice(
              0,
              fileIndex
            ),
            ...(nState[index].pcbSpec.gerberFiles || []).slice(
              fileIndex + 1
            )]

          })
        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/ADD_PICKANDPLACE_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          pickAndPlaceFiles: [
          ...(nState[index].pickAndPlaceFiles || []),
          action.data]

        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/REMOVE_PICKANDPLACE_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        const fileIndex = (nState[index].pickAndPlaceFiles || []).findIndex(
          (f) => f === action.data
        );
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          pickAndPlaceFiles: [
          ...(nState[index].pickAndPlaceFiles || []).slice(0, fileIndex),
          ...(nState[index].pickAndPlaceFiles || []).slice(fileIndex + 1)]

        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/ADD_LAYOUT_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          layoutFiles: [...(nState[index].layoutFiles || []), action.data]
        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/REMOVE_LAYOUT_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        const fileIndex = (nState[index].layoutFiles || []).findIndex(
          (f) => f === action.data
        );
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          layoutFiles: [
          ...(nState[index].layoutFiles || []).slice(0, fileIndex),
          ...(nState[index].layoutFiles || []).slice(fileIndex + 1)]

        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/ADD_OTHER_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          otherFiles: [...(nState[index].otherFiles || []), action.data]
        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/REMOVE_OTHER_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        const fileIndex = (nState[index].otherFiles || []).findIndex(
          (f) => f === action.data
        );
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          otherFiles: [
          ...(nState[index].otherFiles || []).slice(0, fileIndex),
          ...(nState[index].otherFiles || []).slice(fileIndex + 1)]

        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/ADD_COATINGMASK_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          coatingMaskFiles: [
          ...(nState[index].coatingMaskFiles || []),
          action.data]

        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/REMOVE_COATINGMASK_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        const fileIndex = (nState[index].coatingMaskFiles || []).findIndex(
          (f) => f === action.data
        );
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          coatingMaskFiles: [
          ...(nState[index].coatingMaskFiles || []).slice(0, fileIndex),
          ...(nState[index].coatingMaskFiles || []).slice(fileIndex + 1)]

        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/ADD_IMPEDANCECONTROL_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          pcbSpec: Object.assign({}, nState[index].pcbSpec, {
            impedanceControlFiles: [
            ...(nState[index].pcbSpec.impedanceControlFiles || []),
            action.data]

          })
        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/REMOVE_IMPEDANCECONTROL_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        const fileIndex = (
        nState[index].pcbSpec.impedanceControlFiles || []).
        findIndex((f) => f === action.data);
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          pcbSpec: Object.assign({}, nState[index].pcbSpec, {
            impedanceControlFiles: [
            ...(nState[index].pcbSpec.impedanceControlFiles || []).slice(
              0,
              fileIndex
            ),
            ...(nState[index].pcbSpec.impedanceControlFiles || []).slice(
              fileIndex + 1
            )]

          })
        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/ADD_STACKUP_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          pcbSpec: Object.assign({}, nState[index].pcbSpec, {
            stackupFiles: [
            ...(nState[index].pcbSpec.stackupFiles || []),
            action.data]

          })
        }),
        ...nState.slice(index + 1)];

      }
    } else if (action.type === "pcba/specs/REMOVE_STACKUP_FILE") {
      const index = nState.findIndex((s) => s.id === action.id);
      if (index >= 0) {
        const fileIndex = (nState[index].pcbSpec.stackupFiles || []).findIndex(
          (f) => f === action.data
        );
        nState = [
        ...nState.slice(0, index),
        Object.assign({}, nState[index], {
          pcbSpec: Object.assign({}, nState[index].pcbSpec, {
            stackupFiles: [
            ...(nState[index].pcbSpec.stackupFiles || []).slice(
              0,
              fileIndex
            ),
            ...(nState[index].pcbSpec.stackupFiles || []).slice(
              fileIndex + 1
            )]

          })
        }),
        ...nState.slice(index + 1)];

      }
    }
    return nState;
  })(commonReducer.specs(state, action), action).map((spec) =>
  Object.assign({}, spec, { type: "pcba" })
  );
  // .map(spec => {
  //   // spec.pcbSpec.thickness = Number(Number(spec.pcbSpec.thickness).toFixed(10))
  //   return spec
  // })
  // .map(spec => Spec.constructFromObject(spec))
};

const quotations = (state = [], action) => {
  return (function (state, action) {
    if (action.type === "auth/user/GET_QUOTATIONS") {
      return action.data.filter((s) => s.type === "pcba");
    }
    if (action.type === "pcba/quotations/SET_DURATION_QUANTITIES") {
      console.log("setDurationQuantities", state, action);
      const index = state.findIndex((q) => q.id === action.id);
      if (index < 0) {
        return state;
      }
      return update(state, {
        id: action.id,
        durationQuantities: updateMultiple(
          state[index].durationQuantities,
          action.data.map((dq) =>
          Object.assign({ dqId: dq.id }, dq, {
            id: dq.durationDays + "-" + dq.quantity
          })
          )
        )
      });
    }
    // if (action.type === "pcba/quotations/SET_ALL") {
    //   let nState = [...state];
    //   action.data.forEach(quotation => (nState = update(nState, quotation)));
    //   return nState;
    // }
    if (action.type === "quotations/updateAll") {
      let nState = [...state];
      action.data.
      filter((q) => q.type === "pcba").
      forEach((q) => nState = update(nState, q));
      return nState;
    }
    return state;
  })(commonReducer.quotations(state, action), action).map((quotation) => {
    let res = Object.assign({}, quotation, {
      type: "pcba",
      quantity: quotation.pcbaQuantity,
      // delay: quotation.delayDays,
      specId: quotation.specId || quotation.specificationId
    });
    // const prices = [
    //   "assemblyPrice",
    //   "coatingPrice",
    //   "componentsPrice",
    //   "deliveryPrice",
    //   "pcbPrice",
    //   "xrayPrice"
    // ];
    // res.totalHt = prices.reduce((a, b) => {
    //   return a + (quotation[b] || {}).value || 0
    // }, 0)
    // res.totalTaxes = prices.reduce((a, b) => {
    //   return a + ((quotation[b] || {}).taxes || []).reduce((a, b) => {
    //     return a + b.amount || 0
    //   }, 0)
    // }, 0)
    return res;
  });
};

const components = (state = [], action) => {
  switch (action.type) {
    case "pcba/components/SET":
      return update(state, action.data);
      break;
    case "pcba/components/DELETE_ALL":
      return [];
      break;
  }
  return state;
};

const bomFiles = (state = [], action) => {
  // return (function(state, action){
  switch (action.type) {
    case "pcba/bomFiles/SET":
      console.log("SET bom file", action);
      return update(state, parseBomFile(action.data));
      break;
    case "pcba/bomFiles/SET_COLUMN":
      const bomFile = state.find((f) => f.id === action.specId);
      const nBomFile = Object.assign({}, bomFile, {
        data: Object.assign({}, bomFile.data, {
          columnsTypes: updateIndex(
            bomFile.data.columnsTypes,
            action.data,
            action.cIndex
          )
        })
      });
      return update(state, nBomFile);
      break;
    case "pcba/bomFiles/REMOVE":
      const index = state.findIndex((f) => f.id === action.specId);
      return [...state.slice(0, index), ...state.slice(index + 1)];
      break;
  }
  return state;
  // }(state, action))
  // .map(file => {
  //   return Object.assign({}, file, BomFile.constructFromObject(file))
  // })
};

const parseBomFile = (bomFile) => {
  const data = bomFile.data || {};
  let lines = [];
  console.log("parseBomFile", bomFile);
  (data.rowsUse || []).forEach((use, lineNumber) => {
    if (!use) {
      return false;
    }
    let line = { lineNumber };
    const startCell = lineNumber * data.nbColumns;
    data.columnsTypes.forEach((type, colNumber) => {
      if (type === "undef") {
        return;
      }
      line[type] = data.cells[startCell + colNumber];
    });
    lines = [...lines, line];
  });
  console.log("parseBomFile", lines);
  return Object.assign({}, bomFile, {
    data: Object.assign({}, data, { lines })
  });
};

export default combineReducers({
  specs,
  quotations,
  components,
  bomFiles
});