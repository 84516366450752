/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PropertyValue {
  constructor() {


  }

  static typeDescription() {
    return "PropertyValue";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PropertyValue();
      if (data.hasOwnProperty('measureCode')) {
        obj['measureCode'] = ApiClient.convertToType(data['measureCode'], 'String');
      }
      if (data.hasOwnProperty('stringValue')) {
        obj['stringValue'] = ApiClient.convertToType(data['stringValue'], 'String');
      }
      if (data.hasOwnProperty('booleanValue')) {
        obj['booleanValue'] = ApiClient.convertToType(data['booleanValue'], 'Boolean');
      }
      if (data.hasOwnProperty('floatValue')) {
        obj['floatValue'] = ApiClient.convertToType(data['floatValue'], 'Number');
      }
      if (data.hasOwnProperty('unsignedFloatValue')) {
        obj['unsignedFloatValue'] = ApiClient.convertToType(data['unsignedFloatValue'], 'Number');
      }
      if (data.hasOwnProperty('integerValue')) {
        obj['integerValue'] = ApiClient.convertToType(data['integerValue'], 'Number');
      }
      if (data.hasOwnProperty('unsignedIntegerValue')) {
        obj['unsignedIntegerValue'] = ApiClient.convertToType(data['unsignedIntegerValue'], 'Number');
      }
    }
    return obj;
  }


  measureCode = undefined;

  stringValue = undefined;

  booleanValue = undefined;

  floatValue = undefined;

  unsignedFloatValue = undefined;

  integerValue = undefined;

  unsignedIntegerValue = undefined;




  getMeasureCode() {
    return this.measureCode;
  }

  setMeasureCode(measureCode) {
    this['measureCode'] = measureCode;
  }

  getStringValue() {
    return this.stringValue;
  }

  setStringValue(stringValue) {
    this['stringValue'] = stringValue;
  }

  getBooleanValue() {
    return this.booleanValue;
  }

  setBooleanValue(booleanValue) {
    this['booleanValue'] = booleanValue;
  }

  getFloatValue() {
    return this.floatValue;
  }

  setFloatValue(floatValue) {
    this['floatValue'] = floatValue;
  }

  getUnsignedFloatValue() {
    return this.unsignedFloatValue;
  }

  setUnsignedFloatValue(unsignedFloatValue) {
    this['unsignedFloatValue'] = unsignedFloatValue;
  }

  getIntegerValue() {
    return this.integerValue;
  }

  setIntegerValue(integerValue) {
    this['integerValue'] = integerValue;
  }

  getUnsignedIntegerValue() {
    return this.unsignedIntegerValue;
  }

  setUnsignedIntegerValue(unsignedIntegerValue) {
    this['unsignedIntegerValue'] = unsignedIntegerValue;
  }


}