var data = {
  title: "",
  description: "",
  keywords: "",
  image: false,
  video: false
};

const get = () => {
  console.log("get metaData", data);
  return Object.assign({}, data, {
    title: [data.title, "EMSFACTORY"].filter((e) => e).join(" ")
  });
};

const set = (t) => {
  data = Object.assign(data, t);
  if (typeof window !== "undefined") {
    // title
    window.document.title = [data.title, "EMSFACTORY"].
    filter((s) => s).
    join(" ");
  }
};

const setMeta = (name, content) => {
  const el = window.document.querySelector('[name="' + name + '"]');
  if (el) {
    el.remove();
  }
  let meta = document.createElement("meta");
  meta.name = name;
  meta.content = content;

  document.getElementsByTagName("head")[0].appendChild(meta);
};

export default {
  set,
  get
};