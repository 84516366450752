/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class CustomerStocksLocation {
  constructor(quotationType, quotationId, quotationTrackingId) {
    if (quotationType) {
      this['quotationType'] = quotationType;
    }
    if (quotationId) {
      this['quotationId'] = quotationId;
    }
    if (quotationTrackingId) {
      this['quotationTrackingId'] = quotationTrackingId;
    }


  }

  static typeDescription() {
    return "CustomerStocksLocation";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerStocksLocation();
      if (data.hasOwnProperty('quotationType')) {
        obj['quotationType'] = ApiClient.convertToType(data['quotationType'], 'String');
      }
      if (data.hasOwnProperty('quotationId')) {
        obj['quotationId'] = ApiClient.convertToType(data['quotationId'], 'String');
      }
      if (data.hasOwnProperty('quotationTrackingId')) {
        obj['quotationTrackingId'] = ApiClient.convertToType(data['quotationTrackingId'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('ownerName')) {
        obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
      }
      if (data.hasOwnProperty('isInactive')) {
        obj['isInactive'] = ApiClient.convertToType(data['isInactive'], 'Boolean');
      }
    }
    return obj;
  }


  quotationType = "assembly_quotation";

  quotationId = undefined;

  quotationTrackingId = "";

  name = undefined;

  reference = undefined;

  ownerName = undefined;

  isInactive = undefined;




  getQuotationType() {
    return this.quotationType;
  }

  setQuotationType(quotationType) {
    this['quotationType'] = quotationType;
  }

  getQuotationId() {
    return this.quotationId;
  }

  setQuotationId(quotationId) {
    this['quotationId'] = quotationId;
  }

  getQuotationTrackingId() {
    return this.quotationTrackingId;
  }

  setQuotationTrackingId(quotationTrackingId) {
    this['quotationTrackingId'] = quotationTrackingId;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getOwnerName() {
    return this.ownerName;
  }

  setOwnerName(ownerName) {
    this['ownerName'] = ownerName;
  }

  getIsInactive() {
    return this.isInactive;
  }

  setIsInactive(isInactive) {
    this['isInactive'] = isInactive;
  }


  static QuotationTypeEnum = {
    "assembly_quotation": "assembly_quotation",
    "coating_quotation": "coating_quotation",
    "dfm_quotation": "dfm_quotation",
    "enclosure_quotation": "enclosure_quotation",
    "engineering_quotation": "engineering_quotation",
    "pcb_quotation": "pcb_quotation",
    "pcba_quotation": "pcba_quotation",
    "pcb_design_quotation": "pcb_design_quotation",
    "rework_quotation": "rework_quotation",
    "supply_quotation": "supply_quotation",
    "wiring_quotation": "wiring_quotation",
    "xray_quotation": "xray_quotation"
  };

}