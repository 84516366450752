import OrderApi from "api/api/OrderApi";
import { store } from "initStore";
import quotesActions from "Quotes/actions";
import requestsActions from "Requests/actions";

const orderApi = new OrderApi();

const actions = {
  getAll: (opts) => {
    return requestsActions.add(
      "orders/getAll",
      orderApi.getOrders(opts).then((data) => {
        store.dispatch({
          type: "orders/GET_ALL",
          data
        });
        return data;
      })
    );
  },
  createOrder: (order) =>
  orderApi.createOrder(order).then((data) => {
    actions.setOrder(data);
    return data;
  }),
  loadOrder: (id) =>
  requestsActions.add(
    "orders/load/" + id,
    actions.getOrder(id).then((order) => {
      return Promise.all([
      actions.getOrderPayments(id),
      actions.
      getQuote(id).
      then((quote) => {
        return quotesActions.
        getQuoteQuotations(quote.id).
        then((quotations) => Object.assign({}, quote, { quotations }));
        // return Promise.all([
        //   quotesActions.getPcbaQuotations(quote.id).then(quotations => {
        //     console.log("loadorder quotations", quotations);
        //   })
        // ]);
      }).
      catch((err) => {
        return [{}];
      })]
      ).then((res) =>
      Object.assign({}, order, { payments: res[0], quote: res[1] })
      );
    })
  ),
  getOrder: (id) =>
  orderApi.getOrder(id).then((data) => {
    actions.setOrder(data);
    return data;
  }),
  setOrder: (data) =>
  store.dispatch({
    type: "orders/SET",
    data
  }),
  saveOrder: (id, data) =>
  requestsActions.add(
    "orders/set/" + id,
    orderApi.setOrder(id, data).then((data) => {
      actions.setOrder(data);
      return data;
    })
  ),
  register: (id) =>
  orderApi.registerOrder(id).then((res) => {
    actions.setOrder(res);
    return res;
  }),

  // Quotes
  setQuote: (orderId, quoteId) => {
    console.log("ordersActions setQuote", orderId, quoteId);
    return orderApi.setOrderQuote(orderId, quoteId).then((data) => {
      return actions.getQuote(orderId);
      // console.log("setQuote", data);
      // store.distpatch({
      //   type: 'orders/SET'
      // })
    });
  },
  getQuote: (orderId) => {
    return orderApi.getOrderQuote(orderId).then((data) => {
      store.dispatch({
        type: "quotes/SET",
        data
      });
      return data;
    });
  },

  // Payments
  createPayment: (orderId, payment) => {
    return orderApi.createOrderPayment(orderId, payment).then((data) => {
      store.dispatch({
        type: "payments/SET",
        data
      });
      return data;
    });
  },
  getOrderPayments: (orderId) =>
  orderApi.getOrderPaymentTransactions(orderId).then((payments) =>
  store.dispatch({
    type: "payments/SET_ALL",
    data: payments.map((p) =>
    Object.assign({}, p, {
      orderId
    })
    )
  })
  ),
  // getTransactions: (orderId) => orderApi.getOrderPaymentTransactions(orderId),

  cancelOrder: (orderId) =>
  requestsActions.add(
    "orders/cancel/" + orderId,
    orderApi.cancelOrder(orderId).then((order) => {
      actions.setOrder(order);
      return order;
    })
  ),
  reopenOrder: (orderId) =>
  requestsActions.add(
    "orders/reopen/" + orderId,
    orderApi.reopenOrder(orderId).then((order) => {
      actions.setOrder(order);
      return order;
    })
  ),
  getOrderAnomalies: (orderId) =>
  requestsActions.add(
    "orders/anomaly/" + orderId,
    orderApi.getOrderAnomalies(orderId).then((anomalies) => {
      const state = store.getState();
      const { anomalies: stateAnomalies = [] } = state;
      const orderAnomalies = stateAnomalies.filter((anomaly) => {
        const { objects = [] } = anomaly;
        return objects.find((o) => o.type === "order" && o.id === orderId);
      });

      const removedAnomalies = orderAnomalies.filter(
        (anomaly) => !anomalies.find((value) => anomaly.id === value.id)
      );
      if (removedAnomalies.length > 0) {
        store.dispatch({
          type: "anomalies/REMOVE_ALL",
          data: removedAnomalies
        });
      }

      store.dispatch({
        type: "anomalies/ADD_ALL",
        data: anomalies
      });
      return anomalies;
    })
  )
};

export default actions;