/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Anomaly from '../model/Anomaly';
import CreateOrderAnomaly from '../model/CreateOrderAnomaly';
import CreateOrderPayment from '../model/CreateOrderPayment';
import Error from '../model/Error';
import Invoice from '../model/Invoice';
import ManufacturingProcess from '../model/ManufacturingProcess';
import Order from '../model/Order';
import OrderAnomalies from '../model/OrderAnomalies';
import OrderInProduction from '../model/OrderInProduction';
import Payment from '../model/Payment';
import PaymentTransaction from '../model/PaymentTransaction';
import Quote from '../model/Quote';


export default class OrderApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  cancelOrderWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling cancelOrder");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Order;

    return this.apiClient.callApi(
      '/order/{orderId}/cancel', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  cancelOrder(orderId) {
    return this.cancelOrderWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createFileInOrderWithHttpInfo(orderId, name, content) {
    let postBody = content;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling createFileInOrder");
    }

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling createFileInOrder");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createFileInOrder");
    }


    let pathParams = {
      'orderId': orderId,
      'name': name
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/order/{orderId}/file/{name}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createFileInOrder(orderId, name, content) {
    return this.createFileInOrderWithHttpInfo(orderId, name, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createOrderWithHttpInfo(order) {
    let postBody = order;

    // verify the required parameter 'order' is set
    if (order === undefined || order === null) {
      throw new Error("Missing the required parameter 'order' when calling createOrder");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Order;

    return this.apiClient.callApi(
      '/order', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createOrder(order) {
    return this.createOrderWithHttpInfo(order).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createOrderAnomalyWithHttpInfo(orderId, anomaly) {
    let postBody = anomaly;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling createOrderAnomaly");
    }

    // verify the required parameter 'anomaly' is set
    if (anomaly === undefined || anomaly === null) {
      throw new Error("Missing the required parameter 'anomaly' when calling createOrderAnomaly");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = OrderAnomalies;

    return this.apiClient.callApi(
      '/order/{orderId}/anomaly', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createOrderAnomaly(orderId, anomaly) {
    return this.createOrderAnomalyWithHttpInfo(orderId, anomaly).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createOrderPaymentWithHttpInfo(orderId, content) {
    let postBody = content;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling createOrderPayment");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createOrderPayment");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Payment;

    return this.apiClient.callApi(
      '/order/{orderId}/payment', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createOrderPayment(orderId, content) {
    return this.createOrderPaymentWithHttpInfo(orderId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteFileOfOrderWithHttpInfo(orderId, name) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling deleteFileOfOrder");
    }

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling deleteFileOfOrder");
    }


    let pathParams = {
      'orderId': orderId,
      'name': name
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/order/{orderId}/file/{name}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteFileOfOrder(orderId, name) {
    return this.deleteFileOfOrderWithHttpInfo(orderId, name).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteOrderWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling deleteOrder");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/order/{orderId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteOrder(orderId) {
    return this.deleteOrderWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getFileOfOrderWithHttpInfo(orderId, name) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getFileOfOrder");
    }

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling getFileOfOrder");
    }


    let pathParams = {
      'orderId': orderId,
      'name': name
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/octet-stream'];
    let returnType = 'Blob';

    return this.apiClient.callApi(
      '/order/{orderId}/file/{name}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getFileOfOrder(orderId, name) {
    return this.getFileOfOrderWithHttpInfo(orderId, name).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getInProductionOrdersWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'withPcbValue': opts['withPcbValue']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [OrderInProduction];

    return this.apiClient.callApi(
      '/order/inProduction', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getInProductionOrders(opts) {
    return this.getInProductionOrdersWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrder");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Order;

    return this.apiClient.callApi(
      '/order/{orderId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrder(orderId) {
    return this.getOrderWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderAnomaliesWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrderAnomalies");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Anomaly];

    return this.apiClient.callApi(
      '/order/{orderId}/anomaly', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrderAnomalies(orderId) {
    return this.getOrderAnomaliesWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderAnomalyWithHttpInfo(orderId, anomalyType, anomalyId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrderAnomaly");
    }

    // verify the required parameter 'anomalyType' is set
    if (anomalyType === undefined || anomalyType === null) {
      throw new Error("Missing the required parameter 'anomalyType' when calling getOrderAnomaly");
    }

    // verify the required parameter 'anomalyId' is set
    if (anomalyId === undefined || anomalyId === null) {
      throw new Error("Missing the required parameter 'anomalyId' when calling getOrderAnomaly");
    }


    let pathParams = {
      'orderId': orderId,
      'anomalyType': anomalyType,
      'anomalyId': anomalyId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = OrderAnomalies;

    return this.apiClient.callApi(
      '/order/{orderId}/anomaly/{anomalyType}/{anomalyId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrderAnomaly(orderId, anomalyType, anomalyId) {
    return this.getOrderAnomalyWithHttpInfo(orderId, anomalyType, anomalyId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderDocumentWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrderDocument");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/octet-stream'];
    let returnType = 'Blob';

    return this.apiClient.callApi(
      '/order/{orderId}/download', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrderDocument(orderId) {
    return this.getOrderDocumentWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderInvoicesWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrderInvoices");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Invoice];

    return this.apiClient.callApi(
      '/order/{orderId}/invoice', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrderInvoices(orderId) {
    return this.getOrderInvoicesWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderManufacturingWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrderManufacturing");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [ManufacturingProcess];

    return this.apiClient.callApi(
      '/order/{orderId}/manufacturing', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrderManufacturing(orderId) {
    return this.getOrderManufacturingWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderPaymentTransactionsWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrderPaymentTransactions");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PaymentTransaction];

    return this.apiClient.callApi(
      '/order/{orderId}/paymentTransactions', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrderPaymentTransactions(orderId) {
    return this.getOrderPaymentTransactionsWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderPaymentsWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrderPayments");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Payment];

    return this.apiClient.callApi(
      '/order/{orderId}/payment', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrderPayments(orderId) {
    return this.getOrderPaymentsWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrderQuoteWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getOrderQuote");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/order/{orderId}/quote', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrderQuote(orderId) {
    return this.getOrderQuoteWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getOrdersWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'ownerId': opts['ownerId'],
      'reference': opts['reference'],
      'customerReference': opts['customerReference'],
      'creationDateFrom': opts['creationDateFrom'],
      'creationDateUntil': opts['creationDateUntil'],
      'status': opts['status']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Order];

    return this.apiClient.callApi(
      '/order', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getOrders(opts) {
    return this.getOrdersWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  registerOrderWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling registerOrder");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Order;

    return this.apiClient.callApi(
      '/order/{orderId}/register', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  registerOrder(orderId) {
    return this.registerOrderWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeOrderQuoteWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling removeOrderQuote");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/order/{orderId}/quote', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeOrderQuote(orderId) {
    return this.removeOrderQuoteWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  reopenOrderWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling reopenOrder");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Order;

    return this.apiClient.callApi(
      '/order/{orderId}/reopen', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  reopenOrder(orderId) {
    return this.reopenOrderWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  replaceFileOfOrderWithHttpInfo(orderId, name, content) {
    let postBody = content;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling replaceFileOfOrder");
    }

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling replaceFileOfOrder");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling replaceFileOfOrder");
    }


    let pathParams = {
      'orderId': orderId,
      'name': name
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/order/{orderId}/file/{name}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  replaceFileOfOrder(orderId, name, content) {
    return this.replaceFileOfOrderWithHttpInfo(orderId, name, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setOrderWithHttpInfo(orderId, order) {
    let postBody = order;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling setOrder");
    }

    // verify the required parameter 'order' is set
    if (order === undefined || order === null) {
      throw new Error("Missing the required parameter 'order' when calling setOrder");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Order;

    return this.apiClient.callApi(
      '/order/{orderId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setOrder(orderId, order) {
    return this.setOrderWithHttpInfo(orderId, order).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setOrderAnomalyWithHttpInfo(orderId, content) {
    let postBody = content;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling setOrderAnomaly");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setOrderAnomaly");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = OrderAnomalies;

    return this.apiClient.callApi(
      '/order/{orderId}/anomaly', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setOrderAnomaly(orderId, content) {
    return this.setOrderAnomalyWithHttpInfo(orderId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setOrderQuoteWithHttpInfo(orderId, quoteId) {
    let postBody = quoteId;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling setOrderQuote");
    }

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling setOrderQuote");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['*/*'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/order/{orderId}/quote', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setOrderQuote(orderId, quoteId) {
    return this.setOrderQuoteWithHttpInfo(orderId, quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  transferOrderWithHttpInfo(orderId, customerId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling transferOrder");
    }

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling transferOrder");
    }


    let pathParams = {
      'orderId': orderId,
      'customerId': customerId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Order;

    return this.apiClient.callApi(
      '/order/{orderId}/transferTo/{customerId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  transferOrder(orderId, customerId) {
    return this.transferOrderWithHttpInfo(orderId, customerId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}