import React from "react";
import { injectIntl } from "react-intl";
import Section from "marketing/components/Section";
import Content from "components/ContentEditor/Content";

const FactoryKeyFigure = injectIntl((props) => {
  const { data = {} } = props;
  const { title, products = [] } = data;
  const filteredProducts = products.filter((p) => {
    const { capabilities = [] } = p;
    return capabilities.filter((c) => c.capabilities).length;
  });
  if (!filteredProducts.length) {
    return null;
  }
  return (
    <Section className="key_figures" title={title}>
      <div className="row">
        <div className="col-10 offset-1">
          {filteredProducts.
          filter((p) => ["engineering", "pcb"].indexOf(p.slug) < 0).
          map((product, i) =>
          <Product product={product} key={i} />
          )}
        </div>
      </div>
    </Section>);

});

export default FactoryKeyFigure;

const Product = injectIntl((props) => {
  const { product } = props;
  const { capabilities = [] } = product;
  const size = sizes[product.slug] || {};

  return (
    <div className={"key_figures_figure key_figures_figure-" + product.slug}>
      <div className="key_figures_figure_logo">
        <div>
          <div>
            <img
              {...size}
              className="img-fluid lazyload"
              data-src={"/images/products/" + product.slug + ".svg"} />

          </div>
        </div>
        <div className="h6 text-center">{product.title}</div>
      </div>
      {capabilities.map((c, i) =>
      <div className="key_figures_capabilities" key={i}>
          <p className="capabilities_title">{c.title}</p>
          <Content blocks={c.capabilities} />
        </div>
      )}
    </div>);

});

const sizes = {
  pcba: {
    width: 72,
    height: 70
  },
  wiring: {
    width: 42,
    height: 70
  },
  pcb: {
    width: 94,
    height: 70
  },
  enclosure: {
    width: 67,
    height: 70
  },
  coating: {
    width: 43,
    height: 70
  },
  xray: {
    width: 85,
    height: 70
  },
  assembly: {
    width: 68,
    height: 70
  },
  rework: {
    width: 97,
    height: 70
  },
  engineering: {
    width: 68,
    height: 70
  }
};