/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PaymentOrdersDispatch {
  constructor(orderId, amount) {
    if (orderId) {
      this['orderId'] = orderId;
    }
    if (amount) {
      this['amount'] = amount;
    }


  }

  static typeDescription() {
    return "PaymentOrdersDispatch";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PaymentOrdersDispatch();
      if (data.hasOwnProperty('orderId')) {
        obj['orderId'] = ApiClient.convertToType(data['orderId'], 'String');
      }
      if (data.hasOwnProperty('amount')) {
        obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
      }
    }
    return obj;
  }


  orderId = undefined;

  amount = 0.0;




  getOrderId() {
    return this.orderId;
  }

  setOrderId(orderId) {
    this['orderId'] = orderId;
  }

  getAmount() {
    return this.amount;
  }

  setAmount(amount) {
    this['amount'] = amount;
  }


}