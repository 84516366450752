const dotenv = require("dotenv");

dotenv.config();

const config = {
  buildCommit: process.env.REACT_APP_COMMIT,
  cpmUrl: process.env.REACT_APP_CPM_URL,
  version: process.env.REACT_APP_VERSION || "local",
  nodeEnv: process.env.NODE_ENV,
  env: process.env.REACT_APP_ENV,
  cmsUrl: process.env.REACT_APP_CMS_URL,
  templateImgBasePath: process.env.REACT_APP_TEMPLATE_IMG_BASE_PATH
};
console.log("config", config);
module.exports = config;