import React, { Component } from "react";
import { Link } from "Elements";
import { withCart } from "./Context";

class HeaderCartButton extends Component {
  render() {
    const { props } = this;
    let totalQuotations = props.cart.quotations.length;
    if (props.cart.request === "pending") {
      return (
        <li className="dropdown">
          <a href="" className="dropdown-toggle">
            <i className="fas fa-spinner fa-pulse"></i>
          </a>
        </li>);

    }
    return (
      <Link to="/cart">
        <i
          className="fas fa-shopping-cart icon mr-md-2"
          style={{ color: "white" }}>
        </i>
        <span className="text-secondary d-none d-md-inline">
          ( {totalQuotations} )
        </span>
      </Link>);

  }
}

export default withCart(HeaderCartButton);