/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PcbProcurement {
  constructor(supplier) {
    if (supplier) {
      this['supplier'] = supplier;
    }


  }

  static typeDescription() {
    return "PcbProcurement";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PcbProcurement();
      if (data.hasOwnProperty('supplier')) {
        obj['supplier'] = ApiClient.convertToType(data['supplier'], 'String');
      }
      if (data.hasOwnProperty('manufacturingDurationDays')) {
        obj['manufacturingDurationDays'] = ApiClient.convertToType(data['manufacturingDurationDays'], 'Number');
      }
      if (data.hasOwnProperty('shippingDurationDays')) {
        obj['shippingDurationDays'] = ApiClient.convertToType(data['shippingDurationDays'], 'Number');
      }
      if (data.hasOwnProperty('rfqId')) {
        obj['rfqId'] = ApiClient.convertToType(data['rfqId'], 'String');
      }
    }
    return obj;
  }


  supplier = "";

  manufacturingDurationDays = undefined;

  shippingDurationDays = undefined;

  rfqId = undefined;




  getSupplier() {
    return this.supplier;
  }

  setSupplier(supplier) {
    this['supplier'] = supplier;
  }

  getManufacturingDurationDays() {
    return this.manufacturingDurationDays;
  }

  setManufacturingDurationDays(manufacturingDurationDays) {
    this['manufacturingDurationDays'] = manufacturingDurationDays;
  }

  getShippingDurationDays() {
    return this.shippingDurationDays;
  }

  setShippingDurationDays(shippingDurationDays) {
    this['shippingDurationDays'] = shippingDurationDays;
  }

  getRfqId() {
    return this.rfqId;
  }

  setRfqId(rfqId) {
    this['rfqId'] = rfqId;
  }


}