/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class MessageReadBy {
  constructor() {


  }

  static typeDescription() {
    return "MessageReadBy";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MessageReadBy();
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
    }
    return obj;
  }


  userId = undefined;

  date = undefined;




  getUserId() {
    return this.userId;
  }

  setUserId(userId) {
    this['userId'] = userId;
  }

  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }


}