import React from "react";
import config from "config";
import ReactDOM from "react-dom";
import MiscApi from "api/api/MiscApi";
import { Provider } from "react-redux";
import Intl from "Intl";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, StaticRouter } from "react-router-dom";
import initStore from "./initStore";

import languages from "languages";
import request from "superagent";
import metaTags from "utils/metaTags";

// console.log('App', App)
const Wrapper = (props) => {
  const location = props.location || "";
  return typeof window !== "undefined" ?
  <BrowserRouter>
      <Intl />
    </BrowserRouter> :

  <StaticRouter location={location}>
      <Intl />
    </StaticRouter>;


  // return <IntlProvider
  //   locale={language}
  //   defaultLocale='fr'
  //   messages={messages}
  //   >
  //     {typeof window !== 'undefined'
  //       ? <BrowserRouter>
  //         <App/>
  //       </BrowserRouter>
  //       : <StaticRouter location={location}>
  //         <App/>
  //       </StaticRouter>
  //     }
  //   </IntlProvider>
};

// const mapStateToProps = state => {
//   const language = state.auth.user.id
//     ? state.auth.user.frontLang
//     : 'fr'
//     // console.log('messages', language, messages, messages[language])
//   return {
//     language,
//     messages: messages[language] ||{}
//   }
// }

const Container = Wrapper;

const Component = (data, location) => {
  const store = initStore(data);
  return [
  <Provider store={store}>
      <Container location={location} />
    </Provider>,
  metaTags.get];

};

if (typeof window !== "undefined") {
  const language = (
  window.location.pathname.split("/")[1] || languages[0]).
  toLowerCase();
  // request
  //   .get("/locales/marketing/" + language + ".json")
  //   .catch((err) => {
  //     return {};
  //   })
  //   .then((res) => {
  //   });
  ReactDOM.render(
    Component({
      intl: {


        // messages: res.body || {},
        // loadedDomains: ["marketing"],
      } })[0], window.document.getElementById("root")
  );

  // registerServiceWorker();
  serviceWorker.unregister();
  // log to CPM
  const miscApi = new MiscApi();
  // const oldConsoleLog = console.log;
  // window.console.log = function() {
  //   oldConsoleLog.apply(window.console, arguments);
  //   oldConsoleLog(
  //     "CONSOLE LOG: ",
  //     Array.prototype.slice
  //       .call(arguments)
  //       .map(a => JSON.stringify(a))
  //       .join(", ")
  //   );
  //   // miscApi.addLogEntry({
  //   //   severity: "error",
  //   //   data: new Date(),
  //   //   description: arguments.join(", ")
  //   // });
  //   // window.console.error.apply(null, arguments);
  // };
  console.log("config", config);
  const oldConsoleError = console.error;
  console.error = function () {
    try {
      const args = [...arguments];
      if (typeof args[0] !== "string" || !args[0].startsWith("Warning:")) {
        miscApi.addLogEntry({
          severity: "error",
          date: new Date(),
          description: args.
          map((a) => {
            let res = "";
            try {
              res = typeof a === "object" ? JSON.stringify(a) : a;
            } catch (err) {}
            return res;
          }).
          join(", ")
        });
      }
    } catch (err) {
      if (config.nodeEnv !== "production") {
        return oldConsoleError(err);
      }
    }
    if (config.nodeEnv !== "production") {
      return oldConsoleError.apply(console, [...arguments]);
    }
  };
  if (config.env === "production") {
    console.log = function (e) {
      return false;
    };
  }
}
export default Component;