import React from "react";
import { FormControl } from "Elements";

class DateTimeInput extends React.Component {
  constructor(props) {
    super(props);
    // this.state = this.initState(props);
    // // this.state = { date: date.get };
    this.state = {
      date: props.value ? new Date(props.value) : new Date()
    };
  }
  initState(props = this.props) {
    console.log("DateTimeInput initState", props);
    const { value } = props;
    const date = value ? new Date(value) : new Date("2021-08-07T06:05:04");
    const month = date.getMonth() + 1 + "";
    const day = date.getDate() + "";
    return {
      dateString: value ?
      [date.getFullYear(), twoDigits(month), twoDigits(day)].join("-") :
      "",
      value,
      timeString: value ?
      [
      twoDigits(date.getHours()),
      twoDigits(date.getMinutes()),
      twoDigits(date.getSeconds())].
      join(":") :
      "",
      date
    };
    console.log("DateTimeInput initState end", props, value, this.state.value);
  }
  // componentDidUpdate(oProps) {
  //   const { props } = this;
  //   if (props.value !== oProps.value) {
  //     this.setState(this.initState());
  //   }
  // }
  onBlur() {
    const { props, state } = this;
    // const date = new Date(state.dateString + "T" + state.timeString);
    // const month = date.getMonth() + 1 + "";
    // const day = date.getDate() + "";
    return props.onChange({
      target: {
        value: state.date.toISOString()
        // [date.getFullYear(), twoDigits(month), twoDigits(day)].join("-") +
        // "T" +
        // [
        //   twoDigits(date.getHours()),
        //   twoDigits(date.getMinutes()),
        //   twoDigits(date.getSeconds()),
        // ].join(":"),
      }
    });
  }
  render() {
    const { props, state } = this;
    const { disabled, value } = props;
    const { date } = state;
    const month = date.getMonth() + 1 + "";
    const day = date.getDate() + "";
    const dateString = value ?
    [date.getFullYear(), twoDigits(month), twoDigits(day)].join("-") :
    "";
    const timeString = value ?
    [
    twoDigits(date.getHours()),
    twoDigits(date.getMinutes()),
    twoDigits(date.getSeconds())].
    join(":") :
    "";
    console.log("DateTimeInput render", props, state);
    return (
      <div>
        <FormControl
          validationState={props.validationState}
          value={dateString}
          type="date"
          // onChange={(e) => this.setState({ dateString: e.target.value })}
          onChange={(e) => {
            const nDate = new Date(date.getTime());
            const nnDate = new Date(e.target.value);
            nDate.setDate(nnDate.getDate());
            nDate.setMonth(nnDate.getMonth());
            nDate.setFullYear(nnDate.getFullYear());
            this.setState({
              date: nDate
            });
          }}
          onBlur={(e) => this.onBlur()}
          disabled={disabled} />

        <FormControl
          value={timeString}
          type="time"
          onChange={(e) => {
            const nDate = new Date(date.getTime());
            nDate.setHours(e.target.value.split(":")[0]);
            nDate.setMinutes(e.target.value.split(":")[1]);
            if (e.target.value.split(":").length === 3) {
              nDate.setSeconds(e.target.value.split(":")[2]);
            }
            this.setState({
              date: nDate
            });
          }}
          onBlur={(e) => this.onBlur()}
          disabled={disabled} />

      </div>);

  }
}

const twoDigits = (v) => ((v + "").length < 2 ? "0" : "") + v;

export default DateTimeInput;