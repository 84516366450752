export const unitFloat = (
float,
symbol = "",
defaultSize = "",
pitch = 1,
fixed,
opts = {}) =>
{
  defaultSize = defaultSize || "";
  // .toLowerCase();
  pitch = pitch || 1;
  fixed = typeof fixed === "undefined" ? 3 : fixed;
  const { allSizesOutput } = opts;

  if (typeof float === "undefined") {
    return "";
  }
  let value = parseFloat(float) || 0;

  let sizeIndex = (sizes.find((s) => s.symbol === defaultSize) || {}).size || 0;
  sizeIndex = sizeIndex < 0 ? 0 : sizeIndex;
  const sizeValues = sizes.
  filter((s) => s.output || allSizesOutput).
  map((size, i) => {
    const deltaPitch = Math.pow(
      10,
      (sizeIndex - size.size) *
      //  - sizeIndex
      pitch
    );
    return {
      size,
      value: value * deltaPitch,
      deltaPitch,
      sizeIndex
    };
  });
  // console.log("unitFloat", float, defaultSize, value, sizeIndex, sizeValues);
  let res = symbol ?
  sizeValues.find(
    (s) => Math.sign(s.value) * s.value >= Math.pow(10, -1 * pitch)
    //Math.pow(10, pitch / -1)
  ) || sizeValues.slice(-1)[0] :
  sizeValues.find((s) => !s.size.symbol);
  // console.log("unitFloat res", res);
  res.value = Math.round(res.value * Math.pow(10, fixed)) / Math.pow(10, fixed);
  return res.value + (symbol ? res.size.symbol : "") + symbol;
  return Object.assign({}, res, { sizeValues });
  // return sizes.reduce((res, size, i) => {
  //   console.log("unitFloat", res, size, deltaPitch, nValue);
  //   return !res || (nValue > 0.1 && nValue < res.value)
  //     ? {
  //         value: nValue,
  //         size,
  //       }
  //     : res;
  // }, false);
  // while (Math.abs(value) < 0.1) {
  //   sizeIndex += 1;
  //   value = value * Math.pow(10, pitch);
  // }
  // const size = sizes[sizeIndex];
  // return {
  //   value,
  //   size,
  // };
};

export const convertFloat = (
string = "",
symbol = "",
size = "",
pitch = 1) =>
{
  pitch = pitch || 1;
  // string = string.toLowerCase();

  // const r = new RegExp("/(.+)(\\m|n)\\V/");
  const r = new RegExp(
    "([-+]?[0-9]*.?[0-9]+([eE][-+]?[0-9]+)?)(" +
    sizes.map((s) => s.symbol + symbol).join("|") +
    ")?$",
    "i"
  );
  const match = string.match(r) || [];
  let value = match[1] || "";
  const stringUnit = match[3] || "";
  const r2 = new RegExp(
    "^" +
    "(" +
    sizes.
    filter((e) => e.symbol).
    map((s) => s.symbol).
    join("|") +
    ")" +
    symbol +
    "$",
    "i"
  );
  const m2 = stringUnit.match(r2) || [];
  // console.log("regexp", stringUnit, r2, m2);
  const stringSize = m2[1] || "";
  // const value = string.match(r).join(" || ");

  // const s = string.slice(-1 * symbol.length);
  // const lastChar = s.slice(-1)[0];
  const stringSizeIndex =
  (sizes.find((s) => s.symbol === stringSize) || {}).size || 0;
  // stringSizeIndex = stringSizeIndex < 0 ? 1 : stringSizeIndex;
  value = parseFloat(value);
  let sizeIndex = (sizes.find((s) => s.symbol === size) || []).size || 0;
  // sizeIndex = sizeIndex < 0 ? 1 : sizeIndex;
  const deltaPitch = Math.pow(10, (stringSizeIndex - sizeIndex) * pitch);
  value = value * deltaPitch;
  // console.log(
  //   "regexp",
  //   string,
  //   symbol,
  //   size,
  //   pitch,
  //   r,
  //   match,
  //   value,
  //   stringSize,
  //   stringSizeIndex,
  //   sizeIndex,
  //   deltaPitch
  // );
  return value;
};

// const sizes = ["k", "", "m", "μ", "n", "p"];
const sizes = [
{ symbol: "Y", size: 24 },
{ symbol: "Z", size: 21 },
{ symbol: "E", size: 18 },
{ symbol: "P", size: 15 },
{ symbol: "T", size: 12 },
{ symbol: "G", size: 9, output: true },
{ symbol: "M", size: 6, output: true },
{ symbol: "k", size: 3, output: true },
{ symbol: "h", size: 2 },
{ symbol: "da", size: 1 },
{ symbol: "", size: 0, output: true },
{ symbol: "d", size: -1 },
{ symbol: "c", size: -2 },
{ symbol: "m", size: -3, output: true },
{ symbol: "μ", size: -6, output: true },
{ symbol: "n", size: -9, output: true },
{ symbol: "p", size: -12, output: true },
{ symbol: "f", size: -15 },
{ symbol: "a", size: -18 },
{ symbol: "z", size: -21 },
{ symbol: "y", size: -24 }];


// console.log("unitFloat test", unitFloat(0.015));
// console.log("unitFloat test m", unitFloat(0.015, "m"));
// console.log("convertFloat test", convertFloat("0.000015km", "m"));