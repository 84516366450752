import React from "react";
import Section from "marketing/components/Section";

class Images extends React.Component {
  state = {};
  render() {
    const { props } = this;
    const { data = {} } = props;
    const { title, text, prizes = [] } = data;
    if (!prizes.length) {
      return null;
    }
    return (
      <Section title={title} className="factory_prizes">
        <div className="factory_prizes_text">
          <p className="text-secondary text-center">{text}</p>
        </div>
        <div className="factory_prizes__prizes">
          {prizes.map((prize, i) => {
            const { icon = {}, text, link } = prize;
            return (
              <div className="factory_prizes__prize">
                <div className="prize_icon">
                  <img
                    className="img-fluid lazyload"
                    data-src={icon.src}
                    data-srcset={icon.srcset}
                    alt={icon.description} />

                </div>
                <div className="prize_text">{text}</div>
                {link ?
                <div className="prize_link">
                    <a
                    href={link}
                    target="blank"
                    className="btn btn-outline-secondary text-primary py-0 px-3">

                      {link.split("/")[2]}
                    </a>
                  </div> :
                null}
              </div>);

          })}
        </div>
      </Section>);

  }
}

export default Images;