import React from "react";
import ReactDOM from "react-dom";
import { UncontrolledTooltip, Tooltip } from "reactstrap";

class ElementTooltip extends React.Component {
  state = {};
  componentDidMount() {
    this.setState({ el: ReactDOM.findDOMNode(this) });
  }
  render() {
    const { props, state } = this;
    if (props.simple) {
      if (!props.message) {
        return null;
      }
      return (
        <Tooltip
          isOpen={props.isOpen}
          target={props.target}
          placement={props.placement}>

          {props.message}
        </Tooltip>);

    }
    let res = [
    React.cloneElement(props.children, {
      // ref: el => {
      //   if (el && !state.el) {
      //     const elDom = ReactDOM.findDOMNode(el)
      //     this.setState({el: elDom})
      //   }
      // },
      key: "children"
    })];

    if (state.el && props.message) {
      res = [
      ...res,
      <UncontrolledTooltip
        placement={props.placement || "top"}
        // isOpen={props.isOpen}
        boundariesElement="root"
        key="tooltip"
        target={state.el}>

          {props.message}
        </UncontrolledTooltip>];

    }
    return res;
  }
}

export default ElementTooltip;