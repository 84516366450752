/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class DailyTimeslot {
  constructor(begin, end) {
    if (begin) {
      this['begin'] = begin;
    }
    if (end) {
      this['end'] = end;
    }


  }

  static typeDescription() {
    return "DailyTimeslot";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DailyTimeslot();
      if (data.hasOwnProperty('begin')) {
        obj['begin'] = ApiClient.convertToType(data['begin'], 'String');
      }
      if (data.hasOwnProperty('end')) {
        obj['end'] = ApiClient.convertToType(data['end'], 'String');
      }
    }
    return obj;
  }


  begin = "";

  end = "";




  getBegin() {
    return this.begin;
  }

  setBegin(begin) {
    this['begin'] = begin;
  }

  getEnd() {
    return this.end;
  }

  setEnd(end) {
    this['end'] = end;
  }


}