/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class FactoryMetrics {
  constructor() {


  }

  static typeDescription() {
    return "FactoryMetrics";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FactoryMetrics();
      if (data.hasOwnProperty('temperature')) {
        obj['temperature'] = ApiClient.convertToType(data['temperature'], 'Number');
      }
      if (data.hasOwnProperty('moisture')) {
        obj['moisture'] = ApiClient.convertToType(data['moisture'], 'Number');
      }
      if (data.hasOwnProperty('assembledPcbasTotal')) {
        obj['assembledPcbasTotal'] = ApiClient.convertToType(data['assembledPcbasTotal'], 'Number');
      }
      if (data.hasOwnProperty('assembledComponentsTotal')) {
        obj['assembledComponentsTotal'] = ApiClient.convertToType(data['assembledComponentsTotal'], 'Number');
      }
    }
    return obj;
  }


  temperature = undefined;

  moisture = undefined;

  assembledPcbasTotal = undefined;

  assembledComponentsTotal = undefined;




  getTemperature() {
    return this.temperature;
  }

  setTemperature(temperature) {
    this['temperature'] = temperature;
  }

  getMoisture() {
    return this.moisture;
  }

  setMoisture(moisture) {
    this['moisture'] = moisture;
  }

  getAssembledPcbasTotal() {
    return this.assembledPcbasTotal;
  }

  setAssembledPcbasTotal(assembledPcbasTotal) {
    this['assembledPcbasTotal'] = assembledPcbasTotal;
  }

  getAssembledComponentsTotal() {
    return this.assembledComponentsTotal;
  }

  setAssembledComponentsTotal(assembledComponentsTotal) {
    this['assembledComponentsTotal'] = assembledComponentsTotal;
  }


}