import { store } from "initStore";

import requestsActions from "Requests/actions";

import quoteActions from "Quotes/actions";
import ordersActions from "Orders/actions";
import CartApi from "api/api/CartApi";

const cartApi = new CartApi();

const actions = {
  addQuotation: (quotation) => {
    console.log("addquotation", quotation);
    if (typeof quotation.type === "undefined") {
      console.error("Cannot add quotation to cart without its type", quotation);
      return;
    }
    return store.dispatch({
      type: "cart/ADD_QUOTATION",
      data: quotation
    });
  },
  removeQuotation: (quotation) => {
    console.log("removequotation", quotation);
    if (typeof quotation.type === "undefined") {
      console.error(
        "Cannot remove quotation from cart without its type",
        quotation
      );
      return;
    }
    return store.dispatch({
      type: "cart/REMOVE_QUOTATION",
      data: quotation
    });
  },
  orderCart: () => {
    const state = store.getState();
    const { cart } = state;
    console.log("orderCart", cart);
    return requestsActions.add(
      "cart/order",
      quoteActions.createQuote().then((quote) => {
        console.log("createQuote", quote);
        return Promise.all([
        ...cart.pcba.map((quotationId) =>
        quoteActions.addPcbaQuotation(quote.id, quotationId)
        )]
        ).
        then((e) => {
          return ordersActions.createOrder({});
        }).
        then((order) => {
          return ordersActions.
          setQuote(order.id, quote.id).
          then((e) => ordersActions.register(order.id));
        });
      })
      //   .then(order => {
      //     ordersActions.register(order.id)
      //     return order
      //   })
      // .then(order => Promise.all([
      //     ...cart.pcbaQuotationIds.map(quotationId => ordersActions.addPcbaToOrder(order.id, quotationId))
      //   ])
      //   .then(data => actions.empty())
      //   .then(data => order.id))
    );
  },
  empty: () =>
  store.dispatch({
    type: "cart/EMPTY"
  }),

  deleteCart: (id) =>
  requestsActions.add("carts/deleteCart", cartApi.deleteCart(id))
};

export default actions;