import React from 'react';

const Panel = (props) => <div>
  {props.children}
</div>;

Panel.Heading = (props) => <div onClick={props.onClick}>
  {props.children}
</div>;
Panel.Title = (props) => <div>
  {props.children}
</div>;
Panel.Body = (props) => <div>
  {props.children}
</div>;
Panel.Toggle = (props) => <div>
  {props.children}
</div>;

export default Panel;