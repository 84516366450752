import { store } from "initStore";
import cmsActions from "Cms/actions";

const actions = {
  setRoutes: (data) =>
  store.dispatch({
    type: "help/SET_ROUTES",
    data
  }),
  getMessage: (url) => cmsActions.getHelpMessage(url),
  show: () =>
  store.dispatch({
    type: "help/SHOW"
  }),
  hide: () =>
  store.dispatch({
    type: "help/HIDE"
  })
};

export default actions;