/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class WareHouseStock {
  constructor(warehouse, stock) {
    if (warehouse) {
      this['warehouse'] = warehouse;
    }
    if (stock) {
      this['stock'] = stock;
    }


  }

  static typeDescription() {
    return "WareHouseStock";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WareHouseStock();
      if (data.hasOwnProperty('warehouse')) {
        obj['warehouse'] = ApiClient.convertToType(data['warehouse'], 'String');
      }
      if (data.hasOwnProperty('stock')) {
        obj['stock'] = ApiClient.convertToType(data['stock'], 'Number');
      }
      if (data.hasOwnProperty('leadTime')) {
        obj['leadTime'] = ApiClient.convertToType(data['leadTime'], 'Number');
      }
    }
    return obj;
  }


  warehouse = "";

  stock = 0.0;

  leadTime = undefined;




  getWarehouse() {
    return this.warehouse;
  }

  setWarehouse(warehouse) {
    this['warehouse'] = warehouse;
  }

  getStock() {
    return this.stock;
  }

  setStock(stock) {
    this['stock'] = stock;
  }

  getLeadTime() {
    return this.leadTime;
  }

  setLeadTime(leadTime) {
    this['leadTime'] = leadTime;
  }


}