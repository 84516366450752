/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CustomerStocksMovement from './CustomerStocksMovement';

export default class CustomerStocksReferenceStocks {
  constructor(availableQuantity) {
    if (availableQuantity) {
      this['availableQuantity'] = availableQuantity;
    }


  }

  static typeDescription() {
    return "CustomerStocksReferenceStocks";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerStocksReferenceStocks();
      if (data.hasOwnProperty('availableQuantity')) {
        obj['availableQuantity'] = ApiClient.convertToType(data['availableQuantity'], 'Number');
      }
      if (data.hasOwnProperty('locationMovements')) {
        obj['locationMovements'] = ApiClient.convertToType(data['locationMovements'], [CustomerStocksMovement]);
      }
    }
    return obj;
  }


  availableQuantity = 0.0;

  locationMovements = undefined;




  getAvailableQuantity() {
    return this.availableQuantity;
  }

  setAvailableQuantity(availableQuantity) {
    this['availableQuantity'] = availableQuantity;
  }

  getLocationMovements() {
    return this.locationMovements;
  }

  setLocationMovements(locationMovements) {
    this['locationMovements'] = locationMovements;
  }


}