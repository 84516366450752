/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Address from './Address';
import UserNotificationMedia from './UserNotificationMedia';

export default class User {
  constructor() {


  }

  static typeDescription() {
    return "User";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new User();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('firstName')) {
        obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
      }
      if (data.hasOwnProperty('lastName')) {
        obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('pendingEmail')) {
        obj['pendingEmail'] = ApiClient.convertToType(data['pendingEmail'], 'String');
      }
      if (data.hasOwnProperty('phone')) {
        obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
      }
      if (data.hasOwnProperty('mobilePhone')) {
        obj['mobilePhone'] = ApiClient.convertToType(data['mobilePhone'], 'String');
      }
      if (data.hasOwnProperty('function')) {
        obj['function'] = ApiClient.convertToType(data['function'], 'String');
      }
      if (data.hasOwnProperty('language')) {
        obj['language'] = ApiClient.convertToType(data['language'], 'String');
      }
      if (data.hasOwnProperty('timezone')) {
        obj['timezone'] = ApiClient.convertToType(data['timezone'], 'String');
      }
      if (data.hasOwnProperty('newsletterAgrees')) {
        obj['newsletterAgrees'] = ApiClient.convertToType(data['newsletterAgrees'], 'Boolean');
      }
      if (data.hasOwnProperty('mailingAgrees')) {
        obj['mailingAgrees'] = ApiClient.convertToType(data['mailingAgrees'], 'Boolean');
      }
      if (data.hasOwnProperty('deliveryAddresses')) {
        obj['deliveryAddresses'] = ApiClient.convertToType(data['deliveryAddresses'], [Address]);
      }
      if (data.hasOwnProperty('defaultDeliveryAddressIndex')) {
        obj['defaultDeliveryAddressIndex'] = ApiClient.convertToType(data['defaultDeliveryAddressIndex'], 'Number');
      }
      if (data.hasOwnProperty('webLogin')) {
        obj['webLogin'] = ApiClient.convertToType(data['webLogin'], 'String');
      }
      if (data.hasOwnProperty('webPassword')) {
        obj['webPassword'] = ApiClient.convertToType(data['webPassword'], 'String');
      }
      if (data.hasOwnProperty('isActive')) {
        obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
      }
      if (data.hasOwnProperty('editionAllowed')) {
        obj['editionAllowed'] = ApiClient.convertToType(data['editionAllowed'], 'Boolean');
      }
      if (data.hasOwnProperty('registrationDate')) {
        obj['registrationDate'] = ApiClient.convertToType(data['registrationDate'], 'String');
      }
      if (data.hasOwnProperty('notificationMedia')) {
        obj['notificationMedia'] = ApiClient.convertToType(data['notificationMedia'], [UserNotificationMedia]);
      }
      if (data.hasOwnProperty('customerId')) {
        obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
      }
    }
    return obj;
  }


  id = undefined;

  firstName = undefined;

  lastName = undefined;

  email = undefined;

  pendingEmail = undefined;

  phone = undefined;

  mobilePhone = undefined;

  function = undefined;

  language = undefined;

  timezone = undefined;

  newsletterAgrees = undefined;

  mailingAgrees = undefined;

  deliveryAddresses = undefined;

  defaultDeliveryAddressIndex = undefined;

  webLogin = undefined;

  webPassword = undefined;

  isActive = undefined;

  editionAllowed = undefined;

  registrationDate = undefined;

  notificationMedia = undefined;

  customerId = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getFirstName() {
    return this.firstName;
  }

  setFirstName(firstName) {
    this['firstName'] = firstName;
  }

  getLastName() {
    return this.lastName;
  }

  setLastName(lastName) {
    this['lastName'] = lastName;
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    this['email'] = email;
  }

  getPendingEmail() {
    return this.pendingEmail;
  }

  setPendingEmail(pendingEmail) {
    this['pendingEmail'] = pendingEmail;
  }

  getPhone() {
    return this.phone;
  }

  setPhone(phone) {
    this['phone'] = phone;
  }

  getMobilePhone() {
    return this.mobilePhone;
  }

  setMobilePhone(mobilePhone) {
    this['mobilePhone'] = mobilePhone;
  }

  getFunction() {
    return this.function;
  }

  setFunction(_function) {
    this['function'] = _function;
  }

  getLanguage() {
    return this.language;
  }

  setLanguage(language) {
    this['language'] = language;
  }

  getTimezone() {
    return this.timezone;
  }

  setTimezone(timezone) {
    this['timezone'] = timezone;
  }

  getNewsletterAgrees() {
    return this.newsletterAgrees;
  }

  setNewsletterAgrees(newsletterAgrees) {
    this['newsletterAgrees'] = newsletterAgrees;
  }

  getMailingAgrees() {
    return this.mailingAgrees;
  }

  setMailingAgrees(mailingAgrees) {
    this['mailingAgrees'] = mailingAgrees;
  }

  getDeliveryAddresses() {
    return this.deliveryAddresses;
  }

  setDeliveryAddresses(deliveryAddresses) {
    this['deliveryAddresses'] = deliveryAddresses;
  }

  getDefaultDeliveryAddressIndex() {
    return this.defaultDeliveryAddressIndex;
  }

  setDefaultDeliveryAddressIndex(defaultDeliveryAddressIndex) {
    this['defaultDeliveryAddressIndex'] = defaultDeliveryAddressIndex;
  }

  getWebLogin() {
    return this.webLogin;
  }

  setWebLogin(webLogin) {
    this['webLogin'] = webLogin;
  }

  getWebPassword() {
    return this.webPassword;
  }

  setWebPassword(webPassword) {
    this['webPassword'] = webPassword;
  }

  getIsActive() {
    return this.isActive;
  }

  setIsActive(isActive) {
    this['isActive'] = isActive;
  }

  getEditionAllowed() {
    return this.editionAllowed;
  }

  setEditionAllowed(editionAllowed) {
    this['editionAllowed'] = editionAllowed;
  }

  getRegistrationDate() {
    return this.registrationDate;
  }

  setRegistrationDate(registrationDate) {
    this['registrationDate'] = registrationDate;
  }

  getNotificationMedia() {
    return this.notificationMedia;
  }

  setNotificationMedia(notificationMedia) {
    this['notificationMedia'] = notificationMedia;
  }

  getCustomerId() {
    return this.customerId;
  }

  setCustomerId(customerId) {
    this['customerId'] = customerId;
  }


}