/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CalendarEntry from '../model/CalendarEntry';
import EMSProtoProductOrServices from '../model/EMSProtoProductOrServices';
import EmsxFileContent from '../model/EmsxFileContent';
import FactoryMetrics from '../model/FactoryMetrics';
import LogEntry from '../model/LogEntry';
import ManufacturingWorkshop from '../model/ManufacturingWorkshop';
import ObjectReference from '../model/ObjectReference';
import ResourcesPlanning from '../model/ResourcesPlanning';
import ServerStatus from '../model/ServerStatus';
import SessionExpiration from '../model/SessionExpiration';
import Statistics from '../model/Statistics';


export default class MiscApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  addLogEntryWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling addLogEntry");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/log', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  addLogEntry(content) {
    return this.addLogEntryWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  exploreDataFileWithHttpInfo(binaryData) {
    let postBody = binaryData;

    // verify the required parameter 'binaryData' is set
    if (binaryData === undefined || binaryData === null) {
      throw new Error("Missing the required parameter 'binaryData' when calling exploreDataFile");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = ['application/json'];
    let returnType = EmsxFileContent;

    return this.apiClient.callApi(
      '/exploreDataFile', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  exploreDataFile(binaryData) {
    return this.exploreDataFileWithHttpInfo(binaryData).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  exportDataWithHttpInfo(objects) {
    let postBody = objects;

    // verify the required parameter 'objects' is set
    if (objects === undefined || objects === null) {
      throw new Error("Missing the required parameter 'objects' when calling exportData");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/octet-stream'];
    let returnType = 'Blob';

    return this.apiClient.callApi(
      '/exportData', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  exportData(objects) {
    return this.exportDataWithHttpInfo(objects).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCalendarWithHttpInfo(from, to) {
    let postBody = null;

    // verify the required parameter 'from' is set
    if (from === undefined || from === null) {
      throw new Error("Missing the required parameter 'from' when calling getCalendar");
    }

    // verify the required parameter 'to' is set
    if (to === undefined || to === null) {
      throw new Error("Missing the required parameter 'to' when calling getCalendar");
    }


    let pathParams = {
    };
    let queryParams = {
      'from': from,
      'to': to
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [CalendarEntry];

    return this.apiClient.callApi(
      '/calendar', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCalendar(from, to) {
    return this.getCalendarWithHttpInfo(from, to).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getCsvStatsWithHttpInfo(from, to) {
    let postBody = null;

    // verify the required parameter 'from' is set
    if (from === undefined || from === null) {
      throw new Error("Missing the required parameter 'from' when calling getCsvStats");
    }

    // verify the required parameter 'to' is set
    if (to === undefined || to === null) {
      throw new Error("Missing the required parameter 'to' when calling getCsvStats");
    }


    let pathParams = {
    };
    let queryParams = {
      'from': from,
      'to': to
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['text/csv'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/stats/csv', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getCsvStats(from, to) {
    return this.getCsvStatsWithHttpInfo(from, to).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getEmsProductsAndServicesWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'emsReferencePattern': opts['emsReferencePattern'],
      'kind': opts['kind'],
      'descriptionPattern': opts['descriptionPattern']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [EMSProtoProductOrServices];

    return this.apiClient.callApi(
      '/productsAndServices', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getEmsProductsAndServices(opts) {
    return this.getEmsProductsAndServicesWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getFactoryMetricsWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = FactoryMetrics;

    return this.apiClient.callApi(
      '/factoryMetrics', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getFactoryMetrics() {
    return this.getFactoryMetricsWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getFileWithHttpInfo(fileToken) {
    let postBody = null;

    // verify the required parameter 'fileToken' is set
    if (fileToken === undefined || fileToken === null) {
      throw new Error("Missing the required parameter 'fileToken' when calling getFile");
    }


    let pathParams = {
      'fileToken': fileToken
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['application/octet-stream'];
    let returnType = 'Blob';

    return this.apiClient.callApi(
      '/file/{fileToken}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getFile(fileToken) {
    return this.getFileWithHttpInfo(fileToken).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getJsonStatsWithHttpInfo(from, to) {
    let postBody = null;

    // verify the required parameter 'from' is set
    if (from === undefined || from === null) {
      throw new Error("Missing the required parameter 'from' when calling getJsonStats");
    }

    // verify the required parameter 'to' is set
    if (to === undefined || to === null) {
      throw new Error("Missing the required parameter 'to' when calling getJsonStats");
    }


    let pathParams = {
    };
    let queryParams = {
      'from': from,
      'to': to
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Statistics;

    return this.apiClient.callApi(
      '/stats/json', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getJsonStats(from, to) {
    return this.getJsonStatsWithHttpInfo(from, to).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getManufacturingWorkshopWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ManufacturingWorkshop;

    return this.apiClient.callApi(
      '/manufacturing/workshop', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getManufacturingWorkshop() {
    return this.getManufacturingWorkshopWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getManufacturingWorkshopPlanningWithHttpInfo(fromDate, toDate) {
    let postBody = null;

    // verify the required parameter 'fromDate' is set
    if (fromDate === undefined || fromDate === null) {
      throw new Error("Missing the required parameter 'fromDate' when calling getManufacturingWorkshopPlanning");
    }

    // verify the required parameter 'toDate' is set
    if (toDate === undefined || toDate === null) {
      throw new Error("Missing the required parameter 'toDate' when calling getManufacturingWorkshopPlanning");
    }


    let pathParams = {
    };
    let queryParams = {
      'fromDate': fromDate,
      'toDate': toDate
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ResourcesPlanning;

    return this.apiClient.callApi(
      '/manufacturing/workshop/planning', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getManufacturingWorkshopPlanning(fromDate, toDate) {
    return this.getManufacturingWorkshopPlanningWithHttpInfo(fromDate, toDate).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getMetricsWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = ['text/plain'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/metrics', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getMetrics() {
    return this.getMetricsWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getServerStatusWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'outputConfiguration': opts['outputConfiguration'],
      'outputDebugInformations': opts['outputDebugInformations'],
      'outputLoggedUsers': opts['outputLoggedUsers'],
      'outputMemoryData': opts['outputMemoryData']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = ServerStatus;

    return this.apiClient.callApi(
      '/status', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getServerStatus(opts) {
    return this.getServerStatusWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  importDataWithHttpInfo(binaryData) {
    let postBody = binaryData;

    // verify the required parameter 'binaryData' is set
    if (binaryData === undefined || binaryData === null) {
      throw new Error("Missing the required parameter 'binaryData' when calling importData");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = ['application/json'];
    let returnType = [ObjectReference];

    return this.apiClient.callApi(
      '/importData', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  importData(binaryData) {
    return this.importDataWithHttpInfo(binaryData).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  keepAliveWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = SessionExpiration;

    return this.apiClient.callApi(
      '/keepAlive', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  keepAlive() {
    return this.keepAliveWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  resetCalendarDateWithHttpInfo(_date) {
    let postBody = null;

    // verify the required parameter '_date' is set
    if (_date === undefined || _date === null) {
      throw new Error("Missing the required parameter '_date' when calling resetCalendarDate");
    }


    let pathParams = {
    };
    let queryParams = {
      'date': _date
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = CalendarEntry;

    return this.apiClient.callApi(
      '/calendar', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  resetCalendarDate(_date) {
    return this.resetCalendarDateWithHttpInfo(_date).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setApiDebugWithHttpInfo(api, state, opts) {
    opts = opts || {};
    let postBody = null;

    // verify the required parameter 'api' is set
    if (api === undefined || api === null) {
      throw new Error("Missing the required parameter 'api' when calling setApiDebug");
    }

    // verify the required parameter 'state' is set
    if (state === undefined || state === null) {
      throw new Error("Missing the required parameter 'state' when calling setApiDebug");
    }


    let pathParams = {
      'api': api
    };
    let queryParams = {
      'method': opts['method'],
      'state': state
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/debug/api/{api}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setApiDebug(api, state, opts) {
    return this.setApiDebugWithHttpInfo(api, state, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setCalendarDateWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setCalendarDate");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/calendar', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setCalendarDate(content) {
    return this.setCalendarDateWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setEmailFooterImageWithHttpInfo(binaryData) {
    let postBody = binaryData;

    // verify the required parameter 'binaryData' is set
    if (binaryData === undefined || binaryData === null) {
      throw new Error("Missing the required parameter 'binaryData' when calling setEmailFooterImage");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/emailFooterImage', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setEmailFooterImage(binaryData) {
    return this.setEmailFooterImageWithHttpInfo(binaryData).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setFactoryMetricsWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setFactoryMetrics");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/factoryMetrics', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setFactoryMetrics(content) {
    return this.setFactoryMetricsWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setManufacturingWorkshopWithHttpInfo(body) {
    let postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling setManufacturingWorkshop");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ManufacturingWorkshop;

    return this.apiClient.callApi(
      '/manufacturing/workshop', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setManufacturingWorkshop(body) {
    return this.setManufacturingWorkshopWithHttpInfo(body).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  updateEmsfactoryProductsWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/products/update', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  updateEmsfactoryProducts() {
    return this.updateEmsfactoryProductsWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  updateFromErpWithHttpInfo() {
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/erp/update', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  updateFromErp() {
    return this.updateFromErpWithHttpInfo().
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  updateManufacturingWorkshopWithHttpInfo(body) {
    let postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling updateManufacturingWorkshop");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = ManufacturingWorkshop;

    return this.apiClient.callApi(
      '/manufacturing/workshop', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  updateManufacturingWorkshop(body) {
    return this.updateManufacturingWorkshopWithHttpInfo(body).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}