import React from "react";
import { FormattedMessage } from "react-intl";
import { Glyphicon, Link } from "Elements";

const PrevAndNext = (props) => {
  const { prefix, categoryField = "category", list = [], id, category } = props;
  const categoryList = category ?
  list.filter((i) => i[categoryField] === category) :
  list;
  const index = categoryList.findIndex((i) => i.id === id);
  if (index < 0) {
    return null;
  }
  const prevIndex = index - 1;
  const nextIndex = index + 1;

  const prev = categoryList[prevIndex] || {};
  const next = categoryList[nextIndex] || {};

  return (
    <div className="d-flex justify-content-between">
      <div className="mr-3">
        {prev.id ?
        <Link
          to={[prefix, prev[categoryField], prev.slug, prev.id].
          filter((s) => s).
          join("/")}
          style={{ color: "gray" }}>

            <div className="d-flex flex-column text-left">
              <div>
                <Glyphicon glyph="chevron-left" />
                &nbsp;
                <FormattedMessage
                id="marketing.blog.prev"
                defaultMessage="Previous article" />

              </div>
              <div>
                <strong>{prev.title}</strong>
              </div>
            </div>
          </Link> :
        null}
      </div>
      <div className="ml-3">
        {next.id ?
        <Link
          to={[prefix, next[categoryField], next.slug, next.id].
          filter((s) => s).
          join("/")}
          style={{ color: "gray" }}>

            <div className="d-flex flex-column text-right">
              <div>
                <FormattedMessage
                id="marketing.blog.next"
                defaultMessage="Next article" />
              {" "}
                <Glyphicon glyph="chevron-right" />
              </div>
              <div>
                <strong>{next.title}</strong>
              </div>
            </div>
          </Link> :
        null}
      </div>
    </div>);

};

export default PrevAndNext;