const domains = [
"marketing",
"account",
"pcba",
"wiring",
"pcb",
"enclosure",
"coating",
"xray",
"assembly",
"rework",
"engineering",
"supply",
"anomaly"
// "emslabs"
];

module.exports = domains;