import React from "react";
import BetweenSteps from "./components/BetweenSteps";
import BigBadge from "./components/BigBadge";

import Section from "marketing/components/Section";
const tab = [];
const ShortProcess = (props) => {
  const { data = {} } = props;
  const { title, steps = [] } = data;
  if (!steps.length) {
    return null;
  }

  return (
    <Section className="short_process">
      <h2 className="marketing_section__title">{title}</h2>
      <div className="d-flex flex-column flex-md-row flex-wrap flex-xl-nowrap justify-content-around">
        {(steps || []).map((step, i) => {
          const { image, title, text } = step;
          const adresseMess = image.src.split("/");
          const imageAdresse =
          "../../../public" +
          adresseMess[0] +
          "/" +
          adresseMess[1] +
          "/" +
          adresseMess[2] +
          "/" +
          adresseMess[3] +
          "/preview.jpg";
          let res = [
          <div
            key={"step" + i}
            className="card bg-primary text-white mx-lg-2 mx-xl-3 p-3">

              <div className="d-flex flex-md-column align-items-center justify-content-start Njustify-content-md-around h-100">
                <div className="text-center flex-shrink-0 short_process__icon">
                  <img
                  data-src={image.src}
                  alt={image.description}
                  data-srcset={image.srcset}
                  className="img-fluid lazyload" />

                </div>
                <div className="d-flex flex-column flex-md-row short_process_title">
                  <div className="pr-0 mr-2 mb-2">
                    <BigBadge>{i + 1}</BigBadge>
                  </div>
                  <div className="short_process__card_title">{title}</div>
                </div>
              </div>
              <div className="short_process__card_info">
                <p>{text}</p>
              </div>
            </div>];

          if (i < steps.length - 1) {
            res = [...res, <BetweenSteps key={"b" + i} />];
          }
          return res;
        })}
      </div>
    </Section>);

};

export default ShortProcess;