/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MissingPaymentAnomalyActionsCancelOrder from './MissingPaymentAnomalyActionsCancelOrder';
import MissingPaymentAnomalyActionsPay from './MissingPaymentAnomalyActionsPay';

export default class MissingPaymentAnomalyActions {
  constructor(cancelOrder, pay) {
    this['cancelOrder'] = cancelOrder || new MissingPaymentAnomalyActionsCancelOrder();
    this['pay'] = pay || new MissingPaymentAnomalyActionsPay();


  }

  static typeDescription() {
    return "MissingPaymentAnomalyActions";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MissingPaymentAnomalyActions();
      if (data.hasOwnProperty('cancelOrder')) {
        obj['cancelOrder'] = MissingPaymentAnomalyActionsCancelOrder.constructFromObject(data['cancelOrder']);
      }
      if (data.hasOwnProperty('pay')) {
        obj['pay'] = MissingPaymentAnomalyActionsPay.constructFromObject(data['pay']);
      }
    }
    return obj;
  }


  cancelOrder = undefined;

  pay = undefined;




  getCancelOrder() {
    return this.cancelOrder;
  }

  setCancelOrder(cancelOrder) {
    this['cancelOrder'] = cancelOrder;
  }

  getPay() {
    return this.pay;
  }

  setPay(pay) {
    this['pay'] = pay;
  }


}