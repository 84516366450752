// import languages from "languages";
import { combineReducers } from "redux";
import { setLocal, getLocal } from "../utils/localStorage";
import allowedDomains from "./domains";

const messages = (state = {}, action) => {
  if (action.type === "intl/SET_MESSAGES") {
    return Object.assign({}, state, action.messages);
  }
  if (
  ["intl/EMPTY_MESSAGES", "intl/EMPTY_LOADED_DOMAINS"].indexOf(action.type) >=
  0)
  {
    return {};
  }
  return state;
};

const language = (state = false, action) => {
  if (action.type === "intl/SET_LANGUAGE" && action.language) {
    setLocal("language", action.language);
  }

  return getLocal("language") || false;
  // let lang = getLocal('frontLang') || false
  // if (!lang) {
  //   if (typeof window !== 'undefined') {
  //     const navLang = (window.navigator.language || window.navigator.userLanguage).slice(0, 2)
  //     lang = navLang
  //     // setLocal('frontLang', frontLang)
  //   }
  // }
  // return state.toLowerCase()
};

const domains = (state = ["marketing"], action) => {
  const updateDomains = (list, domains) => {
    let nDomains = ["marketing"];
    domains.forEach((domain) => {
      if (nDomains.indexOf(domain) < 0) {
        nDomains = [...nDomains, domain];
      }
    });
    return nDomains.filter((d) => allowedDomains.indexOf(d) >= 0);
  };
  if (action.type === "intl/ASK_DOMAINS") {
    return updateDomains(state, action.domains);
  }
  return state;
};

const loadedDomains = (state = [], action) => {
  if (action.type === "intl/SET_DOMAINS") {
    console.log("intl loadedDomains state", state);
    let nState = [...state];
    (action.domains || []).forEach((domain) =>
    nState.indexOf(domain) < 0 ? nState = [...nState, domain] : false
    );
    return nState;
  }
  if (action.type === "intl/EMPTY_LOADED_DOMAINS") {
    return [];
  }
  return state;
};

export default combineReducers({ messages, language, domains, loadedDomains });