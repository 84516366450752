import React from 'react';

const Arrow = (props) => {
  let points = [];
  const topOrLeft = ['down', 'right'].indexOf(props.direction) < 0;
  for (var i = 0; i < 4; i++) {
    const deltaX = (topOrLeft ? 0 : 15) + i * (topOrLeft ? 5 : -5);
    const y = 23 + i * 6;
    let point1 = [35 + deltaX, y];
    let point2 = [35 - deltaX, y];
    if (['left', 'right'].indexOf(props.direction) >= 0) {
      point1 = point1.reverse();
      point2 = point2.reverse();
    }

    points = [
    ...points,
    point1,
    point2];

  }
  // let steps = [20, 25, ]
  // let points = [[35, 20], [30, 26], [40, 25], [25, 32], [45, 32], [20, 38], [50, 38]]
  // if (props.direction === 'down') {
  //   points = points.map((p, i) => [p[0], points[points.length - 1 - i][1]])
  // }

  return <svg className={props.className}
  viewBox="0 0 70 70">

    {points.map((p, i) => <circle key={i} cx={p[0]} cy={p[1]} r="2" />)}
  </svg>;
};
export default Arrow;