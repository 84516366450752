import React from "react";
import ReactDOM from "react-dom";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import Glyphicon from "components/FontIcon";

class PopoverComponent extends React.Component {
  state = {};
  componentDidMount() {
    // console.log("Popover didMount", ReactDOM.findDOMNode(this));
    this.el = ReactDOM.findDOMNode(this);
    this.toggle = (this.props.toggle || this.toggle).bind(this);
    // this.el.addEventListener("click", this.toggle);
  }
  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    const { target } = e;
    if (target !== this.el) {
      return this.el.click();
    }
    this.setState({ show: !this.state.show });
  }
  render() {
    const { props, state } = this;
    // console.log("Popover render", props, state, this.el);
    const content = [
    props.header ?
    <PopoverHeader key="header">
          {props.header}{" "}
          {props.headerCloseButton ?
      <div
        className="float-right"
        style={{ cursor: "pointer" }}
        onClick={this.toggle}>

              <Glyphicon glyph="times" />
            </div> :
      null}
        </PopoverHeader> :
    null,
    <PopoverBody key="body">{props.body}</PopoverBody>].
    filter((e) => e);

    return !props.target ?
    [
    React.cloneElement(props.children, {
      key: "0"
      // onClick: (e) => {
      //   console.log("Popover click");
      //   e.preventDefault();
      //   e.stopPropagation();
      //   this.setState({ show: !state.show });
      // },
    }),
    // state.show ? (
    //   <div
    //     style={{
    //       position: "fixed",
    //       top: 0,
    //       bottom: 0,
    //       left: 0,
    //       right: 0,
    //       height: "100vh",
    //       // background: "#0008",
    //       zIndex: 1,
    //     }}
    //     onClick={this.toggle}
    //   ></div>
    // ) : null,
    this.el ?
    <Popover
      placement={props.placement || "top"}
      className={props.className}
      onClick={(e) => {
        return props.onClick ? props.onClick(e) : null;
      }}
      hideArrow={props.hideArrow}
      key="1"
      isOpen={state.show}
      disabled={props.disabled}
      target={this.el}
      flip={props.flip}
      boundariesElement={props.boundariesElement}
      toggle={this.toggle}
      trigger="legacy">

            {content}
          </Popover> :
    null] :


    <Popover
      hideArrow={props.hideArrow}
      placement={props.placement}
      isOpen
      target={props.target}
      toggle={this.toggle}
      trigger={props.trigger}
      className={props.className}>

        {content}
      </Popover>;

  }
}

export default PopoverComponent;

// import React from "react";
// import ReactDOM from "react-dom";
// import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

// class Popover extends React.Component {
//   state = {};
//   componentDidMount() {
//     const { props } = this;
//     if (props.children) {
//       this.setState({
//         el: ReactDOM.findDOMNode(this)
//       });
//     }
//   }
//   render() {
//     const { props, state } = this;
//     let res = [];
//     if (props.children) {
//       res = [
//         React.cloneElement(props.children, {
//           key: "children"
//         })
//       ];
//     }

//     const nProps = Object.assign({}, props);
//     delete nProps.show;

//     const placement = props.placement || "top";

//     const body = props.body ? <PopoverBody>{props.body}</PopoverBody> : null;

//     const header = props.header ? (
//       <PopoverHeader>{props.header}</PopoverHeader>
//     ) : null;

//     if (state.el || props.target) {
//       res = [
//         ...res,
//         <UncontrolledPopover
//           modifiers={{
//             preventOverflow: { boundariesElement: "viewport" },
//             flip: { behavior: [placement] }
//           }}
//           trigger="legacy"
//           {...nProps}
//           key={props.key || "popover"}
//           fade={false}
//           // flip={false}
//           target={props.target || state.el}
//           placement={placement}
//           // isOpen={
//           //   typeof props.show !== "undefined"
//           //     ? props.show
//           //     : typeof props.show !== "undefined"
//           //     ? props.show
//           //     : null
//           // }
//         >
//           {header}
//           {body}
//         </UncontrolledPopover>
//       ];
//     }
//     return res;
//   }
// }

// export default Popover;