import React from "react";
import { FormattedMessage } from "react-intl";

const productPages = [
{
  slug: "pcba",
  title:
  <FormattedMessage
    id="marketing.product.pcba.title"
    defaultMessage="Electronic board assembly" />


},
{
  slug: "wiring",
  title:
  <FormattedMessage
    id="marketing.product.wiring.title"
    defaultMessage="Cable assembly" />


},
{
  slug: "pcb",
  title:
  <FormattedMessage id="marketing.product.pcb.title" defaultMessage="PCB" />

},
{
  slug: "enclosure",
  title:
  <FormattedMessage
    id="marketing.product.enclosure.title"
    defaultMessage="Enclosure" />


},
{
  slug: "coating",
  title:
  <FormattedMessage
    id="marketing.product.coating.title"
    defaultMessage="Coating / potting" />


},
{
  slug: "xray",
  title:
  <FormattedMessage
    id="marketing.product.xray.title"
    defaultMessage="X-ray Radiography" />


},
{
  slug: "assembly",
  title:
  <FormattedMessage
    id="marketing.product.assembly.title"
    defaultMessage="Product assembly" />


},
{
  slug: "rework",
  title:
  <FormattedMessage
    id="marketing.product.reworkTitle"
    defaultMessage="Rework" />


},
{
  slug: "engineering",
  title:
  <FormattedMessage
    id="marketing.product.engineeringTitle"
    defaultMessage="Engineering" />


}];


export default productPages;