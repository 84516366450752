import React from "react";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import { Link } from "Elements";

import Section from "marketing/components/Section";

const messages = defineMessages({
  title: { "id": "marketing.page404.title", "defaultMessage": "Page not found" },



  message: { "id": "marketing.page404.message", "defaultMessage": "Lost between our server motherboard components, you can go back at RAM address 0X0000 located 3 resistors at the top and 7 capacitors on the right by clicking on the button below." }




});

const Page404 = (props) =>
<div className="page_404">
    <Section title={props.intl.formatMessage(messages.title)}>
      <div className="text-center pb-3">
        <img alt="404" src="/images/erreur404.png" />
      </div>
      <div className="col-6 offset-3">
        {props.intl.formatMessage(messages.message)}
      </div>
      <div className="text-center">
        <Link to="/" className="btn btn-secondary mt-3 mb-5">
          <FormattedMessage id="marketing.home" defaultMessage="Home" />
        </Link>
      </div>
    </Section>
  </div>;


export default injectIntl(Page404);