/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import MessageReadBy from './MessageReadBy';

export default class Message {
  constructor(subject, body) {
    if (subject) {
      this['subject'] = subject;
    }
    if (body) {
      this['body'] = body;
    }


  }

  static typeDescription() {
    return "Message";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Message();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('threadId')) {
        obj['threadId'] = ApiClient.convertToType(data['threadId'], 'String');
      }
      if (data.hasOwnProperty('emitterId')) {
        obj['emitterId'] = ApiClient.convertToType(data['emitterId'], 'String');
      }
      if (data.hasOwnProperty('emitter')) {
        obj['emitter'] = ApiClient.convertToType(data['emitter'], 'String');
      }
      if (data.hasOwnProperty('subject')) {
        obj['subject'] = ApiClient.convertToType(data['subject'], 'String');
      }
      if (data.hasOwnProperty('body')) {
        obj['body'] = ApiClient.convertToType(data['body'], 'String');
      }
      if (data.hasOwnProperty('attachments')) {
        obj['attachments'] = ApiClient.convertToType(data['attachments'], ['String']);
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('postDate')) {
        obj['postDate'] = ApiClient.convertToType(data['postDate'], 'String');
      }
      if (data.hasOwnProperty('replyTo')) {
        obj['replyTo'] = ApiClient.convertToType(data['replyTo'], 'String');
      }
      if (data.hasOwnProperty('isRead')) {
        obj['isRead'] = ApiClient.convertToType(data['isRead'], 'Boolean');
      }
      if (data.hasOwnProperty('readBy')) {
        obj['readBy'] = ApiClient.convertToType(data['readBy'], [MessageReadBy]);
      }
      if (data.hasOwnProperty('gpaoUserId')) {
        obj['gpaoUserId'] = ApiClient.convertToType(data['gpaoUserId'], 'String');
      }
    }
    return obj;
  }


  id = undefined;

  threadId = undefined;

  emitterId = undefined;

  emitter = undefined;

  subject = "";

  body = "";

  attachments = undefined;

  creationDate = undefined;

  postDate = undefined;

  replyTo = undefined;

  isRead = undefined;

  readBy = undefined;

  gpaoUserId = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getThreadId() {
    return this.threadId;
  }

  setThreadId(threadId) {
    this['threadId'] = threadId;
  }

  getEmitterId() {
    return this.emitterId;
  }

  setEmitterId(emitterId) {
    this['emitterId'] = emitterId;
  }

  getEmitter() {
    return this.emitter;
  }

  setEmitter(emitter) {
    this['emitter'] = emitter;
  }

  getSubject() {
    return this.subject;
  }

  setSubject(subject) {
    this['subject'] = subject;
  }

  getBody() {
    return this.body;
  }

  setBody(body) {
    this['body'] = body;
  }

  getAttachments() {
    return this.attachments;
  }

  setAttachments(attachments) {
    this['attachments'] = attachments;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getPostDate() {
    return this.postDate;
  }

  setPostDate(postDate) {
    this['postDate'] = postDate;
  }

  getReplyTo() {
    return this.replyTo;
  }

  setReplyTo(replyTo) {
    this['replyTo'] = replyTo;
  }

  getIsRead() {
    return this.isRead;
  }

  setIsRead(isRead) {
    this['isRead'] = isRead;
  }

  getReadBy() {
    return this.readBy;
  }

  setReadBy(readBy) {
    this['readBy'] = readBy;
  }

  getGpaoUserId() {
    return this.gpaoUserId;
  }

  setGpaoUserId(gpaoUserId) {
    this['gpaoUserId'] = gpaoUserId;
  }


}