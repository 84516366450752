const convertFileToUpload = (file, type) => {
  const reader = new FileReader();
  if (type === "base64") {
    reader.readAsDataURL(file);
  } else {
    reader.readAsArrayBuffer(file);
  }
  return new Promise((res, rej) => {
    reader.onload = () => {
      try {
        if (type === "base64") {
          const index = reader.result.indexOf(";base64,");
          const content = reader.result.slice(index + 8);
          console.log("convertFileToUpload", content);
          return res(content);
        }
        // const index = reader.result.indexOf(';base64,')
        // const content = reader.result.slice(index + 8)
        console.log("convertFileToUpload nobase64", reader.result);
        return res(reader.result);
      } catch (err) {
        return rej(err);
      }
    };
  });
};

export default convertFileToUpload;