import React, { Component } from "react";
import config from "config";
import { FormattedMessage } from "react-intl";
import { Switch, Route, withRouter } from "react-router-dom";

import { RequestsProvider } from "./Requests";
import { AuthProvider } from "./Auth";

import products from "products";
import Auth from "Auth";
import Signup from "Auth/Signup";

import MarketingRouter from "marketing/MarketingRouter";
import Header from "components/Header";

import Cart from "./Cart";
import PaymentProcessed from "./Payments/Processed";
import { CartContextProvider } from "./Cart/Context";
import Account from "./account";
import request from "superagent";
import miscActions from "Misc/actions";

const checkBuildNumberInterval = 2 * 60 * 1e3;
const checkCpmMaintenanceInterval = 2 * 60 * 1e3;
const buildCommit = config.buildCommit;
console.log("buildCommit", buildCommit);

class App extends Component {
  state = {};
  componentDidMount() {
    const checkBuildNumber = (e) => {
      request.
      get("/version.json?n=" + Math.round(Math.random() * 1e4)).
      then((res) => {
        const { body = {} } = res;
        const currentbuildCommit = body.version;
        if (buildCommit !== currentbuildCommit) {
          this.setState({ shouldReload: true });
        }
      }).
      catch((err) => console.log("frontVersion err", err));
    };
    window.setInterval(checkBuildNumber, checkBuildNumberInterval);

    // CPM Maintenance
    miscActions.checkCpmMaintenanceState();
    this.interval = window.setInterval(
      miscActions.checkCpmMaintenanceState,
      checkCpmMaintenanceInterval
    );

    // CPM version
    miscActions.getCpmVersion();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { props, state } = this;
    const root = props.match.path;
    console.log("App render", props, state, config, process.env);
    return (
      <RequestsProvider>
        <AuthProvider>
          <CartContextProvider>
            <Header />
            <Switch>
              {/* products */}
              {products.map((product, i) => {
                return (
                  <Route
                    key={i}
                    path={root + "/" + product.slug + "/:quotationId"}
                    render={(nProps) => {
                      return (
                        <Auth>
                          {React.createElement(
                            product.component,
                            Object.assign({}, props, nProps)
                          )}
                          {/* <ModuleLoader props={Object.assign({}, props, nProps)} module={'PCBA'} /> */}
                          {/* <EditLoadable {...Object.assign({}, props, nProps)} /> */}
                        </Auth>);

                    }} />);


              })}

              {/* ordering */}
              <Route path={root + "/cart"} component={Cart} />
              <Route
                path={root + "/payment_processed"}
                component={PaymentProcessed} />

              {/* <Route
                 path={root + "/transfer_payment/"}
                 component={TransferPayment}
                /> */}

              {/* account */}
              <Route path={root + "/account"} component={Account} />
              <Route path={props.match.url + "/signup"} component={Signup} />
              {/* <Route path={props.match.url} exact component={Login} /> */}

              <Route component={MarketingRouter} />
            </Switch>
          </CartContextProvider>
        </AuthProvider>
        {state.shouldReload ?
        <div
          className="should_reload"
          onClick={() => window.location.reload()}>

            <FormattedMessage
            id="marketing.app.shouldReload"
            defaultMessage="A new update is available. Click here to reload this page." />

          </div> :
        null}
      </RequestsProvider>);

  }
}

export default withRouter(App);