import React from "react";

const icon =
<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 100 100"
  id="enclosure"
  style={{
    width: "100%",
    maxHeight: "100%"
  }}>

    <defs>
      <pattern
      href="#enclosure_a"
      id="enclosure_b"
      patternTransform="rotate(45 -74.7 -106.97) scale(.80748)" />

      <pattern
      patternUnits="userSpaceOnUse"
      width="5.5"
      height="5.46"
      patternTransform="translate(-159.11 41.55)"
      id="enclosure_a">

        <g transform="translate(159.11 -41.55)" color="#000" fillRule="evenodd">
          <circle
          r=".74"
          cy="42.92"
          cx="-157.74"
          // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
          overflow="visible"
          // fill="#fff"
          strokeWidth=".1" />

          <circle
        // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
        cx="-154.99"
        cy="42.92"
        r=".74"
        overflow="visible"
        // fill="#fff"
        strokeWidth=".1" />

          <circle
        // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
        cx="-157.74"
        cy="45.66"
        r=".74"
        overflow="visible"
        // fill="#fff"
        strokeWidth=".1" />

          <circle
          r=".74"
          cy="45.66"
          cx="-154.99"
          // style="isolation:auto;mix-blend-mode:normal;solid-color:#000;solid-opacity:1"
          overflow="visible"
          // fill="#fff"
          strokeWidth=".1" />

        </g>
      </pattern>
    </defs>
    <g transform="translate(2.08) scale(1.221)">
      <path
      d="M1.8 20.3v42.1l34 17V37.3l-34-17zM15 61.2 8.6 58V32.3l6.4 3.1v25.8z"
      fill="none" />

      <path d="M63.4 71.6h-.2.3-.1z" />
      <path
    // className="st2"
    d="M78 44.1c-.1 0-.1-.1-.2-.1l-4.1-2V18.6l-.1-.2-.1-.1s0-.1-.1-.1l-.1-.1L35.9.1c-.1-.1-.3-.1-.4-.1-.2 0-.3 0-.4.1L.5 18.1l-.1.1H.2l-.1.1c0 .1-.1.1-.1.2V63c0 .4.2.7.5.8l36 18c.2.1.3.1.4.1.1 0 .2 0 .4-.1l23.4-11.7 2.3 1.3c.1.1.2.1.2.1h.6l14.2-7c.3-.1.5-.4.5-.7v-19c-.1-.2-.2-.5-.5-.7ZM34.3 3.3v30.5l-30-15.2 30-15.3zm1.6 76.2L1.8 62.4V20.3l34 17v42.2zM35.7 34V3.3l31.7 15.5L35.7 34Zm26.9 35.3-.2-.1-2-1.1-11.9-6.7V44.7l14 8v16.6zm-.4-33.4c-.2-.1-.3-.1-.5 0l-14.2 6.5c-.1 0-.1 0-.1.1h-.1c-.3.2-.4.4-.4.7v18.6c0 .3.2.6.4.7l11.3 6.4-20.8 10.6V37.3l34-17V41l-9.6-5.1zm14.6 27.3-12.6 6.2V52.7l3.8-1.9 1.9-1 6.9-3.6v17z"
    // fill="#fff"
    />
      <path
      d="M24.8 40.9c-2.4-1.2-4.3-.2-4.3 2.4 0 2.5 1.9 5.6 4.3 6.8 2.4 1.2 4.3.1 4.3-2.4s-1.9-5.7-4.3-6.8zm3.4 6.2c-.2 1.7-1.7 2.3-3.4 1.3-1.7-1-2.8-3.2-2.6-5 .2-1.7 1.7-2.3 3.4-1.2 1.7 1 2.8 3.2 2.6 4.9z"
      // className="st2"
      // fill="#fff"
    />
      <ellipse
      ry="5.7"
      rx="3.5"
      cy="64.49"
      cx="-14.6"
      // className="st2"
      transform="rotate(-34.79)"
      // fill="#fff"
    />
      <path
      d="M7.9 30.5s-1.3-.8-1.3.8v26.6c0 .4.1 1 .9 1.4.8.4 8.3 4.3 8.3 4.3V34.4l-7.9-3.9zM15 61.2 8.6 58V32.3l6.4 3.1v25.8z"
      // className="st2"
      // fill="#fff"
    />
      <path
      d="m62.6 69.3-.2-.1-2-1.1-11.9-6.7V44.7l14 8v16.6zM35.7 34V3.3l31.7 15.5L35.7 34zM1.8 20.3v42.1l34 17.05V37.3l-34-17zm34 59.15v.05h.1l-.1-.05zM7.23 30.3c.33 0 .65.2.65.2l7.9 3.9v29.2s-7.5-3.9-8.3-4.3c-.8-.4-.9-1-.9-1.4V31.3c0-.8.33-1 .65-1zM23.1 40.4c.51.04 1.08.2 1.68.5 2.4 1.1 4.3 4.3 4.3 6.8s-1.9 3.6-4.3 2.4c-2.4-1.2-4.3-4.3-4.3-6.8 0-1.95 1.07-3 2.62-2.9z"
      fill="url(#enclosure_b)" />

    </g>
  </svg>;


export default icon;