import { update } from "utils/updateArray";

const defaultState = {
  shown: false,
  messages: []
};

const messaging = (state = defaultState, action) => {
  if (action.type === "AUTH_LOGOUT") {
    return defaultState;
  }
  if (action.type === "messaging/SHOW") {
    return Object.assign({}, state, { shown: true, minimized: false });
  }
  if (action.type === "messaging/HIDE") {
    return Object.assign({}, state, { shown: false });
  }
  if (action.type === "messaging/TOGGLE_MESSAGING") {
    return Object.assign({}, state, { minimized: !state.minimized });
  }
  if (action.type === "messaging/SET_THREAD") {
    return Object.assign({}, state, { thread: action.data });
  }
  if (action.type === "messaging/SET_MESSAGE") {
    return Object.assign({}, state, {
      messages: update(state.messages || [], action.data)
    });
  }
  return state;
};

export default messaging;