import React from "react";
// import { FormattedMessage } from "react-intl";

import { NavLink } from "Elements";
import products from "marketing/productPages";
import Icon from "Icon";

const ProductSelector = (props) =>
<div className="product_selector d-none d-lg-flex">
    {products.map((product, i) =>
  <NavLink key={i} to={"/" + product.slug} className="d-flex">
        <div className="product_selector__product_icon">
          {/* <img src={'/images/products/' + product.slug + '.svg'} alt=""/> */}
          <Icon icon={product.slug}></Icon>
        </div>
        <div className="product_selector__product_name">
          {/* <FormattedMessage {...product.title} /> */}
          <span>{product.title}</span>
        </div>
      </NavLink>
  )}
  </div>;


export default ProductSelector;