/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Address {
  constructor() {


  }

  static typeDescription() {
    return "Address";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Address();
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('company')) {
        obj['company'] = ApiClient.convertToType(data['company'], 'String');
      }
      if (data.hasOwnProperty('street')) {
        obj['street'] = ApiClient.convertToType(data['street'], 'String');
      }
      if (data.hasOwnProperty('postalCode')) {
        obj['postalCode'] = ApiClient.convertToType(data['postalCode'], 'String');
      }
      if (data.hasOwnProperty('city')) {
        obj['city'] = ApiClient.convertToType(data['city'], 'String');
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = ApiClient.convertToType(data['country'], 'String');
      }
    }
    return obj;
  }


  name = undefined;

  company = undefined;

  street = undefined;

  postalCode = undefined;

  city = undefined;

  country = undefined;




  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getCompany() {
    return this.company;
  }

  setCompany(company) {
    this['company'] = company;
  }

  getStreet() {
    return this.street;
  }

  setStreet(street) {
    this['street'] = street;
  }

  getPostalCode() {
    return this.postalCode;
  }

  setPostalCode(postalCode) {
    this['postalCode'] = postalCode;
  }

  getCity() {
    return this.city;
  }

  setCity(city) {
    this['city'] = city;
  }

  getCountry() {
    return this.country;
  }

  setCountry(country) {
    this['country'] = country;
  }


}