import React from "react";
import { injectIntl } from "react-intl";
import HeaderUserMenu from "components/HeaderUserMenu";
import LanguageSelector from "components/LanguageSelector";
import HeaderCartButton from "Cart/HeaderCartButton";
import HeaderUserNotifications from "components/HeaderUserNotifications";
import HeaderHelp from "components/HeaderHelp";

const HeaderUserBlock = (props) =>
<div
  className="header-user-block"
  style={{
    display: "flex",
    alignItems: "center"
  }}>

    <HeaderHelp />
    <div className="h-100 d-none d-sm-block">
      <LanguageSelector />
    </div>
    <HeaderUserNotifications />
    <HeaderUserMenu />
    <div className="h-100 pr-0">
      <HeaderCartButton />
    </div>
  </div>;


export default injectIntl(HeaderUserBlock);