import React from "react";
import { injectIntl } from "react-intl";
import Section from "../components/Section";

const KeyBenefits = injectIntl((props) => {
  const { data = {} } = props;
  console.log("KeyBenefits render", props);
  return (
    <Section
      className={data.bgWhite ? "background_white keyBenefits" : "keyBenefits"}
      title={data.title}>

      <div
        className="row justify-content-sm-around keyBenefits_benefits"
        id="keyBenefits">

        {(data.keyBenefits || []).map((benefit, i) =>
        <div className="col-md-4 col-lg-3 col-xl" key={i}>
            <div className="keyBenefit">
              <img
              className="lazyload"
              width="150"
              height="150"
              data-src={benefit.icon.src}
              alt="" />

              <div className="keyBenefit__text">
                <div className="keyBenefit__title">{benefit.title}</div>
                <p className="keyBenefit__text">{benefit.text}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Section>);

});

export default KeyBenefits;