/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Component from './Component';

export default class Sourceable {
  constructor() {


  }

  static typeDescription() {
    return "Sourceable";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Sourceable();
      if (data.hasOwnProperty('component')) {
        obj['component'] = Component.constructFromObject(data['component']);
      }
      if (data.hasOwnProperty('libraryId')) {
        obj['libraryId'] = ApiClient.convertToType(data['libraryId'], 'String');
      }
    }
    return obj;
  }


  component = undefined;

  libraryId = undefined;




  getComponent() {
    return this.component;
  }

  setComponent(component) {
    this['component'] = component;
  }

  getLibraryId() {
    return this.libraryId;
  }

  setLibraryId(libraryId) {
    this['libraryId'] = libraryId;
  }


}