/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CustomerStocksStocks from './CustomerStocksStocks';

export default class CustomerStocks {
  constructor(customerId, stocks) {
    if (customerId) {
      this['customerId'] = customerId;
    }
    this['stocks'] = stocks || [];


  }

  static typeDescription() {
    return "CustomerStocks";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerStocks();
      if (data.hasOwnProperty('customerId')) {
        obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
      }
      if (data.hasOwnProperty('stocks')) {
        obj['stocks'] = ApiClient.convertToType(data['stocks'], [CustomerStocksStocks]);
      }
    }
    return obj;
  }


  customerId = undefined;

  stocks = [];




  getCustomerId() {
    return this.customerId;
  }

  setCustomerId(customerId) {
    this['customerId'] = customerId;
  }

  getStocks() {
    return this.stocks;
  }

  setStocks(stocks) {
    this['stocks'] = stocks;
  }


}