import React from "react";
import { Input } from "reactstrap";

class FormControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }
  componentDidUpdate(oProps, oState) {
    const { props, state } = this;
    if (props.value || oProps.value) {
      if (props.value !== oProps.value) {
        if (!state.focused) {
          this.setState({ value: props.value });
        }
      }
      if (!state.focused && oState.focused) {
        this.setState({ value: props.value });
      }
    }
  }
  render() {
    const { props, state } = this;

    let inputProps = {
      type: props.type || props.componentClass || "text",
      name: props.name,
      checked: props.checked,
      placeholder: props.placeholder,
      rows: props.rows,
      required: props.required,
      value: state.value || (props.defaultValue ? null : ""),
      defaultValue: props.defaultValue,
      disabled: props.disabled,
      multiple: props.multiple,
      invalid: props.invalid,
      min: props.min,
      max: props.max,

      minLength: props.minlength,
      id: props.id,
      pattern: props.pattern,
      state: props.validationState,
      bsSize: props.bsSize,
      readOnly: props.readOnly,
      className: props.className,
      onChange: (e) => {
        this.setState({ value: e.target.value });
        return props.onChange ? props.onChange(e) : null;
      },
      onFocus: (e) => {
        this.setState({ focused: true });
        return props.onFocus ? props.onFocus(e) : null;
      },
      onBlur: (e) => {
        this.setState({ focused: false });
        return props.onBlur ?
        props.onBlur(
          e
          // Object.assign({}, e, {
          //   target: Object.assign({}, e.target, { value: state.value }),
          // })
        ) :
        null;
      },
      onClick: props.onClick,
      style: props.style
    };
    if (props.validationState === "success") {
      inputProps.className = "has-success";
    }
    if (props.validationState === "error") {
      inputProps.className = "has-error";
    }
    if (props.validationState === "warning") {
      inputProps.className = "has-warning";
    }

    return <Input {...inputProps}>{props.children}</Input>;
  }
}

export default FormControl;