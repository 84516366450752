import { combineReducers } from "redux";
import { getLocal, setLocal } from "utils/localStorage";

const metrics = (state = [], action) => {
  if (action.type === "misc/factoryMetrics/GET") {
    return action.data;
  }
  return state;
};

const redirectingToPayment = (state = false, action) => {
  if (action.type === "misc/redirectingToPayment/SET") {
    return true;
  }
  return state;
};

let defaultCookieState = true;

if (typeof window !== "undefined") {
  defaultCookieState = JSON.parse(getLocal("cookie"));
}

const cookies = (state = defaultCookieState, action) => {
  if (action.type === "misc/cookies/SET") {
    setLocal("cookie", action.data);
    return action.data;
  }
  return state;
};

const cpmMaintenance = (state = [], action) => {
  if (action.type === "misc/cpmMaintenance/SET") {
    return action.data;
  }
  return state;
};

const cpmVersion = (state = "", action) => {
  if (action.type === "misc/cpmVersion/SET") {
    return action.data;
  }
  return state;
};

export default combineReducers({
  cookies,
  metrics,
  redirectingToPayment,
  cpmMaintenance,
  cpmVersion
});