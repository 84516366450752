import React from "react";

const SquareBenefits = (props) =>
<ul className="square_benefits">
    {props.squares.map((c, i) =>
  <li key={i} className="bg-secondary">
        <span>{c}</span>
      </li>
  )}
  </ul>;


export default SquareBenefits;