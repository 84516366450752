/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CategorizedComponentCommonNames from './CategorizedComponentCommonNames';
import Component from './Component';

export default class CategorizedComponent {
  constructor(asComponent, categories, type) {
    this['asComponent'] = asComponent || new Component();
    this['categories'] = categories || [];
    if (type) {
      this['type'] = type;
    }


  }

  static typeDescription() {
    return "CategorizedComponent";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CategorizedComponent();
      if (data.hasOwnProperty('asComponent')) {
        obj['asComponent'] = Component.constructFromObject(data['asComponent']);
      }
      if (data.hasOwnProperty('categories')) {
        obj['categories'] = ApiClient.convertToType(data['categories'], [['String']]);
      }
      if (data.hasOwnProperty('commonNames')) {
        obj['commonNames'] = CategorizedComponentCommonNames.constructFromObject(data['commonNames']);
      }
      if (data.hasOwnProperty('datasheetUrls')) {
        obj['datasheetUrls'] = ApiClient.convertToType(data['datasheetUrls'], ['String']);
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('imageUrls')) {
        obj['imageUrls'] = ApiClient.convertToType(data['imageUrls'], ['String']);
      }
      if (data.hasOwnProperty('cadFilesUrls')) {
        obj['cadFilesUrls'] = ApiClient.convertToType(data['cadFilesUrls'], ['String']);
      }
      if (data.hasOwnProperty('isActive')) {
        obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
      }
      if (data.hasOwnProperty('isApproved')) {
        obj['isApproved'] = ApiClient.convertToType(data['isApproved'], 'Boolean');
      }
      if (data.hasOwnProperty('norms')) {
        obj['norms'] = ApiClient.convertToType(data['norms'], ['String']);
      }
      if (data.hasOwnProperty('templateSpecificationId')) {
        obj['templateSpecificationId'] = ApiClient.convertToType(data['templateSpecificationId'], 'String');
      }
      if (data.hasOwnProperty('templateObjectId')) {
        obj['templateObjectId'] = ApiClient.convertToType(data['templateObjectId'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
    }
    return obj;
  }


  asComponent = undefined;

  categories = [];

  commonNames = undefined;

  datasheetUrls = undefined;

  id = undefined;

  imageUrls = undefined;

  cadFilesUrls = undefined;

  isActive = undefined;

  isApproved = undefined;

  norms = undefined;

  templateSpecificationId = undefined;

  templateObjectId = undefined;

  type = "Cable";




  getAsComponent() {
    return this.asComponent;
  }

  setAsComponent(asComponent) {
    this['asComponent'] = asComponent;
  }

  getCategories() {
    return this.categories;
  }

  setCategories(categories) {
    this['categories'] = categories;
  }

  getCommonNames() {
    return this.commonNames;
  }

  setCommonNames(commonNames) {
    this['commonNames'] = commonNames;
  }

  getDatasheetUrls() {
    return this.datasheetUrls;
  }

  setDatasheetUrls(datasheetUrls) {
    this['datasheetUrls'] = datasheetUrls;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getImageUrls() {
    return this.imageUrls;
  }

  setImageUrls(imageUrls) {
    this['imageUrls'] = imageUrls;
  }

  getCadFilesUrls() {
    return this.cadFilesUrls;
  }

  setCadFilesUrls(cadFilesUrls) {
    this['cadFilesUrls'] = cadFilesUrls;
  }

  getIsActive() {
    return this.isActive;
  }

  setIsActive(isActive) {
    this['isActive'] = isActive;
  }

  getIsApproved() {
    return this.isApproved;
  }

  setIsApproved(isApproved) {
    this['isApproved'] = isApproved;
  }

  getNorms() {
    return this.norms;
  }

  setNorms(norms) {
    this['norms'] = norms;
  }

  getTemplateSpecificationId() {
    return this.templateSpecificationId;
  }

  setTemplateSpecificationId(templateSpecificationId) {
    this['templateSpecificationId'] = templateSpecificationId;
  }

  getTemplateObjectId() {
    return this.templateObjectId;
  }

  setTemplateObjectId(templateObjectId) {
    this['templateObjectId'] = templateObjectId;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }


  static TypeEnum = {
    "Cable": "Cable",
    "CableTie": "CableTie",
    "CableTieMountAndAccessories": "CableTieMountAndAccessories",
    "ConnectorAndContactSeal": "ConnectorAndContactSeal",
    "ConnectorBackshell": "ConnectorBackshell",
    "ConnectorContact": "ConnectorContact",
    "ConnectorGrommet": "ConnectorGrommet",
    "ConnectorHousing": "ConnectorHousing",
    "ConnectorPlug": "ConnectorPlug",
    "ConnectorSet": "ConnectorSet",
    "ElectronicComponent": "ElectronicComponent",
    "Fastener": "Fastener",
    "Label": "Label",
    "Miscellaneous": "Miscellaneous",
    "PathJunction": "PathJunction",
    "Shielding": "Shielding",
    "Sleeving": "Sleeving",
    "Splice": "Splice",
    "TerminalComponent": "TerminalComponent",
    "Undef": "Undef",
    "Wire": "Wire"
  };

}