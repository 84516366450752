import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import intlActions from "Intl/actions";
import parseQuery from "../utils/queryParser";
import languages from "languages";

const Component = (props) => {
  let lang = props.lang || props.intl.locale;
  lang = languages.indexOf(lang) < 0 ? languages[0] : lang;

  const { onClick = (e) => e } = props;
  let propsTo = props.to.split("/");
  if (propsTo[1] === lang) {
    propsTo = [...propsTo.slice(0, 1), ...propsTo.slice(2)];
  }
  let to = "/" + lang + propsTo.join("/").replace(/\/\//, "/");
  return (
    <NavLink
      className={props.className}
      onClick={(e) => {
        if (lang !== props.intl.locale) {
          intlActions.setLanguage(lang);
        }
        onClick(e);
      }}
      to={to}
      activeClassName={props.activeClassName || "active"}
      exact={props.exact}>

      {props.children}
    </NavLink>);

};

export default injectIntl(withRouter(Component));