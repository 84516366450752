import { update } from "utils/updateArray";

const users = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "users/SET") {
    return update(state, action.data);
  }
  return state;
};

export default function (state, action) {
  return users(state, action).map((user) =>
  Object.assign({}, user, {
    name:
    user.name || [user.firstName, user.lastName].filter((n) => n).join(" ")
  })
  );
}