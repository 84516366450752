import React from "react";
import Section from "marketing/components/Section";
import { Image } from "Elements";

const PricingOptimization = (props) => {
  const { data = {} } = props;
  const { title, text, image, caption } = data;
  return (
    <Section className="pricingOptimization">
      <div className="d-flex align-items-center flex-column pricingOptimization">
        <span className="marketing_section__title">{title}</span>
        <span className="pricingOptimizationText">{text}</span>
        <Image
          image={image}
          className="pricingOptimizationImg w-100"
          sizes="
          (min-width:768px) 570px,
          (min-width:992px) 770px, 
          (min-width:1200px) 919px,
            " />





        <span className="text-white pt-2">{caption}</span>
      </div>
    </Section>);

};

export default PricingOptimization;