/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ComponentFilterResultCategories {
  constructor(path) {
    this['path'] = path || [];


  }

  static typeDescription() {
    return "ComponentFilterResultCategories";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ComponentFilterResultCategories();
      if (data.hasOwnProperty('path')) {
        obj['path'] = ApiClient.convertToType(data['path'], ['String']);
      }
      if (data.hasOwnProperty('nbResults')) {
        obj['nbResults'] = ApiClient.convertToType(data['nbResults'], 'Number');
      }
    }
    return obj;
  }


  path = [];

  nbResults = undefined;




  getPath() {
    return this.path;
  }

  setPath(path) {
    this['path'] = path;
  }

  getNbResults() {
    return this.nbResults;
  }

  setNbResults(nbResults) {
    this['nbResults'] = nbResults;
  }


}