import { update } from "utils/updateArray";

const quotes = (state = [], action) => {
  if (action.type === "AUTH_LOGOUT") {
    return [];
  }
  if (action.type === "quotes/setAll") {
    return action.data;
  }
  if (action.type === "quotes/SET") {
    return update(state, action.data);
  }
  return state;
};

export default quotes;