/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PaymentInvoicesDispatch from './PaymentInvoicesDispatch';
import PaymentOrdersDispatch from './PaymentOrdersDispatch';

export default class Payment {
  constructor() {


  }

  static typeDescription() {
    return "Payment";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Payment();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
      if (data.hasOwnProperty('mode')) {
        obj['mode'] = ApiClient.convertToType(data['mode'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('invoicesDispatch')) {
        obj['invoicesDispatch'] = ApiClient.convertToType(data['invoicesDispatch'], [PaymentInvoicesDispatch]);
      }
      if (data.hasOwnProperty('ordersDispatch')) {
        obj['ordersDispatch'] = ApiClient.convertToType(data['ordersDispatch'], [PaymentOrdersDispatch]);
      }
      if (data.hasOwnProperty('totalAmount')) {
        obj['totalAmount'] = ApiClient.convertToType(data['totalAmount'], 'Number');
      }
      if (data.hasOwnProperty('isProcessed')) {
        obj['isProcessed'] = ApiClient.convertToType(data['isProcessed'], 'Boolean');
      }
    }
    return obj;
  }


  id = undefined;

  ownerId = undefined;

  date = undefined;

  mode = undefined;

  email = undefined;

  invoicesDispatch = undefined;

  ordersDispatch = undefined;

  totalAmount = undefined;

  isProcessed = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }

  getMode() {
    return this.mode;
  }

  setMode(mode) {
    this['mode'] = mode;
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    this['email'] = email;
  }

  getInvoicesDispatch() {
    return this.invoicesDispatch;
  }

  setInvoicesDispatch(invoicesDispatch) {
    this['invoicesDispatch'] = invoicesDispatch;
  }

  getOrdersDispatch() {
    return this.ordersDispatch;
  }

  setOrdersDispatch(ordersDispatch) {
    this['ordersDispatch'] = ordersDispatch;
  }

  getTotalAmount() {
    return this.totalAmount;
  }

  setTotalAmount(totalAmount) {
    this['totalAmount'] = totalAmount;
  }

  getIsProcessed() {
    return this.isProcessed;
  }

  setIsProcessed(isProcessed) {
    this['isProcessed'] = isProcessed;
  }


  static ModeEnum = {
    "cb": "cb",
    "paypal": "paypal",
    "cash": "cash",
    "cheque": "cheque",
    "transfer": "transfer",
    "account": "account"
  };

}