/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class MultiPricingDiscounts {
  constructor() {


  }

  static typeDescription() {
    return "MultiPricingDiscounts";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MultiPricingDiscounts();
      if (data.hasOwnProperty('rate')) {
        obj['rate'] = ApiClient.convertToType(data['rate'], 'Number');
      }
      if (data.hasOwnProperty('amount')) {
        obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
      }
    }
    return obj;
  }


  rate = undefined;

  amount = undefined;




  getRate() {
    return this.rate;
  }

  setRate(rate) {
    this['rate'] = rate;
  }

  getAmount() {
    return this.amount;
  }

  setAmount(amount) {
    this['amount'] = amount;
  }


}