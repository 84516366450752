import React from "react";
import NavLink from "Elements/NavLink";

import "./index.css";

const Tabs = ({ tabs = [], className }) =>
<div className={"nav nav-fill nav-tabs " + className}>
    {tabs.map((tab, i) =>
  <NavLink
    to={tab.to || ""}
    onClick={tab.onClick}
    key={i}
    className={"nav-link " + (tab.className || "")}
    activeClassName={tab.activeClassName}
    exact={tab.exact}>

        <span>{tab.title}</span>
      </NavLink>
  )}
  </div>;


export default Tabs;