import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Section from "marketing/components/Section";
import { NavLink } from "Elements";
import NewsAndTweets from "marketing/tiles/NewsAndTweets";
import YoutubeTile from "marketing/components/YoutubeTile";
import Content from "components/ContentEditor/Content";
import { withRequests } from "Requests";
import { ArticleDeck } from "marketing/components/MoreArticle";
import FormContact from "marketing/components/FormContact";

import CmsActions from "Cms/actions";

class Press extends React.Component {
  state = {};
  componentDidMount() {
    const { props } = this;
    const language = this.props.intl.locale;
    props.addRequest("blog/getArticles", CmsActions.getBlog(language));
  }

  render() {
    const { props, state } = this;
    const { data = {}, page = {} } = props;

    // const { sendMessage } = state;

    const articles = props.articles || [];
    console.log("press render", props);
    return (
      <div>
        <Section
          className="page_cover factoryCover lazyload"
          data-bg="/images/marketing/factory/cover.jpg">

          <div className="col-md-2 offset-md-5">
            <div
              className="product_page_cover_intro text-white d-flex justify-content-center"
              style={{
                letterSpacing: "0.5em"
              }}>

              <h1>{page.title}</h1>
            </div>
          </div>
          <div
            className="col-md-8 offset-md-2 text-center"
            style={{ fontSize: "1.3rem", fontWeight: 400 }}>

            <span className="text-white">{page.description}</span>
          </div>
        </Section>
        <Section className="py-5">
          <div className="marketing_section__title mb-4">
            {data.pressKitTitle}
          </div>
          <div className="row">
            <div
              className="col-md-8 offset-md-2"
              style={{ fontSize: "1.3rem", fontWeight: 400 }}>

              <Content blocks={data.pressKitText} />
            </div>
          </div>

          <div className="d-flex justify-content-center pt-4">
            <a
              target="_blank"
              href="/pdf/DP-EMSPROTO.pdf"
              className="d-flex flex-column press_downlaod text-black-60">

              <img src="/icons/download.svg" alt="" />
              {data.pressKitDownload}
            </a>
          </div>
        </Section>
        <hr className="w-100" />
        <Section className="py-5">
          <div className="marketing_section__title mb-4">
            {data.moreInfoTitle}
          </div>
          <div
            className="d-flex justify-content-center text-center"
            style={{ fontSize: "1.3rem", fontWeight: 400 }}>

            <Content blocks={data.moreInfoText} />
          </div>

          <div>
            <div className="row d-flex justify-content-center my-4">
              <div className="col-9">
                <FormContact form="press" />
              </div>
            </div>
          </div>
        </Section>
        {articles.length ?
        <Section
          className="bg-secondary text-white py-5"
          title={data.articlesTitle}>

            <div className="col-10 offset-1 text-center">
              <div
              className="mb-5"
              style={{ fontSize: "1.3rem", fontWeight: 400 }}>

                <Content blocks={data.articlesText} />
              </div>
              <ArticleDeck prefix="blog" articles={articles} />

              <NavLink to="/blog/press" className={"btn btn-primary mt-3"}>
                {data.articlesButton}
              </NavLink>
            </div>
          </Section> :
        null}
        {/* <section className="d-flex justify-content-center py-5" title>
          </section> */}
        <YoutubeTile title={data.videoTitle} videoUrl={data.videoUrl} />
        <NewsAndTweets {...props} />
      </div>);

  }
}

const mapStateToProps = (state) => {
  const { cms = {} } = state;
  const { blog = {} } = cms;
  const { categories = [], articles = [] } = blog;
  return {
    articles: articles.filter((a) => a.category === "press"),
    categories
  };
};

export default withRequests(connect(mapStateToProps)(injectIntl(Press)));