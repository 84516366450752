import React from "react";
import Section from "../components/Section";
import Image from "components/Image";

class OurCustomers extends React.Component {
  render() {
    const { props } = this;
    const { data = {} } = props;
    const { images = [], title } = data;
    if (!images.length) {
      return null;
    }

    return (
      <Section title={title} className="our_customers">
        <div
          className="d-flex flex-wrap justify-content-around my-5"
          id="customers">

          {images.map((image, i) => {
            return (
              <div
                key={i}
                className="flex-fill flex-shrink-0 flex-grow-0 px-3 my-3 our_customers__customer d-flex align-items-center justify-content-center">

                <Image image={image} sizes="320px" />
              </div>);

          })}
        </div>
      </Section>);

  }
}

export default OurCustomers;