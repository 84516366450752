import { combineReducers } from "redux";
import { update } from "utils/updateArray";

const specs = (state = [], action = {}) => {
  return (function (state, action) {
    if (action.type === "AUTH_LOGOUT") {
      return [];
    }
    if (action.type === "auth/user/GET_SPECS") {
      return (action.data || []).filter((s) => s.type === "pcb");
    }
    if (action.type === "pcb/specs/GET_ALL") {
      return action.data || [];
    }
    if (action.type === "pcb/specs/SET") {
      return update(state, action.data);
    }
    if (action.type === "pcb/specs/DELETE") {
      return state.filter((s) => s.id !== action.specId);
    }
    return state;
  })(state, action);
};

const quotations = (state = [], action = {}) => {
  return (function (state, action) {
    if (action.type === "AUTH_LOGOUT") {
      return [];
    }
    if (action.type === "auth/user/GET_QUOTATIONS") {
      return action.data.filter((s) => s.type === "pcb");
    }
    if (action.type === "pcb/quotations/GET_ALL") {
      return action.data || [];
    }
    if (action.type === "pcb/quotations/SET") {
      return update(state, action.data);
    }
    if (action.type === "pcb/quotations/DELETE") {
      return state.filter((s) => s.id !== action.quotationId);
    }
    return state;
  })(state, action).map((quotation) =>
  Object.assign({ type: "pcb" }, quotation)
  );
};

export default combineReducers({
  specs,
  quotations
});

// const trackingId = () =>
//   parseInt(Math.random() * 100) + "AA" + parseInt(Math.random() * 100);