/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import DeliveryParcelContent from './DeliveryParcelContent';

export default class DeliveryParcel {
  constructor() {


  }

  static typeDescription() {
    return "DeliveryParcel";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DeliveryParcel();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('length')) {
        obj['length'] = ApiClient.convertToType(data['length'], 'Number');
      }
      if (data.hasOwnProperty('width')) {
        obj['width'] = ApiClient.convertToType(data['width'], 'Number');
      }
      if (data.hasOwnProperty('height')) {
        obj['height'] = ApiClient.convertToType(data['height'], 'Number');
      }
      if (data.hasOwnProperty('mass')) {
        obj['mass'] = ApiClient.convertToType(data['mass'], 'Number');
      }
      if (data.hasOwnProperty('trackingId')) {
        obj['trackingId'] = ApiClient.convertToType(data['trackingId'], 'String');
      }
      if (data.hasOwnProperty('trackingUrl')) {
        obj['trackingUrl'] = ApiClient.convertToType(data['trackingUrl'], 'String');
      }
      if (data.hasOwnProperty('content')) {
        obj['content'] = ApiClient.convertToType(data['content'], [DeliveryParcelContent]);
      }
    }
    return obj;
  }


  id = undefined;

  length = undefined;

  width = undefined;

  height = undefined;

  mass = undefined;

  trackingId = undefined;

  trackingUrl = undefined;

  content = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getLength() {
    return this.length;
  }

  setLength(length) {
    this['length'] = length;
  }

  getWidth() {
    return this.width;
  }

  setWidth(width) {
    this['width'] = width;
  }

  getHeight() {
    return this.height;
  }

  setHeight(height) {
    this['height'] = height;
  }

  getMass() {
    return this.mass;
  }

  setMass(mass) {
    this['mass'] = mass;
  }

  getTrackingId() {
    return this.trackingId;
  }

  setTrackingId(trackingId) {
    this['trackingId'] = trackingId;
  }

  getTrackingUrl() {
    return this.trackingUrl;
  }

  setTrackingUrl(trackingUrl) {
    this['trackingUrl'] = trackingUrl;
  }

  getContent() {
    return this.content;
  }

  setContent(content) {
    this['content'] = content;
  }


}