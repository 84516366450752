import React from "react";
import FormControl from "./FormControl";

class NumberInput extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = this.initState(props);
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    const { value = 0 } = props;
    const { value: oValue = 0 } = oProps;
    if (isNaN(value) && isNaN(oValue)) {
      return;
    }
    if (value !== oValue) {
      this.setState(this.initState());
    }
  }

  initState(props = this.props) {
    const { value = 0, fixed = 3 } = props;
    return {
      value: Math.round(value * Math.pow(10, fixed)) / Math.pow(10, fixed)
    };
  }

  render() {
    const { props, state } = this;
    const { placeholder = "", symbol, fixed = 3 } = props;
    const { value = "" } = state;
    console.log("NumberInput render", props, state);
    return (
      <FormControl
        {...props}
        placeholder={placeholder + (symbol ? " (" + symbol + ")" : "")}
        value={value}
        onChange={(e) => this.setState({ value: e.target.value })}
        onBlur={(e) => {
          const res =
          Math.round(parseFloat(e.target.value) * Math.pow(10, fixed)) /
          Math.pow(10, fixed);
          props.onChange(res);
        }}
        type="text" />);


  }
}

export default NumberInput;