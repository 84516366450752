/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ManufacturingOperationOperationConstraints {
  constructor(operationId, type) {
    if (operationId) {
      this['operationId'] = operationId;
    }
    if (type) {
      this['type'] = type;
    }


  }

  static typeDescription() {
    return "ManufacturingOperationOperationConstraints";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingOperationOperationConstraints();
      if (data.hasOwnProperty('operationId')) {
        obj['operationId'] = ApiClient.convertToType(data['operationId'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('offsetSeconds')) {
        obj['offsetSeconds'] = ApiClient.convertToType(data['offsetSeconds'], 'Number');
      }
    }
    return obj;
  }


  operationId = "";

  type = "BeginBegin";

  offsetSeconds = undefined;




  getOperationId() {
    return this.operationId;
  }

  setOperationId(operationId) {
    this['operationId'] = operationId;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getOffsetSeconds() {
    return this.offsetSeconds;
  }

  setOffsetSeconds(offsetSeconds) {
    this['offsetSeconds'] = offsetSeconds;
  }


  static TypeEnum = {
    "BeginBegin": "BeginBegin",
    "BeginEnd": "BeginEnd",
    "EndEnd": "EndEnd",
    "EndBegin": "EndBegin"
  };

}