/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class AnomalyError {
  constructor() {


  }

  static typeDescription() {
    return "AnomalyError";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AnomalyError();
      if (data.hasOwnProperty('messageId')) {
        obj['messageId'] = ApiClient.convertToType(data['messageId'], 'String');
      }
      if (data.hasOwnProperty('message')) {
        obj['message'] = ApiClient.convertToType(data['message'], 'String');
      }
    }
    return obj;
  }


  messageId = undefined;

  message = undefined;




  getMessageId() {
    return this.messageId;
  }

  setMessageId(messageId) {
    this['messageId'] = messageId;
  }

  getMessage() {
    return this.message;
  }

  setMessage(message) {
    this['message'] = message;
  }


}