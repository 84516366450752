import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import cmsActions from "Cms/actions";
import metaTags from "utils/metaTags";
import Loading from "components/Loading";
// import Content from "components/ContentEditor/Content";
import StructuredPage from "components/StructuredPage";

class Page extends React.Component {
  componentDidMount() {
    this.getPage();
  }
  componentDidUpdate(oProps) {
    console.log("Page didUpdate", this.props, oProps);
    if (
    oProps.id !== this.props.id ||
    oProps.intl.locale !== this.props.intl.locale)
    {
      this.getPage();
    }
  }
  getPage() {
    const { props } = this;
    const { id } = props;
    cmsActions.getPage(id, props.intl.locale);
  }
  render() {
    const { props } = this;
    const { pages = [], id, request = {} } = props;
    const page = pages.find((p) => p.id === id) || {};
    metaTags.set(page);
    const { content = [] } = page;
    console.log("Page render", page);
    return request.status === "pending" ?
    <Loading /> :

    <StructuredPage data={page} />;

    // return content.map((block, i) => {
    //   const { type, data = {} } = block;
    //   const component = components[type];

    //   if (!component) {
    //     return null;
    //   }

    //   return React.createElement(component, {
    //     data,
    //     schema,
    //     onChange: (data) =>
    //       this.setPage({
    //         content: updateIndex(
    //           content,
    //           Object.assign({}, block, { data }),
    //           i
    //         ),
    //       }),
    //   });
    // });
    // return (
    //   <div
    //     style={{ paddingTop: 134, paddingBottom: 120 }}
    //     className="container"
    //   >
    //     <h2>{page.title}</h2>
    //     <div className="cms_content my-5">
    //       <Content blocks={page.content} />
    //     </div>
    //   </div>
    // );
  }
}

const mapStateToProps = (state) => {
  return {
    pages: state.cms.pages,
    request: state.requests.find((r) => r.id === "cms/pages/get_page")
  };
};

export default injectIntl(connect(mapStateToProps)(Page));