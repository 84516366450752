/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class SupplyReport {
  constructor() {


  }

  static typeDescription() {
    return "SupplyReport";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SupplyReport();
      if (data.hasOwnProperty('url')) {
        obj['url'] = ApiClient.convertToType(data['url'], 'String');
      }
    }
    return obj;
  }


  url = undefined;




  getUrl() {
    return this.url;
  }

  setUrl(url) {
    this['url'] = url;
  }


}