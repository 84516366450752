/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Quotations {
  constructor(id, type) {
    if (id) {
      this['id'] = id;
    }
    if (type) {
      this['type'] = type;
    }


  }

  static typeDescription() {
    return "Quotations";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Quotations();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
    }
    return obj;
  }


  id = undefined;

  type = "assembly";




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }


  static TypeEnum = {
    "assembly": "assembly",
    "coating": "coating",
    "dfm": "dfm",
    "enclosure": "enclosure",
    "engineering": "engineering",
    "pcb": "pcb",
    "pcba": "pcba",
    "pcbDesign": "pcbDesign",
    "rework": "rework",
    "supply": "supply",
    "wiring": "wiring",
    "xray": "xray"
  };

}