import React from "react";
import Section from "marketing/components/Section";

const Page = (props) => {
  const { title, videoUrl } = props;
  if (!videoUrl) {
    return null;
  }
  const url = videoUrl.endsWith("?rel=0") ? videoUrl : videoUrl + "?rel=0";
  return (
    <Section className="d-flex pb-0" title={title}>
      <div className="d-flex align-items-center flex-column">
        <iframe
          className="w-100"
          // width="100%"
          height="500"
          src={url}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>
    </Section>);

};

export default Page;