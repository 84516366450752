/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Property from './Property';

export default class ComponentFilterRequestProperties {
  constructor(value, comparison) {
    this['value'] = value || new Property();
    if (comparison) {
      this['comparison'] = comparison;
    }


  }

  static typeDescription() {
    return "ComponentFilterRequestProperties";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ComponentFilterRequestProperties();
      if (data.hasOwnProperty('value')) {
        obj['value'] = Property.constructFromObject(data['value']);
      }
      if (data.hasOwnProperty('comparison')) {
        obj['comparison'] = ApiClient.convertToType(data['comparison'], 'String');
      }
    }
    return obj;
  }


  value = undefined;

  comparison = "equal";




  getValue() {
    return this.value;
  }

  setValue(value) {
    this['value'] = value;
  }

  getComparison() {
    return this.comparison;
  }

  setComparison(comparison) {
    this['comparison'] = comparison;
  }


  static ComparisonEnum = {
    "equal": "equal",
    "notEqual": "notEqual",
    "lower": "lower",
    "greater": "greater",
    "lowerOrEqual": "lowerOrEqual",
    "greaterOrEqual": "greaterOrEqual",
    "contain": "contain",
    "match": "match",
    "noValue": "noValue",
    "anyValue": "anyValue"
  };

}