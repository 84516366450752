/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Address from './Address';
import Pricing from './Pricing';
import ProductionProgress from './ProductionProgress';
import QuotationLine from './QuotationLine';

export default class CoatingQuotation {
  constructor() {


  }

  static typeDescription() {
    return "CoatingQuotation";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CoatingQuotation();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('specId')) {
        obj['specId'] = ApiClient.convertToType(data['specId'], 'String');
      }
      if (data.hasOwnProperty('trackingId')) {
        obj['trackingId'] = ApiClient.convertToType(data['trackingId'], 'String');
      }
      if (data.hasOwnProperty('quantity')) {
        obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
      }
      if (data.hasOwnProperty('durationDays')) {
        obj['durationDays'] = ApiClient.convertToType(data['durationDays'], 'Number');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('validationDate')) {
        obj['validationDate'] = ApiClient.convertToType(data['validationDate'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('ownerName')) {
        obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
      }
      if (data.hasOwnProperty('isInProduction')) {
        obj['isInProduction'] = ApiClient.convertToType(data['isInProduction'], 'Boolean');
      }
      if (data.hasOwnProperty('isProductionHalted')) {
        obj['isProductionHalted'] = ApiClient.convertToType(data['isProductionHalted'], 'Boolean');
      }
      if (data.hasOwnProperty('productionHaltDate')) {
        obj['productionHaltDate'] = ApiClient.convertToType(data['productionHaltDate'], 'String');
      }
      if (data.hasOwnProperty('isWaitingForAction')) {
        obj['isWaitingForAction'] = ApiClient.convertToType(data['isWaitingForAction'], 'Boolean');
      }
      if (data.hasOwnProperty('orderId')) {
        obj['orderId'] = ApiClient.convertToType(data['orderId'], 'String');
      }
      if (data.hasOwnProperty('isValid')) {
        obj['isValid'] = ApiClient.convertToType(data['isValid'], 'Boolean');
      }
      if (data.hasOwnProperty('accessPermission')) {
        obj['accessPermission'] = ApiClient.convertToType(data['accessPermission'], 'String');
      }
      if (data.hasOwnProperty('editionAllowed')) {
        obj['editionAllowed'] = ApiClient.convertToType(data['editionAllowed'], 'Boolean');
      }
      if (data.hasOwnProperty('isPriced')) {
        obj['isPriced'] = ApiClient.convertToType(data['isPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('isManuallyPriced')) {
        obj['isManuallyPriced'] = ApiClient.convertToType(data['isManuallyPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('isNoDeliveryPriced')) {
        obj['isNoDeliveryPriced'] = ApiClient.convertToType(data['isNoDeliveryPriced'], 'Boolean');
      }
      if (data.hasOwnProperty('isPricingDone')) {
        obj['isPricingDone'] = ApiClient.convertToType(data['isPricingDone'], 'Boolean');
      }
      if (data.hasOwnProperty('isNoDeliveryPricingDone')) {
        obj['isNoDeliveryPricingDone'] = ApiClient.convertToType(data['isNoDeliveryPricingDone'], 'Boolean');
      }
      if (data.hasOwnProperty('isAutoPricingDone')) {
        obj['isAutoPricingDone'] = ApiClient.convertToType(data['isAutoPricingDone'], 'Boolean');
      }
      if (data.hasOwnProperty('isNoDeliveryAutoPricingDone')) {
        obj['isNoDeliveryAutoPricingDone'] = ApiClient.convertToType(data['isNoDeliveryAutoPricingDone'], 'Boolean');
      }
      if (data.hasOwnProperty('totalPriceTaxesExcluded')) {
        obj['totalPriceTaxesExcluded'] = ApiClient.convertToType(data['totalPriceTaxesExcluded'], 'Number');
      }
      if (data.hasOwnProperty('totalPriceTaxesIncluded')) {
        obj['totalPriceTaxesIncluded'] = ApiClient.convertToType(data['totalPriceTaxesIncluded'], 'Number');
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = Pricing.constructFromObject(data['price']);
      }
      if (data.hasOwnProperty('deliveryPrice')) {
        obj['deliveryPrice'] = Pricing.constructFromObject(data['deliveryPrice']);
      }
      if (data.hasOwnProperty('lines')) {
        obj['lines'] = ApiClient.convertToType(data['lines'], [QuotationLine]);
      }
      if (data.hasOwnProperty('isPublic')) {
        obj['isPublic'] = ApiClient.convertToType(data['isPublic'], 'Boolean');
      }
      if (data.hasOwnProperty('isReadOnly')) {
        obj['isReadOnly'] = ApiClient.convertToType(data['isReadOnly'], 'Boolean');
      }
      if (data.hasOwnProperty('deliveryAddress')) {
        obj['deliveryAddress'] = Address.constructFromObject(data['deliveryAddress']);
      }
      if (data.hasOwnProperty('deliveryMode')) {
        obj['deliveryMode'] = ApiClient.convertToType(data['deliveryMode'], 'String');
      }
      if (data.hasOwnProperty('comments')) {
        obj['comments'] = ApiClient.convertToType(data['comments'], 'String');
      }
      if (data.hasOwnProperty('privateComments')) {
        obj['privateComments'] = ApiClient.convertToType(data['privateComments'], 'String');
      }
      if (data.hasOwnProperty('files')) {
        obj['files'] = ApiClient.convertToType(data['files'], ['String']);
      }
      if (data.hasOwnProperty('privateFiles')) {
        obj['privateFiles'] = ApiClient.convertToType(data['privateFiles'], ['String']);
      }
      if (data.hasOwnProperty('expectedExpeditionDate')) {
        obj['expectedExpeditionDate'] = ApiClient.convertToType(data['expectedExpeditionDate'], 'Date');
      }
      if (data.hasOwnProperty('productionStartDay')) {
        obj['productionStartDay'] = ApiClient.convertToType(data['productionStartDay'], 'Date');
      }
      if (data.hasOwnProperty('nbWorkingDays')) {
        obj['nbWorkingDays'] = ApiClient.convertToType(data['nbWorkingDays'], 'Number');
      }
      if (data.hasOwnProperty('quoteIds')) {
        obj['quoteIds'] = ApiClient.convertToType(data['quoteIds'], ['String']);
      }
      if (data.hasOwnProperty('allQuoteIds')) {
        obj['allQuoteIds'] = ApiClient.convertToType(data['allQuoteIds'], ['String']);
      }
      if (data.hasOwnProperty('productionProgress')) {
        obj['productionProgress'] = ProductionProgress.constructFromObject(data['productionProgress']);
      }
      if (data.hasOwnProperty('manualPricingDate')) {
        obj['manualPricingDate'] = ApiClient.convertToType(data['manualPricingDate'], 'String');
      }
      if (data.hasOwnProperty('manualPricingValidityDays')) {
        obj['manualPricingValidityDays'] = ApiClient.convertToType(data['manualPricingValidityDays'], 'Number');
      }
      if (data.hasOwnProperty('stockIsActive')) {
        obj['stockIsActive'] = ApiClient.convertToType(data['stockIsActive'], 'Boolean');
      }
    }
    return obj;
  }


  id = undefined;

  specId = undefined;

  trackingId = undefined;

  quantity = undefined;

  durationDays = undefined;

  creationDate = undefined;

  validationDate = undefined;

  ownerId = undefined;

  ownerName = undefined;

  isInProduction = undefined;

  isProductionHalted = undefined;

  productionHaltDate = undefined;

  isWaitingForAction = undefined;

  orderId = undefined;

  isValid = undefined;

  accessPermission = undefined;

  editionAllowed = undefined;

  isPriced = undefined;

  isManuallyPriced = undefined;

  isNoDeliveryPriced = undefined;

  isPricingDone = undefined;

  isNoDeliveryPricingDone = undefined;

  isAutoPricingDone = undefined;

  isNoDeliveryAutoPricingDone = undefined;

  totalPriceTaxesExcluded = undefined;

  totalPriceTaxesIncluded = undefined;

  price = undefined;

  deliveryPrice = undefined;

  lines = undefined;

  isPublic = undefined;

  isReadOnly = undefined;

  deliveryAddress = undefined;

  deliveryMode = undefined;

  comments = undefined;

  privateComments = undefined;

  files = undefined;

  privateFiles = undefined;

  expectedExpeditionDate = undefined;

  productionStartDay = undefined;

  nbWorkingDays = undefined;

  quoteIds = undefined;

  allQuoteIds = undefined;

  productionProgress = undefined;

  manualPricingDate = undefined;

  manualPricingValidityDays = undefined;

  stockIsActive = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getSpecId() {
    return this.specId;
  }

  setSpecId(specId) {
    this['specId'] = specId;
  }

  getTrackingId() {
    return this.trackingId;
  }

  setTrackingId(trackingId) {
    this['trackingId'] = trackingId;
  }

  getQuantity() {
    return this.quantity;
  }

  setQuantity(quantity) {
    this['quantity'] = quantity;
  }

  getDurationDays() {
    return this.durationDays;
  }

  setDurationDays(durationDays) {
    this['durationDays'] = durationDays;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getValidationDate() {
    return this.validationDate;
  }

  setValidationDate(validationDate) {
    this['validationDate'] = validationDate;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getOwnerName() {
    return this.ownerName;
  }

  setOwnerName(ownerName) {
    this['ownerName'] = ownerName;
  }

  getIsInProduction() {
    return this.isInProduction;
  }

  setIsInProduction(isInProduction) {
    this['isInProduction'] = isInProduction;
  }

  getIsProductionHalted() {
    return this.isProductionHalted;
  }

  setIsProductionHalted(isProductionHalted) {
    this['isProductionHalted'] = isProductionHalted;
  }

  getProductionHaltDate() {
    return this.productionHaltDate;
  }

  setProductionHaltDate(productionHaltDate) {
    this['productionHaltDate'] = productionHaltDate;
  }

  getIsWaitingForAction() {
    return this.isWaitingForAction;
  }

  setIsWaitingForAction(isWaitingForAction) {
    this['isWaitingForAction'] = isWaitingForAction;
  }

  getOrderId() {
    return this.orderId;
  }

  setOrderId(orderId) {
    this['orderId'] = orderId;
  }

  getIsValid() {
    return this.isValid;
  }

  setIsValid(isValid) {
    this['isValid'] = isValid;
  }

  getAccessPermission() {
    return this.accessPermission;
  }

  setAccessPermission(accessPermission) {
    this['accessPermission'] = accessPermission;
  }

  getEditionAllowed() {
    return this.editionAllowed;
  }

  setEditionAllowed(editionAllowed) {
    this['editionAllowed'] = editionAllowed;
  }

  getIsPriced() {
    return this.isPriced;
  }

  setIsPriced(isPriced) {
    this['isPriced'] = isPriced;
  }

  getIsManuallyPriced() {
    return this.isManuallyPriced;
  }

  setIsManuallyPriced(isManuallyPriced) {
    this['isManuallyPriced'] = isManuallyPriced;
  }

  getIsNoDeliveryPriced() {
    return this.isNoDeliveryPriced;
  }

  setIsNoDeliveryPriced(isNoDeliveryPriced) {
    this['isNoDeliveryPriced'] = isNoDeliveryPriced;
  }

  getIsPricingDone() {
    return this.isPricingDone;
  }

  setIsPricingDone(isPricingDone) {
    this['isPricingDone'] = isPricingDone;
  }

  getIsNoDeliveryPricingDone() {
    return this.isNoDeliveryPricingDone;
  }

  setIsNoDeliveryPricingDone(isNoDeliveryPricingDone) {
    this['isNoDeliveryPricingDone'] = isNoDeliveryPricingDone;
  }

  getIsAutoPricingDone() {
    return this.isAutoPricingDone;
  }

  setIsAutoPricingDone(isAutoPricingDone) {
    this['isAutoPricingDone'] = isAutoPricingDone;
  }

  getIsNoDeliveryAutoPricingDone() {
    return this.isNoDeliveryAutoPricingDone;
  }

  setIsNoDeliveryAutoPricingDone(isNoDeliveryAutoPricingDone) {
    this['isNoDeliveryAutoPricingDone'] = isNoDeliveryAutoPricingDone;
  }

  getTotalPriceTaxesExcluded() {
    return this.totalPriceTaxesExcluded;
  }

  setTotalPriceTaxesExcluded(totalPriceTaxesExcluded) {
    this['totalPriceTaxesExcluded'] = totalPriceTaxesExcluded;
  }

  getTotalPriceTaxesIncluded() {
    return this.totalPriceTaxesIncluded;
  }

  setTotalPriceTaxesIncluded(totalPriceTaxesIncluded) {
    this['totalPriceTaxesIncluded'] = totalPriceTaxesIncluded;
  }

  getPrice() {
    return this.price;
  }

  setPrice(price) {
    this['price'] = price;
  }

  getDeliveryPrice() {
    return this.deliveryPrice;
  }

  setDeliveryPrice(deliveryPrice) {
    this['deliveryPrice'] = deliveryPrice;
  }

  getLines() {
    return this.lines;
  }

  setLines(lines) {
    this['lines'] = lines;
  }

  getIsPublic() {
    return this.isPublic;
  }

  setIsPublic(isPublic) {
    this['isPublic'] = isPublic;
  }

  getIsReadOnly() {
    return this.isReadOnly;
  }

  setIsReadOnly(isReadOnly) {
    this['isReadOnly'] = isReadOnly;
  }

  getDeliveryAddress() {
    return this.deliveryAddress;
  }

  setDeliveryAddress(deliveryAddress) {
    this['deliveryAddress'] = deliveryAddress;
  }

  getDeliveryMode() {
    return this.deliveryMode;
  }

  setDeliveryMode(deliveryMode) {
    this['deliveryMode'] = deliveryMode;
  }

  getComments() {
    return this.comments;
  }

  setComments(comments) {
    this['comments'] = comments;
  }

  getPrivateComments() {
    return this.privateComments;
  }

  setPrivateComments(privateComments) {
    this['privateComments'] = privateComments;
  }

  getFiles() {
    return this.files;
  }

  setFiles(files) {
    this['files'] = files;
  }

  getPrivateFiles() {
    return this.privateFiles;
  }

  setPrivateFiles(privateFiles) {
    this['privateFiles'] = privateFiles;
  }

  getExpectedExpeditionDate() {
    return this.expectedExpeditionDate;
  }

  setExpectedExpeditionDate(expectedExpeditionDate) {
    this['expectedExpeditionDate'] = expectedExpeditionDate;
  }

  getProductionStartDay() {
    return this.productionStartDay;
  }

  setProductionStartDay(productionStartDay) {
    this['productionStartDay'] = productionStartDay;
  }

  getNbWorkingDays() {
    return this.nbWorkingDays;
  }

  setNbWorkingDays(nbWorkingDays) {
    this['nbWorkingDays'] = nbWorkingDays;
  }

  getQuoteIds() {
    return this.quoteIds;
  }

  setQuoteIds(quoteIds) {
    this['quoteIds'] = quoteIds;
  }

  getAllQuoteIds() {
    return this.allQuoteIds;
  }

  setAllQuoteIds(allQuoteIds) {
    this['allQuoteIds'] = allQuoteIds;
  }

  getProductionProgress() {
    return this.productionProgress;
  }

  setProductionProgress(productionProgress) {
    this['productionProgress'] = productionProgress;
  }

  getManualPricingDate() {
    return this.manualPricingDate;
  }

  setManualPricingDate(manualPricingDate) {
    this['manualPricingDate'] = manualPricingDate;
  }

  getManualPricingValidityDays() {
    return this.manualPricingValidityDays;
  }

  setManualPricingValidityDays(manualPricingValidityDays) {
    this['manualPricingValidityDays'] = manualPricingValidityDays;
  }

  getStockIsActive() {
    return this.stockIsActive;
  }

  setStockIsActive(stockIsActive) {
    this['stockIsActive'] = stockIsActive;
  }


  static AccessPermissionEnum = {
    "no_access": "no_access",
    "read_only": "read_only",
    "read_and_write": "read_and_write",
    "full": "full"
  };

  static DeliveryModeEnum = {
    "Transporter": "Transporter",
    "Catch": "Catch"
  };

}