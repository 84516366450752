/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Specification {
  constructor() {


  }

  static typeDescription() {
    return "Specification";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Specification();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('trackingId')) {
        obj['trackingId'] = ApiClient.convertToType(data['trackingId'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('comments')) {
        obj['comments'] = ApiClient.convertToType(data['comments'], 'String');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('ownerName')) {
        obj['ownerName'] = ApiClient.convertToType(data['ownerName'], 'String');
      }
      if (data.hasOwnProperty('customerId')) {
        obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
      }
      if (data.hasOwnProperty('isPublic')) {
        obj['isPublic'] = ApiClient.convertToType(data['isPublic'], 'Boolean');
      }
      if (data.hasOwnProperty('isReadOnly')) {
        obj['isReadOnly'] = ApiClient.convertToType(data['isReadOnly'], 'Boolean');
      }
      if (data.hasOwnProperty('isInProduction')) {
        obj['isInProduction'] = ApiClient.convertToType(data['isInProduction'], 'Boolean');
      }
      if (data.hasOwnProperty('accessPermission')) {
        obj['accessPermission'] = ApiClient.convertToType(data['accessPermission'], 'String');
      }
      if (data.hasOwnProperty('editionAllowed')) {
        obj['editionAllowed'] = ApiClient.convertToType(data['editionAllowed'], 'Boolean');
      }
      if (data.hasOwnProperty('categories')) {
        obj['categories'] = ApiClient.convertToType(data['categories'], [['String']]);
      }
    }
    return obj;
  }


  id = undefined;

  type = undefined;

  trackingId = undefined;

  name = undefined;

  reference = undefined;

  comments = undefined;

  creationDate = undefined;

  ownerId = undefined;

  ownerName = undefined;

  customerId = undefined;

  isPublic = undefined;

  isReadOnly = undefined;

  isInProduction = undefined;

  accessPermission = undefined;

  editionAllowed = undefined;

  categories = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getTrackingId() {
    return this.trackingId;
  }

  setTrackingId(trackingId) {
    this['trackingId'] = trackingId;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getComments() {
    return this.comments;
  }

  setComments(comments) {
    this['comments'] = comments;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getOwnerName() {
    return this.ownerName;
  }

  setOwnerName(ownerName) {
    this['ownerName'] = ownerName;
  }

  getCustomerId() {
    return this.customerId;
  }

  setCustomerId(customerId) {
    this['customerId'] = customerId;
  }

  getIsPublic() {
    return this.isPublic;
  }

  setIsPublic(isPublic) {
    this['isPublic'] = isPublic;
  }

  getIsReadOnly() {
    return this.isReadOnly;
  }

  setIsReadOnly(isReadOnly) {
    this['isReadOnly'] = isReadOnly;
  }

  getIsInProduction() {
    return this.isInProduction;
  }

  setIsInProduction(isInProduction) {
    this['isInProduction'] = isInProduction;
  }

  getAccessPermission() {
    return this.accessPermission;
  }

  setAccessPermission(accessPermission) {
    this['accessPermission'] = accessPermission;
  }

  getEditionAllowed() {
    return this.editionAllowed;
  }

  setEditionAllowed(editionAllowed) {
    this['editionAllowed'] = editionAllowed;
  }

  getCategories() {
    return this.categories;
  }

  setCategories(categories) {
    this['categories'] = categories;
  }


  static TypeEnum = {
    "assembly": "assembly",
    "coating": "coating",
    "dfm": "dfm",
    "enclosure": "enclosure",
    "engineering": "engineering",
    "pcb": "pcb",
    "pcba": "pcba",
    "pcbDesign": "pcbDesign",
    "rework": "rework",
    "supply": "supply",
    "wiring": "wiring",
    "xray": "xray"
  };

  static AccessPermissionEnum = {
    "no_access": "no_access",
    "read_only": "read_only",
    "read_and_write": "read_and_write",
    "full": "full"
  };

}