/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PropertyValue from './PropertyValue';

export default class Property {
  constructor(name, values) {
    if (name) {
      this['name'] = name;
    }
    this['values'] = values || [];


  }

  static typeDescription() {
    return "Property";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Property();
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('values')) {
        obj['values'] = ApiClient.convertToType(data['values'], { 'String': PropertyValue });
      }
    }
    return obj;
  }


  name = "";

  values = {};




  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getValues() {
    return this.values;
  }

  setValues(values) {
    this['values'] = values;
  }


}