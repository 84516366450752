/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ComponentReference from './ComponentReference';
import CustomerStocksLocation from './CustomerStocksLocation';

export default class CustomerStocksMovement {
  constructor(reference, quantity, _date) {
    if (reference) {
      this['reference'] = reference;
    }
    if (quantity) {
      this['quantity'] = quantity;
    }
    if (_date) {
      this['date'] = _date;
    }


  }

  static typeDescription() {
    return "CustomerStocksMovement";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerStocksMovement();
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('quantity')) {
        obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
      if (data.hasOwnProperty('comment')) {
        obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
      }
      if (data.hasOwnProperty('addFrom')) {
        obj['addFrom'] = ComponentReference.constructFromObject(data['addFrom']);
      }
      if (data.hasOwnProperty('moveFrom')) {
        obj['moveFrom'] = CustomerStocksLocation.constructFromObject(data['moveFrom']);
      }
      if (data.hasOwnProperty('moveTo')) {
        obj['moveTo'] = CustomerStocksLocation.constructFromObject(data['moveTo']);
      }
      if (data.hasOwnProperty('removeTo')) {
        obj['removeTo'] = ApiClient.convertToType(data['removeTo'], 'String');
      }
    }
    return obj;
  }


  reference = "";

  quantity = 0.0;

  date = "";

  comment = undefined;

  addFrom = undefined;

  moveFrom = undefined;

  moveTo = undefined;

  removeTo = undefined;




  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getQuantity() {
    return this.quantity;
  }

  setQuantity(quantity) {
    this['quantity'] = quantity;
  }

  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }

  getComment() {
    return this.comment;
  }

  setComment(comment) {
    this['comment'] = comment;
  }

  getAddFrom() {
    return this.addFrom;
  }

  setAddFrom(addFrom) {
    this['addFrom'] = addFrom;
  }

  getMoveFrom() {
    return this.moveFrom;
  }

  setMoveFrom(moveFrom) {
    this['moveFrom'] = moveFrom;
  }

  getMoveTo() {
    return this.moveTo;
  }

  setMoveTo(moveTo) {
    this['moveTo'] = moveTo;
  }

  getRemoveTo() {
    return this.removeTo;
  }

  setRemoveTo(removeTo) {
    this['removeTo'] = removeTo;
  }


}