/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Cart {
  constructor() {


  }

  static typeDescription() {
    return "Cart";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Cart();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('ownerId')) {
        obj['ownerId'] = ApiClient.convertToType(data['ownerId'], 'String');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
    }
    return obj;
  }


  id = undefined;

  ownerId = undefined;

  creationDate = undefined;

  name = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getOwnerId() {
    return this.ownerId;
  }

  setOwnerId(ownerId) {
    this['ownerId'] = ownerId;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }


}