/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ObjectReference {
  constructor(type, id) {
    if (type) {
      this['type'] = type;
    }
    if (id) {
      this['id'] = id;
    }


  }

  static typeDescription() {
    return "ObjectReference";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ObjectReference();
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
    }
    return obj;
  }


  type = "anomaly";

  id = undefined;




  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }


  static TypeEnum = {
    "anomaly": "anomaly",
    "assembly_specification": "assembly_specification",
    "assembly_quotation": "assembly_quotation",
    "coating_specification": "coating_specification",
    "coating_quotation": "coating_quotation",
    "customer": "customer",
    "delivery": "delivery",
    "dfm_specification": "dfm_specification",
    "dfm_quotation": "dfm_quotation",
    "enclosure_specification": "enclosure_specification",
    "enclosure_quotation": "enclosure_quotation",
    "engineering_specification": "engineering_specification",
    "engineering_quotation": "engineering_quotation",
    "invoice": "invoice",
    "message": "message",
    "message_thread": "message_thread",
    "order": "order",
    "payment": "payment",
    "pcb_specification": "pcb_specification",
    "pcb_quotation": "pcb_quotation",
    "pcba_bom": "pcba_bom",
    "pcba_bom_line": "pcba_bom_line",
    "pcba_specification": "pcba_specification",
    "pcba_quotation": "pcba_quotation",
    "pcb_design_specification": "pcb_design_specification",
    "pcb_design_quotation": "pcb_design_quotation",
    "rework_specification": "rework_specification",
    "rework_quotation": "rework_quotation",
    "rework_bom": "rework_bom",
    "rework_bom_line": "rework_bom_line",
    "supply_specification": "supply_specification",
    "supply_quotation": "supply_quotation",
    "supply_bom": "supply_bom",
    "supply_bom_line": "supply_bom_line",
    "user": "user",
    "wiring_specification": "wiring_specification",
    "wiring_quotation": "wiring_quotation",
    "wiring_blade_terminal": "wiring_blade_terminal",
    "wiring_bill_of_material": "wiring_bill_of_material",
    "wiring_bill_of_material_line": "wiring_bill_of_material_line",
    "wiring_bullet_connector": "wiring_bullet_connector",
    "wiring_cable": "wiring_cable",
    "wiring_closed_end_terminal": "wiring_closed_end_terminal",
    "wiring_connector_cavity_plug": "wiring_connector_cavity_plug",
    "wiring_connector_cavity_seal": "wiring_connector_cavity_seal",
    "wiring_connector_shield_terminal": "wiring_connector_shield_terminal",
    "wiring_ferrule": "wiring_ferrule",
    "wiring_fixing": "wiring_fixing",
    "wiring_free_end": "wiring_free_end",
    "wiring_half_connector": "wiring_half_connector",
    "wiring_harness": "wiring_harness",
    "wiring_label": "wiring_label",
    "wiring_modular_half_connector": "wiring_modular_half_connector",
    "wiring_path": "wiring_path",
    "wiring_path_junction": "wiring_path_junction",
    "wiring_ring_terminal": "wiring_ring_terminal",
    "wiring_shielding": "wiring_shielding",
    "wiring_sleeving": "wiring_sleeving",
    "wiring_spad_terminal": "wiring_spad_terminal",
    "wiring_splice": "wiring_splice",
    "wiring_tab_terminal": "wiring_tab_terminal",
    "wiring_wire": "wiring_wire",
    "wiring_wire_contact": "wiring_wire_contact",
    "wiring_wire_terminal": "wiring_wire_terminal",
    "xray_specification": "xray_specification",
    "xray_object_type": "xray_object_type",
    "xray_2D_view": "xray_2D_view",
    "xray_3D_view": "xray_3D_view",
    "xray_live_session": "xray_live_session",
    "xray_2D_target": "xray_2D_target",
    "xray_3D_target": "xray_3D_target",
    "xray_quotation": "xray_quotation",
    "xray_target_device": "xray_target_device",
    "xray_2D_shooting": "xray_2D_shooting",
    "xray_3D_shooting": "xray_3D_shooting",
    "xray_live_shooting": "xray_live_shooting"
  };

}