/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AssemblyQuotation from '../model/AssemblyQuotation';
import CoatingQuotation from '../model/CoatingQuotation';
import DfmQuotation from '../model/DfmQuotation';
import EnclosureQuotation from '../model/EnclosureQuotation';
import EngineeringQuotation from '../model/EngineeringQuotation';
import Error from '../model/Error';
import ManufacturingProcess from '../model/ManufacturingProcess';
import ObjectReference from '../model/ObjectReference';
import PaymentSurcharge from '../model/PaymentSurcharge';
import PcbDesignQuotation from '../model/PcbDesignQuotation';
import PcbQuotation from '../model/PcbQuotation';
import PcbaQuotation from '../model/PcbaQuotation';
import Quotation from '../model/Quotation';
import Quote from '../model/Quote';
import Quoting from '../model/Quoting';
import ReworkQuotation from '../model/ReworkQuotation';
import SetQuoteContacts from '../model/SetQuoteContacts';
import SupplyQuotation from '../model/SupplyQuotation';
import WiringQuotation from '../model/WiringQuotation';
import XRayQuotation from '../model/XRayQuotation';


export default class QuoteApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  appendAssemblyQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendAssemblyQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendAssemblyQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/assembly/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendAssemblyQuotationToQuote(quoteId, quotationId) {
    return this.appendAssemblyQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendCoatingQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendCoatingQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendCoatingQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/coating/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendCoatingQuotationToQuote(quoteId, quotationId) {
    return this.appendCoatingQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendDfmQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendDfmQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendDfmQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/dfm/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendDfmQuotationToQuote(quoteId, quotationId) {
    return this.appendDfmQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendEnclosureQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendEnclosureQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendEnclosureQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/enclosure/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendEnclosureQuotationToQuote(quoteId, quotationId) {
    return this.appendEnclosureQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendEngineeringQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendEngineeringQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendEngineeringQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/engineering/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendEngineeringQuotationToQuote(quoteId, quotationId) {
    return this.appendEngineeringQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendPCBAQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendPCBAQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendPCBAQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcba/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendPCBAQuotationToQuote(quoteId, quotationId) {
    return this.appendPCBAQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendPcbDesignQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendPcbDesignQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendPcbDesignQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcbDesign/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendPcbDesignQuotationToQuote(quoteId, quotationId) {
    return this.appendPcbDesignQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendPcbQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendPcbQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendPcbQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcb/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendPcbQuotationToQuote(quoteId, quotationId) {
    return this.appendPcbQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendReworkQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendReworkQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendReworkQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/rework/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendReworkQuotationToQuote(quoteId, quotationId) {
    return this.appendReworkQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendSupplyQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendSupplyQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendSupplyQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/supply/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendSupplyQuotationToQuote(quoteId, quotationId) {
    return this.appendSupplyQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendWiringQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendWiringQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendWiringQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/wiring/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendWiringQuotationToQuote(quoteId, quotationId) {
    return this.appendWiringQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  appendXRayQuotationToQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling appendXRayQuotationToQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling appendXRayQuotationToQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/xray/quotation/{quotationId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  appendXRayQuotationToQuote(quoteId, quotationId) {
    return this.appendXRayQuotationToQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  cancelQuoteWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling cancelQuote");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote/{quoteId}/cancel', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  cancelQuote(quoteId) {
    return this.cancelQuoteWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createQuoteWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'purchasingCompanyId': opts['purchasingCompanyId']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createQuote(opts) {
    return this.createQuoteWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createQuoteSimulationWithHttpInfo(quotations, opts) {
    opts = opts || {};
    let postBody = quotations;

    // verify the required parameter 'quotations' is set
    if (quotations === undefined || quotations === null) {
      throw new Error("Missing the required parameter 'quotations' when calling createQuoteSimulation");
    }


    let pathParams = {
    };
    let queryParams = {
      'customerId': opts['customerId']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Quoting;

    return this.apiClient.callApi(
      '/quoting', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createQuoteSimulation(quotations, opts) {
    return this.createQuoteSimulationWithHttpInfo(quotations, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteQuoteWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling deleteQuote");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/quote/{quoteId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteQuote(quoteId) {
    return this.deleteQuoteWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuote");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote/{quoteId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuote(quoteId) {
    return this.getQuoteWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteAssemblyQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteAssemblyQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [AssemblyQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/assembly/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteAssemblyQuotations(quoteId) {
    return this.getQuoteAssemblyQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteCoatingQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteCoatingQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [CoatingQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/coating/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteCoatingQuotations(quoteId) {
    return this.getQuoteCoatingQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteDfmQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteDfmQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [DfmQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/dfm/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteDfmQuotations(quoteId) {
    return this.getQuoteDfmQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteEnclosureQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteEnclosureQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [EnclosureQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/enclosure/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteEnclosureQuotations(quoteId) {
    return this.getQuoteEnclosureQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteEngineeringQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteEngineeringQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [EngineeringQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/engineering/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteEngineeringQuotations(quoteId) {
    return this.getQuoteEngineeringQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteManufacturingWithHttpInfo(orderId) {
    let postBody = null;

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling getQuoteManufacturing");
    }


    let pathParams = {
      'orderId': orderId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [ManufacturingProcess];

    return this.apiClient.callApi(
      '/quote/{quoteId}/manufacturing', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteManufacturing(orderId) {
    return this.getQuoteManufacturingWithHttpInfo(orderId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuotePCBAQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuotePCBAQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PcbaQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcba/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuotePCBAQuotations(quoteId) {
    return this.getQuotePCBAQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuotePaymentSurchargesWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuotePaymentSurcharges");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PaymentSurcharge];

    return this.apiClient.callApi(
      '/quote/{quoteId}/paymentSurchages', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuotePaymentSurcharges(quoteId) {
    return this.getQuotePaymentSurchargesWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuotePcbDesignQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuotePcbDesignQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PcbDesignQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcbDesign/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuotePcbDesignQuotations(quoteId) {
    return this.getQuotePcbDesignQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuotePcbQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuotePcbQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PcbQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcb/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuotePcbQuotations(quoteId) {
    return this.getQuotePcbQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Quotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/quotations', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteQuotations(quoteId) {
    return this.getQuoteQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteReworkQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteReworkQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [ReworkQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/rework/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteReworkQuotations(quoteId) {
    return this.getQuoteReworkQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteSupplyQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteSupplyQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [SupplyQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/supply/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteSupplyQuotations(quoteId) {
    return this.getQuoteSupplyQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteWiringQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteWiringQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [WiringQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/wiring/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteWiringQuotations(quoteId) {
    return this.getQuoteWiringQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuoteXRayQuotationsWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling getQuoteXRayQuotations");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [XRayQuotation];

    return this.apiClient.callApi(
      '/quote/{quoteId}/xray/quotation', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuoteXRayQuotations(quoteId) {
    return this.getQuoteXRayQuotationsWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getQuotesWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'customerId': opts['customerId'],
      'creatorId': opts['creatorId'],
      'creationDateFrom': opts['creationDateFrom'],
      'creationDateUntil': opts['creationDateUntil'],
      'erpId': opts['erpId'],
      'erpReference': opts['erpReference'],
      'customerReference': opts['customerReference'],
      'isCancelled': opts['isCancelled'],
      'isExpired': opts['isExpired'],
      'isRegistered': opts['isRegistered'],
      'isOrdered': opts['isOrdered'],
      'isPriced': opts['isPriced'],
      'isValid': opts['isValid'],
      'isValidated': opts['isValidated']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Quote];

    return this.apiClient.callApi(
      '/quote', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getQuotes(opts) {
    return this.getQuotesWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  registerQuoteWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling registerQuote");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote/{quoteId}/register', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  registerQuote(quoteId) {
    return this.registerQuoteWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeAssemblyQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeAssemblyQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeAssemblyQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/assembly/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeAssemblyQuotationFromQuote(quoteId, quotationId) {
    return this.removeAssemblyQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeCoatingQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeCoatingQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeCoatingQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/coating/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeCoatingQuotationFromQuote(quoteId, quotationId) {
    return this.removeCoatingQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeDfmQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeDfmQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeDfmQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/dfm/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeDfmQuotationFromQuote(quoteId, quotationId) {
    return this.removeDfmQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeEnclosureQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeEnclosureQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeEnclosureQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/enclosure/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeEnclosureQuotationFromQuote(quoteId, quotationId) {
    return this.removeEnclosureQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeEngineeringQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeEngineeringQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeEngineeringQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/engineering/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeEngineeringQuotationFromQuote(quoteId, quotationId) {
    return this.removeEngineeringQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removePcbDesignQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removePcbDesignQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removePcbDesignQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcbDesign/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removePcbDesignQuotationFromQuote(quoteId, quotationId) {
    return this.removePcbDesignQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removePcbQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removePcbQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removePcbQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcb/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removePcbQuotationFromQuote(quoteId, quotationId) {
    return this.removePcbQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removePcbaQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removePcbaQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removePcbaQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/pcba/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removePcbaQuotationFromQuote(quoteId, quotationId) {
    return this.removePcbaQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeReworkQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeReworkQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeReworkQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/rework/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeReworkQuotationFromQuote(quoteId, quotationId) {
    return this.removeReworkQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeSupplyQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeSupplyQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeSupplyQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/supply/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeSupplyQuotationFromQuote(quoteId, quotationId) {
    return this.removeSupplyQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeWiringQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeWiringQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeWiringQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/wiring/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeWiringQuotationFromQuote(quoteId, quotationId) {
    return this.removeWiringQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  removeXRayQuotationFromQuoteWithHttpInfo(quoteId, quotationId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling removeXRayQuotationFromQuote");
    }

    // verify the required parameter 'quotationId' is set
    if (quotationId === undefined || quotationId === null) {
      throw new Error("Missing the required parameter 'quotationId' when calling removeXRayQuotationFromQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'quotationId': quotationId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/quote/{quoteId}/xray/quotation/{quotationId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  removeXRayQuotationFromQuote(quoteId, quotationId) {
    return this.removeXRayQuotationFromQuoteWithHttpInfo(quoteId, quotationId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setQuoteWithHttpInfo(quoteId, quote) {
    let postBody = quote;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling setQuote");
    }

    // verify the required parameter 'quote' is set
    if (quote === undefined || quote === null) {
      throw new Error("Missing the required parameter 'quote' when calling setQuote");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote/{quoteId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setQuote(quoteId, quote) {
    return this.setQuoteWithHttpInfo(quoteId, quote).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setQuoteContactsWithHttpInfo(quoteId, quoteContacts) {
    let postBody = quoteContacts;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling setQuoteContacts");
    }

    // verify the required parameter 'quoteContacts' is set
    if (quoteContacts === undefined || quoteContacts === null) {
      throw new Error("Missing the required parameter 'quoteContacts' when calling setQuoteContacts");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote/{quoteId}/contacts', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setQuoteContacts(quoteId, quoteContacts) {
    return this.setQuoteContactsWithHttpInfo(quoteId, quoteContacts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  transferQuoteWithHttpInfo(quoteId, customerId, opts) {
    opts = opts || {};
    let postBody = opts['finalCustomerId'];

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling transferQuote");
    }

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling transferQuote");
    }


    let pathParams = {
      'quoteId': quoteId,
      'customerId': customerId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['text/plain'];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote/{quoteId}/transferTo/{customerId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  transferQuote(quoteId, customerId, opts) {
    return this.transferQuoteWithHttpInfo(quoteId, customerId, opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  uncancelQuoteWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling uncancelQuote");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote/{quoteId}/cancel', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  uncancelQuote(quoteId) {
    return this.uncancelQuoteWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  unregisterQuoteWithHttpInfo(quoteId) {
    let postBody = null;

    // verify the required parameter 'quoteId' is set
    if (quoteId === undefined || quoteId === null) {
      throw new Error("Missing the required parameter 'quoteId' when calling unregisterQuote");
    }


    let pathParams = {
      'quoteId': quoteId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Quote;

    return this.apiClient.callApi(
      '/quote/{quoteId}/register', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  unregisterQuote(quoteId) {
    return this.unregisterQuoteWithHttpInfo(quoteId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}