/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PropertyDescription from './PropertyDescription';
import PropertyEncodingRestrictedValues from './PropertyEncodingRestrictedValues';

export default class PropertyEncoding {
  constructor(code, descriptions) {
    if (code) {
      this['code'] = code;
    }
    this['descriptions'] = descriptions || new PropertyDescription();


  }

  static typeDescription() {
    return "PropertyEncoding";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PropertyEncoding();
      if (data.hasOwnProperty('code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String');
      }
      if (data.hasOwnProperty('measureCodes')) {
        obj['measureCodes'] = ApiClient.convertToType(data['measureCodes'], ['String']);
      }
      if (data.hasOwnProperty('descriptions')) {
        obj['descriptions'] = PropertyDescription.constructFromObject(data['descriptions']);
      }
      if (data.hasOwnProperty('restrictedValues')) {
        obj['restrictedValues'] = ApiClient.convertToType(data['restrictedValues'], [PropertyEncodingRestrictedValues]);
      }
      if (data.hasOwnProperty('usedByCpm')) {
        obj['usedByCpm'] = ApiClient.convertToType(data['usedByCpm'], 'Boolean');
      }
    }
    return obj;
  }


  code = "";

  measureCodes = undefined;

  descriptions = undefined;

  restrictedValues = undefined;

  usedByCpm = undefined;




  getCode() {
    return this.code;
  }

  setCode(code) {
    this['code'] = code;
  }

  getMeasureCodes() {
    return this.measureCodes;
  }

  setMeasureCodes(measureCodes) {
    this['measureCodes'] = measureCodes;
  }

  getDescriptions() {
    return this.descriptions;
  }

  setDescriptions(descriptions) {
    this['descriptions'] = descriptions;
  }

  getRestrictedValues() {
    return this.restrictedValues;
  }

  setRestrictedValues(restrictedValues) {
    this['restrictedValues'] = restrictedValues;
  }

  getUsedByCpm() {
    return this.usedByCpm;
  }

  setUsedByCpm(usedByCpm) {
    this['usedByCpm'] = usedByCpm;
  }


}