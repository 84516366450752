import React from "react";
import FormControl from "./FormControl";

class FloatInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: Math.round((props.value || 0) * 100) / 100 + ""
    };
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    if (
    typeof props.value !== "undefined" &&
    !isNaN(props.value) &&
    props.value !== oProps.value)
    {
      this.initValue();
    }
  }
  initValue() {
    const { props } = this;
    this.setState({ value: Math.round((props.value || 0) * 100) / 100 + "" });
  }
  render() {
    const { props, state } = this;
    if (!props.disabled) console.log("FloatInputRender", props, state);
    return (
      <FormControl
        validationState={isNaN(state.value.replace(",", ".")) ? "error" : ""}
        value={state.value}
        onChange={(e) => this.setState({ value: e.target.value })}
        onBlur={(e) => {
          const res = parseFloat(state.value.replace(",", "."));
          return isNaN(res) ? this.initValue() : props.onChange(res);
        }}
        disabled={props.disabled} />);


  }
}

export default FloatInput;