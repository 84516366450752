/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Component from './Component';
import PriceRange from './PriceRange';
import WareHouseStock from './WareHouseStock';

export default class OfflineComponentSource {
  constructor(component, supplier, sku, expirationDate, minimumOrderQuantity, quantityOrderMultiplier, priceRanges, discountedPriceRanges) {
    this['component'] = component || new Component();
    if (supplier) {
      this['supplier'] = supplier;
    }
    if (sku) {
      this['sku'] = sku;
    }
    if (expirationDate) {
      this['expirationDate'] = expirationDate;
    }
    if (minimumOrderQuantity) {
      this['minimumOrderQuantity'] = minimumOrderQuantity;
    }
    if (quantityOrderMultiplier) {
      this['quantityOrderMultiplier'] = quantityOrderMultiplier;
    }
    this['priceRanges'] = priceRanges || [];
    this['discountedPriceRanges'] = discountedPriceRanges || [];


  }

  static typeDescription() {
    return "OfflineComponentSource";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OfflineComponentSource();
      if (data.hasOwnProperty('component')) {
        obj['component'] = Component.constructFromObject(data['component']);
      }
      if (data.hasOwnProperty('supplier')) {
        obj['supplier'] = ApiClient.convertToType(data['supplier'], 'String');
      }
      if (data.hasOwnProperty('sku')) {
        obj['sku'] = ApiClient.convertToType(data['sku'], 'String');
      }
      if (data.hasOwnProperty('internalId')) {
        obj['internalId'] = ApiClient.convertToType(data['internalId'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('category')) {
        obj['category'] = ApiClient.convertToType(data['category'], 'String');
      }
      if (data.hasOwnProperty('editionDate')) {
        obj['editionDate'] = ApiClient.convertToType(data['editionDate'], 'String');
      }
      if (data.hasOwnProperty('expirationDate')) {
        obj['expirationDate'] = ApiClient.convertToType(data['expirationDate'], 'String');
      }
      if (data.hasOwnProperty('url')) {
        obj['url'] = ApiClient.convertToType(data['url'], 'String');
      }
      if (data.hasOwnProperty('imageUrl')) {
        obj['imageUrl'] = ApiClient.convertToType(data['imageUrl'], 'String');
      }
      if (data.hasOwnProperty('datasheetUrl')) {
        obj['datasheetUrl'] = ApiClient.convertToType(data['datasheetUrl'], 'String');
      }
      if (data.hasOwnProperty('minimumOrderQuantity')) {
        obj['minimumOrderQuantity'] = ApiClient.convertToType(data['minimumOrderQuantity'], 'Number');
      }
      if (data.hasOwnProperty('quantityOrderMultiplier')) {
        obj['quantityOrderMultiplier'] = ApiClient.convertToType(data['quantityOrderMultiplier'], 'Number');
      }
      if (data.hasOwnProperty('packSize')) {
        obj['packSize'] = ApiClient.convertToType(data['packSize'], 'Number');
      }
      if (data.hasOwnProperty('availablePackagings')) {
        obj['availablePackagings'] = ApiClient.convertToType(data['availablePackagings'], ['String']);
      }
      if (data.hasOwnProperty('packaging')) {
        obj['packaging'] = ApiClient.convertToType(data['packaging'], 'String');
      }
      if (data.hasOwnProperty('reelSize')) {
        obj['reelSize'] = ApiClient.convertToType(data['reelSize'], 'Number');
      }
      if (data.hasOwnProperty('requireReeling')) {
        obj['requireReeling'] = ApiClient.convertToType(data['requireReeling'], 'Boolean');
      }
      if (data.hasOwnProperty('priceRanges')) {
        obj['priceRanges'] = ApiClient.convertToType(data['priceRanges'], [PriceRange]);
      }
      if (data.hasOwnProperty('discountedPriceRanges')) {
        obj['discountedPriceRanges'] = ApiClient.convertToType(data['discountedPriceRanges'], [PriceRange]);
      }
      if (data.hasOwnProperty('warehouseStocks')) {
        obj['warehouseStocks'] = ApiClient.convertToType(data['warehouseStocks'], [WareHouseStock]);
      }
      if (data.hasOwnProperty('totalStockQuantity')) {
        obj['totalStockQuantity'] = ApiClient.convertToType(data['totalStockQuantity'], 'Number');
      }
      if (data.hasOwnProperty('deliveryPriceByQuantityRanges')) {
        obj['deliveryPriceByQuantityRanges'] = ApiClient.convertToType(data['deliveryPriceByQuantityRanges'], [PriceRange]);
      }
      if (data.hasOwnProperty('deliveryPriceByTotalPriceRanges')) {
        obj['deliveryPriceByTotalPriceRanges'] = ApiClient.convertToType(data['deliveryPriceByTotalPriceRanges'], [PriceRange]);
      }
      if (data.hasOwnProperty('salesUnitDescription')) {
        obj['salesUnitDescription'] = ApiClient.convertToType(data['salesUnitDescription'], 'String');
      }
      if (data.hasOwnProperty('rollLength')) {
        obj['rollLength'] = ApiClient.convertToType(data['rollLength'], 'Number');
      }
      if (data.hasOwnProperty('mountingTechnology')) {
        obj['mountingTechnology'] = ApiClient.convertToType(data['mountingTechnology'], 'String');
      }
      if (data.hasOwnProperty('packageDescription')) {
        obj['packageDescription'] = ApiClient.convertToType(data['packageDescription'], 'String');
      }
      if (data.hasOwnProperty('weight')) {
        obj['weight'] = ApiClient.convertToType(data['weight'], 'Number');
      }
    }
    return obj;
  }


  component = undefined;

  supplier = "";

  sku = "";

  internalId = undefined;

  id = undefined;

  category = undefined;

  editionDate = undefined;

  expirationDate = "";

  url = undefined;

  imageUrl = undefined;

  datasheetUrl = undefined;

  minimumOrderQuantity = 0.0;

  quantityOrderMultiplier = 0.0;

  packSize = undefined;

  availablePackagings = undefined;

  packaging = undefined;

  reelSize = undefined;

  requireReeling = undefined;

  priceRanges = [];

  discountedPriceRanges = [];

  warehouseStocks = undefined;

  totalStockQuantity = undefined;

  deliveryPriceByQuantityRanges = undefined;

  deliveryPriceByTotalPriceRanges = undefined;

  salesUnitDescription = undefined;

  rollLength = undefined;

  mountingTechnology = undefined;

  packageDescription = undefined;

  weight = undefined;




  getComponent() {
    return this.component;
  }

  setComponent(component) {
    this['component'] = component;
  }

  getSupplier() {
    return this.supplier;
  }

  setSupplier(supplier) {
    this['supplier'] = supplier;
  }

  getSku() {
    return this.sku;
  }

  setSku(sku) {
    this['sku'] = sku;
  }

  getInternalId() {
    return this.internalId;
  }

  setInternalId(internalId) {
    this['internalId'] = internalId;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getCategory() {
    return this.category;
  }

  setCategory(category) {
    this['category'] = category;
  }

  getEditionDate() {
    return this.editionDate;
  }

  setEditionDate(editionDate) {
    this['editionDate'] = editionDate;
  }

  getExpirationDate() {
    return this.expirationDate;
  }

  setExpirationDate(expirationDate) {
    this['expirationDate'] = expirationDate;
  }

  getUrl() {
    return this.url;
  }

  setUrl(url) {
    this['url'] = url;
  }

  getImageUrl() {
    return this.imageUrl;
  }

  setImageUrl(imageUrl) {
    this['imageUrl'] = imageUrl;
  }

  getDatasheetUrl() {
    return this.datasheetUrl;
  }

  setDatasheetUrl(datasheetUrl) {
    this['datasheetUrl'] = datasheetUrl;
  }

  getMinimumOrderQuantity() {
    return this.minimumOrderQuantity;
  }

  setMinimumOrderQuantity(minimumOrderQuantity) {
    this['minimumOrderQuantity'] = minimumOrderQuantity;
  }

  getQuantityOrderMultiplier() {
    return this.quantityOrderMultiplier;
  }

  setQuantityOrderMultiplier(quantityOrderMultiplier) {
    this['quantityOrderMultiplier'] = quantityOrderMultiplier;
  }

  getPackSize() {
    return this.packSize;
  }

  setPackSize(packSize) {
    this['packSize'] = packSize;
  }

  getAvailablePackagings() {
    return this.availablePackagings;
  }

  setAvailablePackagings(availablePackagings) {
    this['availablePackagings'] = availablePackagings;
  }

  getPackaging() {
    return this.packaging;
  }

  setPackaging(packaging) {
    this['packaging'] = packaging;
  }

  getReelSize() {
    return this.reelSize;
  }

  setReelSize(reelSize) {
    this['reelSize'] = reelSize;
  }

  getRequireReeling() {
    return this.requireReeling;
  }

  setRequireReeling(requireReeling) {
    this['requireReeling'] = requireReeling;
  }

  getPriceRanges() {
    return this.priceRanges;
  }

  setPriceRanges(priceRanges) {
    this['priceRanges'] = priceRanges;
  }

  getDiscountedPriceRanges() {
    return this.discountedPriceRanges;
  }

  setDiscountedPriceRanges(discountedPriceRanges) {
    this['discountedPriceRanges'] = discountedPriceRanges;
  }

  getWarehouseStocks() {
    return this.warehouseStocks;
  }

  setWarehouseStocks(warehouseStocks) {
    this['warehouseStocks'] = warehouseStocks;
  }

  getTotalStockQuantity() {
    return this.totalStockQuantity;
  }

  setTotalStockQuantity(totalStockQuantity) {
    this['totalStockQuantity'] = totalStockQuantity;
  }

  getDeliveryPriceByQuantityRanges() {
    return this.deliveryPriceByQuantityRanges;
  }

  setDeliveryPriceByQuantityRanges(deliveryPriceByQuantityRanges) {
    this['deliveryPriceByQuantityRanges'] = deliveryPriceByQuantityRanges;
  }

  getDeliveryPriceByTotalPriceRanges() {
    return this.deliveryPriceByTotalPriceRanges;
  }

  setDeliveryPriceByTotalPriceRanges(deliveryPriceByTotalPriceRanges) {
    this['deliveryPriceByTotalPriceRanges'] = deliveryPriceByTotalPriceRanges;
  }

  getSalesUnitDescription() {
    return this.salesUnitDescription;
  }

  setSalesUnitDescription(salesUnitDescription) {
    this['salesUnitDescription'] = salesUnitDescription;
  }

  getRollLength() {
    return this.rollLength;
  }

  setRollLength(rollLength) {
    this['rollLength'] = rollLength;
  }

  getMountingTechnology() {
    return this.mountingTechnology;
  }

  setMountingTechnology(mountingTechnology) {
    this['mountingTechnology'] = mountingTechnology;
  }

  getPackageDescription() {
    return this.packageDescription;
  }

  setPackageDescription(packageDescription) {
    this['packageDescription'] = packageDescription;
  }

  getWeight() {
    return this.weight;
  }

  setWeight(weight) {
    this['weight'] = weight;
  }


  static AvailablePackagingsEnum = {
    "each": "each",
    "cut_tape": "cut_tape",
    "reel": "reel",
    "packet": "packet",
    "tube": "tube",
    "ammopack": "ammopack",
    "bulk": "bulk",
    "tray": "tray",
    "roll": "roll",
    "box": "box",
    "bag": "bag",
    "blister_card": "blister_card",
    "tape_and_box": "tape_and_box"
  };

  static PackagingEnum = {
    "each": "each",
    "cut_tape": "cut_tape",
    "reel": "reel",
    "packet": "packet",
    "tube": "tube",
    "ammopack": "ammopack",
    "bulk": "bulk",
    "tray": "tray",
    "roll": "roll",
    "box": "box",
    "bag": "bag",
    "blister_card": "blister_card",
    "tape_and_box": "tape_and_box"
  };

  static SalesUnitDescriptionEnum = {
    "unit": "unit",
    "m": "m",
    "m2": "m2",
    "m3": "m3",
    "l": "l",
    "kg": "kg"
  };

  static MountingTechnologyEnum = {
    "ThruHole": "ThruHole",
    "SMT": "SMT",
    "Pressfit": "Pressfit",
    "Screwing": "Screwing",
    "Riveting": "Riveting",
    "Sticking": "Sticking",
    "Clipping": "Clipping"
  };

}