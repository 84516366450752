/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PropertyDescription from './PropertyDescription';

export default class PropertyEncodingRestrictedValues {
  constructor(value, descriptions) {
    if (value) {
      this['value'] = value;
    }
    this['descriptions'] = descriptions || new PropertyDescription();


  }

  static typeDescription() {
    return "PropertyEncodingRestrictedValues";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PropertyEncodingRestrictedValues();
      if (data.hasOwnProperty('value')) {
        obj['value'] = ApiClient.convertToType(data['value'], 'String');
      }
      if (data.hasOwnProperty('descriptions')) {
        obj['descriptions'] = PropertyDescription.constructFromObject(data['descriptions']);
      }
    }
    return obj;
  }


  value = "";

  descriptions = undefined;




  getValue() {
    return this.value;
  }

  setValue(value) {
    this['value'] = value;
  }

  getDescriptions() {
    return this.descriptions;
  }

  setDescriptions(descriptions) {
    this['descriptions'] = descriptions;
  }


}