import React from "react";

import { InputGroup, InputGroupAddon } from "reactstrap";

const InputGroupComponent = (props) => {
  return <InputGroup {...props}>{props.children}</InputGroup>;
};

InputGroupComponent.Addon = (props) =>
<InputGroupAddon {...props}>{props.children}</InputGroupAddon>;


InputGroupComponent.Button = InputGroupComponent.Addon;

export default InputGroupComponent;