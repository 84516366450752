import React from "react";
import { connect } from "react-redux";

import cartActions from "./actions";

import products from "products";

const CartContext = React.createContext();

const Provider = CartContext.Provider;
const Consumer = CartContext.Consumer;

class ProviderComponent extends React.Component {
  render() {
    const { props } = this;
    return <Provider value={props.value}>{props.children}</Provider>;
  }
}

const mapStateToProps = (state) => {
  let cart = Object.assign({}, state.cart);
  const { auth = {} } = state;
  const { user = {}, customer = {} } = auth;
  const { collaborators = [] } = customer;
  const userCollaborator =
  collaborators.find((c) => c.userId === user.id) || {};
  const { canOrder } = userCollaborator;

  products.forEach((p) => {
    cart[p.slug] = cart[p.slug] || [];
  });

  cart.allQuotationIds = [
  ...(cart.pcba || []),
  ...(cart.wiring || []),
  ...(cart.pcb || []),
  ...(cart.coating || []),
  ...(cart.xray || []),
  ...(cart.enclosure || []),
  ...(cart.assembly || []),
  ...(cart.rework || []),
  ...(cart.engineering || [])];


  cart.pcbaQuotations = (cart.pcba || []).map((quotationId) => {
    let quotation = Object.assign(
      {},
      state.pcba.quotations.find((quotation) => quotation.id === quotationId),
      {
        id: quotationId
      }
    );
    if (quotation.specId) {
      quotation.spec =
      state.pcba.specs.find((s) => s.id === quotation.specId) || {};
      if (quotation.spec.ownerId) {
        quotation.spec.owner =
        state.users.find((u) => u.id === quotation.spec.ownerId) || {};
      }
    }
    return quotation;
  });

  cart.quotations = products.reduce((quotations, p) => {
    const type = p.slug;
    const data = state[type] || {};
    return [
    ...quotations,
    ...cart[type].map((id) => {
      return Object.assign(
        {},
        (data.quotations || []).find((q) => q.id === id),
        {
          id,
          type
        }
      );
    })];

  }, []);

  cart.totalHt = cart.quotations.reduce(
    (a, b) => a + b.totalPriceTaxesExcluded,
    0
  );

  cart.deliveryPrice = cart.quotations.reduce(
    (total, quotation) => total + (quotation.deliveryPrice || {}).value,
    0
  );

  cart.isValid =
  cart.quotations.length &&
  cart.quotations.reduce(
    (res, quotation) => res && quotation.isNoDeliveryPriced,
    true
  );

  let request = "done";
  let err = false;
  const requests = state.requests.filter((r) => r.id.startsWith("cart/"));
  requests.forEach((r) => {
    if (request === "done" && r.status === "err") {
      request = "err";
      err = request.err;
    }
    if (r.status === "pending") {
      request = "pending";
    }
  });

  return Object.assign({}, cart, {
    user,
    customer,
    canOrder,
    request,
    requests
  });
};

const mapDispatchToProps = (dispatch) => {
  return cartActions;
};

const mergeProps = (sProps, dProps, props) => {
  return {
    value: {
      cart: sProps,
      cartActions: dProps
    },
    children: props.children
  };
};

const ProviderHydrated = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ProviderComponent);
export { ProviderHydrated as CartContextProvider };
export { Consumer as CartContextConsumer };

export default Consumer;

export const withCart = (Component) => (props) =>

<Consumer>
      {(cartProps) => <Component {...Object.assign({}, props, cartProps)} />}
    </Consumer>;