import React from "react";

const PageTitle = ({ page = {} }) =>
<div className="container">
    <h2 className="marketing_section__title" style={{ paddingTop: 65 }}>
      {page.title}
    </h2>
  </div>;


export default PageTitle;