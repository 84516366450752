import ComponentsApi from "api/api/ComponentsApi";
// import localId from "utils/localId";
// import { getLocal, setLocal, removeLocal } from "utils/localStorage";
import { update } from "utils/updateArray";

const componentsApi = new ComponentsApi();

const actions = {
  getCategories: () => componentsApi.getComponentRootCategory(),
  getComponents: (filter) => {
    // console.log("getComponents", filter);
    filter.types = [...(filter.types || []), filter.type].filter((s) => s);
    delete filter.type;
    if (!filter.types.length) {
      delete filter.types;
    }
    filter.pagingStart = filter.pagingStart || 0;
    return componentsApi.getComponents(filter);
  },
  getComponent: (id) => componentsApi.getComponent(id),
  getComponentByMpn: (ref) =>
  componentsApi.getComponentByMpn(ref.origin, ref.reference),
  getAssociated: (componentId) =>
  componentsApi.getAssociatedComponents(componentId),
  getEncodings: () => componentsApi.getComponentPropertyEncodings(),
  getMeasures: () => componentsApi.getComponentPropertyMeasures(),
  getUnits: () => componentsApi.getComponentPropertyUnits(),
  getCrossRefs: (mpn) => componentsApi.crossReferenceCompatibles(mpn),

  createComponent: (data) => componentsApi.createComponent(data),
  saveComponent: (data) => componentsApi.setComponent(data),
  removeComponent: (component) => {
    const { id } = component;
    return componentsApi.deleteComponent(id);
  },
  // Associated
  associate: (id, associatedId) =>
  componentsApi.associateComponents(id, associatedId),
  dissociate: (id, associatedId) =>
  componentsApi.dissociateComponents(id, associatedId),
  // getLocalComponents: () => JSON.parse(getLocal("userLibrary")) || [],
  // saveLocalComponents: (components) =>
  //   setLocal("userLibrary", JSON.stringify(components)),
  getComponentPropertyValue: (
  component,
  propertyName,
  unitCode = "",
  value) =>
  {
    const { asComponent = {} } = component;
    const componentProperties = asComponent.componentProperties || {};
    const property = componentProperties[propertyName] || {};
    const { values = {} } = property;
    const unit = values[unitCode] || {};
    return (
      unit[value] ||
      unit.stringValue ||
      unit.integerValue ||
      unit.floatValue ||
      unit.booleanValue);

  }
};

export default actions;