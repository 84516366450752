/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class AuthenticationToken {
  constructor() {


  }

  static typeDescription() {
    return "AuthenticationToken";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AuthenticationToken();
      if (data.hasOwnProperty('api_key')) {
        obj['api_key'] = ApiClient.convertToType(data['api_key'], 'String');
      }
    }
    return obj;
  }


  api_key = undefined;




  getApiKey() {
    return this.api_key;
  }

  setApiKey(apiKey) {
    this['api_key'] = apiKey;
  }


}