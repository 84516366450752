/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PropertyMeasure {
  constructor(code, unitCode) {
    if (code) {
      this['code'] = code;
    }
    if (unitCode) {
      this['unitCode'] = unitCode;
    }


  }

  static typeDescription() {
    return "PropertyMeasure";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PropertyMeasure();
      if (data.hasOwnProperty('code')) {
        obj['code'] = ApiClient.convertToType(data['code'], 'String');
      }
      if (data.hasOwnProperty('unitCode')) {
        obj['unitCode'] = ApiClient.convertToType(data['unitCode'], 'String');
      }
    }
    return obj;
  }


  code = "";

  unitCode = "";




  getCode() {
    return this.code;
  }

  setCode(code) {
    this['code'] = code;
  }

  getUnitCode() {
    return this.unitCode;
  }

  setUnitCode(unitCode) {
    this['unitCode'] = unitCode;
  }


}