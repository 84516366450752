/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class LoggedUser {
  constructor() {


  }

  static typeDescription() {
    return "LoggedUser";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LoggedUser();
      if (data.hasOwnProperty('login')) {
        obj['login'] = ApiClient.convertToType(data['login'], 'String');
      }
      if (data.hasOwnProperty('firstName')) {
        obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
      }
      if (data.hasOwnProperty('lastName')) {
        obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('customer')) {
        obj['customer'] = ApiClient.convertToType(data['customer'], 'String');
      }
    }
    return obj;
  }


  login = undefined;

  firstName = undefined;

  lastName = undefined;

  id = undefined;

  customer = undefined;




  getLogin() {
    return this.login;
  }

  setLogin(login) {
    this['login'] = login;
  }

  getFirstName() {
    return this.firstName;
  }

  setFirstName(firstName) {
    this['firstName'] = firstName;
  }

  getLastName() {
    return this.lastName;
  }

  setLastName(lastName) {
    this['lastName'] = lastName;
  }

  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getCustomer() {
    return this.customer;
  }

  setCustomer(customer) {
    this['customer'] = customer;
  }


}