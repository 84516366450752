/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Pricing from './Pricing';

export default class MultiPricingDetails {
  constructor(name, price) {
    if (name) {
      this['name'] = name;
    }
    this['price'] = price || new Pricing();


  }

  static typeDescription() {
    return "MultiPricingDetails";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MultiPricingDetails();
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = Pricing.constructFromObject(data['price']);
      }
    }
    return obj;
  }


  name = "";

  price = undefined;




  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getPrice() {
    return this.price;
  }

  setPrice(price) {
    this['price'] = price;
  }


}