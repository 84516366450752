import React from "react";
import { injectIntl } from "react-intl";
import { Link, Glyphicon, Tooltip } from "Elements";
import Section from "marketing/components/Section";
import Timeline from "components/Timeline";

class ManufacturingLines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: ""
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(i) {
    console.log("i", i);
    if (this.state.tooltipOpen === i) {
      this.setState({ tooltipOpen: "" });
    }
    this.setState({
      tooltipOpen: i
    });
  }
  closeTooltip() {
    this.setState({ tooltipOpen: "" });
  }

  render() {
    const { props, state } = this;
    const { data = {} } = props;
    const { title, products = [] } = data;
    const filteredProducts = products.filter((p) => {
      const { manufacturing = [] } = p;
      return manufacturing.length;
    });
    if (!filteredProducts.length) {
      return null;
    }
    console.log("main state", state);
    return (
      <Section className="manufacturing_lines" title={title}>
        {filteredProducts.
        filter((m) => m.slug != "engineering").
        map((product, i) =>
        <Product
          product={product}
          state={state}
          toggle={(res) => this.toggle(res)}
          key={i} />

        // <div >
        // </div>
        )}
        {/* <div onClick={(e) => this.closeTooltip()}>
           
          </div> */}
      </Section>);

  }
}

export default injectIntl(ManufacturingLines);

class Product extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { product } = props;
    const { manufacturing = [] } = product;
    const size = sizes[product.slug] || {};
    console.log("Product render", props, state);
    return (
      <div className="manufacturing_lines__product">
        <Link
          to={"/" + product.slug}
          className="mr-3 d-flex align-items-center">

          <div
            className={"factoryProductLogo-" + product.slug}
            style={{ cursor: "pointer" }}>

            <img
              {...size}
              className="factoryProductLogo lazyload"
              data-src={"/images/products/" + product.slug + ".svg"} />

          </div>
        </Link>
        <Timeline
          className={"product_line-" + product.slug}
          title={product.title}
          steps={manufacturing} />

      </div>);

  }
}

const sizes = {
  pcba: {
    width: 72,
    height: 70
  },
  wiring: {
    width: 42,
    height: 70
  },
  pcb: {
    width: 94,
    height: 70
  },
  enclosure: {
    width: 67,
    height: 70
  },
  coating: {
    width: 43,
    height: 70
  },
  xray: {
    width: 85,
    height: 70
  },
  assembly: {
    width: 68,
    height: 70
  },
  rework: {
    width: 97,
    height: 70
  },
  engineering: {
    width: 68,
    height: 70
  }
};