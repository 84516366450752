/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class CustomerStocksFilterRequest {
  constructor() {


  }

  static typeDescription() {
    return "CustomerStocksFilterRequest";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerStocksFilterRequest();
      if (data.hasOwnProperty('customerId')) {
        obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
      }
      if (data.hasOwnProperty('locationType')) {
        obj['locationType'] = ApiClient.convertToType(data['locationType'], 'String');
      }
      if (data.hasOwnProperty('locationId')) {
        obj['locationId'] = ApiClient.convertToType(data['locationId'], 'String');
      }
      if (data.hasOwnProperty('referencePattern')) {
        obj['referencePattern'] = ApiClient.convertToType(data['referencePattern'], 'String');
      }
      if (data.hasOwnProperty('withAvailableStocks')) {
        obj['withAvailableStocks'] = ApiClient.convertToType(data['withAvailableStocks'], 'Boolean');
      }
      if (data.hasOwnProperty('excludeInactiveLocations')) {
        obj['excludeInactiveLocations'] = ApiClient.convertToType(data['excludeInactiveLocations'], 'Boolean');
      }
    }
    return obj;
  }


  customerId = undefined;

  locationType = undefined;

  locationId = undefined;

  referencePattern = undefined;

  withAvailableStocks = undefined;

  excludeInactiveLocations = undefined;




  getCustomerId() {
    return this.customerId;
  }

  setCustomerId(customerId) {
    this['customerId'] = customerId;
  }

  getLocationType() {
    return this.locationType;
  }

  setLocationType(locationType) {
    this['locationType'] = locationType;
  }

  getLocationId() {
    return this.locationId;
  }

  setLocationId(locationId) {
    this['locationId'] = locationId;
  }

  getReferencePattern() {
    return this.referencePattern;
  }

  setReferencePattern(referencePattern) {
    this['referencePattern'] = referencePattern;
  }

  getWithAvailableStocks() {
    return this.withAvailableStocks;
  }

  setWithAvailableStocks(withAvailableStocks) {
    this['withAvailableStocks'] = withAvailableStocks;
  }

  getExcludeInactiveLocations() {
    return this.excludeInactiveLocations;
  }

  setExcludeInactiveLocations(excludeInactiveLocations) {
    this['excludeInactiveLocations'] = excludeInactiveLocations;
  }


  static LocationTypeEnum = {
    "assembly_quotation": "assembly_quotation",
    "coating_quotation": "coating_quotation",
    "dfm_quotation": "dfm_quotation",
    "enclosure_quotation": "enclosure_quotation",
    "engineering_quotation": "engineering_quotation",
    "pcb_quotation": "pcb_quotation",
    "pcba_quotation": "pcba_quotation",
    "pcb_design_quotation": "pcb_design_quotation",
    "rework_quotation": "rework_quotation",
    "supply_quotation": "supply_quotation",
    "wiring_quotation": "wiring_quotation",
    "xray_quotation": "xray_quotation"
  };

}