export const update = (list = [], data = {}, idField = "id") => {
  const index = list.findIndex((i) => i[idField] === data[idField]);
  return updateIndex(list, data, index);
};

export const updateIndex = (list = [], data = {}, index = -1) => {
  if (index >= 0) {
    return [
    ...list.slice(0, index),
    list[index] !== null && typeof list[index] === "object" ?
    Object.assign({}, list[index], data) :
    data,
    ...list.slice(index + 1)];

  } else {
    // console.log('update', data)
    return [...list, data];
  }
};

export const updateMultiple = (list = [], data = [], idField = "id") => {
  return data.reduce((res, item) => {
    return update(res, item, idField);
  }, list);
};