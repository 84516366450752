import { combineReducers } from 'redux';
import User from '../api/model/User';
import Customer from '../api/model/Customer';

const initialState = {};

const user = (state = initialState, action) => {
  const nState = function (state, action) {
    if (action.type === 'USER_GET') {
      return Object.assign({}, action.data);
    }
    if (action.type === 'USER_SET') {
      return Object.assign({}, state, action.data);
    }
    if (action.type === 'AUTH_LOGOUT') {
      return {};
    }
    return state;
  }(state, action);
  return nState;
};

const customer = (state = {}, action) => {
  return Customer.constructFromObject(function (state, action) {
    switch (action.type) {
      case 'AUTH_CUSTOMER_GET':
        return Object.assign({}, action.data);
        break;
      case 'AUTH_CUSTOMER_SET':
        return Object.assign({}, state, action.data);
        break;
      case 'AUTH_LOGOUT':
        return {};
        break;
    }
    return state;
  }(state, action));
};

const customerStocks = (state = {}, action) => {
  const nState = function (state, action) {
    if (action.type === 'AUTH_CUSTOMER_STOCKS_GET') {
      return Object.assign({ haveSupply: false, stocks: [] }, action.data);
    }
    return state;
  }(state, action);
  return nState;
};

export default combineReducers({
  user,
  customer,
  customerStocks
});