import NotificationApi from "api/api/NotificationApi";
import { store } from "initStore";

import requestsActions from "Requests/actions";

const notificationApi = new NotificationApi();

const actions = {
  getAll: (status = "unread", orderBy = "eventDateDesc") => {
    const state = store.getState();
    const user = state.auth.user;
    if (!user.webLogin) {
      return Promise.resolve([]);
    }
    return requestsActions.add(
      "notifications/getAll",
      notificationApi.getNotifications({ status, orderBy }).then((data) => {
        store.dispatch({
          type: "notifications/SET_ALL",
          data: data.map((id) => {
            return { id, status };
          })
        });
        return data;
      })
    );
  },
  fetchAll: (status = "unread", orderBy = "eventDateDesc") => {
    const state = store.getState();
    const user = state.auth.user;
    if (!user.webLogin) {
      return Promise.resolve([]);
    }
    return requestsActions.add(
      "notifications/getAll",
      notificationApi.getNotifications({ status, orderBy }).then((data) => {
        store.dispatch({
          type: "notifications/UPDATE_ALL",
          data: data.map((id) => {
            return { id, status };
          })
        });
        return data;
      })
    );
  },
  empty: () => {
    return store.dispatch({
      type: "notifications/SET_ALL",
      data: []
    });
  },
  get: (id) =>
  notificationApi.getNotification(id).then((data) => actions.set(data)),
  set: (data) => {
    store.dispatch({
      type: "notifications/SET",
      data
    });
    return data;
  },
  // get: id =>
  //   notificationApi.getNotification(id).then(data => {
  //     return actions.getEvent(data.eventId).then(event => {
  //       const nData = Object.assign({}, data, { event });
  //       store.dispatch({
  //         type: "notifications/SET",
  //         data: nData
  //       });
  //       return nData;
  //     });
  //   }),
  getEvent: (id) =>
  notificationApi.
  getEvent(id).
  then((data) => {
    store.dispatch({
      type: "notifications/SET_EVENT",
      data
    });
    return data;
  }).
  catch((err) => {
    return null;
  }),
  read: (id) => notificationApi.setNotificationRead(id).then(actions.set),
  unread: (id) => notificationApi.setNotificationUnread(id).then(actions.set)
};

export default actions;