import { store } from "initStore";
import { update } from "utils/updateArray";

import QuoteApi from "api/api/QuoteApi";

import { createQuotationActions } from "commonTunnel/actions";
import savedCartsActions from "SavedCarts/actions";

const quoteApi = new QuoteApi();

const actions = {
  createQuote: (data, opts) => {
    console.log("quoteActions create quote", data);
    let quote = {};
    // let quotationRels = {};
    const nQuote = Object.assign({}, data);
    return data.quotations.
    reduce(
      (p, quotation) => {
        return p.then(
          (e) =>
          // productsActions[quotation.type]
          //   .duplicateQuotation(quotation.id, true)
          //   .then(q => {
          //     const index = nQuote.technicalContactIdsByQuotationIds.findIndex(
          //       o => o.quotationId === quotation.id
          //     );
          //     nQuote.technicalContactIdsByQuotationIds = [
          //       ...nQuote.technicalContactIdsByQuotationIds.slice(0, index),
          //       Object.assign(
          //         {},
          //         nQuote.technicalContactIdsByQuotationIds[index],
          //         { quotationId: q.id }
          //       ),
          //       ...nQuote.technicalContactIdsByQuotationIds.slice(index + 1)
          //     ];
          //   })
          // return
          actions[
          "add" +
          quotation.type[0].toUpperCase() +
          quotation.type.slice(1) +
          "Quotation"](
            quote.id, quotation.id)
          // ;
        );
      },
      quoteApi.createQuote(opts).then((q) => {
        quote = q;
        actions.setQuote(q);
      })
    ).
    then((e) => {
      delete nQuote.quotations;
      return actions.saveQuote(quote.id, nQuote);
    }).
    then((quote) => actions.registerQuote(quote.id));
  },
  // quoteApi.createQuote().then(data => {
  //   actions.setQuote(data);
  //   return data;
  // }),
  getQuotes: (opts) =>
  quoteApi.getQuotes(opts).then((data) => {
    store.dispatch({
      type: "quotes/setAll",
      data
    });
    return data;
  }),
  cancelQuote: (quoteId) => quoteApi.cancelQuote(quoteId),
  deleteQuote: (quoteId) => quoteApi.deleteQuote(quoteId),
  registerQuote: (quoteId) => quoteApi.registerQuote(quoteId),
  getQuoteQuotations: (quoteId) =>
  quoteApi.getQuoteQuotations(quoteId).then((data) => {
    actions.setQuote({ id: quoteId, quotations: data });
    return data;
  }),
  loadQuote: (quoteId) =>
  actions.getQuote(quoteId).then((quote) => {
    return actions.
    getQuoteQuotations(quoteId).
    then((quotations) =>
    actions.setQuote(Object.assign({}, quote, { quotations }))
    );
  }),
  unregisterQuote: (quoteId) =>
  quoteApi.unregisterQuote(quoteId).then((res) => {
    return res;
  }),
  getQuote: (quoteId) =>
  quoteApi.getQuote(quoteId).then((res) => {
    actions.setQuote(res);
    return res;
  }),
  readQuote: (quoteId) => {
    const state = store.getState();
    const quotes = state.quotes;
    return actions.hydrateQuote(quotes.find((q) => q.id === quoteId));
  },
  readOrderQuote: (orderId) => {
    const state = store.getState();
    const quotes = state.quotes;
    return actions.hydrateQuote(quotes.find((q) => q.orderId === orderId));
  },
  hydrateQuote: (quote) => {
    const state = store.getState();
    quote = Object.assign(
      { administrativeContactIds: [], technicalContactIdsByQuotationIds: [] },
      quote
    );

    let areUsersLoaded = true;
    let accessPermissions = [];
    let accessUserIds = [];
    quote.quotations = (quote.quotations || []).map((quotation) => {
      const type = quotation.type;
      const tState = state[type] || {};
      const tQuotations = tState.quotations || [];
      const tQuotation = tQuotations.find((q) => q.id === quotation.id) || {};
      if (
      !tQuotation.accessPermissions ||
      !tQuotation.accessPermissions.length)
      {
        areUsersLoaded = false;
      } else {
        const userIds = (tQuotation.accessPermissions || []).map(
          (a) => a.userId
        );
        accessUserIds = [
        ...accessUserIds,
        ...userIds.filter((id) => accessUserIds.indexOf(id) < 0)];

        accessPermissions = [...accessPermissions, userIds];
      }
      return Object.assign({}, quotation, tQuotation);
    });
    const intersectUsers = accessUserIds.filter((id) => {
      return (
        accessPermissions.filter((a) => a.indexOf(id) >= 0).length ===
        accessPermissions.length);

    });
    quote.administrativeUserIds = [
    state.auth.user.id,
    ...(areUsersLoaded ? intersectUsers : [])];

    quote.administrativeContactIds = (quote.administrativeContactIds || []).
    length ?
    quote.administrativeContactIds :
    [state.auth.user.id];

    quote.isValid = quote.quotations.reduce(
      (res, quotation) => res && quotation.isPriced,
      true
    );
    return quote;
  },
  duplicateOnlyQuote: (quoteId, cartName) => {
    return actions.loadQuote(quoteId).then((quote) => {
      console.log("QuotesActions duplicateOnlyQuote", quoteId, cartName, quote);
      return savedCartsActions.
      createCart(cartName || quote.erpReference).
      then((cart) =>
      Promise.all(
        quote.quotations.map((quotation) => {
          const funcName =
          "append" +
          quotation.type[0].toUpperCase() +
          quotation.type.slice(1) +
          "QuotationToCart";
          console.log("duplicateOnlyQuote", quotation, cart, funcName);
          const func = savedCartsActions[funcName];
          if (typeof func !== "function") {
            return null;
          }
          return func(cart.id, quotation.id);
        })
      ).then((e) => cart)
      );
    });
  },
  duplicateQuote: (quoteId, cartName) => {
    return actions.loadQuote(quoteId).then((quote) => {
      console.log("QuotesActions duplicateQuote", quoteId, cartName, quote);
      return Promise.all(
        quote.quotations.map((quotation) => {
          const { type } = quotation;
          const quotationActions = createQuotationActions(type);
          const { duplicateQuotation } = quotationActions;
          if (typeof duplicateQuotation !== "function") {
            return null;
          }
          return duplicateQuotation(quotation.id).then((q) =>
          Object.assign({}, q, { type })
          );
        })
      ).then((quotations) =>
      savedCartsActions.
      createCart(cartName || quote.erpReference).
      then((cart) =>
      Promise.all(
        quotations.map((quotation) => {
          const funcName =
          "append" +
          quotation.type[0].toUpperCase() +
          quotation.type.slice(1) +
          "QuotationToCart";
          console.log("duplicateQuote", quotation, cart, funcName);
          const func = savedCartsActions[funcName];
          if (typeof func !== "function") {
            return null;
          }
          return func(cart.id, quotation.id);
        })
      ).then((e) => cart)
      )
      );
    });
  },
  initTechContacts: (q) => {
    let quote = Object.assign({}, q);
    const { technicalContactIdsByQuotationIds = [] } = quote;
    quote.quotations.forEach((quotation) => {
      const state = store.getState();
      const tState = state[quotation.type] || {};
      // spec
      const tSpecs = tState.specs || [];
      const spec = tSpecs.find((s) => s.id === quotation.specId) || {};
      let technicalContactIds = Object.assign(
        { contactIds: [], quotationId: quotation.id },
        technicalContactIdsByQuotationIds.find(
          (o) => o.quotationId === quotation.id
        )
      );
      if (!technicalContactIds.contactIds.length) {
        technicalContactIds.contactIds = [spec.ownerId];
      }
      quote.technicalContactIdsByQuotationIds = update(
        technicalContactIdsByQuotationIds,
        technicalContactIds,
        "quotationId"
      );
    });
    console.log("initTechContacts", quote);
    return quote;
  },
  setQuote: (data) => {
    store.dispatch({
      type: "quotes/SET",
      data
    });
    return data;
  },
  saveQuote: (quoteId, quote) =>
  quoteApi.setQuote(quoteId, quote).then((res) => {
    actions.setQuote(res);
    return res;
  }),
  saveQuoteContacts: (quoteId, quoteContacts) =>
  quoteApi.
  setQuoteContacts(quoteId, quoteContacts).
  then((quote) => actions.setQuote(quote)),
  getSurcharges: (id) => quoteApi.getQuotePaymentSurcharges(id),

  // PCBA
  addPcbaQuotation: (quoteId, quotationId) =>
  quoteApi.appendPCBAQuotationToQuote(quoteId, quotationId),
  getPcbaQuotations: (quoteId) =>
  quoteApi.getQuotePCBAQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "pcba/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removePcbaQuotation: (quoteId, quotationId) =>
  quoteApi.removePcbaQuotationFromQuote(quoteId, quotationId),

  // ENGINEERING
  addEngineeringQuotation: (quoteId, quotationId) =>
  quoteApi.appendEngineeringQuotationToQuote(quoteId, quotationId),
  getEngineeringQuotations: (quoteId) =>
  quoteApi.getQuoteEngineeringQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "Engineering/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeEngineeringQuotation: (quoteId, quotationId) =>
  quoteApi.removeEngineeringQuotationFromQuote(quoteId, quotationId),

  // ASSEMBLY
  addAssemblyQuotation: (quoteId, quotationId) =>
  quoteApi.appendAssemblyQuotationToQuote(quoteId, quotationId),
  getAssemblyQuotations: (quoteId) =>
  quoteApi.getQuoteAssemblyQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "assembly/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeAssemblyQuotation: (quoteId, quotationId) =>
  quoteApi.removeAssemblyQuotationFromQuote(quoteId, quotationId),

  // COATING
  addCoatingQuotation: (quoteId, quotationId) =>
  quoteApi.appendCoatingQuotationToQuote(quoteId, quotationId),
  getCoatingQuotations: (quoteId) =>
  quoteApi.getQuoteCoatingQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "coating/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeCoatingQuotation: (quoteId, quotationId) =>
  quoteApi.removeCoatingQuotationFromQuote(quoteId, quotationId),

  // DFM
  addDfmQuotation: (quoteId, quotationId) =>
  quoteApi.appendDfmQuotationToQuote(quoteId, quotationId),
  getDfmQuotations: (quoteId) =>
  quoteApi.getQuoteDfmQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "dfm/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeDfmQuotation: (quoteId, quotationId) =>
  quoteApi.removeDfmQuotationFromQuote(quoteId, quotationId),

  // ENCLOSURE
  addEnclosureQuotation: (quoteId, quotationId) =>
  quoteApi.appendEnclosureQuotationToQuote(quoteId, quotationId),
  getEnclosureQuotations: (quoteId) =>
  quoteApi.getQuoteEnclosureQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "enclosure/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeEnclosureQuotation: (quoteId, quotationId) =>
  quoteApi.removeEnclosureQuotationFromQuote(quoteId, quotationId),

  // PCB
  addPcbQuotation: (quoteId, quotationId) =>
  quoteApi.appendPcbQuotationToQuote(quoteId, quotationId),
  getPcbQuotations: (quoteId) =>
  quoteApi.getQuotePcbQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "pcb/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removePcbQuotation: (quoteId, quotationId) =>
  quoteApi.removePcbQuotationFromQuote(quoteId, quotationId),

  // PCBDESIGN
  addPcbDesignQuotation: (quoteId, quotationId) =>
  quoteApi.appendPcbDesignQuotationToQuote(quoteId, quotationId),
  getPcbDesignQuotations: (quoteId) =>
  quoteApi.getQuotePcbDesignQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "pcbDesign/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removePcbDesignQuotation: (quoteId, quotationId) =>
  quoteApi.removePcbDesignQuotationFromQuote(quoteId, quotationId),

  // REWORK
  addReworkQuotation: (quoteId, quotationId) =>
  quoteApi.appendReworkQuotationToQuote(quoteId, quotationId),
  getReworkQuotations: (quoteId) =>
  quoteApi.getQuoteReworkQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "rework/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeReworkQuotation: (quoteId, quotationId) =>
  quoteApi.removeReworkQuotationFromQuote(quoteId, quotationId),

  // SUPPLY
  addSupplyQuotation: (quoteId, quotationId) =>
  quoteApi.appendSupplyQuotationToQuote(quoteId, quotationId),
  getSupplyQuotations: (quoteId) =>
  quoteApi.getQuoteSupplyQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "supply/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeSupplyQuotation: (quoteId, quotationId) =>
  quoteApi.removeSupplyQuotationFromQuote(quoteId, quotationId),

  // WIRE
  addWiringQuotation: (quoteId, quotationId) =>
  quoteApi.appendWiringQuotationToQuote(quoteId, quotationId),
  getWiringQuotations: (quoteId) =>
  quoteApi.getQuoteWiringQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "wiring/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeWiringQuotation: (quoteId, quotationId) =>
  quoteApi.removeWiringQuotationFromQuote(quoteId, quotationId),

  // XRAY
  addXrayQuotation: (quoteId, quotationId) =>
  quoteApi.appendXRayQuotationToQuote(quoteId, quotationId),
  getXrayQuotations: (quoteId) =>
  quoteApi.getQuoteXRayQuotations(quoteId).then((quotations) => {
    quotations.map((data) =>
    store.dispatch({
      type: "xray/quotations/SET",
      data
    })
    );
    store.dispatch({
      type: "quotes/SET",
      data: {
        id: quoteId,
        quotationIds: quotations.map((quotation) => quotation.id)
      }
    });
    return quotations;
  }),
  removeXrayQuotation: (quoteId, quotationId) =>
  quoteApi.removeXRayQuotationFromQuote(quoteId, quotationId)
};

export default actions;