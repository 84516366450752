import React from "react";
import Section from "../components/Section";
import marketingMessages from "marketing/messages";
const FactoryProcess = ({ data = {}, components, intl }) =>
<Section bgWhite title={data.title}>
    <div>
      {components ?
    <div className="card bg-primaryDark factory_card__secondary text-white">
          <div className="card-body d-flex flex-column">
            <p className="icon icon-md" style={{ color: "#ec7b51" }}>
              {components}
            </p>
            <p>{intl.formatMessages(marketingMessages.mountedComponents)}</p>
          </div>
        </div> :
    null}

      <img
      className="img-fluid lazyload"
      data-src={"/images/marketing/factory/Process/factory_process.png"}
      alt="" />

    </div>
  </Section>;


export default FactoryProcess;