/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Address from './Address';
import PcbValue from './PcbValue';
import ProductionPhases from './ProductionPhases';

export default class OrderInProductionQuotations {
  constructor() {


  }

  static typeDescription() {
    return "OrderInProductionQuotations";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new OrderInProductionQuotations();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('trackingId')) {
        obj['trackingId'] = ApiClient.convertToType(data['trackingId'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('productionProgressPhase')) {
        obj['productionProgressPhase'] = ProductionPhases.constructFromObject(data['productionProgressPhase']);
      }
      if (data.hasOwnProperty('deliveryMode')) {
        obj['deliveryMode'] = ApiClient.convertToType(data['deliveryMode'], 'String');
      }
      if (data.hasOwnProperty('deliveryAddress')) {
        obj['deliveryAddress'] = Address.constructFromObject(data['deliveryAddress']);
      }
      if (data.hasOwnProperty('expectedExpeditionDate')) {
        obj['expectedExpeditionDate'] = ApiClient.convertToType(data['expectedExpeditionDate'], 'Date');
      }
      if (data.hasOwnProperty('isProductionHalted')) {
        obj['isProductionHalted'] = ApiClient.convertToType(data['isProductionHalted'], 'Boolean');
      }
      if (data.hasOwnProperty('productionHaltDate')) {
        obj['productionHaltDate'] = ApiClient.convertToType(data['productionHaltDate'], 'String');
      }
      if (data.hasOwnProperty('isWaitingForAction')) {
        obj['isWaitingForAction'] = ApiClient.convertToType(data['isWaitingForAction'], 'Boolean');
      }
      if (data.hasOwnProperty('pcbValue')) {
        obj['pcbValue'] = PcbValue.constructFromObject(data['pcbValue']);
      }
    }
    return obj;
  }


  id = undefined;

  trackingId = undefined;

  type = undefined;

  productionProgressPhase = undefined;

  deliveryMode = undefined;

  deliveryAddress = undefined;

  expectedExpeditionDate = undefined;

  isProductionHalted = undefined;

  productionHaltDate = undefined;

  isWaitingForAction = undefined;

  pcbValue = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getTrackingId() {
    return this.trackingId;
  }

  setTrackingId(trackingId) {
    this['trackingId'] = trackingId;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getProductionProgressPhase() {
    return this.productionProgressPhase;
  }

  setProductionProgressPhase(productionProgressPhase) {
    this['productionProgressPhase'] = productionProgressPhase;
  }

  getDeliveryMode() {
    return this.deliveryMode;
  }

  setDeliveryMode(deliveryMode) {
    this['deliveryMode'] = deliveryMode;
  }

  getDeliveryAddress() {
    return this.deliveryAddress;
  }

  setDeliveryAddress(deliveryAddress) {
    this['deliveryAddress'] = deliveryAddress;
  }

  getExpectedExpeditionDate() {
    return this.expectedExpeditionDate;
  }

  setExpectedExpeditionDate(expectedExpeditionDate) {
    this['expectedExpeditionDate'] = expectedExpeditionDate;
  }

  getIsProductionHalted() {
    return this.isProductionHalted;
  }

  setIsProductionHalted(isProductionHalted) {
    this['isProductionHalted'] = isProductionHalted;
  }

  getProductionHaltDate() {
    return this.productionHaltDate;
  }

  setProductionHaltDate(productionHaltDate) {
    this['productionHaltDate'] = productionHaltDate;
  }

  getIsWaitingForAction() {
    return this.isWaitingForAction;
  }

  setIsWaitingForAction(isWaitingForAction) {
    this['isWaitingForAction'] = isWaitingForAction;
  }

  getPcbValue() {
    return this.pcbValue;
  }

  setPcbValue(pcbValue) {
    this['pcbValue'] = pcbValue;
  }


  static TypeEnum = {
    "assembly": "assembly",
    "coating": "coating",
    "dfm": "dfm",
    "enclosure": "enclosure",
    "engineering": "engineering",
    "pcb": "pcb",
    "pcba": "pcba",
    "pcbDesign": "pcbDesign",
    "rework": "rework",
    "supply": "supply",
    "wiring": "wiring",
    "xray": "xray"
  };

  static DeliveryModeEnum = {
    "Transporter": "Transporter",
    "Catch": "Catch"
  };

}