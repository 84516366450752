var React = require("react");
var blockTypes = require("./blocks");

const Content = ({ blocks = [] }) =>
Array.isArray(blocks) ?
blocks.
map((block, i) => {
  const { type = "P" } = block;
  const blockType = blockTypes.find((b) => b.type === type);
  if (!blockType) {
    return null;
  }
  return React.createElement(
    blockType.component,
    Object.assign({ key: i }, block)
  );
}).
filter((b) => b) :
null;

export default Content;