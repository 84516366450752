/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ManufacturingResource {
  constructor(id) {
    if (id) {
      this['id'] = id;
    }


  }

  static typeDescription() {
    return "ManufacturingResource";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingResource();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('firstActiveDay')) {
        obj['firstActiveDay'] = ApiClient.convertToType(data['firstActiveDay'], 'Date');
      }
      if (data.hasOwnProperty('lastActiveDay')) {
        obj['lastActiveDay'] = ApiClient.convertToType(data['lastActiveDay'], 'Date');
      }
    }
    return obj;
  }


  id = "";

  name = undefined;

  firstActiveDay = undefined;

  lastActiveDay = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getFirstActiveDay() {
    return this.firstActiveDay;
  }

  setFirstActiveDay(firstActiveDay) {
    this['firstActiveDay'] = firstActiveDay;
  }

  getLastActiveDay() {
    return this.lastActiveDay;
  }

  setLastActiveDay(lastActiveDay) {
    this['lastActiveDay'] = lastActiveDay;
  }


}