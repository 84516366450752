import React from "react";
import { Carousel, Glyphicon } from "Elements";
import Image from "components/Image";

class Gallery extends React.Component {
  render() {
    const { props } = this;
    if (!props.images) {
      return null;
    }

    // console.log("Gallery", props.images)
    return (
      <div className="product_modal">
        {props.images.length ?
        <div className="home_modal" onClick={props.onHide}>
            <div
            className="m-3"
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              color: "#fff",
              fontSize: 27,
              zIndex: 1030
            }}>

              <Glyphicon glyph="times" style={{ cursor: "pointer" }} />
            </div>
            <Carousel
            activeIndex={props.index}
            items={props.images.map((image, i) =>
            <div
              key={i}
              className="d-flex align-items-center justify-content-center h-100">

                  <Image
                image={image}
                style={{ maxHeight: "950px", maxWidth: "50%" }} />

                </div>
            )} />

          </div> :
        null}
      </div>);

  }
}

export default Gallery;