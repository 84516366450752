export const setLocal = (key, value) => {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem(key, value);
  }
};

export const getLocal = (key) => {
  if (typeof localStorage !== "undefined") {
    return localStorage.getItem(key);
  }
};

export const removeLocal = (key) => {
  if (typeof localStorage !== "undefined") {
    return localStorage.removeItem(key);
  }
};