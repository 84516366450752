/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class HttpRequest {
  constructor(method, uri) {
    if (method) {
      this['method'] = method;
    }
    if (uri) {
      this['uri'] = uri;
    }


  }

  static typeDescription() {
    return "HttpRequest";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HttpRequest();
      if (data.hasOwnProperty('method')) {
        obj['method'] = ApiClient.convertToType(data['method'], 'String');
      }
      if (data.hasOwnProperty('uri')) {
        obj['uri'] = ApiClient.convertToType(data['uri'], 'String');
      }
      if (data.hasOwnProperty('headers')) {
        obj['headers'] = ApiClient.convertToType(data['headers'], { 'String': 'String' });
      }
      if (data.hasOwnProperty('content')) {
        obj['content'] = ApiClient.convertToType(data['content'], 'String');
      }
    }
    return obj;
  }


  method = "PATCH";

  uri = "";

  headers = undefined;

  content = undefined;




  getMethod() {
    return this.method;
  }

  setMethod(method) {
    this['method'] = method;
  }

  getUri() {
    return this.uri;
  }

  setUri(uri) {
    this['uri'] = uri;
  }

  getHeaders() {
    return this.headers;
  }

  setHeaders(headers) {
    this['headers'] = headers;
  }

  getContent() {
    return this.content;
  }

  setContent(content) {
    this['content'] = content;
  }


  static MethodEnum = {
    "PATCH": "PATCH",
    "POST": "POST",
    "PUT": "PUT",
    "GET": "GET",
    "DELETE": "DELETE",
    "HEAD": "HEAD",
    "CONNECT": "CONNECT",
    "OPTION": "OPTION",
    "TRACE": "TRACE"
  };

}