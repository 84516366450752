/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import XRay2DImage from './XRay2DImage';
import XRay3DImage from './XRay3DImage';

export default class XRayLiveShooting {
  constructor(xrayLiveSessionId, xrayTargetDeviceId) {
    if (xrayLiveSessionId) {
      this['xrayLiveSessionId'] = xrayLiveSessionId;
    }
    if (xrayTargetDeviceId) {
      this['xrayTargetDeviceId'] = xrayTargetDeviceId;
    }


  }

  static typeDescription() {
    return "XRayLiveShooting";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new XRayLiveShooting();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('xrayLiveSessionId')) {
        obj['xrayLiveSessionId'] = ApiClient.convertToType(data['xrayLiveSessionId'], 'String');
      }
      if (data.hasOwnProperty('xrayTargetDeviceId')) {
        obj['xrayTargetDeviceId'] = ApiClient.convertToType(data['xrayTargetDeviceId'], 'String');
      }
      if (data.hasOwnProperty('results2D')) {
        obj['results2D'] = ApiClient.convertToType(data['results2D'], [XRay2DImage]);
      }
      if (data.hasOwnProperty('results3D')) {
        obj['results3D'] = ApiClient.convertToType(data['results3D'], [XRay3DImage]);
      }
    }
    return obj;
  }


  id = undefined;

  xrayLiveSessionId = undefined;

  xrayTargetDeviceId = undefined;

  results2D = undefined;

  results3D = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getXrayLiveSessionId() {
    return this.xrayLiveSessionId;
  }

  setXrayLiveSessionId(xrayLiveSessionId) {
    this['xrayLiveSessionId'] = xrayLiveSessionId;
  }

  getXrayTargetDeviceId() {
    return this.xrayTargetDeviceId;
  }

  setXrayTargetDeviceId(xrayTargetDeviceId) {
    this['xrayTargetDeviceId'] = xrayTargetDeviceId;
  }

  getResults2D() {
    return this.results2D;
  }

  setResults2D(results2D) {
    this['results2D'] = results2D;
  }

  getResults3D() {
    return this.results3D;
  }

  setResults3D(results3D) {
    this['results3D'] = results3D;
  }


}