/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Address from './Address';
import Collaborator from './Collaborator';
import CustomerNotificationUsers from './CustomerNotificationUsers';

export default class Customer {
  constructor(name) {
    if (name) {
      this['name'] = name;
    }


  }

  static typeDescription() {
    return "Customer";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Customer();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('erpId')) {
        obj['erpId'] = ApiClient.convertToType(data['erpId'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('siret')) {
        obj['siret'] = ApiClient.convertToType(data['siret'], 'String');
      }
      if (data.hasOwnProperty('vatNumber')) {
        obj['vatNumber'] = ApiClient.convertToType(data['vatNumber'], 'String');
      }
      if (data.hasOwnProperty('vatStatus')) {
        obj['vatStatus'] = ApiClient.convertToType(data['vatStatus'], 'String');
      }
      if (data.hasOwnProperty('billingAddress')) {
        obj['billingAddress'] = Address.constructFromObject(data['billingAddress']);
      }
      if (data.hasOwnProperty('deliveryAddress')) {
        obj['deliveryAddress'] = Address.constructFromObject(data['deliveryAddress']);
      }
      if (data.hasOwnProperty('phone')) {
        obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('language')) {
        obj['language'] = ApiClient.convertToType(data['language'], 'String');
      }
      if (data.hasOwnProperty('collaborators')) {
        obj['collaborators'] = ApiClient.convertToType(data['collaborators'], [Collaborator]);
      }
      if (data.hasOwnProperty('maxCreditAmount')) {
        obj['maxCreditAmount'] = ApiClient.convertToType(data['maxCreditAmount'], 'Number');
      }
      if (data.hasOwnProperty('availableCreditAmount')) {
        obj['availableCreditAmount'] = ApiClient.convertToType(data['availableCreditAmount'], 'Number');
      }
      if (data.hasOwnProperty('debtAmount')) {
        obj['debtAmount'] = ApiClient.convertToType(data['debtAmount'], 'Number');
      }
      if (data.hasOwnProperty('editionAllowed')) {
        obj['editionAllowed'] = ApiClient.convertToType(data['editionAllowed'], 'Boolean');
      }
      if (data.hasOwnProperty('paymentMethod')) {
        obj['paymentMethod'] = ApiClient.convertToType(data['paymentMethod'], 'String');
      }
      if (data.hasOwnProperty('paymentCondition')) {
        obj['paymentCondition'] = ApiClient.convertToType(data['paymentCondition'], 'String');
      }
      if (data.hasOwnProperty('notificationUsers')) {
        obj['notificationUsers'] = ApiClient.convertToType(data['notificationUsers'], [CustomerNotificationUsers]);
      }
      if (data.hasOwnProperty('purchasingCompanyIds')) {
        obj['purchasingCompanyIds'] = ApiClient.convertToType(data['purchasingCompanyIds'], ['String']);
      }
      if (data.hasOwnProperty('havePaymentTerm')) {
        obj['havePaymentTerm'] = ApiClient.convertToType(data['havePaymentTerm'], 'Boolean');
      }
      if (data.hasOwnProperty('attachInvoiceToMail')) {
        obj['attachInvoiceToMail'] = ApiClient.convertToType(data['attachInvoiceToMail'], 'Boolean');
      }
    }
    return obj;
  }


  id = undefined;

  erpId = undefined;

  name = "";

  siret = undefined;

  vatNumber = undefined;

  vatStatus = undefined;

  billingAddress = undefined;

  deliveryAddress = undefined;

  phone = undefined;

  email = undefined;

  language = undefined;

  collaborators = undefined;

  maxCreditAmount = undefined;

  availableCreditAmount = undefined;

  debtAmount = undefined;

  editionAllowed = undefined;

  paymentMethod = undefined;

  paymentCondition = undefined;

  notificationUsers = undefined;

  purchasingCompanyIds = undefined;

  havePaymentTerm = undefined;

  attachInvoiceToMail = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getErpId() {
    return this.erpId;
  }

  setErpId(erpId) {
    this['erpId'] = erpId;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getSiret() {
    return this.siret;
  }

  setSiret(siret) {
    this['siret'] = siret;
  }

  getVatNumber() {
    return this.vatNumber;
  }

  setVatNumber(vatNumber) {
    this['vatNumber'] = vatNumber;
  }

  getVatStatus() {
    return this.vatStatus;
  }

  setVatStatus(vatStatus) {
    this['vatStatus'] = vatStatus;
  }

  getBillingAddress() {
    return this.billingAddress;
  }

  setBillingAddress(billingAddress) {
    this['billingAddress'] = billingAddress;
  }

  getDeliveryAddress() {
    return this.deliveryAddress;
  }

  setDeliveryAddress(deliveryAddress) {
    this['deliveryAddress'] = deliveryAddress;
  }

  getPhone() {
    return this.phone;
  }

  setPhone(phone) {
    this['phone'] = phone;
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    this['email'] = email;
  }

  getLanguage() {
    return this.language;
  }

  setLanguage(language) {
    this['language'] = language;
  }

  getCollaborators() {
    return this.collaborators;
  }

  setCollaborators(collaborators) {
    this['collaborators'] = collaborators;
  }

  getMaxCreditAmount() {
    return this.maxCreditAmount;
  }

  setMaxCreditAmount(maxCreditAmount) {
    this['maxCreditAmount'] = maxCreditAmount;
  }

  getAvailableCreditAmount() {
    return this.availableCreditAmount;
  }

  setAvailableCreditAmount(availableCreditAmount) {
    this['availableCreditAmount'] = availableCreditAmount;
  }

  getDebtAmount() {
    return this.debtAmount;
  }

  setDebtAmount(debtAmount) {
    this['debtAmount'] = debtAmount;
  }

  getEditionAllowed() {
    return this.editionAllowed;
  }

  setEditionAllowed(editionAllowed) {
    this['editionAllowed'] = editionAllowed;
  }

  getPaymentMethod() {
    return this.paymentMethod;
  }

  setPaymentMethod(paymentMethod) {
    this['paymentMethod'] = paymentMethod;
  }

  getPaymentCondition() {
    return this.paymentCondition;
  }

  setPaymentCondition(paymentCondition) {
    this['paymentCondition'] = paymentCondition;
  }

  getNotificationUsers() {
    return this.notificationUsers;
  }

  setNotificationUsers(notificationUsers) {
    this['notificationUsers'] = notificationUsers;
  }

  getPurchasingCompanyIds() {
    return this.purchasingCompanyIds;
  }

  setPurchasingCompanyIds(purchasingCompanyIds) {
    this['purchasingCompanyIds'] = purchasingCompanyIds;
  }

  getHavePaymentTerm() {
    return this.havePaymentTerm;
  }

  setHavePaymentTerm(havePaymentTerm) {
    this['havePaymentTerm'] = havePaymentTerm;
  }

  getAttachInvoiceToMail() {
    return this.attachInvoiceToMail;
  }

  setAttachInvoiceToMail(attachInvoiceToMail) {
    this['attachInvoiceToMail'] = attachInvoiceToMail;
  }


  static VatStatusEnum = {
    "unchecked": "unchecked",
    "invalid": "invalid",
    "valid": "valid"
  };

  static PaymentMethodEnum = {
    "CB": "CB",
    "Cash": "Cash",
    "Cheque": "Cheque",
    "Transfer": "Transfer",
    "Order": "Order",
    "TIP": "TIP"
  };

  static PaymentConditionEnum = {
    "FiftyFifty": "FiftyFifty",
    "FortyFiveDays": "FortyFiveDays",
    "FortyFiveDaysEndOfMonth": "FortyFiveDaysEndOfMonth",
    "OnDelivery": "OnDelivery",
    "OnInvoice": "OnInvoice",
    "OnOrder": "OnOrder",
    "SixtyDays": "SixtyDays",
    "SixtyDaysEndOfMonth": "SixtyDaysEndOfMonth",
    "ThirtyDays": "ThirtyDays",
    "ThirtyDaysEndOfMonth": "ThirtyDaysEndOfMonth",
    "ThirtyDaysEndOfMonth15th": "ThirtyDaysEndOfMonth15th"
  };

}