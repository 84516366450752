/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PartNumberMatching from './PartNumberMatching';

export default class CrossReferencesExport {
  constructor() {


  }

  static typeDescription() {
    return "CrossReferencesExport";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CrossReferencesExport();
      if (data.hasOwnProperty('declinations')) {
        obj['declinations'] = ApiClient.convertToType(data['declinations'], [['String']]);
      }
      if (data.hasOwnProperty('matchings')) {
        obj['matchings'] = ApiClient.convertToType(data['matchings'], [PartNumberMatching]);
      }
    }
    return obj;
  }


  declinations = undefined;

  matchings = undefined;




  getDeclinations() {
    return this.declinations;
  }

  setDeclinations(declinations) {
    this['declinations'] = declinations;
  }

  getMatchings() {
    return this.matchings;
  }

  setMatchings(matchings) {
    this['matchings'] = matchings;
  }


}