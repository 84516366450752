/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class CustomerShort {
  constructor() {


  }

  static typeDescription() {
    return "CustomerShort";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerShort();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('erpId')) {
        obj['erpId'] = ApiClient.convertToType(data['erpId'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('siret')) {
        obj['siret'] = ApiClient.convertToType(data['siret'], 'String');
      }
      if (data.hasOwnProperty('vatNumber')) {
        obj['vatNumber'] = ApiClient.convertToType(data['vatNumber'], 'String');
      }
      if (data.hasOwnProperty('vatStatus')) {
        obj['vatStatus'] = ApiClient.convertToType(data['vatStatus'], 'String');
      }
      if (data.hasOwnProperty('phone')) {
        obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('maxCreditAmount')) {
        obj['maxCreditAmount'] = ApiClient.convertToType(data['maxCreditAmount'], 'Number');
      }
      if (data.hasOwnProperty('editionAllowed')) {
        obj['editionAllowed'] = ApiClient.convertToType(data['editionAllowed'], 'Boolean');
      }
      if (data.hasOwnProperty('havePaymentTerm')) {
        obj['havePaymentTerm'] = ApiClient.convertToType(data['havePaymentTerm'], 'Boolean');
      }
      if (data.hasOwnProperty('attachInvoiceToMail')) {
        obj['attachInvoiceToMail'] = ApiClient.convertToType(data['attachInvoiceToMail'], 'Boolean');
      }
    }
    return obj;
  }


  id = undefined;

  erpId = undefined;

  name = undefined;

  siret = undefined;

  vatNumber = undefined;

  vatStatus = undefined;

  phone = undefined;

  email = undefined;

  maxCreditAmount = undefined;

  editionAllowed = undefined;

  havePaymentTerm = undefined;

  attachInvoiceToMail = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getErpId() {
    return this.erpId;
  }

  setErpId(erpId) {
    this['erpId'] = erpId;
  }

  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getSiret() {
    return this.siret;
  }

  setSiret(siret) {
    this['siret'] = siret;
  }

  getVatNumber() {
    return this.vatNumber;
  }

  setVatNumber(vatNumber) {
    this['vatNumber'] = vatNumber;
  }

  getVatStatus() {
    return this.vatStatus;
  }

  setVatStatus(vatStatus) {
    this['vatStatus'] = vatStatus;
  }

  getPhone() {
    return this.phone;
  }

  setPhone(phone) {
    this['phone'] = phone;
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    this['email'] = email;
  }

  getMaxCreditAmount() {
    return this.maxCreditAmount;
  }

  setMaxCreditAmount(maxCreditAmount) {
    this['maxCreditAmount'] = maxCreditAmount;
  }

  getEditionAllowed() {
    return this.editionAllowed;
  }

  setEditionAllowed(editionAllowed) {
    this['editionAllowed'] = editionAllowed;
  }

  getHavePaymentTerm() {
    return this.havePaymentTerm;
  }

  setHavePaymentTerm(havePaymentTerm) {
    this['havePaymentTerm'] = havePaymentTerm;
  }

  getAttachInvoiceToMail() {
    return this.attachInvoiceToMail;
  }

  setAttachInvoiceToMail(attachInvoiceToMail) {
    this['attachInvoiceToMail'] = attachInvoiceToMail;
  }


  static VatStatusEnum = {
    "unchecked": "unchecked",
    "invalid": "invalid",
    "valid": "valid"
  };

}