import React from 'react';
import { ButtonGroup } from 'reactstrap';

const ButtonGroupComponent = (props) => {
  let nProps = {
    size: props.size || props.bsSize,
    onClick: props.onClick,
    vertical: props.vertical,
    className: props.className
  };
  if (nProps.size === 'xsmall') {
    nProps.size = 'sm';
  }
  return <ButtonGroup {...nProps}>
    {props.children}
  </ButtonGroup>;
};

export default ButtonGroupComponent;