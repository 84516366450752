/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import LoggedUser from './LoggedUser';
import ServerStatusInMemoryObjects from './ServerStatusInMemoryObjects';

export default class ServerStatus {
  constructor() {


  }

  static typeDescription() {
    return "ServerStatus";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ServerStatus();
      if (data.hasOwnProperty('version')) {
        obj['version'] = ApiClient.convertToType(data['version'], 'String');
      }
      if (data.hasOwnProperty('coatingPricerConfiguration')) {
        obj['coatingPricerConfiguration'] = ApiClient.convertToType(data['coatingPricerConfiguration'], 'String');
      }
      if (data.hasOwnProperty('componentSourcingConfiguration')) {
        obj['componentSourcingConfiguration'] = ApiClient.convertToType(data['componentSourcingConfiguration'], 'String');
      }
      if (data.hasOwnProperty('journeyManagerConfiguration')) {
        obj['journeyManagerConfiguration'] = ApiClient.convertToType(data['journeyManagerConfiguration'], 'String');
      }
      if (data.hasOwnProperty('pcbaPricerConfiguration')) {
        obj['pcbaPricerConfiguration'] = ApiClient.convertToType(data['pcbaPricerConfiguration'], 'String');
      }
      if (data.hasOwnProperty('reworkPricerConfiguration')) {
        obj['reworkPricerConfiguration'] = ApiClient.convertToType(data['reworkPricerConfiguration'], 'String');
      }
      if (data.hasOwnProperty('wiringPricerConfiguration')) {
        obj['wiringPricerConfiguration'] = ApiClient.convertToType(data['wiringPricerConfiguration'], 'String');
      }
      if (data.hasOwnProperty('xrayPricerConfiguration')) {
        obj['xrayPricerConfiguration'] = ApiClient.convertToType(data['xrayPricerConfiguration'], 'String');
      }
      if (data.hasOwnProperty('apisInDebugMode')) {
        obj['apisInDebugMode'] = ApiClient.convertToType(data['apisInDebugMode'], ['String']);
      }
      if (data.hasOwnProperty('loggedUsers')) {
        obj['loggedUsers'] = ApiClient.convertToType(data['loggedUsers'], [LoggedUser]);
      }
      if (data.hasOwnProperty('inMemoryObjects')) {
        obj['inMemoryObjects'] = ServerStatusInMemoryObjects.constructFromObject(data['inMemoryObjects']);
      }
    }
    return obj;
  }


  version = undefined;

  coatingPricerConfiguration = undefined;

  componentSourcingConfiguration = undefined;

  journeyManagerConfiguration = undefined;

  pcbaPricerConfiguration = undefined;

  reworkPricerConfiguration = undefined;

  wiringPricerConfiguration = undefined;

  xrayPricerConfiguration = undefined;

  apisInDebugMode = undefined;

  loggedUsers = undefined;

  inMemoryObjects = undefined;




  getVersion() {
    return this.version;
  }

  setVersion(version) {
    this['version'] = version;
  }

  getCoatingPricerConfiguration() {
    return this.coatingPricerConfiguration;
  }

  setCoatingPricerConfiguration(coatingPricerConfiguration) {
    this['coatingPricerConfiguration'] = coatingPricerConfiguration;
  }

  getComponentSourcingConfiguration() {
    return this.componentSourcingConfiguration;
  }

  setComponentSourcingConfiguration(componentSourcingConfiguration) {
    this['componentSourcingConfiguration'] = componentSourcingConfiguration;
  }

  getJourneyManagerConfiguration() {
    return this.journeyManagerConfiguration;
  }

  setJourneyManagerConfiguration(journeyManagerConfiguration) {
    this['journeyManagerConfiguration'] = journeyManagerConfiguration;
  }

  getPcbaPricerConfiguration() {
    return this.pcbaPricerConfiguration;
  }

  setPcbaPricerConfiguration(pcbaPricerConfiguration) {
    this['pcbaPricerConfiguration'] = pcbaPricerConfiguration;
  }

  getReworkPricerConfiguration() {
    return this.reworkPricerConfiguration;
  }

  setReworkPricerConfiguration(reworkPricerConfiguration) {
    this['reworkPricerConfiguration'] = reworkPricerConfiguration;
  }

  getWiringPricerConfiguration() {
    return this.wiringPricerConfiguration;
  }

  setWiringPricerConfiguration(wiringPricerConfiguration) {
    this['wiringPricerConfiguration'] = wiringPricerConfiguration;
  }

  getXrayPricerConfiguration() {
    return this.xrayPricerConfiguration;
  }

  setXrayPricerConfiguration(xrayPricerConfiguration) {
    this['xrayPricerConfiguration'] = xrayPricerConfiguration;
  }

  getApisInDebugMode() {
    return this.apisInDebugMode;
  }

  setApisInDebugMode(apisInDebugMode) {
    this['apisInDebugMode'] = apisInDebugMode;
  }

  getLoggedUsers() {
    return this.loggedUsers;
  }

  setLoggedUsers(loggedUsers) {
    this['loggedUsers'] = loggedUsers;
  }

  getInMemoryObjects() {
    return this.inMemoryObjects;
  }

  setInMemoryObjects(inMemoryObjects) {
    this['inMemoryObjects'] = inMemoryObjects;
  }


}