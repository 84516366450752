import pcba from "./pcba";
import wiring from "./wiring";
import pcb from "./pcb";
import enclosure from "./enclosure";
import coating from "./coating";
import xray from "./xray";
import assembly from "./assembly";
import rework from "./rework";
import engineering from "./engineering";
// import emslabs from "./emslabs";
// import pcbaSvg from './pcba.svg'

const icons = {
  pcba,
  wiring,
  pcb,
  enclosure,
  coating,
  xray,
  assembly,
  rework,
  engineering
  // emslabs
};

const Icon = (props) => icons[props.icon] || null;

export default Icon;