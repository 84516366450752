/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import DailyTimeslot from './DailyTimeslot';

export default class DailyTimetable {
  constructor(timeslots) {
    this['timeslots'] = timeslots || [];


  }

  static typeDescription() {
    return "DailyTimetable";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DailyTimetable();
      if (data.hasOwnProperty('timeslots')) {
        obj['timeslots'] = ApiClient.convertToType(data['timeslots'], [DailyTimeslot]);
      }
    }
    return obj;
  }


  timeslots = [];




  getTimeslots() {
    return this.timeslots;
  }

  setTimeslots(timeslots) {
    this['timeslots'] = timeslots;
  }


}