const defaultState = {
  routes: {}
};

const help = (state = defaultState, action) => {
  if (action.type === 'help/SET_ROUTES') {
    return Object.assign({}, state, {
      routes: Object.assign({}, state.routes, action.data)
    });
  }
  if (action.type === 'help/SHOW') {
    return Object.assign({}, state, {
      shown: true
    });
  }
  if (action.type === 'help/HIDE') {
    return Object.assign({}, state, {
      shown: false
    });
  }
  return state;
};

export default help;