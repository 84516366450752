import React from "react";
import GalleryPreview from "components/GalleryPreview";
import Section from "marketing/components/Section";

class BehindScene extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { data = {} } = props;
    const { images = [] } = data;
    console.log("BehindScene render", props);
    return (
      <Section
        noContainer
        className="behind_scene lazyload"
        data-bg="/images/marketing/map.svg"
        title={data.title}
        // bgImage="http://www.smt-china.com/images/oktek_okano_smt_pcba_machine1.jpg"
      >
        <svg
          className="footer__top"
          id="behind_the_scene"
          viewBox="0 0 100 10"
          preserveAspectRatio="none">

          <path d="M0 0L0 10L100 10L100 0Q50 20, 0 0z" />
        </svg>
        <div className="row h-100">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 behind_scene__gallery_wrapper">
            <div className="behind_scene__gallery">
              <GalleryPreview images={images} />
            </div>
          </div>
          <div className="col-12 col-lg-6 behind_scene__text">
            <p>{data.text}</p>
          </div>
        </div>
      </Section>);

  }
}

export default BehindScene;