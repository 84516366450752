/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class CreateOrderPayment {
  constructor() {


  }

  static typeDescription() {
    return "CreateOrderPayment";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateOrderPayment();
      if (data.hasOwnProperty('mode')) {
        obj['mode'] = ApiClient.convertToType(data['mode'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
    }
    return obj;
  }


  mode = undefined;

  email = undefined;




  getMode() {
    return this.mode;
  }

  setMode(mode) {
    this['mode'] = mode;
  }

  getEmail() {
    return this.email;
  }

  setEmail(email) {
    this['email'] = email;
  }


  static ModeEnum = {
    "cb": "cb",
    "paypal": "paypal",
    "cash": "cash",
    "cheque": "cheque",
    "transfer": "transfer",
    "account": "account"
  };

}