/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AnomalyEdition from './AnomalyEdition';
import AnomalyError from './AnomalyError';
import ObjectReference from './ObjectReference';

export default class Anomaly {
  constructor(severity, objects, origin) {
    if (severity) {
      this['severity'] = severity;
    }
    this['objects'] = objects || [];
    if (origin) {
      this['origin'] = origin;
    }


  }

  static typeDescription() {
    return "Anomaly";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Anomaly();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('severity')) {
        obj['severity'] = ApiClient.convertToType(data['severity'], 'String');
      }
      if (data.hasOwnProperty('objects')) {
        obj['objects'] = ApiClient.convertToType(data['objects'], [ObjectReference]);
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('origin')) {
        obj['origin'] = ApiClient.convertToType(data['origin'], 'String');
      }
      if (data.hasOwnProperty('actionStatus')) {
        obj['actionStatus'] = ApiClient.convertToType(data['actionStatus'], 'String');
      }
      if (data.hasOwnProperty('isOpen')) {
        obj['isOpen'] = ApiClient.convertToType(data['isOpen'], 'Boolean');
      }
      if (data.hasOwnProperty('editionAllowed')) {
        obj['editionAllowed'] = AnomalyEdition.constructFromObject(data['editionAllowed']);
      }
      if (data.hasOwnProperty('errors')) {
        obj['errors'] = ApiClient.convertToType(data['errors'], [AnomalyError]);
      }
    }
    return obj;
  }


  id = undefined;

  severity = "error";

  objects = [];

  type = undefined;

  description = undefined;

  creationDate = undefined;

  origin = "cpm";

  actionStatus = undefined;

  isOpen = undefined;

  editionAllowed = undefined;

  errors = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getSeverity() {
    return this.severity;
  }

  setSeverity(severity) {
    this['severity'] = severity;
  }

  getObjects() {
    return this.objects;
  }

  setObjects(objects) {
    this['objects'] = objects;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getOrigin() {
    return this.origin;
  }

  setOrigin(origin) {
    this['origin'] = origin;
  }

  getActionStatus() {
    return this.actionStatus;
  }

  setActionStatus(actionStatus) {
    this['actionStatus'] = actionStatus;
  }

  getIsOpen() {
    return this.isOpen;
  }

  setIsOpen(isOpen) {
    this['isOpen'] = isOpen;
  }

  getEditionAllowed() {
    return this.editionAllowed;
  }

  setEditionAllowed(editionAllowed) {
    this['editionAllowed'] = editionAllowed;
  }

  getErrors() {
    return this.errors;
  }

  setErrors(errors) {
    this['errors'] = errors;
  }


  static SeverityEnum = {
    "error": "error",
    "warning": "warning"
  };

  static OriginEnum = {
    "cpm": "cpm",
    "production": "production",
    "user": "user"
  };

  static ActionStatusEnum = {
    "unset": "unset",
    "pending": "pending",
    "done": "done",
    "failed": "failed"
  };

}