/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import ObjectReference from './ObjectReference';

export default class MessageThread {
  constructor(title, _object) {
    if (title) {
      this['title'] = title;
    }
    this['object'] = _object || new ObjectReference();


  }

  static typeDescription() {
    return "MessageThread";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MessageThread();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('object')) {
        obj['object'] = ObjectReference.constructFromObject(data['object']);
      }
      if (data.hasOwnProperty('messageIds')) {
        obj['messageIds'] = ApiClient.convertToType(data['messageIds'], ['String']);
      }
    }
    return obj;
  }


  id = undefined;

  title = "";

  object = undefined;

  messageIds = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this['title'] = title;
  }

  getObject() {
    return this.object;
  }

  setObject(_object) {
    this['object'] = _object;
  }

  getMessageIds() {
    return this.messageIds;
  }

  setMessageIds(messageIds) {
    this['messageIds'] = messageIds;
  }


}