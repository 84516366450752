import React from "react";

import Contact from "./Contact";
import NewsAndTweets from "./NewsAndTweets";

const CommonTiles = (props) => {
  return [
  [<Contact {...props} />, <NewsAndTweets {...props} />].map((e, i) =>
  React.cloneElement(e, {
    key: i
  })
  )];

};

export default CommonTiles;