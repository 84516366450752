import { getLocal, setLocal } from "../utils/localStorage";

// import { store } from "initStore";

const defaultCartState = {
  pcbaQuotationIds: []
};

const cart = (s = {} || defaultCartState, action) => {
  // if (action.type === 'orders/cart/INIT') {
  //   console.log('get cart local', getCartLocal())
  //   return getCartLocal() || defaultCartState
  // }
  const state = getCartLocal();
  // const pcbaQuotationIds = state.pcbaQuotationIds || [];

  if (action.type === "cart/ADD_QUOTATION") {
    const type = action.data.type;
    if (typeof type === "undefined") {
      return state;
    }
    const res = {};
    res[type] = [...(state[type] || [])];
    if (res[type].indexOf(action.data.id) < 0) {
      res[type] = [...res[type], action.data.id];
    }
    return Object.assign({}, state, res);
  }

  if (action.type === "cart/REMOVE_QUOTATION") {
    const type = action.data.type;
    if (typeof type === "undefined") {
      return state;
    }
    const res = {};
    res[type] = state[type].filter((q) => q !== action.data.id);
    return Object.assign({}, state, res);
  }
  if (action.type === "cart/EMPTY") {
    return {};
  }
  return state;
};

const getAllCartsLocal = () => JSON.parse(getLocal("cart") || "{}");

const getCartLocal = () => {
  const localState = getAllCartsLocal();
  const userId = getUserId();
  return localState[userId] || {};
};

const getUserId = (e) => {
  return getLocal("apiKey");
};
const setCartLocal = (data) => setLocal("cart", JSON.stringify(data));

export default function (state, action) {
  let res = getAllCartsLocal();
  const cartState = cart(state, action);
  const userId = getUserId();
  if (userId) {
    res[userId] = cartState;
    setCartLocal(res);
  }
  return cartState;
}