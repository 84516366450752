/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Action from './Action';
import Anomaly from './Anomaly';

export default class CustomAnomaly {
  constructor(asAnomaly, parameters, actions, selectedActions) {
    this['asAnomaly'] = asAnomaly || new Anomaly();
    if (parameters) {
      this['parameters'] = parameters;
    }
    this['actions'] = actions || [];
    this['selectedActions'] = selectedActions || [];

    if (!this.asAnomaly.type || this.asAnomaly.type === "") {
      this.asAnomaly.type = "CustomAnomaly";
    }
    if (!this.asAnomaly.description || this.asAnomaly.description === "") {
      this.asAnomaly.description = "Generic anomaly";
    }
  }

  static typeDescription() {
    return "CustomAnomaly";
  }

  static defaultDescription() {
    return "Generic anomaly";
  }

  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomAnomaly();
      if (data.hasOwnProperty('asAnomaly')) {
        obj['asAnomaly'] = Anomaly.constructFromObject(data['asAnomaly']);
      }
      if (data.hasOwnProperty('parameters')) {
        obj['parameters'] = ApiClient.convertToType(data['parameters'], 'String');
      }
      if (data.hasOwnProperty('actions')) {
        obj['actions'] = ApiClient.convertToType(data['actions'], [Action]);
      }
      if (data.hasOwnProperty('selectedActions')) {
        obj['selectedActions'] = ApiClient.convertToType(data['selectedActions'], ['String']);
      }
      if (data.hasOwnProperty('allowMultipleSelection')) {
        obj['allowMultipleSelection'] = ApiClient.convertToType(data['allowMultipleSelection'], 'Boolean');
      }
    }
    return obj;
  }


  asAnomaly = undefined;

  parameters = "{}";

  actions = [];

  selectedActions = [];

  allowMultipleSelection = undefined;




  getAsAnomaly() {
    return this.asAnomaly;
  }

  setAsAnomaly(asAnomaly) {
    this['asAnomaly'] = asAnomaly;
  }

  getParameters() {
    return this.parameters;
  }

  setParameters(parameters) {
    this['parameters'] = parameters;
  }

  getActions() {
    return this.actions;
  }

  setActions(actions) {
    this['actions'] = actions;
  }

  getSelectedActions() {
    return this.selectedActions;
  }

  setSelectedActions(selectedActions) {
    this['selectedActions'] = selectedActions;
  }

  getAllowMultipleSelection() {
    return this.allowMultipleSelection;
  }

  setAllowMultipleSelection(allowMultipleSelection) {
    this['allowMultipleSelection'] = allowMultipleSelection;
  }


}