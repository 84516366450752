/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import EmsxFileContentObjects from './EmsxFileContentObjects';

export default class EmsxFileContent {
  constructor() {


  }

  static typeDescription() {
    return "EmsxFileContent";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmsxFileContent();
      if (data.hasOwnProperty('version')) {
        obj['version'] = ApiClient.convertToType(data['version'], 'String');
      }
      if (data.hasOwnProperty('creationDate')) {
        obj['creationDate'] = ApiClient.convertToType(data['creationDate'], 'String');
      }
      if (data.hasOwnProperty('generator')) {
        obj['generator'] = ApiClient.convertToType(data['generator'], 'String');
      }
      if (data.hasOwnProperty('host')) {
        obj['host'] = ApiClient.convertToType(data['host'], 'String');
      }
      if (data.hasOwnProperty('objects')) {
        obj['objects'] = ApiClient.convertToType(data['objects'], [EmsxFileContentObjects]);
      }
    }
    return obj;
  }


  version = undefined;

  creationDate = undefined;

  generator = undefined;

  host = undefined;

  objects = undefined;




  getVersion() {
    return this.version;
  }

  setVersion(version) {
    this['version'] = version;
  }

  getCreationDate() {
    return this.creationDate;
  }

  setCreationDate(creationDate) {
    this['creationDate'] = creationDate;
  }

  getGenerator() {
    return this.generator;
  }

  setGenerator(generator) {
    this['generator'] = generator;
  }

  getHost() {
    return this.host;
  }

  setHost(host) {
    this['host'] = host;
  }

  getObjects() {
    return this.objects;
  }

  setObjects(objects) {
    this['objects'] = objects;
  }


}