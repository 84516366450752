import { combineReducers } from "redux";

import { update } from "../utils/updateArray";

const specs = (state = [], action) => {
  return (function (state, action) {
    if (action.type === "auth/user/GET_SPECS") {
      return (action.data || []).filter((s) => s.type === "potting");
    }
    if (action.type === "potting/specs/SET_ALL") {
      return action.data;
    }
    if (action.type === "auth/user/GET_SPECS") {
      return (action.data || []).filter((s) => s.type === "potting");
    }
    if (action.type === "potting/specs/SET") {
      return update(state, action.data);
    }
    if (action.type === "potting/specs/REMOVE") {
      return state.filter((s) => s.id !== action.id);
    }
    return state;
  })(state, action).map((spec) =>
  Object.assign(
    {
      enclosureSpec: {
        tech: "supplier"
      },
      editionAllowed: true,
      trackingId:
      parseInt(Math.random() * 100) + "AA" + parseInt(Math.random() * 100),
      files: [],
      type: "potting"
    },
    spec
  )
  );
};

const quotations = (state = [], action) => {
  return (function (state, action) {
    if (action.type === "auth/user/GET_QUOTATIONS") {
      return action.data.filter((s) => s.type === "potting");
    }
    if (action.type === "potting/quotations/SET_ALL") {
      return action.data;
    }
    if (action.type === "potting/quotations/SET") {
      return update(state, action.data);
    }
    return state;
  })(state, action).map((quotation) =>
  Object.assign(
    {
      type: "potting",
      specId: quotation.specId || quotation.specificationId
    },
    quotation
  )
  );
  // if (action.type === 'auth/user/GET_QUOTATIONS') {
  //   return action.data.filter(s => s.type === 'potting')
  // }
  // if (action.type === 'potting/quotations/SET_ALL') {
  //   return action.data
  // }
  // if (action.type === 'potting/quotations/SET') {
  //   return update(state, action.data)
  // }
  // return state
};

export default combineReducers({
  specs,
  quotations
});