/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PropertyDescription {
  constructor(fr, en) {
    if (fr) {
      this['fr'] = fr;
    }
    if (en) {
      this['en'] = en;
    }


  }

  static typeDescription() {
    return "PropertyDescription";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PropertyDescription();
      if (data.hasOwnProperty('fr')) {
        obj['fr'] = ApiClient.convertToType(data['fr'], 'String');
      }
      if (data.hasOwnProperty('en')) {
        obj['en'] = ApiClient.convertToType(data['en'], 'String');
      }
      if (data.hasOwnProperty('es')) {
        obj['es'] = ApiClient.convertToType(data['es'], 'String');
      }
      if (data.hasOwnProperty('it')) {
        obj['it'] = ApiClient.convertToType(data['it'], 'String');
      }
      if (data.hasOwnProperty('de')) {
        obj['de'] = ApiClient.convertToType(data['de'], 'String');
      }
      if (data.hasOwnProperty('nl')) {
        obj['nl'] = ApiClient.convertToType(data['nl'], 'String');
      }
    }
    return obj;
  }


  fr = "";

  en = "";

  es = undefined;

  it = undefined;

  de = undefined;

  nl = undefined;




  getFr() {
    return this.fr;
  }

  setFr(fr) {
    this['fr'] = fr;
  }

  getEn() {
    return this.en;
  }

  setEn(en) {
    this['en'] = en;
  }

  getEs() {
    return this.es;
  }

  setEs(es) {
    this['es'] = es;
  }

  getIt() {
    return this.it;
  }

  setIt(it) {
    this['it'] = it;
  }

  getDe() {
    return this.de;
  }

  setDe(de) {
    this['de'] = de;
  }

  getNl() {
    return this.nl;
  }

  setNl(nl) {
    this['nl'] = nl;
  }


}