import { combineReducers } from "redux";

import { update } from "../utils/updateArray";

const blogDefaultState = { categories: [], articles: [] };
const blog = (state = blogDefaultState, action) => {
  if (action.type === "cms/blog/GET_ALL") {
    return action.data;
  }
  if (action.type === "cms/blog/SET_ALL") {
    const { data = {} } = action;
    const { categories = [], articles = [] } = data;
    return { categories, articles };
  }
  if (action.type === "cms/blog/SET") {
    return Object.assign({}, state, {
      articles: update(state.articles, action.data)
    });
  }
  if (action.type === "intl/SET_LANGUAGE") {
    return blogDefaultState;
  }

  return state;
};

const ressources = (state = [], action) => {
  if (action.type === "cms/ressources/GET_ALL") {
    return action.data;
  }
  if (action.type === "cms/ressources/SET_ALL") {
    return action.data;
  }
  if (action.type === "cms/ressources/SET") {
    return update(state, action.data);
  }

  return state;
};

const faq = (state = [], action) => {
  if (action.type === "cms/faq/GET_ALL") {
    return action.data;
  }
  if (action.type === "cms/faq/SET_ALL") {
    return action.data;
  }

  return state;
};

const images = (state = [], action) => {
  if (action.type === "cms/images/GET") {
    return action.data || null;
  }

  return state;
};

const pages = (state = [], action) => {
  if (action.type === "cms/pages/SET") {
    return update(state, action.data);
  }

  return state;
};

const demos = (state = [], action) => {
  if (action.type === "cms/demos/SET") {
    return update(state, action.data);
  }

  return state;
};

const helpMessages = (state = [], action) => {
  if (action.type === "cms/helpMessages/SET") {
    return update(state, action.data);
  }

  return state;
};

const timeline = (state = [], action) => {
  if (action.type === "cms/timeline/SET") {
    return [...action.data];
  }
  return state;
};

export default combineReducers({
  blog,
  ressources,
  faq,
  images,
  pages,
  demos,
  helpMessages,
  timeline
});