/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ProductionPhases {
  constructor(value) {
    if (value) {
      this['value'] = value;
    }


  }

  static typeDescription() {
    return "ProductionPhases";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ProductionPhases();
      if (data.hasOwnProperty('value')) {
        obj['value'] = ApiClient.convertToType(data['value'], 'String');
      }
    }
    return obj;
  }


  value = "Start";




  getValue() {
    return this.value;
  }

  setValue(value) {
    this['value'] = value;
  }


  static ValueEnum = {
    "Start": "Start",
    "OrderControl": "OrderControl",
    "OrderControl_in": "OrderControl_in",
    "OrderControl_done": "OrderControl_done",
    "BuyComponents": "BuyComponents",
    "BuyComponents_in": "BuyComponents_in",
    "BuyComponents_done": "BuyComponents_done",
    "PcbProduction": "PcbProduction",
    "PcbProduction_in": "PcbProduction_in",
    "PcbProduction_done": "PcbProduction_done",
    "WaitingForComponents": "WaitingForComponents",
    "WaitingForComponents_in": "WaitingForComponents_in",
    "WaitingForComponents_done": "WaitingForComponents_done",
    "WaitingForCustomerComponents": "WaitingForCustomerComponents",
    "WaitingForCustomerComponents_in": "WaitingForCustomerComponents_in",
    "WaitingForCustomerComponents_done": "WaitingForCustomerComponents_done",
    "ComponentsControl": "ComponentsControl",
    "ComponentsControl_in": "ComponentsControl_in",
    "ComponentsControl_done": "ComponentsControl_done",
    "CustomerComponentsControl": "CustomerComponentsControl",
    "CustomerComponentsControl_in": "CustomerComponentsControl_in",
    "CustomerComponentsControl_done": "CustomerComponentsControl_done",
    "Production": "Production",
    "Production_in": "Production_in",
    "Production_done": "Production_done",
    "ProductionCoating": "ProductionCoating",
    "ProductionCoating_in": "ProductionCoating_in",
    "ProductionCoating_done": "ProductionCoating_done",
    "ProductionXRay": "ProductionXRay",
    "ProductionXRay_in": "ProductionXRay_in",
    "ProductionXRay_done": "ProductionXRay_done",
    "ProductionControl": "ProductionControl",
    "ProductionControl_in": "ProductionControl_in",
    "ProductionControl_done": "ProductionControl_done",
    "Packaging": "Packaging",
    "Packaging_in": "Packaging_in",
    "Packaging_done": "Packaging_done",
    "Expedition": "Expedition",
    "Expedition_in": "Expedition_in",
    "Expedition_done": "Expedition_done",
    "Delivery": "Delivery",
    "Delivery_in": "Delivery_in",
    "Delivery_done": "Delivery_done"
  };

}