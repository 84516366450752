import React from "react";
import Section from "../components/Section";

const Characteristics = (props) => {
  // console.log("Characteristics render", props);
  const { data = {} } = props;
  //data Characteristics

  // const { title } = data;
  // console.log("title of props", title);
  //Verifie que le tableau à des valeurs et existe bien
  if (data.hasOwnProperty("characteristics")) {
    return (
      <Section title={data.title} className="characteristics">
        {data.characteristics.map((cat, i) =>
        <div className="characteristics_category" key={i}>
            <p className="characteristics_title">{cat.title}</p>
            <div className="characteristics_chars row">
              {cat.list.map((char, i) =>
            <div className="characteristics_char col-3" key={i}>
                  <div className="row">
                    <div className="col-1">
                      <img src="/icons/check.svg" alt="" />
                    </div>
                    <div className="col">{char}</div>
                  </div>
                </div>
            )}
            </div>
          </div>
        )}
      </Section>);

  }
  return null;
};

export default Characteristics;