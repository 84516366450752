const productList = [
"pcba",
"wiring",
"pcb",
"enclosure",
"coating",
"xray",
"assembly",
"rework",
"engineering"
// "account",
// "marketing",
];

module.exports = productList;