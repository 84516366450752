/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class EMSProtoProductOrServices {
  constructor() {


  }

  static typeDescription() {
    return "EMSProtoProductOrServices";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EMSProtoProductOrServices();
      if (data.hasOwnProperty('reference')) {
        obj['reference'] = ApiClient.convertToType(data['reference'], 'String');
      }
      if (data.hasOwnProperty('kind')) {
        obj['kind'] = ApiClient.convertToType(data['kind'], 'String');
      }
      if (data.hasOwnProperty('label')) {
        obj['label'] = ApiClient.convertToType(data['label'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('unitPrice')) {
        obj['unitPrice'] = ApiClient.convertToType(data['unitPrice'], 'Number');
      }
      if (data.hasOwnProperty('taxRate')) {
        obj['taxRate'] = ApiClient.convertToType(data['taxRate'], 'Number');
      }
    }
    return obj;
  }


  reference = undefined;

  kind = undefined;

  label = undefined;

  description = undefined;

  unitPrice = undefined;

  taxRate = undefined;




  getReference() {
    return this.reference;
  }

  setReference(reference) {
    this['reference'] = reference;
  }

  getKind() {
    return this.kind;
  }

  setKind(kind) {
    this['kind'] = kind;
  }

  getLabel() {
    return this.label;
  }

  setLabel(label) {
    this['label'] = label;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getUnitPrice() {
    return this.unitPrice;
  }

  setUnitPrice(unitPrice) {
    this['unitPrice'] = unitPrice;
  }

  getTaxRate() {
    return this.taxRate;
  }

  setTaxRate(taxRate) {
    this['taxRate'] = taxRate;
  }


  static KindEnum = {
    "Product": "Product",
    "Service": "Service"
  };

}