/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Notification {
  constructor() {


  }

  static typeDescription() {
    return "Notification";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Notification();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('level')) {
        obj['level'] = ApiClient.convertToType(data['level'], 'String');
      }
      if (data.hasOwnProperty('title')) {
        obj['title'] = ApiClient.convertToType(data['title'], 'String');
      }
      if (data.hasOwnProperty('details')) {
        obj['details'] = ApiClient.convertToType(data['details'], 'String');
      }
      if (data.hasOwnProperty('eventId')) {
        obj['eventId'] = ApiClient.convertToType(data['eventId'], 'String');
      }
      if (data.hasOwnProperty('eventDate')) {
        obj['eventDate'] = ApiClient.convertToType(data['eventDate'], 'String');
      }
      if (data.hasOwnProperty('readDate')) {
        obj['readDate'] = ApiClient.convertToType(data['readDate'], 'String');
      }
    }
    return obj;
  }


  id = undefined;

  level = undefined;

  title = undefined;

  details = undefined;

  eventId = undefined;

  eventDate = undefined;

  readDate = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getLevel() {
    return this.level;
  }

  setLevel(level) {
    this['level'] = level;
  }

  getTitle() {
    return this.title;
  }

  setTitle(title) {
    this['title'] = title;
  }

  getDetails() {
    return this.details;
  }

  setDetails(details) {
    this['details'] = details;
  }

  getEventId() {
    return this.eventId;
  }

  setEventId(eventId) {
    this['eventId'] = eventId;
  }

  getEventDate() {
    return this.eventDate;
  }

  setEventDate(eventDate) {
    this['eventDate'] = eventDate;
  }

  getReadDate() {
    return this.readDate;
  }

  setReadDate(readDate) {
    this['readDate'] = readDate;
  }


  static LevelEnum = {
    "info": "info",
    "warning": "warning"
  };

}