/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import AnomalyError from './AnomalyError';

export default class Action {
  constructor(type, description) {
    if (type) {
      this['type'] = type;
    }
    if (description) {
      this['description'] = description;
    }


  }

  static typeDescription() {
    return "Action";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Action();
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('priceAdjustment')) {
        obj['priceAdjustment'] = ApiClient.convertToType(data['priceAdjustment'], 'Number');
      }
      if (data.hasOwnProperty('productionDaysAdjustment')) {
        obj['productionDaysAdjustment'] = ApiClient.convertToType(data['productionDaysAdjustment'], 'Number');
      }
      if (data.hasOwnProperty('parameters')) {
        obj['parameters'] = ApiClient.convertToType(data['parameters'], 'String');
      }
      if (data.hasOwnProperty('errors')) {
        obj['errors'] = ApiClient.convertToType(data['errors'], [AnomalyError]);
      }
    }
    return obj;
  }


  type = "";

  description = "";

  priceAdjustment = undefined;

  productionDaysAdjustment = undefined;

  parameters = undefined;

  errors = undefined;




  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }

  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getPriceAdjustment() {
    return this.priceAdjustment;
  }

  setPriceAdjustment(priceAdjustment) {
    this['priceAdjustment'] = priceAdjustment;
  }

  getProductionDaysAdjustment() {
    return this.productionDaysAdjustment;
  }

  setProductionDaysAdjustment(productionDaysAdjustment) {
    this['productionDaysAdjustment'] = productionDaysAdjustment;
  }

  getParameters() {
    return this.parameters;
  }

  setParameters(parameters) {
    this['parameters'] = parameters;
  }

  getErrors() {
    return this.errors;
  }

  setErrors(errors) {
    this['errors'] = errors;
  }


}