/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import Anomaly from './Anomaly';
import MissingPaymentAnomalyActions from './MissingPaymentAnomalyActions';
import MissingPaymentAnomalyParameters from './MissingPaymentAnomalyParameters';

export default class MissingPaymentAnomaly {
  constructor(asAnomaly, parameters, actions) {
    this['asAnomaly'] = asAnomaly || new Anomaly();
    this['parameters'] = parameters || new MissingPaymentAnomalyParameters();
    this['actions'] = actions || new MissingPaymentAnomalyActions();

    if (!this.asAnomaly.type || this.asAnomaly.type === "") {
      this.asAnomaly.type = "MissingPaymentAnomaly";
    }
    if (!this.asAnomaly.description || this.asAnomaly.description === "") {
      this.asAnomaly.description = "Missing payment";
    }
  }

  static typeDescription() {
    return "MissingPaymentAnomaly";
  }

  static defaultDescription() {
    return "Missing payment";
  }

  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MissingPaymentAnomaly();
      if (data.hasOwnProperty('asAnomaly')) {
        obj['asAnomaly'] = Anomaly.constructFromObject(data['asAnomaly']);
      }
      if (data.hasOwnProperty('parameters')) {
        obj['parameters'] = MissingPaymentAnomalyParameters.constructFromObject(data['parameters']);
      }
      if (data.hasOwnProperty('actions')) {
        obj['actions'] = MissingPaymentAnomalyActions.constructFromObject(data['actions']);
      }
      if (data.hasOwnProperty('selectedAction')) {
        obj['selectedAction'] = ApiClient.convertToType(data['selectedAction'], 'String');
      }
    }
    return obj;
  }


  asAnomaly = undefined;

  parameters = undefined;

  actions = undefined;

  selectedAction = undefined;




  getAsAnomaly() {
    return this.asAnomaly;
  }

  setAsAnomaly(asAnomaly) {
    this['asAnomaly'] = asAnomaly;
  }

  getParameters() {
    return this.parameters;
  }

  setParameters(parameters) {
    this['parameters'] = parameters;
  }

  getActions() {
    return this.actions;
  }

  setActions(actions) {
    this['actions'] = actions;
  }

  getSelectedAction() {
    return this.selectedAction;
  }

  setSelectedAction(selectedAction) {
    this['selectedAction'] = selectedAction;
  }


  static SelectedActionEnum = {
    "cancelOrder": "cancelOrder",
    "pay": "pay"
  };

}