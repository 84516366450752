/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CustomAnomaly from './CustomAnomaly';

export default class CreateOrderAnomaly {
  constructor() {


  }

  static typeDescription() {
    return "CreateOrderAnomaly";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CreateOrderAnomaly();
      if (data.hasOwnProperty('customAnomaly')) {
        obj['customAnomaly'] = CustomAnomaly.constructFromObject(data['customAnomaly']);
      }
    }
    return obj;
  }


  customAnomaly = undefined;




  getCustomAnomaly() {
    return this.customAnomaly;
  }

  setCustomAnomaly(customAnomaly) {
    this['customAnomaly'] = customAnomaly;
  }


}