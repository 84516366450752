/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Error from '../model/Error';
import HttpRequest from '../model/HttpRequest';
import Payment from '../model/Payment';
import PaymentTransaction from '../model/PaymentTransaction';


export default class PaymentApi {


  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  createFileInPaymentTransactionWithHttpInfo(transactionId, name, content) {
    let postBody = content;

    // verify the required parameter 'transactionId' is set
    if (transactionId === undefined || transactionId === null) {
      throw new Error("Missing the required parameter 'transactionId' when calling createFileInPaymentTransaction");
    }

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling createFileInPaymentTransaction");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createFileInPaymentTransaction");
    }


    let pathParams = {
      'transactionId': transactionId,
      'name': name
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/paymentTransaction/{transactionId}/file/{name}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createFileInPaymentTransaction(transactionId, name, content) {
    return this.createFileInPaymentTransactionWithHttpInfo(transactionId, name, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createPaymentWithHttpInfo(content) {
    let postBody = content;

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createPayment");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Payment;

    return this.apiClient.callApi(
      '/payment', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createPayment(content) {
    return this.createPaymentWithHttpInfo(content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  createPaymentTransactionWithHttpInfo(customerId, content) {
    let postBody = content;

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling createPaymentTransaction");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling createPaymentTransaction");
    }


    let pathParams = {
      'customerId': customerId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = PaymentTransaction;

    return this.apiClient.callApi(
      '/paymentTransaction/customer/{customerId}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  createPaymentTransaction(customerId, content) {
    return this.createPaymentTransactionWithHttpInfo(customerId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deleteFileOfPaymentTransactionWithHttpInfo(transactionId, name) {
    let postBody = null;

    // verify the required parameter 'transactionId' is set
    if (transactionId === undefined || transactionId === null) {
      throw new Error("Missing the required parameter 'transactionId' when calling deleteFileOfPaymentTransaction");
    }

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling deleteFileOfPaymentTransaction");
    }


    let pathParams = {
      'transactionId': transactionId,
      'name': name
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/paymentTransaction/{transactionId}/file/{name}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deleteFileOfPaymentTransaction(transactionId, name) {
    return this.deleteFileOfPaymentTransactionWithHttpInfo(transactionId, name).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deletePaymentWithHttpInfo(paymentId) {
    let postBody = null;

    // verify the required parameter 'paymentId' is set
    if (paymentId === undefined || paymentId === null) {
      throw new Error("Missing the required parameter 'paymentId' when calling deletePayment");
    }


    let pathParams = {
      'paymentId': paymentId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = 'Boolean';

    return this.apiClient.callApi(
      '/payment/{paymentId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deletePayment(paymentId) {
    return this.deletePaymentWithHttpInfo(paymentId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  deletePaymentTransactionWithHttpInfo(transactionId) {
    let postBody = null;

    // verify the required parameter 'transactionId' is set
    if (transactionId === undefined || transactionId === null) {
      throw new Error("Missing the required parameter 'transactionId' when calling deletePaymentTransaction");
    }


    let pathParams = {
      'transactionId': transactionId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      '/paymentTransaction/{transactionId}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  deletePaymentTransaction(transactionId) {
    return this.deletePaymentTransactionWithHttpInfo(transactionId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  findPaymentTransactionsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'customerId': opts['customerId'],
      'dateFrom': opts['dateFrom'],
      'dateUntil': opts['dateUntil'],
      'orderId': opts['orderId'],
      'invoiceId': opts['invoiceId']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [PaymentTransaction];

    return this.apiClient.callApi(
      '/paymentTransaction', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  findPaymentTransactions(opts) {
    return this.findPaymentTransactionsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getFileOfPaymentTransactionWithHttpInfo(transactionId, name) {
    let postBody = null;

    // verify the required parameter 'transactionId' is set
    if (transactionId === undefined || transactionId === null) {
      throw new Error("Missing the required parameter 'transactionId' when calling getFileOfPaymentTransaction");
    }

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling getFileOfPaymentTransaction");
    }


    let pathParams = {
      'transactionId': transactionId,
      'name': name
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/octet-stream'];
    let returnType = 'Blob';

    return this.apiClient.callApi(
      '/paymentTransaction/{transactionId}/file/{name}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getFileOfPaymentTransaction(transactionId, name) {
    return this.getFileOfPaymentTransactionWithHttpInfo(transactionId, name).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getPaymentWithHttpInfo(paymentId) {
    let postBody = null;

    // verify the required parameter 'paymentId' is set
    if (paymentId === undefined || paymentId === null) {
      throw new Error("Missing the required parameter 'paymentId' when calling getPayment");
    }


    let pathParams = {
      'paymentId': paymentId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = Payment;

    return this.apiClient.callApi(
      '/payment/{paymentId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getPayment(paymentId) {
    return this.getPaymentWithHttpInfo(paymentId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getPaymentTransactionWithHttpInfo(transactionId) {
    let postBody = null;

    // verify the required parameter 'transactionId' is set
    if (transactionId === undefined || transactionId === null) {
      throw new Error("Missing the required parameter 'transactionId' when calling getPaymentTransaction");
    }


    let pathParams = {
      'transactionId': transactionId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = PaymentTransaction;

    return this.apiClient.callApi(
      '/paymentTransaction/{transactionId}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getPaymentTransaction(transactionId) {
    return this.getPaymentTransactionWithHttpInfo(transactionId).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  getPaymentsWithHttpInfo(opts) {
    opts = opts || {};
    let postBody = null;


    let pathParams = {
    };
    let queryParams = {
      'customerId': opts['customerId'],
      'dateFrom': opts['dateFrom'],
      'dateUntil': opts['dateUntil'],
      'isProcessed': opts['isProcessed']
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [Payment];

    return this.apiClient.callApi(
      '/payments', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  getPayments(opts) {
    return this.getPaymentsWithHttpInfo(opts).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  moneticoPaymentResultWithHttpInfo(parameters) {
    let postBody = parameters;

    // verify the required parameter 'parameters' is set
    if (parameters === undefined || parameters === null) {
      throw new Error("Missing the required parameter 'parameters' when calling moneticoPaymentResult");
    }


    let pathParams = {
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = [];
    let contentTypes = ['*/*'];
    let accepts = ['text/plain'];
    let returnType = 'String';

    return this.apiClient.callApi(
      '/payment/monetico/result', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  moneticoPaymentResult(parameters) {
    return this.moneticoPaymentResultWithHttpInfo(parameters).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  processPaymentWithHttpInfo(paymentId, returnUrl) {
    let postBody = null;

    // verify the required parameter 'paymentId' is set
    if (paymentId === undefined || paymentId === null) {
      throw new Error("Missing the required parameter 'paymentId' when calling processPayment");
    }

    // verify the required parameter 'returnUrl' is set
    if (returnUrl === undefined || returnUrl === null) {
      throw new Error("Missing the required parameter 'returnUrl' when calling processPayment");
    }


    let pathParams = {
      'paymentId': paymentId
    };
    let queryParams = {
      'returnUrl': returnUrl
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = HttpRequest;

    return this.apiClient.callApi(
      '/payment/{paymentId}/process', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  processPayment(paymentId, returnUrl) {
    return this.processPaymentWithHttpInfo(paymentId, returnUrl).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  replaceFileOfPaymentTransactionWithHttpInfo(transactionId, name, content) {
    let postBody = content;

    // verify the required parameter 'transactionId' is set
    if (transactionId === undefined || transactionId === null) {
      throw new Error("Missing the required parameter 'transactionId' when calling replaceFileOfPaymentTransaction");
    }

    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling replaceFileOfPaymentTransaction");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling replaceFileOfPaymentTransaction");
    }


    let pathParams = {
      'transactionId': transactionId,
      'name': name
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/octet-stream'];
    let accepts = ['application/json'];
    let returnType = null;

    return this.apiClient.callApi(
      '/paymentTransaction/{transactionId}/file/{name}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  replaceFileOfPaymentTransaction(transactionId, name, content) {
    return this.replaceFileOfPaymentTransactionWithHttpInfo(transactionId, name, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setPaymentWithHttpInfo(paymentId, content) {
    let postBody = content;

    // verify the required parameter 'paymentId' is set
    if (paymentId === undefined || paymentId === null) {
      throw new Error("Missing the required parameter 'paymentId' when calling setPayment");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setPayment");
    }


    let pathParams = {
      'paymentId': paymentId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = Payment;

    return this.apiClient.callApi(
      '/payment/{paymentId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setPayment(paymentId, content) {
    return this.setPaymentWithHttpInfo(paymentId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }
  setPaymentTransactionWithHttpInfo(transactionId, content) {
    let postBody = content;

    // verify the required parameter 'transactionId' is set
    if (transactionId === undefined || transactionId === null) {
      throw new Error("Missing the required parameter 'transactionId' when calling setPaymentTransaction");
    }

    // verify the required parameter 'content' is set
    if (content === undefined || content === null) {
      throw new Error("Missing the required parameter 'content' when calling setPaymentTransaction");
    }


    let pathParams = {
      'transactionId': transactionId
    };
    let queryParams = {
    };
    let headerParams = {
    };
    let formParams = {
    };

    let authNames = ['api_key'];
    let contentTypes = ['application/json'];
    let accepts = ['application/json'];
    let returnType = PaymentTransaction;

    return this.apiClient.callApi(
      '/paymentTransaction/{transactionId}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  setPaymentTransaction(transactionId, content) {
    return this.setPaymentTransactionWithHttpInfo(transactionId, content).
    then(function (response_and_data) {
      return response_and_data.data;
    });
  }


}