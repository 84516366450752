import React from "react";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";

import { Glyphicon, Link } from "Elements";
import Button from "../components/Button";
import Section from "../components/Section";
import cmsActions from "Cms/actions";

const nbArticle = 7;

const messages = defineMessages({
  lastNews: { "id": "marketing.lastNews", "defaultMessage": "The latest news" },



  allNews: { "id": "marketing.allNews", "defaultMessage": "All the news" },



  lastTweets: { "id": "marketing.newsAndTweets.lastTweets", "defaultMessage": "Last tweets" },



  ourTimeLine: { "id": "marketing.newsAndTweets.ourTimeLine", "defaultMessage": "Our timeline" }



});

class NewsAndTweets extends React.Component {
  componentDidMount() {
    const { props } = this;
    const { articles = [], timeline = [], intl = {} } = props;
    if (articles.length < nbArticle) {
      cmsActions.getBlog();
    }
    if (!timeline.length) {
      cmsActions.getTwitterTimeline();
    }
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    const { intl = {} } = props;
    const { intl: oIntl = {} } = oProps;
    if (intl.locale !== oIntl.locale) {
      cmsActions.getBlog();
    }
  }
  render() {
    const { props } = this;
    const { data = {}, timeline = [] } = props;

    const { articles = [] } = props;
    console.log("NewsAndTweets render", props);
    return (
      <Section className="news_and_tweets pb-0">
        <div className="row" id="latestNews">
          <div className="col-12 my-4 col-sm-10 offset-sm-1 col-md-6 offset-md-0 d-flex flex-column justify-content-between">
            <h3 className="marketing_section__title text-pcba">
              {props.intl.formatMessage(messages.lastNews)}
            </h3>
            <div className="row">
              <div
                className="col-10 offset-1"
                style={{
                  maxHeight: 380,
                  overflow: "hidden"
                }}>

                <table className="table">
                  <tbody>
                    {articles.slice(0, nbArticle).map((article, i) =>
                    <tr key={i}>
                        <td>
                          <Link
                          to={[
                          "blog",
                          article.category,
                          article.slug,
                          article.id].
                          join("/")}>

                            {article.title}
                          </Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-center">
              <Button to="/blog">
                <span>{props.intl.formatMessage(messages.allNews)}</span>
              </Button>
            </div>
          </div>
          <div className="col-12 my-4 col-sm-10 offset-sm-1 col-md-6 offset-md-0 d-flex flex-column justify-content-between news_and_tweets__tweets">
            <h4 className="marketing_section__title text-pcba">
              {props.intl.formatMessage(messages.lastTweets)}
            </h4>
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <table className="table">
                  <tbody>
                    {timeline.map((t, i) => {
                      const { text = "" } = t;
                      const url =
                      "https://" + text.split("https://").splice(-1);
                      return (
                        <tr key={i}>
                          <td>
                            <Glyphicon lib="fab fa-3x" glyph="twitter" />
                          </td>
                          <td>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={url}
                              className="text-decoration-none text-left">

                              {text}
                            </a>
                          </td>
                        </tr>);

                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-center">
              <a
                target="_blank"
                href="https://twitter.com/ems_factory"
                className="btn btn-outline-primary">

                <span>{props.intl.formatMessage(messages.ourTimeLine)}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="row news_and_tweets__social mb-5">
          {}
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 mt-md-3 col-lg-6 offset-lg-3 mt-lg-4 mt-xl-5">
            <div className="row">
              <div className="col">
                <a target="_blank" href="https://twitter.com/ems_factory">
                  <img
                    width="55"
                    height="55"
                    className="lazyload"
                    data-src="/images/marketing/social/twitter.svg"
                    alt="" />

                </a>
              </div>
              <div className="col">
                <a
                  target="_blank"
                  href="https://www.facebook.com/EmsFactoryFrance">

                  <img
                    width="55"
                    height="55"
                    className="lazyload"
                    data-src="/images/marketing/social/facebook.svg"
                    alt="" />

                </a>
              </div>
              <div className="col">
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCO5KcNWcaQlEzbkmOngZCIw">

                  <img
                    width="55"
                    height="55"
                    className="lazyload"
                    data-src="/images/marketing/social/youtube.svg"
                    alt="" />

                </a>
              </div>
              <div className="col">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/emsfactory/">

                  <img
                    width="55"
                    height="55"
                    className="lazyload"
                    data-src="/images/marketing/social/linkedin.svg"
                    alt="" />

                </a>
              </div>
              <div className="col">
                <a
                  target="_blank"
                  href="https://www.instagram.com/emsfactory_fr/">

                  <img
                    width="55"
                    height="55"
                    className="lazyload"
                    data-src="/images/marketing/social/instagram.svg"
                    alt="" />

                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>);

  }
}

const mapStateToProps = (state) => {
  return {
    articles: state.cms.blog.articles || [],
    timeline: state.cms.timeline
  };
};

export default connect(mapStateToProps)(injectIntl(NewsAndTweets));