import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import "./index.css";

import Auth from "../Auth";

import ModuleLoader from "ModuleLoader";

import intlActions from "Intl/actions";

import metaTags from "utils/metaTags";

const messages = defineMessages({
  accountTitle: { "id": "account.accountTitle", "defaultMessage": "Customer account" }



});

const Module = ModuleLoader("account");

class Account extends React.Component {
  componentDidMount() {
    intlActions.askDomains(["account"]);
  }
  render() {
    const { props } = this;

    metaTags.set({
      title: props.intl.formatMessage(messages.accountTitle)
    });
    // console.log('Account render', root)
    return (
      <Auth userMustBeLogged>
        {React.createElement(Module, Object.assign({}, props))}
      </Auth>);

  }
}

export default injectIntl(Account);

// const Menu = injectIntl(props => <div id="side-menu">
//   <ul className="nav nav-pills flex-column">
//     <NavLink exact className="nav-link" to='/account' >{props.intl.formatMessage(accountMessages.dashboard)}</NavLink>
//     <NavLink className="nav-link" to='/account/profile' >{props.intl.formatMessage(accountMessages.profile)}</NavLink>
//     <NavLink className="nav-link" to='/account/projects' >{props.intl.formatMessage(accountMessages.projects)}</NavLink>
//     <NavLink className="nav-link" to='/account/quotations' >{props.intl.formatMessage(accountMessages.quotations)}</NavLink>
//     <NavLink className="nav-link" to='/account/orders' >{props.intl.formatMessage(accountMessages.orders)}</NavLink>
//     <NavLink className="nav-link" to='/account/Invoices' >{props.intl.formatMessage(accountMessages.Invoices)}</NavLink>
//   </ul>
// </div>)