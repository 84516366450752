/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class QuotationLine {
  constructor(description, quantity, unitPrice) {
    if (description) {
      this['description'] = description;
    }
    if (quantity) {
      this['quantity'] = quantity;
    }
    if (unitPrice) {
      this['unitPrice'] = unitPrice;
    }


  }

  static typeDescription() {
    return "QuotationLine";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new QuotationLine();
      if (data.hasOwnProperty('description')) {
        obj['description'] = ApiClient.convertToType(data['description'], 'String');
      }
      if (data.hasOwnProperty('descriptionEn')) {
        obj['descriptionEn'] = ApiClient.convertToType(data['descriptionEn'], 'String');
      }
      if (data.hasOwnProperty('label')) {
        obj['label'] = ApiClient.convertToType(data['label'], 'String');
      }
      if (data.hasOwnProperty('kind')) {
        obj['kind'] = ApiClient.convertToType(data['kind'], 'String');
      }
      if (data.hasOwnProperty('emsReference')) {
        obj['emsReference'] = ApiClient.convertToType(data['emsReference'], 'String');
      }
      if (data.hasOwnProperty('quantity')) {
        obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
      }
      if (data.hasOwnProperty('unitPrice')) {
        obj['unitPrice'] = ApiClient.convertToType(data['unitPrice'], 'Number');
      }
      if (data.hasOwnProperty('taxRate')) {
        obj['taxRate'] = ApiClient.convertToType(data['taxRate'], 'Number');
      }
      if (data.hasOwnProperty('discountRate')) {
        obj['discountRate'] = ApiClient.convertToType(data['discountRate'], 'Number');
      }
    }
    return obj;
  }


  description = "";

  descriptionEn = undefined;

  label = undefined;

  kind = undefined;

  emsReference = undefined;

  quantity = 0.0;

  unitPrice = 0.0;

  taxRate = undefined;

  discountRate = undefined;




  getDescription() {
    return this.description;
  }

  setDescription(description) {
    this['description'] = description;
  }

  getDescriptionEn() {
    return this.descriptionEn;
  }

  setDescriptionEn(descriptionEn) {
    this['descriptionEn'] = descriptionEn;
  }

  getLabel() {
    return this.label;
  }

  setLabel(label) {
    this['label'] = label;
  }

  getKind() {
    return this.kind;
  }

  setKind(kind) {
    this['kind'] = kind;
  }

  getEmsReference() {
    return this.emsReference;
  }

  setEmsReference(emsReference) {
    this['emsReference'] = emsReference;
  }

  getQuantity() {
    return this.quantity;
  }

  setQuantity(quantity) {
    this['quantity'] = quantity;
  }

  getUnitPrice() {
    return this.unitPrice;
  }

  setUnitPrice(unitPrice) {
    this['unitPrice'] = unitPrice;
  }

  getTaxRate() {
    return this.taxRate;
  }

  setTaxRate(taxRate) {
    this['taxRate'] = taxRate;
  }

  getDiscountRate() {
    return this.discountRate;
  }

  setDiscountRate(discountRate) {
    this['discountRate'] = discountRate;
  }


  static KindEnum = {
    "Product": "Product",
    "Service": "Service"
  };

}