import { combineReducers } from "redux";

import { update, updateMultiple } from "utils/updateArray";

const specs = (state = [], action) => {
  return (function (state, action) {
    if (action.type === "AUTH_LOGOUT") {
      return [];
    }
    if (action.type === "auth/user/GET_SPECS") {
      return (action.data || []).filter((s) => s.type === "xray");
    }
    if (action.type === "xray/specs/SET_ALL") {
      return action.data;
    }
    if (action.type === "auth/user/GET_SPECS") {
      return (action.data || []).filter((s) => s.type === "xray");
    }
    if (action.type === "xray/specs/SET") {
      return update(state, action.data);
    }
    if (action.type === "xray/specs/REMOVE") {
      return state.filter((s) => s.id !== action.id);
    }
    return state;
  })(state, action).map((spec) =>
  Object.assign(
    {
      designators: [],
      allSurface: false,
      editionAllowed: true,
      trackingId:
      parseInt(Math.random() * 100) + "AA" + parseInt(Math.random() * 100),
      files: [],
      type: "xray"
    },
    spec
  )
  );
};

const quotations = (state = [], action) => {
  return (function (state, action) {
    if (action.type === "AUTH_LOGOUT") {
      return [];
    }
    if (action.type === "auth/user/GET_QUOTATIONS") {
      return action.data.filter((s) => s.type === "xray");
    }
    if (action.type === "xray/quotations/SET_ALL") {
      return action.data;
    }
    if (action.type === "xray/quotations/SET") {
      return update(state, action.data);
    }
    if (action.type === "quotes/SET") {
      const { data = {} } = action;
      const { quotations = [] } = data;
      return updateMultiple(
        state,
        quotations.filter((q) => q.type === "xray")
      );
    }
    return state;
  })(state, action).map((quotation) =>
  Object.assign(
    {
      type: "xray",
      specId: quotations.specId || quotation.specificationId
    },
    quotation,
    {
      quantity: (quotation.targetDevices || []).length
    }
  )
  );
  // if (action.type === 'auth/user/GET_QUOTATIONS') {
  //   return action.data.filter(s => s.type === 'xray')
  // }
  // if (action.type === 'xray/quotations/SET_ALL') {
  //   return action.data
  // }
  // if (action.type === 'xray/quotations/SET') {
  //   return update(state, action.data)
  // }
  // return state
};

export default combineReducers({
  specs,
  quotations
});