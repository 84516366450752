/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PaymentInvoicesDispatch {
  constructor(invoiceId, amount) {
    if (invoiceId) {
      this['invoiceId'] = invoiceId;
    }
    if (amount) {
      this['amount'] = amount;
    }


  }

  static typeDescription() {
    return "PaymentInvoicesDispatch";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PaymentInvoicesDispatch();
      if (data.hasOwnProperty('invoiceId')) {
        obj['invoiceId'] = ApiClient.convertToType(data['invoiceId'], 'String');
      }
      if (data.hasOwnProperty('amount')) {
        obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
      }
    }
    return obj;
  }


  invoiceId = undefined;

  amount = 0.0;




  getInvoiceId() {
    return this.invoiceId;
  }

  setInvoiceId(invoiceId) {
    this['invoiceId'] = invoiceId;
  }

  getAmount() {
    return this.amount;
  }

  setAmount(amount) {
    this['amount'] = amount;
  }


}