import React from "react";

import Section from "../components/Section";
import Content from "components/ContentEditor/Content";
import GalleryPreview from "components/GalleryPreview";

class RespectfulFactory extends React.Component {
  state = {};
  render() {
    const { props } = this;
    const { data = {} } = props;

    const { images = [], title, subTitle, list } = data;
    return (
      <Section title={title}>
        <div className="row">
          <div className="col-md-4 col-lg-6">
            <GalleryPreview images={images} />
          </div>

          <div className="col-md-8 col-lg-6">
            <h4 className="pl-4">{subTitle}</h4>
            <ul>
              <Content blocks={list} /> 
            </ul>
          </div>
        </div>
      </Section>);

  }
}

export default RespectfulFactory;