/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PriceRange {
  constructor(quantityMin, price) {
    if (quantityMin) {
      this['quantityMin'] = quantityMin;
    }
    if (price) {
      this['price'] = price;
    }


  }

  static typeDescription() {
    return "PriceRange";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PriceRange();
      if (data.hasOwnProperty('quantityMin')) {
        obj['quantityMin'] = ApiClient.convertToType(data['quantityMin'], 'Number');
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = ApiClient.convertToType(data['price'], 'Number');
      }
    }
    return obj;
  }


  quantityMin = 0.0;

  price = 0.0;




  getQuantityMin() {
    return this.quantityMin;
  }

  setQuantityMin(quantityMin) {
    this['quantityMin'] = quantityMin;
  }

  getPrice() {
    return this.price;
  }

  setPrice(price) {
    this['price'] = price;
  }


}