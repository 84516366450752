import React from "react";
import { Button } from "reactstrap";

const ButtonComponent = (props) => {
  let nProps = {
    color: props.bsStyle,
    onClick: props.onClick,
    block: props.block,
    className: props.className,
    id: props.id,
    size: props.size || props.bsSize,
    disabled: props.disabled ? true : false,
    outline: props.outline,
    ref: props.ref,
    active: props.active,
    type: props.type,
    style: props.style
  };
  if (nProps.color === "default") {
    nProps.outline = true;
    nProps.color = "primary";
  }

  return <Button {...nProps}>{props.children}</Button>;
};

export default ButtonComponent;