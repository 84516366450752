import React from "react";
import { connect } from "react-redux";

import metaTags from "utils/metaTags";

import { injectIntl } from "react-intl";

import Section from "marketing/components/Section";
import HelpTile from "marketing/components/HelpTile";
import StartProject from "marketing/components/StartProject";
import { Faq } from "marketing/Faq";
// import { DateInput } from "Elements";

import FormContact from "marketing/components/FormContact";
import Map from "marketing/components/map";
import { withRequests } from "Requests";
// import commonsMessages from "../../commons/messages";
import cmsActions from "Cms/actions";

// const messages = defineMessages({
//   // nbPerson: {
//   //   id: "marketing.contact.nbPerson",
//   //   defaultMessage: "Nb de personnes",
//   // },
//   // send: {
//   //   id: "marketing.contact.send",
//   //   defaultMessage: "Envoyer",
//   // },
// });

class ContactPage extends React.Component {
  state = {};
  // componentDidMount() {
  //   const { props } = this;
  //   const language = this.props.intl.locale;
  //   props.addRequest("blog/getArticles", CmsActions.getBlog(language));
  // }
  onSubmit(e) {
    this.setState({ sendMessage: "pending" });
    e.preventDefault();
    const { target = {} } = e;
    const data = [
    "senderName",
    "senderEmail",
    "phone",
    "company",
    "comment"].
    reduce((res, k) => {
      res[k] = (target[k] || {}).value;
      return res;
    }, {});
    cmsActions.sendForm("press", data).then(({ body }) => {
      console.log("PressPage sendForm", body);
      this.setState({ sendMessage: body.sent ? "done" : "err" });
    });
  }
  render() {
    const { props, state } = this;
    const { data = {}, page = {} } = props;

    // const { sendMessage } = state;

    // const articles = props.articles || [];
    console.log("press render", props);

    metaTags.set(page);
    return (
      <div className="contact_background">
        <Section
          className="page_cover contactcover background_white lazyload"
          data-bg="/images/marketing/pcba/cover.jpg">

          <div
            className="bg-primary"
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              left: "0",
              top: "0",
              opacity: "0.75"
            }}>
          </div>
          <div className="col">
            <div className="product_page_cover_intro text-white d-flex flex-column align-items-center">
              <h1 style={{ letterSpacing: "4px" }}>{page.title}</h1>
              <div className="pt-2">
                <span>{page.description}</span>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <h4 className="marketing_section__title">{data.faqTitle}</h4>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <Faq />
              <h4>{data.formTitle}</h4>
              <p>{data.formText}</p>
              <FormContact></FormContact>
            </div>
          </div>
        </Section>

        <Map
          contactezNous
          findUs={data.mapTitle}
          address={data.mapAddress}
          openHours={data.openHours} />

        <Section>
          <h4 className="marketing_section__title" style={{ width: "60%" }}>
            {data.visitTitle}
          </h4>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <p
                  className="text-center"
                  style={{ fontSize: "18px", fontWeight: "600" }}>

                  {data.visitText}
                </p>
              </div>
            </div>
            <strong className="mx-5 pb-5">{data.openHours}</strong>
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="row">
                  <div className="col-md-6">
                    <FormContact
                      vertical
                      no={["company", "name"]}
                      form="visit"
                      capchaMaxWords="8"
                      rows={4}>

                      {/*<div className="col-md-12 sep-top-xs">
                        <div className="form-group">
                        <input
                         id="email"
                         type="email"
                         placeholder={props.intl.formatMessage(messages.email)}
                         name="senderEmail"
                         className="form-control input-lg required email"
                         style={{ backgroundColor: "#e9ecef" }}
                        />
                        </div>
                        </div>
                        <div className="col-md-12 sep-top-xs">
                        <div className="form-group">
                        <input
                         id="phone"
                         type="text"
                         placeholder={props.intl.formatMessage(messages.phone)}
                         name="phone"
                         className="form-control input-lg required"
                         style={{ backgroundColor: "#e9ecef" }}
                        />
                        </div>
                        </div>
                        <div className="col-md-12 sep-top-xs">
                        <div className="form-group">
                        <select
                         className="form-control"
                         id="person"
                         style={{ backgroundColor: "#e9ecef" }}
                        >
                         <option>
                           {props.intl.formatMessage(messages.nbPerson)}
                         </option>
                         <option value="1">1</option>
                         <option value="2">2</option>
                         <option value="3">3</option>
                         <option value="4">4</option>
                         <option value="5">5</option>
                         <option value="6">6</option>
                         <option value="7">7</option>
                         <option value="8">8</option>
                         <option value="9">9</option>
                         <option value="10">10</option>
                         <option value="11">11</option>
                         <option value="12">12</option>
                         <option value="13">13</option>
                         <option value="14">14</option>
                         <option value="15">15</option>
                        </select>
                        </div>
                        </div>
                        <div className="col-md-12 sep-top-xs">
                        <div className="form-group">
                        <DateInput />
                        </div>
                        </div>
                        <div className="col-md-12 sep-top-xs d-flex justify-content-end">
                        <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                         <i className="fa fa-paper-plane"></i>&nbsp;
                         {props.intl.formatMessage(messages.send)}
                        </button>
                        </div>
                        </div> */}
                    </FormContact>
                  </div>
                  <div id="map" className="col-md-6">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11342.328370916672!2d-0.5821163!3d44.7077304!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6b35206c88bfc1d!2sEMSFACTORY!5e0!3m2!1sfr!2sfr!4v1647365896893!5m2!1sfr!2sfr"
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy">
                    </iframe>
                    {/* <iframe
                       className=""
                       title="map"
                       src="https://www.google.com/maps/d/embed?mid=1dk2lv0zydep6MBPr9AFzO8ZzxuOSs4vX"
                       width="100%"
                       height="300"
                      ></iframe> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <StartProject />
        <HelpTile />
      </div>);

  }
}

const mapStateToProps = (state) => {
  const { cms = {} } = state;
  const { blog = {} } = cms;
  const { categories = [], articles = [] } = blog;
  return {
    articles: articles.filter((a) => a.category === "press"),
    categories
  };
};

export default withRequests(connect(mapStateToProps)(injectIntl(ContactPage)));