import PaymentApi from "../api/api/PaymentApi";
import { store } from "../initStore";
import RequestsActions from "../Requests/actions";

// const store = getStore()
const { dispatch } = store;
const paymentApi = new PaymentApi();

const actions = {
  process: (paymentId, url) => {
    const payments = store.getState().payments || [];
    const payment = payments.find((p) => p.id === paymentId) || {};
    if (["cb", "paypal"].indexOf(payment) >= 0) {
      store.dispatch({
        type: "misc/redirectingToPayment/SET"
      });
    }
    return paymentApi.processPayment(paymentId, url).then((res) => {
      console.log("payment process res", res);
      if (res.uri) {
        post(res.uri);
        return false;
      } else {
        return true;
      }
    });
  },
  get: (paymentId) => {
    return paymentApi.getPayment(paymentId).then((payment) => {
      store.dispatch({
        type: "payments/SET",
        data: payment
      });
      return payment;
    });
  },
  getAll: () =>
  paymentApi.getPayments().then((data) => {
    store.dispatch({ type: "payments/SET_ALL", data });
    return data;
  }),
  savePayment: (paymentId, content) => {
    return RequestsActions.add(
      "payments/save/" + paymentId,
      paymentApi.setPayment(paymentId, content).then((payment) => {
        store.dispatch({
          type: "payments/SET",
          data: payment
        });
        return payment;
      })
    );
  }
};

export default actions;

function post(path, params, method) {
  method = method || "post"; // Set method to post by default if not specified.

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  if (typeof window !== "undefined") {
    var form = window.document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var hiddenField = window.document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);

        form.appendChild(hiddenField);
      }
    }

    window.document.body.appendChild(form);
    form.submit();
  }
}