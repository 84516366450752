import { store } from "../initStore";

// const store = getStore()
const { dispatch } = store;

const actions = {
  add: (id, promise) => {
    if (typeof promise.then === "function") {
      store.dispatch({
        type: "requests/ADD",
        id
      });
      return promise.
      then((data) => {
        store.dispatch({
          type: "requests/DONE",
          id
        });
        return data;
      }).
      catch((err) => {
        store.dispatch({
          type: "requests/ERR",
          id,
          err: err || {}
        });
        return Promise.reject(err) || {};
      });
    } else {
      return Promise.resolve(promise);
    }
  },
  remove: (id) =>
  store.dispatch({
    type: "requests/REMOVE",
    id
  }),
  removeAll: (ids) =>
  store.dispatch({
    type: "requests/REMOVE_ALL",
    ids
  })
};

export default actions;