import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withAuth } from "Auth/Context";
import { Glyphicon, Link } from "Elements";

import notificationsActions from "Notifications/actions";

class HeaderUserNotifications extends React.Component {
  componentDidMount() {
    window.setInterval(notificationsActions.fetchAll, 2 * 60 * 1000);
  }
  componentDidUpdate(oProps) {
    if (this.props.user.webLogin !== oProps.user.webLogin) {
      notificationsActions.getAll();
    }
  }
  render() {
    const { props } = this;
    return props.auth.user.webLogin ?
    <div>
        <Link
        to={"/" + props.intl.locale + "/account/notifications"}
        className="p-0 m-0">

          <Glyphicon glyph="bell" className="icon mr-md-2" />
          <span className="text-secondary d-none d-md-inline">
            ( {props.notifications.length} )
          </span>
        </Link>
      </div> :
    null;
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.filter((n) => n.status === "unread"),
    user: state.auth.user
  };
};

export default injectIntl(
  withAuth(connect(mapStateToProps)(HeaderUserNotifications))
);