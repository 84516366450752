/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import XRay3DImage from './XRay3DImage';

export default class XRay3DShooting {
  constructor(xray3DTargetId, xrayTargetDeviceId) {
    if (xray3DTargetId) {
      this['xray3DTargetId'] = xray3DTargetId;
    }
    if (xrayTargetDeviceId) {
      this['xrayTargetDeviceId'] = xrayTargetDeviceId;
    }


  }

  static typeDescription() {
    return "XRay3DShooting";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new XRay3DShooting();
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('xray3DTargetId')) {
        obj['xray3DTargetId'] = ApiClient.convertToType(data['xray3DTargetId'], 'String');
      }
      if (data.hasOwnProperty('xrayTargetDeviceId')) {
        obj['xrayTargetDeviceId'] = ApiClient.convertToType(data['xrayTargetDeviceId'], 'String');
      }
      if (data.hasOwnProperty('results')) {
        obj['results'] = ApiClient.convertToType(data['results'], [XRay3DImage]);
      }
    }
    return obj;
  }


  id = undefined;

  xray3DTargetId = undefined;

  xrayTargetDeviceId = undefined;

  results = undefined;




  getId() {
    return this.id;
  }

  setId(id) {
    this['id'] = id;
  }

  getXray3DTargetId() {
    return this.xray3DTargetId;
  }

  setXray3DTargetId(xray3DTargetId) {
    this['xray3DTargetId'] = xray3DTargetId;
  }

  getXrayTargetDeviceId() {
    return this.xrayTargetDeviceId;
  }

  setXrayTargetDeviceId(xrayTargetDeviceId) {
    this['xrayTargetDeviceId'] = xrayTargetDeviceId;
  }

  getResults() {
    return this.results;
  }

  setResults(results) {
    this['results'] = results;
  }


}