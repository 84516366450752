import { store } from "initStore";
import requestsActions from "Requests/actions";
import request from "superagent";

import MiscApi from "api/api/MiscApi";

const miscApi = new MiscApi();

const actions = {
  getMetrics: () =>
  requestsActions.
  add("misc/factoryMetrics/GET", miscApi.getFactoryMetrics()).
  then((data) => {
    store.dispatch({
      type: "misc/factoryMetrics/GET",
      data
    });
    return data;
  }),
  checkCpmMaintenanceState: () =>
  request.
  get("/cpmMaintenance.json?n=" + Math.round(Math.random() * 1e4)).
  then((res) => {
    const body = res.body || "";
    const data = body.
    split(",").
    map((s) => s.trim().toLowerCase()).
    filter((s) => s && s !== "no");
    store.dispatch({
      type: "misc/cpmMaintenance/SET",
      data
    });
  }).
  catch((err) => {
    return {};
  }),
  getCpmVersion: () =>
  miscApi.getServerStatus().then((serverStatus) =>
  store.dispatch({
    type: "misc/cpmVersion/SET",
    data: serverStatus.version
  })
  ),
  setCookies: (data) => {
    actions.updateGTagConsent(data);
    return store.dispatch({
      type: "misc/cookies/SET",
      data
    });
  },
  updateGTagConsent: (data) =>
  gtag("consent", "update", {
    analytics_storage: data ? "granted" : "denied"
  })
};

function gtag() {
  return window.dataLayer ? window.dataLayer.push(arguments) : null;
}

export default actions;