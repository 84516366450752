import React from "react";

const Image = ({
  image = {},
  onClick = (e) => {},
  className = "img-fluid",
  style,
  sizes
}) =>
<img
  data-src={image.src}
  data-srcset={image.srcset}
  alt={image.description}
  onClick={(e) => onClick(e)}
  className={"lazyload " + className}
  style={style}
  data-sizes={sizes} />;



export default Image;