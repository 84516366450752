import { createStore, combineReducers, compose } from "redux";

import anomalies from "Anomalies/reducer";
import requests from "./Requests/reducer";
import auth from "./Auth/reducer";
import cart from "./Cart/reducer";
import savedCarts from "./SavedCarts/reducer";
import collaborators from "./Collaborators/reducer";

import pcba from "./PCBA/reducer";
import wiring from "./Wiring/reducer";
import pcb from "./Pcb/reducer";
import enclosure from "Enclosure/reducer";
import coating from "./Coating/reducer";
import potting from "./Potting/reducer";
import xray from "./XRay/reducer";
import assembly from "Assembly/reducer";
import rework from "Rework/reducer";
import engineering from "Engineering/reducer";
import supply from "Supply/reducer";

import quotes from "Quotes/reducer";
import quoteIdToReferences from "./Quotes/reducerQuoteIdToReferences";
import orders from "./Orders/reducer";
import payments from "./Payments/reducer";
import deliveries from "Deliveries/reducer";
import quotationCustomerDeliveryContent from "Deliveries/quotationCustomerDeliveryContentReducer";
import invoices from "Invoices/reducer";

import users from "Users/reducer";
import intl from "Intl/reducer";
import help from "Help/reducer";
import messaging from "Messaging/reducer";
import notifications from "Notifications/reducer";
import cms from "Cms/reducer";
import misc from "Misc/reducer";

export var store = { dispatch: (e) => e };

const initStore = (data = {}) => {
  // if (store.dispatch) {
  //   return store
  // }
  const composeEnhancers =
  typeof window !== "undefined" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
  compose;
  store = createStore(
    combineReducers({
      anomalies,
      requests,
      auth,
      cart,
      savedCarts,
      collaborators,
      quotes,
      quoteIdToReferences,
      orders,
      payments,
      deliveries,
      quotationCustomerDeliveryContent,
      invoices,

      users,
      intl,
      help,
      messaging,
      notifications,
      cms,
      misc,

      pcba,
      wiring,
      pcb,
      enclosure,
      coating,
      potting,
      xray,
      assembly,
      rework,
      engineering,
      supply
    }),
    data,
    composeEnhancers()
  );

  if (typeof window !== "undefined") {
    window.store = store;
  }

  return store;
};

export default initStore;