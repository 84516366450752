import React from 'react';

const Wrapper = (props) => {
  if (props.primary) {
    return <WrapperPrimary>{props.children}</WrapperPrimary>;
  }
  if (props.gray) {
    return <WrapperGray>{props.children}</WrapperGray>;
  }
  return <section className="call-to-action sep-top-md sep-bottom-md">
        <div className="container">
          {props.children}
        </div>
      </section>;
};

export const WrapperPrimary = (props) => {
  return <section className="call-to-action bg-primary sep-top-md sep-bottom-md">
        <div className="container">
          {props.children}
        </div>
      </section>;
};

export const WrapperGray = (props) => {
  return <section className="call-to-action section-gray sep-top-md sep-bottom-md">
        <div className="container">
          {props.children}
        </div>
      </section>;
};

export default Wrapper;