/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ManufacturingOperationDateConstraints {
  constructor(_date, type) {
    if (_date) {
      this['date'] = _date;
    }
    if (type) {
      this['type'] = type;
    }


  }

  static typeDescription() {
    return "ManufacturingOperationDateConstraints";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ManufacturingOperationDateConstraints();
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'String');
      }
      if (data.hasOwnProperty('type')) {
        obj['type'] = ApiClient.convertToType(data['type'], 'String');
      }
    }
    return obj;
  }


  date = "";

  type = "BeginBefore";




  getDate() {
    return this.date;
  }

  setDate(_date) {
    this['date'] = _date;
  }

  getType() {
    return this.type;
  }

  setType(type) {
    this['type'] = type;
  }


  static TypeEnum = {
    "BeginBefore": "BeginBefore",
    "BeginAfter": "BeginAfter",
    "EndBefore": "EndBefore",
    "EndAfter": "EndAfter"
  };

}