import React from "react";
import { Label } from "reactstrap";
import FormGroup from "./FormGroup";
import FormControl from "./FormControl";

const Checkbox = (props) => {
  const groupProps = {
    disabled: props.disabled,
    className: props.className
  };
  if (props.validationState === "success") {
    groupProps.className = "has-success";
  }
  if (props.validationState === "warning") {
    groupProps.className = "has-warning";
  }
  if (props.validationState === "error") {
    groupProps.className = "has-error";
  }
  const checkProps = {
    checked: props.checked,
    onChange: props.onChange,
    disabled: props.disabled,
    name: props.name
  };
  return (
    <FormGroup {...groupProps} check>
      <Label check>
        <FormControl {...checkProps} type="checkbox" /> {props.children}
      </Label>
    </FormGroup>);

};

export default Checkbox;