import React from "react";

class Image extends React.Component {
  render() {
    const { props } = this;
    const { image = {} } = props;

    let att = {};

    if (props.preview) {
      att["data-src"] = image.preview;
    } else {
      att["data-srcset"] = image.srcset;
      att["data-sizes"] = props.sizes || "auto";
    }

    return (
      <img
        width={props.preview ? 200 : image.width}
        height={props.preview ? 200 : image.height}
        className={"lazyload img-fluid " + (props.className || "")}
        alt={image.alt}
        style={props.style}
        onClick={props.onClick}
        {...att} />);


  }
}

export default Image;