import React from 'react';
import { connect } from 'react-redux';
import UserApi from '../api/api/UserApi';

import authActions from './actions';

// import {withRequests} from '../Requests'

const userApi = new UserApi();
if (typeof localStorage !== 'undefined') {
  const apiKey = localStorage.getItem('apiKey');
  userApi.apiClient.authentications.api_key.apiKey = apiKey;
}

const AuthContext = React.createContext();

const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;

export const withAuth = (Component) => (props) => <AuthConsumer>
  {(authProps) => <Component {...Object.assign({}, props, authProps)} />}
</AuthConsumer>;

const mapStateToProps = (state) => {
  return {
    user: state.auth.user || {},
    customer: state.auth.customer || {},
    customerStocks: state.auth.customerStocks || {},
    requests: state.requests.filter((r) => r.id.startsWith('auth/'))
  };
};

const mapDispatchToProps = (dispatch) => {
  return authActions;
};

const mergeProps = (sProps, dProps, props) => {
  return {
    value: {
      auth: sProps,
      authActions: dProps
    },
    children: props.children
  };
};

class ProviderComponent extends React.Component {
  componentDidMount() {
    if (!this.props.value.auth.user || !this.props.value.auth.user.id) {
      this.props.value.authActions.init();
    }
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    const webLogin = (props.value.auth.user || {}).webLogin;
    const oWebLogin = (oProps.value.auth.user || {}).webLogin;

    if (webLogin && oWebLogin !== webLogin) {
      props.value.authActions.getCustomer().
      catch((err) => {
        props.value.authActions.saveCustomer({
          name: props.value.auth.user.firstName + ' ' + props.value.auth.user.lastName
        });
      });
    }
  }
  render() {
    const { props } = this;

    return <AuthProvider value={props.value}>
       {props.children}
    </AuthProvider>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProviderComponent);