/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class QuoteTechnicalContactIdsByQuotationIds {
  constructor(quotationId, contactIds) {
    if (quotationId) {
      this['quotationId'] = quotationId;
    }
    this['contactIds'] = contactIds || [];


  }

  static typeDescription() {
    return "QuoteTechnicalContactIdsByQuotationIds";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new QuoteTechnicalContactIdsByQuotationIds();
      if (data.hasOwnProperty('quotationId')) {
        obj['quotationId'] = ApiClient.convertToType(data['quotationId'], 'String');
      }
      if (data.hasOwnProperty('contactIds')) {
        obj['contactIds'] = ApiClient.convertToType(data['contactIds'], ['String']);
      }
    }
    return obj;
  }


  quotationId = undefined;

  contactIds = [];




  getQuotationId() {
    return this.quotationId;
  }

  setQuotationId(quotationId) {
    this['quotationId'] = quotationId;
  }

  getContactIds() {
    return this.contactIds;
  }

  setContactIds(contactIds) {
    this['contactIds'] = contactIds;
  }


}