function parseQuery(queryString) {
  var query = {};
  var pairs = (
  queryString[0] === "?" ? queryString.substr(1) : queryString).
  split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    const key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1] || "");
    if (!isNaN(value)) {
      value = parseFloat(value);
    }
    if (value.toLowerCase && value.toLowerCase() === "true") {
      value = true;
    }
    if (value.toLowerCase && value.toLowerCase() === "false") {
      value = false;
    }
    query[key] = value;
  }
  return query;
}

export default parseQuery;