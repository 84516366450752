/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class CategorizedComponentCommonNames {
  constructor(fr, en) {
    this['fr'] = fr || [];
    this['en'] = en || [];


  }

  static typeDescription() {
    return "CategorizedComponentCommonNames";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CategorizedComponentCommonNames();
      if (data.hasOwnProperty('fr')) {
        obj['fr'] = ApiClient.convertToType(data['fr'], ['String']);
      }
      if (data.hasOwnProperty('en')) {
        obj['en'] = ApiClient.convertToType(data['en'], ['String']);
      }
    }
    return obj;
  }


  fr = [];

  en = [];




  getFr() {
    return this.fr;
  }

  setFr(fr) {
    this['fr'] = fr;
  }

  getEn() {
    return this.en;
  }

  setEn(en) {
    this['en'] = en;
  }


}