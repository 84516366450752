import React from "react";

import "./index.css";

class ScrollTable extends React.Component {
  render() {
    const { props, state } = this;
    return (
      <div className={"scroll_table " + props.className}>
        <div
          className="scroll_table__corner"
          style={{ width: props.columnsWidth, height: props.headHeight }}>

          {props.corner}
        </div>
        <div
          className="scroll_table__head"
          ref={(el) => this.head = el}
          onWheel={(e) => {
            e.preventDefault();
            this.body.scrollTop += e.deltaY;
          }}
          style={{
            height: props.headHeight,
            width: "calc(100% - " + props.columnsWidth + "px)"
          }}>

          <div className="h-100">{props.head}</div>
        </div>
        <div
          className="scroll_table__columnns"
          ref={(el) => this.cols = el}
          onWheel={(e) => {
            e.preventDefault();
            this.body.scrollTop += e.deltaY;
          }}
          style={{
            width: props.columnsWidth,
            height: "calc(100% - " + props.headHeight + "px)"
          }}>

          {props.columns}
        </div>
        <div
          className="scroll_table__body"
          ref={(el) => this.body = el}
          onScroll={(e) => {
            this.cols.scrollTop = e.target.scrollTop;
            this.head.scrollLeft = e.target.scrollLeft;
          }}
          style={{
            height: "calc(100% - " + props.headHeight + "px)",
            width: "calc(100% - " + props.columnsWidth + "px)"
          }}>

          {props.children}
        </div>
      </div>);

  }
}

export default ScrollTable;