import React from "react";
import Section from "marketing/components/Section";
import BigBadge from "marketing/components/BigBadge";
import { Image } from "Elements";

const PricingExperience = (props) => {
  const { data = {} } = props;
  const { title, benefits = [] } = data;
  if (!benefits.length) {
    return null;
  }
  return (
    <Section title={title}>
      {/* <span className="marketing_section__title">{title}</span> */}
      {benefits.map((benefit, i) => {
        const { title, text, image } = benefit;
        return (
          <div className="row mb-2" key={i}>
            <div className="col-sm-5 offset-sm-0 col-xl-4 offset-xl-1">
              <div
                className="w-100 bg-black-10 text-center p-2"
                style={{ height: 170, overflow: "hidden" }}>

                <Image image={image} className="h-100" />
              </div>
            </div>
            <div className="col-sm-7 col-lg-6 offset-lg-1 col-xl-5 d-flex flex-column justify-content-center">
              <div className="row pricingExperienceColor m-0 my-2 my-sm-0">
                <BigBadge className="col ">{i + 1}</BigBadge>
                <div className="col d-flex flex-column justify-content-center">
                  <span className="pricingExperience_Title mb-3 mt-2">
                    {title}
                  </span>
                  <span className="pricingExperience_Text">{text}</span>
                </div>
              </div>
            </div>
          </div>);

      })}
    </Section>);

};

export default PricingExperience;