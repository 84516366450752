import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalComponent = (props) => {
  let nProps = {
    isOpen: props.show,
    toggle: props.onHide,
    className: props.dialogClassName,
    size: props.size || props.bsSize
  };
  if (nProps.size === 'large') {
    nProps.size = 'lg';
  }
  return <Modal {...nProps}>
    {props.children}
  </Modal>;
};

ModalComponent.Header = ModalHeader;
ModalComponent.Title = (props) => <span className="modal-title">{props.children}</span>;
ModalComponent.Body = ModalBody;
ModalComponent.Footer = ModalFooter;

export default ModalComponent;