import React from "react";

import Section from "marketing/components/Section";
import GalleryPreview from "components/GalleryPreview";
import Content from "components/ContentEditor/Content";

class Capabilities extends React.Component {
  render() {
    const { props } = this;
    const { data = {}, page = {} } = props;
    const { title, images = [], lists = [] } = data;

    if (!lists.length || !title) {
      return null;
    }

    return (
      <Section
        title={title}
        className="capabilities lazyload"
        data-bg={"/images/marketing/embleme-" + page.id + ".jpg"}>

        <div className="row justify-content-center">
          <div className="col-sm-6 col-lg-4">
            <div className="capabilities_gallery">
              <GalleryPreview images={images} mosaic max="0" />
            </div>
          </div>
          <div className="col-sm-6 col-lg">
            <div className="row flex-wrap">
              {lists.map(({ title, capabilities }, i) =>
              <div key={i} className="col mb-3" style={{ minWidth: 300 }}>
                  <p>
                    <span className="capabilities_title">{title}</span>
                  </p>
                  <Content blocks={capabilities} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Section>);

  }
}

export default Capabilities;