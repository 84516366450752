import UserApi from "api/api/UserApi";
import { store } from "initStore";

import requestsActions from "Requests/actions";

const addRequest = requestsActions.add;
const userApi = new UserApi();

const actions = {
  getUser: () =>
  userApi.getUser().then((data) => {
    actions.setUser(data);
  }),
  getUsers: (opts) =>
  addRequest(
    "users/getUsers",
    userApi.getUsers(opts).then((users) => {
      users.forEach((user) => actions.setUser(user));
      return users;
    })
  ),
  setUser: (data) =>
  store.dispatch({
    type: "users/SET",
    data
  }),
  getUserName: (id = false) =>
  userApi.getUserById(id).then((data) => {
    const user = Object.assign({}, data, {
      name: [data.firstName, data.lastName].filter((s) => s).join(" "),
      id
    });
    console.log("getUserName", user);
    actions.setUser(user);
    return user;
  }),
  getProjects: () => userApi.getSpecifications(),
  getCustomer: (id) => userApi.getCustomerByID(id)
};

export default actions;