/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import CustomerStocksLocation from './CustomerStocksLocation';
import CustomerStocksReferenceStocks from './CustomerStocksReferenceStocks';

export default class CustomerStocksStocks {
  constructor(location, referenceStocks) {
    this['location'] = location || new CustomerStocksLocation();
    this['referenceStocks'] = referenceStocks || [];


  }

  static typeDescription() {
    return "CustomerStocksStocks";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CustomerStocksStocks();
      if (data.hasOwnProperty('location')) {
        obj['location'] = CustomerStocksLocation.constructFromObject(data['location']);
      }
      if (data.hasOwnProperty('referenceStocks')) {
        obj['referenceStocks'] = ApiClient.convertToType(data['referenceStocks'], { 'String': CustomerStocksReferenceStocks });
      }
    }
    return obj;
  }


  location = undefined;

  referenceStocks = {};




  getLocation() {
    return this.location;
  }

  setLocation(location) {
    this['location'] = location;
  }

  getReferenceStocks() {
    return this.referenceStocks;
  }

  setReferenceStocks(referenceStocks) {
    this['referenceStocks'] = referenceStocks;
  }


}