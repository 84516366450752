import React from "react";
import Content from "components/ContentEditor/Content";

const HtmlContent = ({ data }) =>
<div className="container">
    <div className="cms_content my-5">
      <Content blocks={data} />
    </div>
  </div>;


export default HtmlContent;