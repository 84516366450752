/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class AccessPermission {
  constructor() {


  }

  static typeDescription() {
    return "AccessPermission";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AccessPermission();
      if (data.hasOwnProperty('userId')) {
        obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
      }
      if (data.hasOwnProperty('accessLevel')) {
        obj['accessLevel'] = ApiClient.convertToType(data['accessLevel'], 'String');
      }
    }
    return obj;
  }


  userId = undefined;

  accessLevel = undefined;




  getUserId() {
    return this.userId;
  }

  setUserId(userId) {
    this['userId'] = userId;
  }

  getAccessLevel() {
    return this.accessLevel;
  }

  setAccessLevel(accessLevel) {
    this['accessLevel'] = accessLevel;
  }


  static AccessLevelEnum = {
    "no_access": "no_access",
    "read_only": "read_only",
    "read_and_write": "read_and_write",
    "full": "full"
  };

}