import React from "react";

import Section from "marketing/components/Section";
import { Image } from "Elements";

const OnlineSetup = (props) => {
  const { data = {}, page = {} } = props;
  const { id } = page;
  const { title, steps = [] } = data;
  if (!steps.length) {
    return null;
  }
  return (
    <Section className="product_process manufacturing_process">
      <h2 className="marketing_section__title">{title}</h2>
      <div className="product_process_steps">
        {steps.
        filter((s) => s.title).
        map((step, i) =>
        <div key={i}>
              <div className="mt-2 mb-md-5 mt-md-0 product_process__text">
                <div className="product_process__step_title">
                  <div className="product_process_step__badge flex-shrink-0">
                    {i + 1}
                  </div>
                  <span>{step.title}</span>
                </div>
                <span className="product_process__text_content">
                  {step.text}
                </span>
              </div>
              <Image image={step.image} className="order-3" />
              {/* <img
            src={
             "/images/marketing/" + id + "/onlineSetup/" + (i + 1) + ".svg"
            }
            alt=""
            /> */}
            </div>
        )}
      </div>
    </Section>);

};

export default OnlineSetup;

// import React from "react";

// import Section from "marketing/components/Section";
// import { Image } from "Elements";

// const Process = (props) => {
//   const { data = {} } = props;
//   const { title, steps = [] } = data;
//   if (!steps.length) {
//     return null;
//   }
//   return (
//     <Section className="manufacturing_process process" bottomRounded>
//       <h2 className="marketing_section__title" style={{ fontSize: "35px " }}>
//         {title}
//       </h2>
//       <div className="process_steps">
//         {steps.map((step, i) => {
//           const { title, text, image } = step;
//           return (
//             <div key={i}>
//               <div className="process_step__badge">{i + 1}</div>
//               <div className="m-3 mb-5">
//                 <div className="process__step_title">{title}</div>
//                 {text}
//               </div>
//               <Image image={step.image} />
//             </div>
//           );
//         })}
//       </div>
//     </Section>
//   );
// };

// export default Process;