/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class ComponentCategory {
  constructor(name, descriptionEn, descriptionFr) {
    if (name) {
      this['name'] = name;
    }
    if (descriptionEn) {
      this['descriptionEn'] = descriptionEn;
    }
    if (descriptionFr) {
      this['descriptionFr'] = descriptionFr;
    }


  }

  static typeDescription() {
    return "ComponentCategory";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ComponentCategory();
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('parentPath')) {
        obj['parentPath'] = ApiClient.convertToType(data['parentPath'], ['String']);
      }
      if (data.hasOwnProperty('descriptionEn')) {
        obj['descriptionEn'] = ApiClient.convertToType(data['descriptionEn'], 'String');
      }
      if (data.hasOwnProperty('descriptionFr')) {
        obj['descriptionFr'] = ApiClient.convertToType(data['descriptionFr'], 'String');
      }
      if (data.hasOwnProperty('imageUrl')) {
        obj['imageUrl'] = ApiClient.convertToType(data['imageUrl'], 'String');
      }
      if (data.hasOwnProperty('subCategories')) {
        obj['subCategories'] = ApiClient.convertToType(data['subCategories'], [ComponentCategory]);
      }
    }
    return obj;
  }


  name = "";

  parentPath = undefined;

  descriptionEn = "";

  descriptionFr = "";

  imageUrl = undefined;

  subCategories = undefined;




  getName() {
    return this.name;
  }

  setName(name) {
    this['name'] = name;
  }

  getParentPath() {
    return this.parentPath;
  }

  setParentPath(parentPath) {
    this['parentPath'] = parentPath;
  }

  getDescriptionEn() {
    return this.descriptionEn;
  }

  setDescriptionEn(descriptionEn) {
    this['descriptionEn'] = descriptionEn;
  }

  getDescriptionFr() {
    return this.descriptionFr;
  }

  setDescriptionFr(descriptionFr) {
    this['descriptionFr'] = descriptionFr;
  }

  getImageUrl() {
    return this.imageUrl;
  }

  setImageUrl(imageUrl) {
    this['imageUrl'] = imageUrl;
  }

  getSubCategories() {
    return this.subCategories;
  }

  setSubCategories(subCategories) {
    this['subCategories'] = subCategories;
  }


}